import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/dist/query';
import { MutationLifecycleApi } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { logError, logInfo } from 'src/utils/logUtils';
import {
  JoinSubmissionOptions,
  JoinSubmissionResponse,
} from './submissionApiTypes';

export const onSubmitJoinQueryStarted = async (
  props: JoinSubmissionOptions,
  api: MutationLifecycleApi<
    JoinSubmissionOptions,
    BaseQueryFn<
      string | FetchArgs,
      unknown,
      FetchBaseQueryError,
      {},
      FetchBaseQueryMeta
    >,
    JoinSubmissionResponse,
    'submissionApi'
  >
) => {
  const { correlationId } = props;
  const { queryFulfilled } = api;
  logInfo({
    message: 'sending POST /v1/sales/consumer-join',
    correlationId,
  });

  try {
    const { data: response } = await queryFulfilled;
    const submissionReferenceId = response.data;
    logInfo({
      message: 'POST /v1/sales/consumer-join succeeded',
      data: { submissionReferenceId },
      correlationId,
    });
  } catch (err) {
    logError({
      message: 'POST /v1/sales/consumer-join failed',
      data: { error: (err as any)?.error },
      correlationId,
    });
  }
};
