import { store } from 'src/store/store';
import { logApi, LogRequest } from '../services/log/logApi';
import dateUtils from './dateUtils';

// We use separate calls for different levels so no need to include it in the data
type LogData = Omit<LogRequest, 'logLevel'>;

/**
 * Convenience functions to write a log message. You can specify just a message or a LogDetails
 * structure. Returns some sort of promise resolving to the result of the logging API call. In
 * most cases you can just discard the return value. If you really want to make sure the log gets
 * sent, you could `await` the result.
 *
 * This Sumo Logic query will give you the message and the data, nicely deserialized back into JSON
 * (using config.loggingApiKey; i.e : "DtcJoin_nib"):
 *
 *   _sourcecategory="kaos/aws/lambda/nibnz-api-loggingservice-LoggingWebApi-master" AND (DtcJoin_nib)
 *   | json field=_raw "message.message" as message nodrop
 *   | json "message.CorrelationId" as CorrelationID
 *   | json "message.logLevel" as LogLevel
 *
 * Examples:
 *
 * logInfo('Something happened');
 * logWarning({
 *     message: 'Something interesting happened',
 *     data: {temperature: 21, dimensions: {size: 'small', weight: 'heavy'}},
 *     correlationId: '76817269145'
 *   });
 * logError({
 *     message: 'Something bad happened',
 *     data: errorDetails,
 *   });
 * await logError("Don't do anything else until this log is sent");
 */
export const logInfo = (logData: string | LogData) =>
  logAtLevel('Information', logData);
export const logWarning = (logData: string | LogData) =>
  logAtLevel('Warning', logData);
export const logError = (logData: string | LogData) =>
  logAtLevel('Error', logData);

const logAtLevel = (logLevel: string, logData: string | LogData) => {
  let logRequest: LogRequest;
  if (typeof logData === 'string') {
    logRequest = {
      message: logData,
      logLevel,
      data: {
        clientTimestamp: dateUtils.getCurrentDateTimeIso(),
      },
    };
  } else {
    logRequest = {
      message: logData.message,
      logLevel,
      data: {
        clientTimestamp: dateUtils.getCurrentDateTimeIso(),
        ...logData,
      },
    };
  }
  logLocally(logLevel, logRequest.message, logRequest.data);
  return store.dispatch(logApi.endpoints.log.initiate(logRequest));
};

function logLocally(logLevel: string, message: string, data: any) {
  if (process.env.NODE_ENV !== 'development') {
    return;
  }
  // When running development mode, log warnings and errors to the console.
  //
  if (logLevel === 'Warning') {
    console.info(message, data);
  }
  if (logLevel === 'Error') {
    console.info(message, data);
  }
}
