import { SecondaryButton } from '@nib-components/button';
import Heading from '@nib-components/heading';
import { EditSystemIcon } from '@nib/icons';
import config from 'src/utils/env';
import styled from 'styled-components';

const ButtonContainer = styled('div')`
  position: relative;
`;

const ButtonPositioner = styled('div')`
  position: absolute;
  right: 0;
  top: 0;
  width: 120px;
`;

/**
 * A heading that indicates a step in a multi-step form appearing on one page.
 * The `visible` prop indicates whether the form for this step is visible; the `disabled` prop
 * will make the heading appear as greyed out.
 * If an onEdit handler is passed in, an 'Edit' button will be displayed by the step unless
 * it is disabled.
 */
const StepHeading = ({
  // Controls whether the content is displayed
  visible,
  // Controls whether the heading shows as greyed-out. If not given, works off the 'visible' prop.
  disabled,
  // If supplied, shows an "Edit" button which emits this event when clicked (unless disabled)
  onEdit,
  children,
}: React.PropsWithChildren<{
  visible: boolean;
  disabled?: boolean;
  onEdit?: () => void;
}>) => {
  const style = visible || !disabled ? {} : { opacity: '40%' };
  const attrs = visible ? {} : { 'aria-disabled': 'true' };
  return (
    <ButtonContainer>
      <Heading
        component="h2"
        size={{ xs: 3, md: 2 }}
        fontType="serif"
        style={style}
        color={config.brand.customisation?.headersColor}
        {...attrs}
      >
        {children}
        {onEdit && !disabled && !visible && (
          <ButtonPositioner>
            <SecondaryButton
              onClick={onEdit}
              iconPlacement="left"
              icon={EditSystemIcon}
              disabled={disabled}
              size="small"
              type="button"
              fullWidth
            >
              Edit
            </SecondaryButton>
          </ButtonPositioner>
        )}
      </Heading>
    </ButtonContainer>
  );
};

export default StepHeading;
