import { generateCorrelationId } from 'src/utils/correlationUtils';
import { logError } from 'src/utils/logUtils';
import AccordionItem from './AccordionItem';
import AccordionPanel from './AccordionPanel';
import RichTextPanel from './RichTextPanel';
import SimpleTable from './SimpleTable';
import TextLink from './TextLink';
import {
  isAccordionItem,
  isAccordionPanel,
  isRichTextPanel,
  isSimpleTable,
  isTextLink,
} from './typeGuards';
import { ContentfulNodeProps } from './types';

/**
 * Render a Contentful content node
 */
const ContentfulNode = (props: ContentfulNodeProps) => {
  if (isRichTextPanel(props)) {
    return <RichTextPanel {...props} />;
  }

  if (isTextLink(props)) {
    return <TextLink {...props} />;
  }

  if (isAccordionPanel(props)) {
    return <AccordionPanel {...props} />;
  }

  if (isAccordionItem(props)) {
    return <AccordionItem {...props} />;
  }

  if (isSimpleTable(props)) {
    return <SimpleTable {...props} />;
  }

  // If we can't render it yet, send a log message and render nothing
  logError({
    message: 'Render Important Information failed',
    data: { error: `contentType: ${props.sys.contentType.sys.id}` }, // record the content type we failed to render
    correlationId: generateCorrelationId(),
  });
  return null;
};

export default ContentfulNode;
