import { PrimaryButton } from '@nib-components/button';
import { FormControl } from '@nib-components/form-control';
import Textbox from '@nib-components/textbox';
import { ChevronRightSystemIcon } from '@nib/icons';
import { Stack } from '@nib/layout';
import { useField, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useLazyValidateMembershipNumberQuery } from 'src/services/membershipValidation/membershipValidationApi';
import membershipValidationApiUtils from 'src/services/membershipValidation/membershipValidationApiUtils';
import { BuyNowFormData } from 'src/types/BuyNowForm';
import config from 'src/utils/env';
import { validateExternalMemberNumber } from 'src/utils/validation/validation';
import { AnyObject, TestConfig } from 'yup';
import MarkdownAlert from '../MarkdownAlert';

const content = config.brand.content.buyNow.step2!;

// Convert validator to function we can use to check whether to submit the form
const curry = (fn: (message: string) => TestConfig<string, AnyObject>) => {
  const test = fn('message').test as (s: string) => boolean;
  return (value: string) => test(value);
};
const isMembershipNumberValid = curry(validateExternalMemberNumber);

interface ExternalMemberNumberFieldProps {
  label: string;
  name: string;
  setSuccess: (success: boolean) => void;
}

const ExternalMemberNumberField = (props: ExternalMemberNumberFieldProps) => {
  const { label, name, setSuccess } = props;
  const [{ onChange, ...field }, meta] = useField(name);
  const {
    setFieldValue,
    setFieldTouched,
    validateForm,
    submitForm,
    values: { applicantExtraDetails, externalMemberApplicant },
  } = useFormikContext<BuyNowFormData>();

  // SHould we submit the form? THis is set true after a successful
  // validation response, so we move on to the next step in the form.
  const [shouldSubmit, setShouldSubmit] = useState(false);

  // Should we show the "API is down" message?
  const [showError, setShowError] = useState(false);

  // Should we show the "Invalid membership number" message?
  const [showInvalid, setShowInvalid] = useState(false);

  // The contents of the member number field; it only goes into
  // form state after we've validated it against the API
  const [membershipNumber, setMembershipNumber] = useState<string>(field.value);

  // The query to validate a member number.
  const [validateMembershipNumber, { isFetching }] =
    useLazyValidateMembershipNumberQuery();

  // Find the applicant that has been selected, if any
  const selectedApplicant = applicantExtraDetails.find(
    (a) => a.id === externalMemberApplicant
  );

  // When we change the field contents, blank out the validated
  // member number.
  useEffect(() => {
    if (
      membershipValidationApiUtils.formatMembershipNumber(membershipNumber) !==
      membershipValidationApiUtils.formatMembershipNumber(field.value)
    ) {
      setFieldValue(field.name, '');
      setFieldTouched(field.name, false);
      setSuccess(false);
      setShowError(false);
      setShowInvalid(false);
    }
  }, [
    membershipNumber,
    field.value,
    field.name,
    setFieldValue,
    setFieldTouched,
    setSuccess,
  ]);

  // When we change the validated number contents, re-validate the form.
  useEffect(() => {
    if (field.value) {
      validateForm();
    }
  }, [field.value, validateForm]);

  // When we've successfully set the validated member number into the form,
  // submit the form so we move on to the next step.
  // We need to do this asynchronously; if we try and do it in the submit
  // button click handler, the value won't be set into the form yet.
  useEffect(() => {
    if (shouldSubmit) {
      setSuccess(true);
      submitForm();
    }
  }, [shouldSubmit, submitForm, setSuccess]);

  // Find the error message to display.  We can't get this from the form
  // validation, as the form validation validates the value that is in
  // the form, and we want to validate the value of the field stored in
  // membershipNumber instead.
  let error: string | undefined;
  if (!membershipNumber) {
    error = config.brand.content.validation.externalMemberNumber.required;
  } else if (!isMembershipNumberValid(membershipNumber)) {
    error = config.brand.content.validation.externalMemberNumber.format;
  }

  return (
    <Stack space={5}>
      <FormControl
        label={label}
        id={field.name}
        name={field.name}
        valid={false}
        validated={meta.touched && !isFetching && (!!error || showInvalid)}
        error={error}
      >
        <Textbox
          {...field}
          type="tel"
          inputmode="numeric"
          maxLength={19} // BOA has 50 char limit, even tho actual valid numbers are much shorter
          value={membershipNumber}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            // Set the number into local state; we only add it into form state once it has been validated
            setMembershipNumber(e.target.value);
          }}
          onBlur={() => {
            setFieldTouched(field.name, true);
            // Do not set the value into form state yet; this happens after
            // successful API validation
          }}
        />
      </FormControl>
      {showInvalid && !isFetching && (
        <MarkdownAlert
          type="error"
          variation="soft"
          content={content.externalMemberNumberInvalidInfoText}
        />
      )}
      {showError && !isFetching && (
        <MarkdownAlert
          type="info"
          variation="soft"
          content={content.externalMemberNumberErrorInfoText}
        />
      )}
      <PrimaryButton
        type="button"
        color="dark"
        icon={ChevronRightSystemIcon}
        iconPlacement="right"
        disabled={isFetching}
        isLoading={isFetching}
        onClick={() => {
          // Touch the field so we display any validation errors
          setFieldTouched(field.name);
          // Reset the various messages
          setShowError(false);
          setShowInvalid(false);
          setSuccess(false);
          if (!selectedApplicant) {
            return;
          }
          if (!membershipNumber) {
            return;
          }
          if (!isMembershipNumberValid(membershipNumber)) {
            validateForm();
            return;
          }
          const options =
            membershipValidationApiUtils.createValidateExternalMemberNumberOptions(
              selectedApplicant.surname,
              selectedApplicant.dateOfBirth,
              membershipNumber
            );
          validateMembershipNumber(options, true)
            .unwrap()
            .then((response) => {
              if (response.data.valid) {
                setFieldValue(field.name, response.data.membershipNumber, true);
                setShouldSubmit(true);
              } else {
                setShowInvalid(true);
              }
            })
            .catch((_err) => {
              setShowError(true);
            });
        }}
      >
        {content.nextButtonText}
      </PrimaryButton>
    </Stack>
  );
};

export default ExternalMemberNumberField;
