import {
  ValidateSpecialOfferApplicant,
  ValidateSpecialOfferProduct,
  ValidateSpecialOfferRequest,
  ValidateSpecialOfferResponse,
} from 'src/services/join/contentfulApiTypes';
import {
  JoinApiBrand,
  JoinApiCartApplicant,
  JoinApiCartDetails,
  JoinApiGetQuoteOptions,
  JoinApiJourneyStage,
  JoinApiPaymentFrequency,
  JoinApiRequestCallbackOptions,
} from 'src/services/join/joinApiTypes';
import { PriceApiFetchPriceResponse } from 'src/services/price/priceApiTypes';
import priceApiUtils from 'src/services/price/priceApiUtils';
import { FormPage } from 'src/types/FormPage';
import {
  ApplicantDetails,
  PaymentFrequency,
  PolicyDetails,
  QuoteSession,
} from 'src/types/QuoteSession';
import { generateCorrelationId } from './correlationUtils';
import config from './env';
import { getAdviserDetails, getJoinId } from './localStorageUtils';
import { getProductByCode } from './productUtils';

export const createCartDetails = (
  quoteSession: QuoteSession,
  priceData?: PriceApiFetchPriceResponse
): JoinApiCartDetails => {
  const totalPrice = priceApiUtils.getPolicyTotalPrice(priceData);
  const cartDetails: JoinApiCartDetails = {
    brand: getJoinApiBrand(),
    journeyCurrentStage: getJourneyCurrentStage(quoteSession),
    totalPrice: totalPrice,
    paymentFrequency: getJoinApiPaymentFrequency(
      quoteSession.paymentDetails.frequency
    ),
  };
  if (priceData) {
    cartDetails.applicants = createJoinApiApplicants(quoteSession, priceData);
  }
  // If we are on AACN, or on AA with a resumed quote from AACN then we need to add an uan.
  const adviserUan = getAdviserUan(quoteSession);
  if (adviserUan) {
    cartDetails.uan = adviserUan;
  }

  return cartDetails;
};

const createJoinApiApplicants = (
  quoteSession: QuoteSession,
  priceData?: PriceApiFetchPriceResponse
): JoinApiCartApplicant[] => {
  return quoteSession.applicantDetails.map((a) =>
    createQuoteApplicant(a, quoteSession, priceData)
  );
};

const createQuoteApplicant = (
  applicantDetails: ApplicantDetails,
  quoteSession: QuoteSession,
  priceData?: PriceApiFetchPriceResponse
): JoinApiCartApplicant => {
  const applicantExtraDetails =
    quoteSession.applicantExtraDetails[applicantDetails.id];
  const policyDetails = quoteSession.memberPolicyDetails[applicantDetails.id];

  const hospitalProduct = getSelectedHospitalProduct(
    policyDetails.hospitalProductCode
  );
  const everydayProduct = getSelectedEveryDayProduct(
    policyDetails.everydayProductCode
  );

  const quoteApplicant: JoinApiCartApplicant = {
    firstName: applicantDetails.firstName,
    lastName: applicantExtraDetails.surname,
    gender: applicantDetails.gender,
    email: applicantExtraDetails.email,
    phoneNumber: applicantDetails.phone,
    isSmoker: applicantDetails.smoker === 'Yes',
    age: parseInt(applicantDetails.age),
    price: priceData
      ? priceApiUtils.getApplicantTotalPrice(
          applicantDetails,
          quoteSession,
          priceData
        )
      : 0,
    isPolicyOwner: applicantDetails.isPolicyOwner,
    hospitalProduct: hospitalProduct,
    hospitalExcess: hospitalProduct ? parseInt(policyDetails.excess) : null,
    everydayProduct: everydayProduct,
    nonPharmacPlusCoverLimit: hospitalProduct
      ? parseInt(policyDetails.nonPharmacPlus)
      : null,
  };

  return quoteApplicant;
};

const getSelectedEveryDayProduct = (
  everydayProductCode: string | undefined
) => {
  if (everydayProductCode) {
    const product = getProductByCode(everydayProductCode);
    if (product) {
      return product.productDetails.name;
    } else {
      throw new Error(
        'Failed to get selected everyday product code even though user has selected product'
      );
    }
  }
  return null;
};

const getSelectedHospitalProduct = (
  hospitalProductCode: string | undefined
) => {
  if (hospitalProductCode) {
    const product = getProductByCode(hospitalProductCode);
    if (product) {
      return product.productDetails.name;
    } else {
      throw new Error(
        'Failed to get selected hospital product code even though user has selected product'
      );
    }
  }
  return null;
};

const getJoinApiPaymentFrequency = (
  frequency: PaymentFrequency
): JoinApiPaymentFrequency => {
  const mapping: Record<PaymentFrequency, JoinApiPaymentFrequency> = {
    Weekly: 'Weekly',
    Fortnightly: 'Fortnightly',
    Monthly: 'Monthly',
    Quarterly: 'Quarterly',
    'Half Yearly': 'HalfYearly',
    Yearly: 'Yearly',
  };
  return mapping[frequency];
};

const getAdviserUan = (quoteSession: QuoteSession) => {
  // If we are on AACN ... or on AA with a resumed quote from AACN ...
  // then the adviserDetails with an uan should be in the QuoteSession
  //
  if (quoteSession.adviserDetails) {
    return quoteSession.adviserDetails.uan;
  }

  // If there are no adviserDetails in the QuoteSession ... there is still a chance
  // that there may be some adviserDetails with an uan that we stored in local storage in AA
  // when we resumed a quote from AACN.  If there are ... then use them.
  //
  const savedAdviserDetails = getAdviserDetails();
  if (savedAdviserDetails) {
    return savedAdviserDetails.uan;
  }
  // Otherwise, this is a "normal" join ... nib or AA ... with no adviser
  return null;
};

export const createValidateSpecialOfferRequest = (
  quoteSession: QuoteSession
): ValidateSpecialOfferRequest => {
  const request: ValidateSpecialOfferRequest = {
    applicants: quoteSession.applicantDetails.map((a) => {
      const policyDetails = quoteSession.getApplicantPolicyDetails(a.id);
      const applicant: ValidateSpecialOfferApplicant = {
        id: a.id,
        age: +a.age,
        products: getSpecialOfferProducts(policyDetails),
      };
      return applicant;
    }),
  };
  // If an applicant has no products (a guardian) then don't include
  // the applicant in the request to validate eligibility as this
  // will cause the API endpoint to return an error
  //
  request.applicants = request.applicants.filter((a) => a.products.length);
  return request;
};

const getSpecialOfferProducts = (
  policyDetails: PolicyDetails
): ValidateSpecialOfferProduct[] => {
  const products: ValidateSpecialOfferProduct[] = [];
  if (policyDetails.hospitalProductCode) {
    products.push({ code: policyDetails.hospitalProductCode });
    if (+policyDetails.nonPharmacPlus > 0) {
      products.push(
        getNonPharmacPlusProduct(policyDetails.hospitalProductCode)
      );
    }
  }
  if (policyDetails.everydayProductCode) {
    products.push({ code: policyDetails.everydayProductCode });
  }
  return products;
};

export const getNonPharmacPlusProduct = (
  hospitalProductCode: string
): ValidateSpecialOfferProduct => {
  const hospitalProductConfig = getProductByCode(hospitalProductCode);
  if (!hospitalProductConfig) {
    throw Error(
      `getNonPharmacPlusProduct: unable to find product config for ${hospitalProductCode}`
    );
  }
  if (!hospitalProductConfig.nonPharmacPlusProductCode) {
    throw Error(
      `getNonPharmacPlusProduct product config for ${hospitalProductCode} has no nonPharmacPlusProductCode`
    );
  }
  return { code: hospitalProductConfig?.nonPharmacPlusProductCode };
};

export const isEligibleForSpecialOffer = (
  applicantId: string,
  validateSpecialOfferResponse: ValidateSpecialOfferResponse | undefined
): boolean => {
  if (!validateSpecialOfferResponse) {
    return false;
  }
  const outcome = validateSpecialOfferResponse.data.applicantOutcomes.find(
    (o) => o.id === applicantId
  );
  if (!outcome) {
    // This is normal for the case of guardians who have no selected
    // products and are not included in the request to validate eligibility
    //
    return false;
  }
  return outcome.isEligible;
};

export const createRequestCallbackOptions = (
  name: string,
  phoneNumber: string,
  preferredCallbackDay?: string,
  preferredCallbackTimeRange?: string,
  notes?: string
): JoinApiRequestCallbackOptions => {
  const options: JoinApiRequestCallbackOptions = {
    requestDetails: {
      brand: getJoinApiBrand(),
      name,
      phoneNumber,
      preferredCallbackDay,
      preferredCallbackTimeRange,
      notes,
    },
    joinId: getJoinId(),
    correlationId: generateCorrelationId(),
  };
  return options;
};

const getJourneyCurrentStage = (
  quoteSession: QuoteSession
): JoinApiJourneyStage => {
  // JourneyCurrentStage represents the highest step the user has
  // "reached".  This will be one beyond the higest step they have "completed".
  //
  // For example:
  //   if completed "About You" then current stage is "ChooseYourCover"
  //   if completed "Buy Now" then current stage is "PurchaseCompleted"
  //
  if (quoteSession.completedPages.includes(FormPage.BuyNow)) {
    return 'PurchaseCompleted';
  }
  if (quoteSession.completedPages.includes(FormPage.TailorYourQuote)) {
    return 'BuyNow';
  }
  if (quoteSession.completedPages.includes(FormPage.ChooseYourCover)) {
    return 'TailorYourQuote';
  }
  if (quoteSession.completedPages.includes(FormPage.AboutYou)) {
    return 'ChooseYourCover';
  }
  if (quoteSession.completedPages.length === 0) {
    return 'AboutYou';
  }
  throw new Error(
    'getJourneyCurrentStage() called with unexpected completedPages: ' +
      quoteSession.completedPages
  );
};

export const createJoinApiGetQuoteOptions = (
  joinId: string,
  quoteIndex: number
): JoinApiGetQuoteOptions => {
  const options: JoinApiGetQuoteOptions = {
    joinId,
    quoteIndex,
  };
  return options;
};

export const getJoinApiBrand = (): JoinApiBrand => {
  return config.brand.joinApiBrand;
};
