import React from 'react';

type ApplicantIdState = {
  applicantId: string | null;
};

const defaultState: ApplicantIdState = {
  applicantId: null,
};

export const ApplicantIdContext = React.createContext(defaultState);
