import {
  aanz,
  colorBrandBase,
  colorLightest,
  nib,
} from '@nib-components/theme';
import {
  reWriteFeatureToggleValueByQueryString,
  setPropertiesToBoolean,
} from '@nib/nz-feature-flags';
import mergeDeepRight from 'ramda/src/mergeDeepRight';

import aaProductConfig from 'src/brands/AA/Data/productConfig.json';
import aaContent from '../brands/AA/Data/content.json';
import aacnContent from '../brands/AACentre/Data/content.json';

import nibProductConfig from 'src/brands/nib/Data/productConfig.json';
import nibContent from '../brands/nib/Data/content.json';

import { ApplicationConfig } from 'src/types/ApplicationConfig';
import { ProductConfig } from 'src/types/ProductConfig';
import configOverrides from '../config-override.json';
import configBase from '../config.json';
import BrandConfig from '../types/BrandConfig';

const baseUrl = process.env.PUBLIC_URL || '/';

export const getBrandConfig = (brand: string): BrandConfig => {
  const defaultFeatureSwitches = {
    hasDirectDebitDiscount: false,
    hasMemberProducts: false,
    hasMemberDiscount: false,
    hasAdviser: false,
    useNibWelcomeScreen: false,
  };
  switch (brand) {
    case 'nib':
      return {
        content: nibContent,
        theme: nib,
        customisation: {
          pageBackgroundColor: {
            light: colorLightest,
          },
        },
        productConfig: nibProductConfig as ProductConfig[],
        priceApiBrand: 'Nib',
        joinApiBrand: 'DTCJoin',
        ...defaultFeatureSwitches,
        hasDirectDebitDiscount: true,
        useNibWelcomeScreen: true,
        billingServiceBrand: 'DTCNewJoin',
        baseUrl,
      };
    case 'AA':
      return {
        content: aaContent,
        theme: aanz,
        productConfig: aaProductConfig as ProductConfig[],
        priceApiBrand: 'AA',
        joinApiBrand: 'AAJoin',
        ...defaultFeatureSwitches,
        hasMemberProducts: true,
        hasMemberDiscount: true,
        billingServiceBrand: 'AANewJoin',
        customisation: {
          headersColor: colorBrandBase,
        },
        baseUrl,
      };
    case 'AACentre':
      return {
        content: aacnContent,
        theme: aanz,
        // Shares product/benefit config with AA brand
        productConfig: aaProductConfig as ProductConfig[],
        priceApiBrand: 'AA',
        joinApiBrand: 'AACentre',
        ...defaultFeatureSwitches,
        hasMemberProducts: true,
        hasMemberDiscount: true,
        hasAdviser: true,
        billingServiceBrand: 'AACNNewJoin',
        customisation: {
          headersColor: colorBrandBase,
        },
        baseUrl,
      };
  }

  throw new Error(`No matching brand found for ${brand}`);
};

const config: any = mergeDeepRight(configBase, configOverrides);

setPropertiesToBoolean(config);
reWriteFeatureToggleValueByQueryString(config, window.location.search);

// @ts-ignore - Doesn't like setting a value on a MergeDeepObject, can ignore since we specify type below
config.brand = getBrandConfig(config.variation);

export default config as ApplicationConfig;
