import { Box } from '@nib/layout';
import RichText from '../RichText';
import { ContentfulRichTextPanelProps } from '../types';

const RichTextPanel = (props: ContentfulRichTextPanelProps) => {
  return (
    <Box>
      <RichText document={props.fields.content} />
    </Box>
  );
};

export default RichTextPanel;
