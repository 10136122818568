import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { FormPage } from 'src/types/FormPage';
import config from 'src/utils/env';
import { getFormPageByIndex } from 'src/utils/formPageUtils';
import { prepareHeaders } from '../serviceHelpers';
import {
  BillingServiceHppRegisterOptions,
  BillingServiceHppRegisterResponse,
} from './billingApiTypes';
import { onHppRegisterStarted } from './eventHandlers';

const billingApi = createApi({
  reducerPath: 'billingApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.billingServiceApiRoot}`,
    prepareHeaders,
  }),
  endpoints(builder) {
    return {
      hppRegister: builder.mutation<
        BillingServiceHppRegisterResponse,
        BillingServiceHppRegisterOptions
      >({
        query: (request) => {
          return {
            url: `/api/billing/${config.brand.billingServiceBrand}/cc/hpp-registration`,
            method: 'POST',
            headers: {
              'x-correlation-id': request.correlationId,
            },
            body: {
              UrlFail: `${window.location.origin}${
                config.brand.baseUrl === '/' ? '' : config.brand.baseUrl
              }${getFormPageByIndex(FormPage.BuyNow)!.path}`,
              UrlSuccess: `${window.location.origin}${
                config.brand.baseUrl === '/' ? '' : config.brand.baseUrl
              }/payment-redirect`,
              TransactionDataLine1: '-', // Content does not appear if we do not send anything for line 1
              TransactionDataLine2: request.paymentDate,
              TransactionDataLine3: request.premium,
            },
          };
        },
        onQueryStarted: onHppRegisterStarted,
        transformResponse: (
          response: BillingServiceHppRegisterResponse,
          meta
        ) => {
          return {
            ...response,
            windcaveUrl: meta?.response?.headers.get('location') ?? '',
          };
        },
      }),
    };
  },
});

export const { useHppRegisterMutation } = billingApi;

export { billingApi };
