import Heading from '@nib-components/heading';
import Card from '@nib/card';
import { Stack } from '@nib/layout';
import React from 'react';
import { BuyNowAdviserDeclarationContent } from 'src/types/Content/BuyNowContent';
import config from 'src/utils/env';
import styled from 'styled-components';
import BrandedCard from '../BrandedCard';
import BrandedModal from '../BrandedModal';
import FormCheckbox from '../form/FormCheckbox';
import FormRadioGroup from '../form/FormRadioGroup';
import MarkdownContent from '../MarkdownContent';

const MarkdownContainer = styled('div')`
  p {
    font-weight: 400;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

interface AdviserDeclarationProps {
  content: BuyNowAdviserDeclarationContent;
  /** The fieldnames to populate.  Will be suffixed with ".group.n.question.n" */
  prefix: string;
}

const AdviserDeclaration = (props: AdviserDeclarationProps) => {
  const { content, prefix } = props;
  const [showModal, setShowModal] = React.useState<string | undefined>();

  // Respond to events in markdown links
  React.useEffect(() => {
    const showModal = (name: string) => () => setShowModal(name);
    content.modals.forEach((modal) => {
      window.addEventListener(modal.linkEvent, showModal(modal.linkEvent));
    });
    return () => {
      content.modals.forEach((modal) => {
        window.removeEventListener(modal.linkEvent, showModal(modal.linkEvent));
      });
    };
  }, [content.modals]);

  return (
    <>
      <Stack space={5}>
        {content.subtitle && (
          <Heading color={config.brand.customisation?.headersColor} size={3}>
            {content.subtitle}
          </Heading>
        )}
        {content.groups.map((group, gi) => (
          <BrandedCard padding={6}>
            <Card.Content title={group.title ?? ''}>
              {group.questions.map((question, qi) => {
                const label = (
                  <MarkdownContainer>
                    <MarkdownContent
                      content={question.label}
                      linkEvent={question.linkEvent}
                    />
                  </MarkdownContainer>
                );
                // No options given; show the label as markdown with no field
                if (question.options === undefined) {
                  return label;
                }
                // Multiple options given; return them as a radio group
                if (Object.values(question.options).length > 1) {
                  return (
                    <FormRadioGroup
                      label={label as unknown as string}
                      name={`${prefix}.group.${gi}.question.${qi}`}
                      options={question.options}
                    />
                  );
                }
                // Single option; return as a checkbox
                return (
                  <>
                    {label}
                    <FormCheckbox
                      name={`${prefix}.group.${gi}.question.0`}
                      label={Object.values(question.options)[0]!}
                      value={Object.keys(question.options)[0]}
                    />
                  </>
                );
              })}
            </Card.Content>
          </BrandedCard>
        ))}
      </Stack>
      {content.modals.map((modal) => (
        <BrandedModal
          visible={showModal === modal.linkEvent}
          onClose={() => setShowModal(undefined)}
          title={modal.title}
        >
          <MarkdownContainer>
            <MarkdownContent content={modal.content} />
          </MarkdownContainer>
        </BrandedModal>
      ))}
    </>
  );
};

export default AdviserDeclaration;
