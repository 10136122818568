import { format } from 'date-fns';
import { Markdown } from 'src/types/Content/Markdown';
import { PaymentFrequency } from 'src/types/QuoteSession';
import {
  getEarliestFirstPaymentDate,
  hasDoubleDeduction,
} from 'src/utils/buyNowUtils';
import MarkdownAlert from '../MarkdownAlert';
import { FIRST_PAYMENT_DATE_FORMAT } from './FirstPaymentDateField';

interface DoubleDeductionWarningProps {
  firstPaymentDate: string;
  paymentFrequency: PaymentFrequency;
  doubleDeductionAvoidableInfo: Markdown;
  doubleDeductionUnavoidableInfo: Markdown;
}

const DoubleDeductionWarning = ({
  firstPaymentDate,
  paymentFrequency,
  doubleDeductionAvoidableInfo,
  doubleDeductionUnavoidableInfo,
}: DoubleDeductionWarningProps) => {
  if (!hasDoubleDeduction(firstPaymentDate, paymentFrequency)) {
    return null;
  }
  const earliestDate = getEarliestFirstPaymentDate(paymentFrequency);
  const earliestDateString = format(earliestDate, FIRST_PAYMENT_DATE_FORMAT);
  if (hasDoubleDeduction(earliestDateString, paymentFrequency)) {
    return (
      <MarkdownAlert
        variation="soft"
        type="info"
        content={doubleDeductionUnavoidableInfo}
      />
    );
  } else {
    return (
      <MarkdownAlert
        variation="soft"
        type="info"
        content={doubleDeductionAvoidableInfo}
      />
    );
  }
};

export default DoubleDeductionWarning;
