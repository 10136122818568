import Copy from '@nib-components/copy';
import { Stack } from '@nib/layout';
import { ProductType } from 'src/types/ProductConfig';
import { PaymentFrequency } from 'src/types/QuoteSession';
import config from 'src/utils/env';
import {
  everydayProducts,
  hospitalProducts,
  isValidMemberProductCode,
} from 'src/utils/productUtils';
import { ProductCardList } from './ProductCardList';

const content = config.brand.content.chooseYourCover.step2;

interface ChoosePlanStepProps {
  /** Whether the user is a member or not */
  isMember: boolean | undefined;
  /** Which types of products we selected in step 1 */
  productTypes: ProductType[];
  hospitalProductCode?: string;
  everydayProductCode?: string;
  setHospitalProductCode: (productCode: string) => void;
  setEverydayProductCode: (productCode: string) => void;
  // Manages state of frequency select
  frequency: PaymentFrequency;
  setFrequency: (f: PaymentFrequency) => void;
}

const ChoosePlanStep = (props: ChoosePlanStepProps) => {
  const {
    isMember,
    productTypes,
    hospitalProductCode,
    everydayProductCode,
    setHospitalProductCode,
    setEverydayProductCode,
    frequency,
    setFrequency,
  } = props;

  // Find which products we want to display
  const validEverydayProducts = everydayProducts.filter((p) =>
    isValidMemberProductCode(isMember, p.productDetails.code)
  );
  const validHospitalProducts = hospitalProducts.filter((p) =>
    isValidMemberProductCode(isMember, p.productDetails.code)
  );

  const hasEveryday = productTypes.includes('Everyday');
  const hasHospital = productTypes.includes('Hospital');

  return (
    <Stack space={5}>
      {productTypes.length === 2 && (
        <Copy measure={true}>{content.bothInfoText}</Copy>
      )}
      {hasHospital && (
        <ProductCardList
          selected={hospitalProductCode}
          onSelect={(hospitalProductCode) => {
            setHospitalProductCode(hospitalProductCode);
          }}
          products={validHospitalProducts}
          frequency={frequency}
          setFrequency={setFrequency}
        />
      )}
      {hasEveryday && (
        <ProductCardList
          selected={everydayProductCode}
          onSelect={(everydayProductCode) => {
            setEverydayProductCode(everydayProductCode);
          }}
          products={validEverydayProducts}
          frequency={frequency}
          setFrequency={setFrequency}
        />
      )}
    </Stack>
  );
};

export default ChoosePlanStep;
