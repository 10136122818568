import { SavingsGraphicIcon } from '@nib/icons';
import InfoBox from '@nib/info-box';
import { Box, Stack } from '@nib/layout';
import { BuyNowFormDataItem } from 'src/types/BuyNowForm';
import config from 'src/utils/env';
import HasMemberRadioGroup from '../form/HasMemberRadioGroup';
import BottomButtons from '../navigation/BottomButtons';
import ExternalMemberApplicantField from './ExternalMemberApplicantField';
import ExternalMemberNumberField from './ExternalMemberNumberField';

const content = config.brand.content.buyNow.step2!;

interface MembershipStepProps {
  hasMember: boolean | undefined;
  applicants: BuyNowFormDataItem[];
  setSuccess: (success: boolean) => void;
}

// Wrapper without content, so we can unit test this more easily
const MembershipStepWrapper = (props: MembershipStepProps) => {
  return <MembershipStep {...props} content={content} />;
};

export const MembershipStep = (
  props: MembershipStepProps & { content: typeof content }
) => {
  const { hasMember, applicants, content, setSuccess } = props;

  // Convert to {id: "firstName surname"}
  const applicantEntries = applicants.map((a, i) => [
    a.id,
    `${a.firstName} ${a.surname}`,
  ]);
  applicantEntries.unshift(['', '']);
  const applicantOptions = Object.fromEntries(applicantEntries);

  return (
    <Box maxWidth="600px">
      <Stack space={5}>
        <HasMemberRadioGroup
          name="hasMember"
          label={content.hasMemberLabel}
          onAfterChange={() => {
            setSuccess(false);
          }}
        />
        <InfoBox icon={SavingsGraphicIcon}>{content.hasMemberInfoText}</InfoBox>
        {hasMember && (
          <ExternalMemberApplicantField
            name="externalMemberApplicant"
            externalMemberNumberName="externalMemberNumber"
            label={content.applicantSelectLabel}
            options={applicantOptions}
          />
        )}
        {hasMember && (
          <ExternalMemberNumberField
            name="externalMemberNumber"
            label={content.externalMemberNumberLabel}
            setSuccess={setSuccess}
          />
        )}
        {!hasMember && (
          // Only show the Save button if we've selected No.
          // If we've selected Yes, ExternalMemberNumberField will
          // show an identical Save button that will perform
          // validation before continuing.
          <BottomButtons
            submitButtonText={content.nextButtonText}
            submitButtonColor="dark"
            submitButtonSize="medium"
          />
        )}
      </Stack>
    </Box>
  );
};

export default MembershipStepWrapper;
