import { FieldsetFormControl } from '@nib-components/form-control';
import { FieldsetFormControlProps } from '@nib-components/form-control/dist/@types/FieldsetFormControl';
import RadioGroup from '@nib-components/radio-group';
import { FieldHookConfig, useField, useFormikContext } from 'formik';

export type HasMemberRadioGroupProps = Omit<
  FieldsetFormControlProps,
  'children'
> &
  FieldHookConfig<string> & {
    label: string;
    onAfterChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  };

const HasMemberRadioGroup = ({
  label,
  name,
  onAfterChange,
  ...otherProps
}: HasMemberRadioGroupProps) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField<string>({ name });

  // Radios are OK to immediately emit state changes to formik; no need
  // to keep local state as per text boxes

  // Transform the boolean state in the form into 'Yes' | 'No' to select the
  // appropriate radio, or leave both unselected if the user hasn't specified
  // an option yet.
  let value;
  if ((field.value as unknown as boolean) === true) {
    value = 'Yes';
  }
  if ((field.value as unknown as boolean) === false) {
    value = 'No';
  }

  return (
    <FieldsetFormControl
      id={field.name}
      name={field.name}
      label={label}
      valid={meta.error === undefined}
      validated={meta.touched}
      error={meta.error}
      hasNegativeFieldsetMargin={true}
      {...otherProps}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setFieldValue(field.name, e.target.value === 'Yes');
        if (onAfterChange) {
          onAfterChange(e);
        }
      }}
    >
      <RadioGroup
        {...field}
        value={value}
        options={{
          Yes: 'Yes',
          No: 'No',
        }}
      />
    </FieldsetFormControl>
  );
};

export default HasMemberRadioGroup;
