import { ProductType } from 'src/types/ProductConfig';
import { QuoteSession } from 'src/types/QuoteSession';
import config from 'src/utils/env';
import * as Yup from 'yup';

const messages = config.brand.content.validation;

const ChooseYourCoverSchema: Yup.ObjectSchema<
  Pick<QuoteSession, 'policyDetails'>
> = Yup.object().shape({
  policyDetails: Yup.object().shape({
    productTypes: Yup.array()
      .defined()
      .required(messages.planOptions.required)
      .min(1, messages.planOptions.required)
      .of(Yup.string<ProductType>().ensure()),
    hospitalProductCode: Yup.string().test(
      'hospitalProductCodeRequiredForHospitalPlan',
      messages.hospitalProductCode.required,
      function (value) {
        const { productTypes } = this.parent;
        return productTypes.includes('Hospital') ? Boolean(value) : true;
      }
    ),
    everydayProductCode: Yup.string().test(
      'everydayProductCodeRequiredForEverydayPlan',
      messages.everydayProductCode.required,
      function (value) {
        const { productTypes } = this.parent;
        return productTypes.includes('Everyday') ? Boolean(value) : true;
      }
    ),
    excess: Yup.string()
      .ensure()
      .when('productTypes', ([productTypes], schema) =>
        schema.test({
          name: 'excessRequiredForHospitalPlan',
          message: messages.excess.required, // not shown to user
          test: (excess) => {
            // If we have a hospital plan, we need an excess
            if (productTypes.find((p: string) => p === 'Hospital')) {
              return excess !== '';
            }
            return true;
          },
        })
      ),
    nonPharmacPlus: Yup.string()
      .ensure()
      .when('productTypes', ([productTypes], schema) =>
        schema.test({
          name: 'nonPharmacPlusRequiredForHospitalPlan',
          message: messages.nonPharmacPlus.required, // not shown to user
          test: (nonPharmacPlus) => {
            // If we have a hospital plan, we need a non-PHARMAC Plus option
            if (productTypes.find((p: string) => p === 'Hospital')) {
              return nonPharmacPlus !== '';
            }
            return true;
          },
        })
      ),
    productCodeToSelectedExcess: Yup.object(),
  }),
});

export default ChooseYourCoverSchema;
