import { PrimaryButton, SecondaryButton } from '@nib-components/button';
import Copy from '@nib-components/copy';
import { Inline, Stack } from '@nib/layout';
import BrandedModal from './BrandedModal';

export interface ResumeSessionModalProps {
  onResume: () => void;
  onStartAgain: () => void;
}

const ResumeSessionModal: React.FC<ResumeSessionModalProps> = ({
  onResume,
  onStartAgain: onStartOver,
}) => {
  return (
    <>
      <BrandedModal visible={true} title="Welcome Back" onClose={onResume}>
        <Stack space={{ xs: 4, md: 6 }}>
          <Copy>
            Would you like to resume your last quote or start a new quote?
          </Copy>
          <Inline space={2} collapseBelow="md">
            <SecondaryButton
              fullWidth={{ xs: true, md: false }}
              onClick={onStartOver}
            >
              Start again
            </SecondaryButton>
            <PrimaryButton
              fullWidth={{ xs: true, md: false }}
              onClick={onResume}
            >
              Resume
            </PrimaryButton>
          </Inline>
        </Stack>
      </BrandedModal>
    </>
  );
};

export default ResumeSessionModal;
