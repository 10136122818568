import { PrimaryButton } from '@nib-components/button';
import Heading from '@nib-components/heading';
import { EmailGraphicIcon, TickSystemIcon } from '@nib/icons';
import { Box, Stack } from '@nib/layout';
import Loader from '@nib/loader';
import { Formik } from 'formik';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuoteSession } from 'src/contexts/QuoteSessionProvider';
import { useSaveQuote } from 'src/store/hooks/useSaveQuote';
import config from 'src/utils/env';
import { SaveAndSendFormValuesSchema } from 'src/utils/validation/schema/EmailQuoteFormValuesSchema';
import BrandedModal from '../BrandedModal';
import FormCheckbox from '../form/FormCheckbox';
import FormTextbox from '../form/FormTextbox';
import MarkdownContent from '../MarkdownContent';
import BottomButtons from '../navigation/BottomButtons';

const content = config.brand.content.emailQuote;

interface QuoteSentProps {
  copyLinkUrl: string;
}

const QuoteSent = ({ copyLinkUrl }: QuoteSentProps) => {
  const [resumeUrlCopied, setResumeUrlCopied] = React.useState(false);

  return (
    <Box>
      <MarkdownContent content={content.sentDetail} />
      <PrimaryButton
        type="submit"
        size="large"
        fullWidth={{ xs: true, md: false }}
        icon={resumeUrlCopied ? TickSystemIcon : undefined}
        iconPlacement="left"
        onClick={() => {
          navigator.clipboard.writeText(copyLinkUrl);
          setResumeUrlCopied(true);
        }}
      >
        {resumeUrlCopied ? content.linkCopiedText : content.copyLinkButton}
      </PrimaryButton>
    </Box>
  );
};

interface EmailQuoteModalProps {
  onClose: () => void;
}

export interface SendAndSaveQuoteFormValues {
  firstName: string;
  email: string;
  hasAgreedToBeContacted: boolean;
}

const SaveAndSendQuoteModal = (props: EmailQuoteModalProps) => {
  const { onClose } = props;
  const [showSentQuote, setshowSentQuote] = React.useState(false);
  const [copyResumeUrl, setCopyResumeUrl] = React.useState<string>('');
  const quoteSession = useQuoteSession();
  const navigate = useNavigate();
  const location = useLocation();
  const saveQuote = useSaveQuote();

  const applicant = quoteSession.getNominatedOwner();
  const initialValues: SendAndSaveQuoteFormValues = {
    firstName: applicant.firstName,
    email: quoteSession.applicantExtraDetails[applicant.id].email,
    hasAgreedToBeContacted: true,
  };

  const handleSubmit = async (values: SendAndSaveQuoteFormValues) => {
    try {
      // send quote to back end and email
      const copyLinkUrl = await saveQuote(
        quoteSession,
        values.firstName,
        values.email,
        !values.hasAgreedToBeContacted
      );

      setCopyResumeUrl(copyLinkUrl ? copyLinkUrl : '');
      setshowSentQuote(true);
    } catch (error) {
      console.warn(
        'Failed to save quote for adviser ... navigating to error page.'
      );
      navigate(`/error?returnURL=${location.pathname}`);
    }
  };

  return (
    <BrandedModal
      visible={true}
      title={showSentQuote ? content.sentTitle : content.title}
      onClose={onClose}
      closeOnClick={showSentQuote ? true : false}
      closeOnEsc={showSentQuote ? false : true}
    >
      {showSentQuote ? (
        <QuoteSent copyLinkUrl={copyResumeUrl} />
      ) : (
        <>
          <MarkdownContent content={content.mainMessage} />
          <Heading color={config.brand.customisation?.headersColor} size={3}>
            {content.customerDetailsTitle}
          </Heading>
          <Box>
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={SaveAndSendFormValuesSchema}
              /* Without validateOnBlur=false, validation runs twice and the second time has the old value */
              validateOnBlur={false}
            >
              {({ isSubmitting, handleReset, handleSubmit }) => {
                return (
                  <form
                    id="emailQuote"
                    name="emailQuote"
                    onReset={handleReset}
                    onSubmit={handleSubmit}
                  >
                    <Stack space={6}>
                      <FormTextbox
                        label={content.nameLabel}
                        name="firstName"
                        tag="optional"
                        maxLength={30}
                        autoComplete={'given-name'}
                      />
                      <FormTextbox
                        label={content.emailLabel}
                        name="email"
                        tag="optional"
                        maxLength={50}
                        formMode="light"
                        type="email"
                        autoComplete={'email'}
                      />
                      <BottomButtons
                        disabled={isSubmitting}
                        cancelButtonText={content.cancelButtonText}
                        onCancel={() => onClose()}
                        submitButtonText={content.emailQuoteButton}
                        hideSubmitButtonIcon={false}
                        cancelButtonSize="medium"
                        submitButtonSize="medium"
                        submitButtonColor="dark"
                        submitButtonIcon={EmailGraphicIcon}
                      />
                      <FormCheckbox
                        name="hasAgreedToBeContacted"
                        label={content.agreedToBeContactedText}
                        formMode="white"
                      />
                      {isSubmitting && <Loader size="lg" />}
                    </Stack>
                  </form>
                );
              }}
            </Formik>
          </Box>
        </>
      )}
    </BrandedModal>
  );
};

export default SaveAndSendQuoteModal;
