import { BuyNowFormData } from 'src/types/BuyNowForm';
import {
  AdviserDeclaration,
  BuyNowStep,
  DeclarationDetails,
  PaymentDetails,
  PaymentFrequency,
  PaymentMethod,
  YourDetailsDeclaration,
} from 'src/types/QuoteSession';
import * as Yup from 'yup';
import config from '../../env';
import { showError, validateBankAccount } from '../bankAccountValidation';
import {
  optional,
  validateBirthdate120,
  validateBirthdate16,
  validateBirthdate75,
  validateDateFormat,
  validateDateNotInFuture,
  validateEmailAddress,
  validateExternalMemberNumber,
  validateNumeric,
  validatePaymentDetails,
  validatePersonalName,
} from '../validation';
import { ApplicantDetailsSchema } from './AboutYouSchema';
import PhoneSchema from './PhoneSchema';

const messages = config.brand.content.validation;

/**
 * Validation schemas for Buy Now page.
 * We combine the applicantDetails and applicantExtraDetails into one object,
 * as some validation rules depend on accessing the values of properties from
 * the other object.
 * Yup is much simpler to manage when dependant values are sibling properties
 * of the same object.
 * The buyNowStep property is also included so we can switch validations on
 * for the Declarations step as needed.
 */

export const BuyNowYourDetailsSchema = Yup.array()
  .defined()
  .of(
    // Has all the same validation as ApplicantDetails, but with additional fields for extra details.
    ApplicantDetailsSchema.shape({
      firstName: Yup.string()
        .ensure()
        .required(messages.name.required)
        .max(30, messages.name.maxLength)
        .test(validatePersonalName(messages.name.format)),
      surname: Yup.string()
        .defined()
        .required(messages.name.required)
        .max(30, messages.name.maxLength)
        .test(validatePersonalName(messages.name.format)),
      email: Yup.string()
        .ensure()
        .when('isPolicyOwner', ([isPolicyOwner], schema) =>
          // Required for policy owners
          isPolicyOwner
            ? schema
                .required(messages.email.required)
                .min(5, messages.email.maxLength)
            : schema
        )
        .max(50, messages.email.maxLength)
        .test(validateEmailAddress(messages.email.invalid)),
      dateOfBirth: Yup.string()
        .defined()
        .required(messages.dateOfBirth.required)
        .test(validateDateFormat(messages.dateOfBirth.format))
        .test(validateDateNotInFuture(messages.dateOfBirth.notInFuture))
        .test(validateBirthdate120(messages.dateOfBirth.over120))
        .when('isGuardian', ([isGuardian], schema) =>
          // Guardians must be >=16yo
          isGuardian
            ? schema.test(
                validateBirthdate16(messages.dateOfBirth.guardian16yo)
              )
            : schema
        )
        .when('hasHospitalCover', ([hasHospitalCover], schema) =>
          // Applicants with hospital cover must be <=75yo
          hasHospitalCover
            ? schema.test(
                validateBirthdate75(messages.dateOfBirth.hospitalcover75)
              )
            : schema
        ),
      addressLine1: Yup.string()
        .ensure()
        .when('isPolicyOwner', ([isPolicyOwner], schema) =>
          // Required for policy owners
          isPolicyOwner ? schema.required(messages.address.required) : schema
        )
        .max(60, messages.address.maxLength60),
      addressLine2: Yup.string()
        .ensure()
        .when('isPolicyOwner', ([isPolicyOwner], schema) =>
          // Required for policy owners
          isPolicyOwner ? schema.required(messages.address.required) : schema
        )
        .max(30, messages.address.maxLength30),
      addressLine3: Yup.string()
        .ensure()
        .when('isPolicyOwner', ([isPolicyOwner], schema) =>
          // Required for policy owners
          isPolicyOwner ? schema.required(messages.address.required) : schema
        )
        .max(30, messages.address.maxLength30),
      addressLine4: Yup.string()
        .ensure()
        .when('isPolicyOwner', ([isPolicyOwner], schema) =>
          // Required for policy owners
          isPolicyOwner
            ? schema
                .required(messages.address.required)
                .min(4, messages.address.minLength4)
            : schema
        )
        .max(4, messages.address.maxLength4)
        .test(validateNumeric(messages.address.numericPostcode)),
      addressLine5: Yup.string()
        .ensure()
        .when('isPolicyOwner', ([isPolicyOwner], schema) =>
          // Required for policy owners
          isPolicyOwner ? schema.required(messages.address.required) : schema
        )
        .max(30, messages.address.maxLength30),
      phone: PhoneSchema.when('isPolicyOwner', ([isPolicyOwner], schema) =>
        // Required for policy owners
        isPolicyOwner ? schema.required(messages.phone.required) : schema
      ),
      isGuardian: Yup.boolean().defined(),
      hasHospitalCover: Yup.boolean().defined(),
    })
  );

export const BuyNowYourDetailsDeclarationSchema: Yup.ObjectSchema<YourDetailsDeclaration> =
  Yup.object({
    hasReadEmailDeclaration: Yup.boolean()
      .default(false)
      // hasReadEmailDeclaration is only validated if the brand uses it; the field is hidden, but
      // if the field is empty the user can't proceed from About You
      .when({
        is: () => config.brand.hasAdviser,
        then: (schema) =>
          schema.equals(
            [true],
            config.brand.content.validation.hasReadEmailDeclaration.required
          ),
        otherwise: (schema) => schema,
      }),
  });

export const BuyNowDeclarationSchema = Yup.object<DeclarationDetails>().when(
  'buyNowStep',
  ([buyNowStep], notRequiredSchema) => {
    if (buyNowStep < BuyNowStep.Declaration) {
      return notRequiredSchema;
    }
    // On Declaration step and after, the declaration parts are required
    return notRequiredSchema.required().shape({
      hasInsurance: Yup.mixed<'Yes' | 'No'>()
        .nullable()
        .required(messages.hasInsurance.required),
      allApplicantsEligible: Yup.boolean().equals(
        [true],
        messages.allApplicantsEligible.required
      ),
      hasReadPreExistingConditions: Yup.boolean().equals(
        [true],
        messages.hasReadPreExistingConditions.required
      ),
      hasReadPrivacyPolicy: Yup.boolean().equals(
        [true],
        messages.hasReadPrivacyPolicy.required
      ),
    });
  }
);

export const BuyNowAdviserDeclarationSchema = (testStep: BuyNowStep) =>
  Yup.object<AdviserDeclaration>().when(
    'buyNowStep',
    ([buyNowStep], notRequiredSchema) => {
      if (buyNowStep < testStep) {
        return notRequiredSchema;
      }
      // On Declaration step and after, the declaration parts are required
      return notRequiredSchema.required().shape({
        group: Yup.array()
          .ensure()
          .of(
            Yup.object({
              question: Yup.array()
                .ensure()
                .of(
                  Yup.mixed()
                    .required(messages.adviserDeclaration!.required)
                    .test(
                      'validateAdviserDeclarationValue',
                      '',
                      (value, ctx) => {
                        // Checkboxes cannot be unchecked
                        if (value === false) {
                          return ctx.createError({
                            message: messages.adviserDeclaration!.required,
                          });
                        }
                        // Values with multiple options will return an error if the value starts with
                        // a space.  In this case, the space is removed and the value is substituted into
                        // the error message in place of token {value}
                        if (
                          typeof value === 'string' &&
                          value.substring(0, 1) === ' '
                        ) {
                          return ctx.createError({
                            message:
                              messages.adviserDeclaration!.invalid.replace(
                                /{value}/g,
                                value.trim()
                              ),
                          });
                        }
                        return true;
                      }
                    )
                ),
            })
          ),
      });
    }
  );

export const BuyNowPaymentDetailsSchema = Yup.mixed<PaymentDetails>()
  .required()
  .when('buyNowStep', ([buyNowStep], notRequiredSchema) => {
    if (buyNowStep < BuyNowStep.PaymentDetails) {
      return notRequiredSchema;
    }
    // On Payment Details step, the payment details fields are required.
    return Yup.object({
      paymentMethod: Yup.string()
        .oneOf(Object.values(PaymentMethod))
        .required(), // no message needed as it's defaulted in QuoteSession constructor
      frequency: Yup.string().oneOf(Object.values(PaymentFrequency)).required(), // no message needed as it's defaulted in QuoteSession constructor
      firstPaymentDate: Yup.string().required(
        messages.firstPaymentDate.required
      ),
      bankName: Yup.string()
        .when(
          'paymentMethod',
          ([paymentMethod]: any[], notRequiredSchema: Yup.StringSchema) =>
            // Only required for Direct Debit
            paymentMethod === PaymentMethod.DirectDebit
              ? notRequiredSchema.required(messages.bankName.required)
              : notRequiredSchema
        )
        .ensure()
        .test(validatePaymentDetails(messages.bankName.format))
        .max(40, messages.bankName.maxLength),
      accountName: Yup.string()
        .when(
          'paymentMethod',
          ([paymentMethod]: any[], notRequiredSchema: Yup.StringSchema) =>
            // Only required for Direct Debit
            paymentMethod === PaymentMethod.DirectDebit
              ? notRequiredSchema.required(messages.accountName.required)
              : notRequiredSchema
        )
        .ensure()
        .test(validatePaymentDetails(messages.accountName.format))
        .max(40, messages.accountName.maxLength),
      accountNumber: Yup.string()
        .when(
          'paymentMethod',
          ([paymentMethod]: any[], notRequiredSchema: Yup.StringSchema) =>
            // Only required for Direct Debit
            paymentMethod === PaymentMethod.DirectDebit
              ? notRequiredSchema.required(messages.accountNumber.required)
              : notRequiredSchema
        )
        .test(
          'validateAccountNumber',
          (v) => v,
          (value, ctx) => {
            if (optional(value)) {
              return true;
            }
            // Validate the bank account number
            const result = validateBankAccount(value);
            if (result.success) {
              return true;
            }
            return ctx.createError({ message: showError(result.error) });
          }
        ),
      termsAndConditions: Yup.boolean().when(
        'paymentMethod',
        ([paymentMethod]: any[], notRequiredSchema: Yup.BooleanSchema) =>
          // Only required for Direct Debit when we're not an adviser
          paymentMethod === PaymentMethod.DirectDebit &&
          !config.brand.hasAdviser
            ? notRequiredSchema.equals(
                [true],
                messages.allApplicantsEligible.required
              )
            : notRequiredSchema
      ),
    });
  });

/**
 * Used to validate the entire Buy Now form prior to submitting it
 */
const BuyNowSchema: Yup.ObjectSchema<BuyNowFormData> = Yup.object()
  .shape({
    applicantExtraDetails: BuyNowYourDetailsSchema,
    paymentDetails: BuyNowPaymentDetailsSchema,
    buyNowStep: Yup.number().required(),
    // hasMember can be any value unless we're on the Membership step
    hasMember: Yup.boolean().when(
      'buyNowStep',
      ([buyNowStep], notRequiredSchema) => {
        if (buyNowStep < BuyNowStep.AaMembership) {
          return notRequiredSchema;
        }
        // hasMember is only required if the brand uses member products.
        return notRequiredSchema.test(
          'hasMemberRequired',
          messages.hasMember.required,
          (value) => {
            if (!config.brand.hasMemberProducts) {
              return true;
            }
            return value === true || value === false;
          }
        );
      }
    ),
    // externalMemberApplicant can be any value unless we're on the Membership step
    externalMemberApplicant: Yup.string()
      .ensure()
      .when('buyNowStep', ([buyNowStep], notRequiredSchema) => {
        if (buyNowStep < BuyNowStep.AaMembership) {
          return notRequiredSchema;
        }
        // externalMemberApplicant is only required when 'Yes' is
        // selected for hasMember
        return notRequiredSchema.when('hasMember', ([hasMember], schema) =>
          hasMember
            ? schema.required(messages.externalMemberApplicant.required)
            : schema
        );
      }),
    // externalMemberNumber can be any value unless we're on the Membership step
    externalMemberNumber: Yup.string()
      .ensure()
      .when('buyNowStep', ([buyNowStep], notRequiredSchema) => {
        if (buyNowStep < BuyNowStep.AaMembership) {
          return notRequiredSchema;
        }
        // externalMemberNumber is only required when 'Yes' is
        // selected for hasMember
        return notRequiredSchema.when('hasMember', ([hasMember], schema) =>
          hasMember
            ? schema
                .required(messages.externalMemberNumber.required)
                .test(
                  validateExternalMemberNumber(
                    messages.externalMemberNumber.format
                  )
                )
            : schema
        );
      }),
    // Declaration field validation is added below, but we need these defaults
    // to appease TypeScript
    declaration: Yup.mixed(),
    adviserDeclaration: Yup.mixed(),
    directDebitAdviserDeclaration: Yup.mixed(),
    creditCardAdviserDeclaration: Yup.mixed(),
    yourDetailsDeclaration: Yup.mixed(),
  })
  .when({
    // If we have an adviser, ensure the Adviser Declaration fields are validated
    is: () => config.brand.hasAdviser,
    then: (schema) =>
      schema.shape({
        adviserDeclaration: BuyNowAdviserDeclarationSchema(
          BuyNowStep.Declaration
        ),
        yourDetailsDeclaration: BuyNowYourDetailsDeclarationSchema,
        directDebitAdviserDeclaration: Yup.mixed<AdviserDeclaration>().when(
          'paymentDetails',
          ([paymentDetails], notRequiredSchema) => {
            // If this brand doesn't use advisers, this field isn't required
            if (!config.brand.hasAdviser) {
              return notRequiredSchema;
            }
            // If we haven't selected Direct Debit, this field isn't required
            if (paymentDetails.paymentMethod !== PaymentMethod.DirectDebit) {
              return notRequiredSchema;
            }
            return BuyNowAdviserDeclarationSchema(BuyNowStep.PaymentDetails);
          }
        ),
        creditCardAdviserDeclaration: Yup.mixed<AdviserDeclaration>().when(
          'paymentDetails',
          ([paymentDetails], notRequiredSchema) => {
            // If this brand doesn't use advisers, this field isn't required
            if (!config.brand.hasAdviser) {
              return notRequiredSchema;
            }
            // If we haven't selected Credit Card, this field isn't required
            if (paymentDetails.paymentMethod !== PaymentMethod.CreditCard) {
              return notRequiredSchema;
            }
            return BuyNowAdviserDeclarationSchema(BuyNowStep.PaymentDetails);
          }
        ),
      }),
    // If we don't have an adviser, the regular Declaration step
    // fields should be validated
    otherwise: (schema) =>
      schema.shape({
        declaration: BuyNowDeclarationSchema,
      }),
  });

export default BuyNowSchema;
