import { ApiResponse } from 'src/types/Api';
import { ProductType } from 'src/types/ProductConfig';
import { PaymentFrequency } from 'src/types/QuoteSession';

export interface JoinApiCreateCartOptions {
  cartDetails: JoinApiCartDetails;
  correlationId: string;
}

export interface JoinApiUpdateCartOptions {
  joinId: string;
  cartDetails: JoinApiCartDetails;
  correlationId: string;
}

export type JoinApiBrand = 'DTCJoin' | 'AACentre' | 'AAJoin'; // Values match updated join service and submission service
export type JoinApiJourneyStage =
  | 'AboutYou'
  | 'ChooseYourCover'
  | 'TailorYourQuote'
  | 'BuyNow'
  | 'PurchaseCompleted';

export interface JoinApiCartDetails {
  brand: JoinApiBrand;
  journeyCurrentStage: JoinApiJourneyStage;
  applicants?: JoinApiCartApplicant[];
  uan?: string;
  paymentFrequency?: string;
  totalPrice: number;
}

export type JoinApiPaymentFrequency =
  | 'Weekly'
  | 'Fortnightly'
  | 'Monthly'
  | 'Quarterly'
  | 'HalfYearly'
  | 'Yearly'; // Values are validated on BE

export interface JoinApiCartApplicant {
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  gender: string;
  email?: string;
  address?: JoinApiAddress;
  isSmoker: boolean;
  age: number;
  price: number;
  isPolicyOwner: boolean;
  hospitalProduct: string | null;
  hospitalExcess: number | null;
  everydayProduct: string | null;
  nonPharmacPlusCoverLimit: number | null;
}

export interface JoinApiAddress {
  doorNumber: string;
  street: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
}

export interface JoinApiCreateCartResponse extends ApiResponse {
  data: string; // id for the created join
}
export type JoinApiUpdateCartResponse = ApiResponse;

export interface JoinApiRequestCallbackOptions {
  joinId: string | null;
  requestDetails: JoinApiRequestCallbackDetails;
  correlationId: string;
}

export interface JoinApiRequestCallbackDetails {
  brand: JoinApiBrand;
  name: string; // Firstname
  phoneNumber: string;
  preferredCallbackDay?: string;
  preferredCallbackTimeRange?: string;
  notes?: string;
}

export type JoinApiRequestCallbackResponse = ApiResponse;
export interface JoinApiValidatePromoCodeOptions {
  brandId: JoinApiBrand;
  promoCode: string;
}

export enum JoinApiRequestCallbackPreferredCallbackDay {
  AsSoonAsPossible = 'As soon as possible',
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
}

export enum JoinApiRequestCallbackPreferredCallbackTimeRange {
  AnyTime = 'Any time',
  TimeRangeOne = '8:30am - 10:00am',
  TimeRangeTwo = '10:00am - 12:00pm',
  TimeRangeThree = '12:00pm - 2:00pm',
  TimeRangeFour = '2:00pm - 4:00pm',
  TimeRangeFive = '4:00pm - 5:30pm',
}

export interface JoinApiValidatePromoCodeResponse extends ApiResponse {
  data: {
    summary: string;
    confirmationDescription: string;
  };
}

export interface JoinApiValidateAdviserNumberOptions {
  adviserNumber: string;
}

export type JoinApiValidateAdviserNumberResponse =
  | JoinApiValidateAdviserNumberValidResponse
  | JoinApiValidateAdviserNumberInvalidResponse;

interface JoinApiValidateAdviserNumberValidResponse extends ApiResponse {
  data: {
    valid: true;
    uan: string;
    agreementId: string;
  };
}

interface JoinApiValidateAdviserNumberInvalidResponse extends ApiResponse {
  data: {
    valid: false;
  };
}

export interface JoinApiQuoteDetails {
  schemaVersion: number; // FE sets and can detect if trying to load older version
  brand: JoinApiBrand; // The brand whose join flow the user is in when they submit the quote
  originatingBrand: JoinApiBrand; // Usually the same as brand.  But will be AACentre for
  // quotes that start life as AACN ... get resumed in AA join
  // and then the user creates another quote in AA join
  applicants: JoinApiQuoteApplicant[];
  hasMember?: boolean;
  promoCode?: string;
  paymentFrequency: PaymentFrequency;
  adviserDetails?: JoinApiAdviserDetails; // AACN only
  effectiveDate: string; // YYYY-MM-DD Should be today and validated on API
  totalPrice: number;
  hasOptedOutOfMarketing: boolean;
}

export interface JoinApiAdviserDetails {
  /** The number the user types into the Adviser Number field */
  adviserNumber: string;
  /** The translated UAN/Adviser Number returned from the API */
  uan: string;
  /** The Agreement ID returned from the API */
  agreementId: string;
}

export interface JoinApiQuoteApplicant {
  id: string;
  firstName: string;
  age: string;
  phone: string;
  gender: string;
  isSmoker: boolean;
  isPolicyOwner: boolean;
  isGuardian?: boolean;
  policyDetails: JoinApiPolicyDetails;
}

export interface JoinApiPolicyDetails {
  // Basic details that can be used to resume memberPolicyDetails
  //
  productTypes: ProductType[];
  hospitalProductCode?: string;
  everydayProductCode?: string;
  excess: string;
  productCodeToSelectedExcess: Record<string, string>;
  nonPharmacPlus: string;

  // Additional details to be used by the join API to validate the price
  //
  hospitalBenefitCode?: string;
  everydayBenefitCode?: string;
  nonPharmacPlusBenefitCode?: string;

  applicantPrice: number;
}

export interface JoinApiCreateQuoteOptions {
  correlationId: string;
  joinId: string;
  quotePayload: JoinApiCreateQuotePayload;
}

export interface JoinApiCreateQuoteContactDetails {
  customerEmailAddress?: string;
  firstName?: string;
}
export interface JoinApiCreateQuotePayload {
  contactDetails: JoinApiCreateQuoteContactDetails;
  quoteDetails: JoinApiQuoteDetails;
}

export interface JoinApiCreateQuoteResponse extends ApiResponse {
  data: number; // quoteIndex assigned by the API for the created quote
}

export interface JoinApiGetQuoteOptions {
  joinId: string;
  quoteIndex: number;
}

export interface JoinApiGetQuoteResponse extends ApiResponse {
  data: JoinApiGetQuoteResponseData;
}

export interface JoinApiGetQuoteResponseData {
  quoteDetails: JoinApiQuoteDetails;
  expired: boolean;
}
