import { FieldsetFormControl } from '@nib-components/form-control';
import { FieldsetFormControlProps } from '@nib-components/form-control/dist/@types/FieldsetFormControl';
import RadioGroup, { RadioGroupProps } from '@nib-components/radio-group';
import { FieldHookConfig, useField } from 'formik';

export type FormRadioGroupProps = Omit<FieldsetFormControlProps, 'children'> &
  Pick<RadioGroupProps, 'options'> &
  FieldHookConfig<string> & {
    label: string;
  };

const FormRadioGroup = ({
  label,
  name,
  options,
  ...otherProps
}: FormRadioGroupProps) => {
  const [field, meta] = useField<string>({ name });

  // Radios are OK to immediately emit state changes to formik; no need
  // to keep local state as per text boxes

  return (
    <FieldsetFormControl
      id={field.name}
      name={field.name}
      label={label}
      valid={meta.error === undefined}
      validated={meta.touched}
      error={meta.error}
      hasNegativeFieldsetMargin={true}
      {...otherProps}
    >
      <RadioGroup {...field} value={field.value ?? ''} options={options} />
    </FieldsetFormControl>
  );
};

export default FormRadioGroup;
