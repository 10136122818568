import { Box, Stack } from '@nib/layout';
import React from 'react';
import { BuyNowDeclarationContent } from 'src/types/Content/BuyNowContent';
import { DeclarationDetails } from 'src/types/QuoteSession';
import config from 'src/utils/env';
import gtmUtils from 'src/utils/gtmUtils';
import BrandedModal from '../BrandedModal';
import FormCheckbox from '../form/FormCheckbox';
import FormRadioGroup from '../form/FormRadioGroup';
import MarkdownContent from '../MarkdownContent';
import BottomButtons from '../navigation/BottomButtons';

const content = config.brand.content.buyNow.step3 as BuyNowDeclarationContent;

interface DeclarationStepProps {
  declarationDetails: DeclarationDetails;
}

const DeclarationStep = (props: DeclarationStepProps) => {
  const { declarationDetails } = props;
  const [showEligibleModal, setShowEligibleModal] =
    React.useState<boolean>(false);
  const [showPreExistingModal, setShowPreExistingModal] =
    React.useState<boolean>(false);

  // Respond to events in markdown links
  React.useEffect(() => {
    const showEligible = () => setShowEligibleModal(true);
    const showPreExisting = () => setShowPreExistingModal(true);
    window.addEventListener('allApplicantsEligible', showEligible);
    window.addEventListener('hasReadPreExistingConditions', showPreExisting);

    return () => {
      window.removeEventListener('allApplicantsEligible', showEligible);
      window.removeEventListener(
        'hasReadPreExistingConditions',
        showPreExisting
      );
    };
  }, []);

  React.useEffect(() => {
    gtmUtils.viewDeclarationStep();
  }, []);

  return (
    <>
      <Stack space={5}>
        <FormRadioGroup
          label={
            (
              <MarkdownContent content={content.hasInsuranceLabel} />
            ) as unknown as string
          }
          name="declaration.hasInsurance"
          options={{
            Yes: content.hasInsuranceYes,
            No: content.hasInsuranceNo,
          }}
          formMode="light"
        />
        {declarationDetails.hasInsurance === 'Yes' && (
          <MarkdownContent content={content.hasInsuranceInfo} />
        )}
        <Box>
          <FormCheckbox
            name="declaration.allApplicantsEligible"
            label={
              (
                <MarkdownContent
                  content={content.allApplicantsEligibleLabel}
                  linkEvent="allApplicantsEligible"
                />
              ) as unknown as string
            }
            formMode="light"
          />
          <FormCheckbox
            name="declaration.hasReadPreExistingConditions"
            label={
              (
                <MarkdownContent
                  content={content.hasReadPreExistingConditionsLabel}
                  linkEvent="hasReadPreExistingConditions"
                />
              ) as unknown as string
            }
            formMode="light"
          />
          <FormCheckbox
            name="declaration.hasReadPrivacyPolicy"
            label={
              (
                <MarkdownContent content={content.hasReadPrivacyPolicyLabel} />
              ) as unknown as string
            }
            formMode="light"
          />
        </Box>
        <BottomButtons
          submitButtonText={config.brand.content.buyNow.step3.nextButtonText}
          submitButtonColor="dark"
          submitButtonSize="medium"
        />
      </Stack>
      <BrandedModal
        visible={showEligibleModal}
        onClose={() => setShowEligibleModal(false)}
        title={content.allApplicantsEligibleModalTitle}
      >
        <MarkdownContent content={content.allApplicantsEligibleModalContent} />
      </BrandedModal>
      <BrandedModal
        visible={showPreExistingModal}
        onClose={() => setShowPreExistingModal(false)}
        title={content.hasReadPreExistingConditionsModalTitle}
      >
        <MarkdownContent
          content={content.hasReadPreExistingConditionsModalContent}
        />
      </BrandedModal>
    </>
  );
};

export default DeclarationStep;
