import { Box } from '@nib/layout';
import { useEffect, useState } from 'react';
import HeadingPanel from 'src/components/heading/HeadingPanel';
import HelmetComponent from 'src/components/HelmetComponent/HelmetComponent';
import FeaturePanels from 'src/components/Welcome/FeaturePanels';
import FeaturePanelsForNib from 'src/components/Welcome/FeaturePanelsForNib';
import { useSaveCart } from 'src/store/hooks/useSaveCart';
import { FormPage } from 'src/types/FormPage';
import emailQuoteUtils from 'src/utils/emailQuoteUtils';
import config from 'src/utils/env';
import {
  getQuoteSession,
  removeAllFromLocalStorage,
} from 'src/utils/localStorageUtils';

var welcomePageInitialized = false;

const WelcomePage = ({ onLoad }: { onLoad: () => void }) => {
  const [quoteSession, setQuoteSession] = useState(getQuoteSession());
  const [name] = useState(getQuoteSession()?.getNominatedOwner().firstName);
  const saveCart = useSaveCart();

  useEffect(() => {
    if (quoteSession && !welcomePageInitialized) {
      welcomePageInitialized = true;
      // We just completed a successful join
      // Update the cart so that the cart watcher doesn't try to create a lead
      // for this join
      //
      quoteSession.completedPages.push(FormPage.BuyNow);
      // We wait for saveCart to complete one way or the other
      // before we clear local storage so that we can use the join id
      // in the call to update the cart.
      saveCart(quoteSession, true)
        .catch((error) => console.log('saveCart caught error', error))
        .finally(() => {
          setQuoteSession(null);
          removeAllFromLocalStorage();
          // cross-join originating info does NOT get removed by removeAll above
          // so, since we have now completed the join successfully ... we remove it below
          emailQuoteUtils.removeCrossJoinOriginatingInfo();
          onLoad();
        });
    }
  }, [onLoad, quoteSession, saveCart]);

  let featurePanel;
  let headingPanel;

  let title = config.brand.content.welcome.title;
  if (name) {
    title += name;
  } else {
    if (title.endsWith(', ')) {
      title = title.slice(0, -2);
    }
  }
  if (config.brand.useNibWelcomeScreen) {
    featurePanel = <FeaturePanelsForNib />;
    headingPanel = (
      <HeadingPanel
        background="sageGreen60"
        title={title}
        image={{
          src: `${process.env.PUBLIC_URL}/assets/images/welcome/Welcome.png`,
          altText: config.brand.content.welcome.welcomeAltText,
          width: 8, // 8rems
        }}
      />
    );
  } else {
    featurePanel = <FeaturePanels />;
    headingPanel = <HeadingPanel background="sageGreen60" title={title} />;
  }
  return (
    <Box>
      <HelmetComponent content={config.brand.content.welcome.helmet} />
      {headingPanel}
      {featurePanel}
    </Box>
  );
};

export default WelcomePage;
