import DatePicker from '@nib-components/date-picker';
import { FormControl } from '@nib-components/form-control';
import { format, parse } from 'date-fns';
import { useField, useFormikContext } from 'formik';
import React from 'react';
import { useAutocomplete } from 'src/hooks/useAutocomplete';
import dateUtils from 'src/utils/dateUtils';
import styled from 'styled-components';
import { FormTextboxProps } from '../form/FormTextbox';

const StyledDatePicker = styled('div')`
  // Hide the original heading
  .react-datepicker__current-month {
    display: none;
  }
  .react-datepicker__header__dropdown {
    margin-top: 0;
  }
  // Bold the dropdowns to make them look like the original heading
  .react-datepicker__month-read-view--selected-month,
  .react-datepicker__year-read-view--selected-year {
    font-weight: bold;
    font-size: calc(1.0325rem);
  }
  // Remove border radius on options
  .react-datepicker__year-dropdown,
  .react-datepicker__month-dropdown {
    border-radius: 0;
  }
  .react-datepicker__year-option,
  .react-datepicker__month-option {
    line-height: 2rem;
    &:first-of-type,
    &:last-of-type {
      border-radius: 0;
    }
  }
  // Give thye dropdowns a drop shadow
  .react-datepicker__year-dropdown,
  .react-datepicker__month-dropdown {
    box-shadow: rgba(203, 203, 203, 0.5) 0px 2px 4px 2px;
  }
`;

export const DATE_OF_BIRTH_FORMAT = 'dd/MM/yyyy';

export type DateOfBirthFormFieldProps = FormTextboxProps & {
  /** The field name for the 'age' field that should be populated from this field, eg. 'applicants.0.age' */
  ageFieldname: string;
  /** The field name for the 'smoker' fiueld that should be set to 'No' if the age is under 18 */
  smokerFieldname: string;
};

const DateOfBirthFormField = ({
  isOptional,
  label,
  name,
  help,
  maxLength,
  ageFieldname,
  smokerFieldname,
  autoComplete,
  ...otherProps
}: DateOfBirthFormFieldProps) => {
  const { getFieldProps, setFieldValue, setFieldTouched, validateForm } =
    useFormikContext();
  const [field, meta] = useField<string>({ name });

  // Note: onChange is not used, we implement our own below.
  const { onChange: _onChange, value: formikValue, ...fieldProps } = field;

  // After setting a value, we need to tell the form to revalidate and mark the field
  // as touched.  The user can use the calendar picker to set the value without interacting
  // with the field itself, so the usual onBlur won't always trigger the validation.
  const [shouldTouchAndValidate, touchAndValidate] =
    React.useState<boolean>(false);
  React.useEffect(() => {
    if (!shouldTouchAndValidate) {
      return;
    }
    touchAndValidate(false);
    validateForm();
    setFieldTouched(field.name, true);
  }, [field.name, setFieldTouched, shouldTouchAndValidate, validateForm]);

  // Convert formik value into Date object needed by component.
  let formikDate = formikValue
    ? parse(formikValue, DATE_OF_BIRTH_FORMAT, new Date())
    : undefined;
  if (formikDate && isNaN(formikDate.valueOf())) {
    formikDate = undefined;
  }

  let openDatePickerDate = new Date(
    dateUtils.getNominalBirthdateForAge(getFieldProps(ageFieldname).value)
  );

  const autoCompleteValue = useAutocomplete(autoComplete);

  return (
    <StyledDatePicker>
      <FormControl
        id={field.name}
        name={field.name}
        label={label}
        help={help}
        valid={meta.error === undefined}
        validated={meta.touched}
        error={meta.error}
        isEmptyAndOptional={isOptional && !formikDate}
        autoComplete={autoCompleteValue}
        {...otherProps}
      >
        <DatePicker
          // Hide the default calendar title, and replace it with dropdowns for month and year
          dateFormatCalendar="LLLL"
          showMonthDropdown
          scrollableMonthDropdown
          showYearDropdown
          scrollableYearDropdown
          // Show wide range of years in year dropdown
          yearDropdownItemNumber={120}
          // Years in dropdown are between now and 1900
          maxDate={new Date()}
          minDate={new Date(1900, 0, 1)}
          //
          dateFormat="dd/MM/yyyy"
          hasMask={true}
          selected={formikDate}
          openToDate={openDatePickerDate}
          // On change, set the field value into formik as a formatted string
          onChange={(dateObj: Date) => {
            setFieldValue(
              field.name,
              dateObj ? format(dateObj, DATE_OF_BIRTH_FORMAT) : ''
            );
            touchAndValidate(true);
            // Find the age from the date of birth, and set it into the age field
            const age = dateObj
              ? dateUtils.getAgeForDateOfBirth(dateObj)
              : null;
            // If we didn't set an age, don't alter any fields; we want the age field
            // to retain whatever was entered on previous screens unless we have a valid
            // date entered
            if (age !== null) {
              setFieldValue(ageFieldname, age.toString(), true);
              // If the age is under 18, set smoking status to No
              if (age < 18) {
                setFieldValue(smokerFieldname, 'No', true);
              }
            }
          }}
          // If we pressed enter, emit a field change event so that
          // Formik updates the field's value before the form element's
          // submit event.  If we do not do this, the form will submit
          // with the previous value of the field instead of the new value
          // the user just typed.
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter' || e.keyCode === 13) {
              setFieldValue(field.name, e.currentTarget.value, true);
            }
          }}
          {...fieldProps}
        />
      </FormControl>
    </StyledDatePicker>
  );
};

export default DateOfBirthFormField;
