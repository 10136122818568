import { FormControl } from '@nib-components/form-control';
import { FormControlProps } from '@nib-components/form-control/dist/@types/FormControl';
import Select from '@nib-components/select';
import { useField, useFormikContext } from 'formik';
import { BuyNowFormData } from 'src/types/BuyNowForm';

type FormSelectProps = Omit<FormControlProps, 'children'> & {
  label: string;
  name: string;
  /** Fieldname for the externalMemberNumber field. We blank this out whenever we change values. */
  externalMemberNumberName: string;
  options: Record<string, string>;
};

const FormSelect = (props: FormSelectProps) => {
  const {
    label,
    name,
    options,
    externalMemberNumberName,
    ...formControlProps
  } = props;
  const [{ onChange, ...field }, meta] = useField<string>({ name });
  const { setFieldValue, setFieldTouched } = useFormikContext<BuyNowFormData>();
  return (
    <FormControl
      id={field.name}
      name={field.name}
      label={label}
      valid={meta.error === undefined}
      validated={meta.touched}
      error={meta.error}
      {...formControlProps}
    >
      <Select
        {...field}
        options={options}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          onChange(e);
          // Whenever we change this field, blank out externalMemberNumber as it is no longer valid.
          setFieldValue(externalMemberNumberName, '');
          setFieldTouched(externalMemberNumberName, false);
        }}
      />
    </FormControl>
  );
};

export default FormSelect;
