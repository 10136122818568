import config from 'src/utils/env';
import MarkdownAlert from '../MarkdownAlert';

const FirstPaymentDateWarning = () => {
  return (
    <MarkdownAlert
      variation="soft"
      type="info"
      content={config.brand.content.buyNow.step4.firstPaymentInfo}
    />
  );
};

export default FirstPaymentDateWarning;
