import Alert from '@nib-components/alert';
import Checkbox from '@nib-components/checkbox';
import { FormControl } from '@nib-components/form-control';
import Heading from '@nib-components/heading';
import { Box, Column, Columns, Stack } from '@nib/layout';
import { FieldArray, useFormikContext } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { PriceContext } from 'src/contexts/PriceContext';
import { useQuoteSession } from 'src/contexts/QuoteSessionProvider';
import {
  useGetSpecialOfferQuery,
  useValidateSpecialOfferQuery,
} from 'src/services/join/joinApi';
import { BuyNowFormData, BuyNowFormDataItem } from 'src/types/BuyNowForm';
import config from 'src/utils/env';
import gtmUtils from 'src/utils/gtmUtils';
import { createValidateSpecialOfferRequest } from 'src/utils/joinApiUtils';
import styled from 'styled-components';
import SmokerFormField from '../AboutYou/SmokerFormField';
import FormAddressSearch from '../form/FormAddressSearch.tsx';
import FormCheckbox from '../form/FormCheckbox';
import FormRadioGroup from '../form/FormRadioGroup';
import FormTextbox from '../form/FormTextbox';
import StepHeading from '../heading/StepHeading';
import MarkdownContent from '../MarkdownContent';
import BottomButtons from '../navigation/BottomButtons';
import VerticalIndentBar from '../VerticalIndentBar';
import AgeInfoBox from './AgeInfoBox';
import DateOfBirthFormField from './DateOfBirthFormField';

// Hide the parent label of Policy checkboxes
const StyledFormControl = styled(FormControl)`
  > label {
    display: none;
  }
`;

const content = config.brand.content.buyNow.step1;

export interface YourDetailsProps {
  applicantExtraDetails: BuyNowFormDataItem[];
}

const YourDetailsStep: React.FC<YourDetailsProps> = ({
  applicantExtraDetails,
}) => {
  const {
    setFieldValue,
    values: {
      applicantExtraDetails: formApplicants,
      externalMemberApplicant: formExternalMemberApplicantId,
    },
  } = useFormikContext<BuyNowFormData>();

  // If we changed dateOfBirth or surname, blank out any existing externalMemberNumber
  // as it is no longer valid.
  const newExternalMemberApplicant = formApplicants.find(
    (a) => a.id === formExternalMemberApplicantId
  );
  useEffect(() => {
    setFieldValue('externalMemberNumber', '');
  }, [
    newExternalMemberApplicant?.dateOfBirth,
    newExternalMemberApplicant?.surname,
    setFieldValue,
  ]);

  const { data: priceData } = useContext(PriceContext);
  const { data: getSpecialOfferResponse } = useGetSpecialOfferQuery();
  const quoteSession = useQuoteSession();
  const { data: validateSpecialOfferResponse } = useValidateSpecialOfferQuery(
    createValidateSpecialOfferRequest(quoteSession)
  );
  const [beginCheckoutReported, setBeginCheckoutReported] = useState(false);
  useEffect(() => {
    if (priceData && getSpecialOfferResponse && validateSpecialOfferResponse) {
      // When the user completes the Your Details section, we will
      // refetch pricing and validate special offers so this useEffect will
      // trigger again.  However, if we have already reported the begin_checkout
      // event then we suppress it here.  The details may have changed (price/coupon)
      // but this will be reflected in the purchase event if they buy ... and we
      // don't want to have a lot more begin_checkout events than purchase events
      // for flows where the user actually does purchase
      //
      if (!beginCheckoutReported) {
        gtmUtils.beginCheckout(
          quoteSession,
          priceData,
          getSpecialOfferResponse,
          validateSpecialOfferResponse
        );
        setBeginCheckoutReported(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceData, getSpecialOfferResponse, validateSpecialOfferResponse]);

  return (
    <Box maxWidth="600px">
      <FieldArray name="applicantExtraDetails">
        {() => (
          <div>
            {applicantExtraDetails.map((applicant, index) => {
              const isFirst = index === 0;
              // Find the heading text
              let headingText: string;
              if (applicant.isGuardian) {
                headingText = content.guardianTitle;
              } else if (isFirst) {
                headingText = '1. ' + content.title;
              } else {
                headingText = content.otherTitle;
              }
              return (
                <Stack space={{ xs: 5, sm: 0 }} key={applicant.id}>
                  <Columns collapseBelow="sm" space={{ xs: 5, sm: 0 }}>
                    <Column>
                      <Box
                        // Top padding allows heading to align with checkbox,
                        // which has an empty label pushing it down
                        paddingTop={{ xs: 0, sm: 5 }}
                        marginBottom={{ xs: 0, sm: 4 }}
                      >
                        <StepHeading visible={true}>{headingText}</StepHeading>
                      </Box>
                    </Column>
                    <Column align="right">
                      <StyledFormControl
                        // CSS hides the label element, while allowing it to be present
                        // for screenreaders.
                        label={content.policyOwnerLabel}
                        id={`applicantExtraDetails.${index}.isPolicyOwner`}
                        name={`applicantExtraDetails.${index}.isPolicyOwner`}
                        onClick={() => {
                          // Clicking a checkbox always selects it
                          if (applicant.isPolicyOwner) {
                            return;
                          }
                          applicantExtraDetails.forEach((_a, i) => {
                            setFieldValue(
                              `applicantExtraDetails.${i}.isPolicyOwner`,
                              i === index
                            );
                          });
                        }}
                        formMode="light"
                        disabled={parseInt(applicant.age) < 16}
                      >
                        <Checkbox
                          label={content.policyOwnerLabel}
                          checked={applicant.isPolicyOwner}
                          value="true"
                        />
                      </StyledFormControl>
                    </Column>
                  </Columns>
                  <Box display="flex" flexDirection="row" marginBottom={6}>
                    <VerticalIndentBar />
                    <Box flex="1" flexGrow={1}>
                      <Stack space={5}>
                        <FormTextbox
                          label={content.firstNameLabel}
                          name={`applicantExtraDetails.${index}.firstName`}
                          maxLength={30}
                          formMode="light"
                          autoComplete={'given-name'}
                        />
                        <FormTextbox
                          label={content.surnameLabel}
                          name={`applicantExtraDetails.${index}.surname`}
                          maxLength={30}
                          formMode="light"
                          autoComplete={'family-name'}
                        />
                        <DateOfBirthFormField
                          label={content.dateOfBirthLabel}
                          name={`applicantExtraDetails.${index}.dateOfBirth`}
                          ageFieldname={`applicantExtraDetails.${index}.age`}
                          smokerFieldname={`applicantExtraDetails.${index}.smoker`}
                          maxLength={30}
                          formMode="light"
                          autoComplete={'bday'}
                        />
                        <AgeInfoBox
                          fieldNamePrefix={`applicantExtraDetails.${index}`}
                        />
                        <FormRadioGroup
                          label={
                            isFirst
                              ? content.genderLabel
                              : content.otherGenderLabel
                          }
                          name={`applicantExtraDetails.${index}.gender`}
                          options={{
                            Male: 'Male',
                            Female: 'Female',
                          }}
                          formMode="light"
                        />
                        <SmokerFormField
                          label={
                            isFirst
                              ? content.smokerLabel
                              : content.otherSmokerLabel
                          }
                          name={`applicantExtraDetails.${index}.smoker`}
                          options={{
                            Yes: 'Yes',
                            No: 'No',
                          }}
                          tooltipContents={
                            isFirst
                              ? content.smokerInfoText
                              : content.otherSmokerInfoText
                          }
                          formMode="light"
                        />
                        {applicant.isPolicyOwner && (
                          <FormTextbox
                            label={content.phoneLabel}
                            name={`applicantExtraDetails.${index}.phone`}
                            maxLength={255}
                            formMode="light"
                            stripWhitespace={true}
                            type="tel"
                            autoComplete={'tel-national'}
                          />
                        )}
                        {applicant.isPolicyOwner && (
                          <Stack space={4}>
                            <FormTextbox
                              label={content.emailLabel}
                              name={`applicantExtraDetails.${index}.email`}
                              maxLength={50}
                              formMode="light"
                              type="email"
                              autoComplete={'email'}
                            />
                            {config.brand.hasAdviser && (
                              <Heading
                                component="h3"
                                size={{ xs: 4, md: 3 }}
                                color={config.brand.customisation?.headersColor}
                              >
                                {content.emailDeclarationTitle}
                              </Heading>
                            )}
                            <Alert
                              type="info"
                              variation="soft"
                              fullWidth={false}
                            >
                              <Box marginBottom={4}>
                                <MarkdownContent
                                  content={content.emailInformationMessage}
                                />
                              </Box>
                              <Box>
                                <MarkdownContent
                                  content={content.emailMarketingCommsMessage}
                                ></MarkdownContent>
                              </Box>
                            </Alert>
                            {config.brand.hasAdviser && (
                              <FormCheckbox
                                name={`yourDetailsDeclaration.hasReadEmailDeclaration`}
                                label={content.emailDeclarationLabel ?? ''}
                                value={`yourDetailsDeclaration?.hasReadEmailDeclaration`}
                              ></FormCheckbox>
                            )}
                          </Stack>
                        )}
                        {applicant.isPolicyOwner && (
                          <FormAddressSearch
                            names={{
                              addressLine1: `applicantExtraDetails.${index}.addressLine1`,
                              addressLine2: `applicantExtraDetails.${index}.addressLine2`,
                              addressLine3: `applicantExtraDetails.${index}.addressLine3`,
                              addressLine4: `applicantExtraDetails.${index}.addressLine4`,
                              addressLine5: `applicantExtraDetails.${index}.addressLine5`,
                            }}
                            labels={{
                              search: content.addressSearchLabel,
                              manualButton: content.addressManualButtonText,
                              searchButton: content.addressSearchButtonText,
                              notFoundMessage: content.addressNotFoundMessage,
                            }}
                            formMode="light"
                          />
                        )}
                      </Stack>
                    </Box>
                  </Box>
                </Stack>
              );
            })}
          </div>
        )}
      </FieldArray>
      <BottomButtons
        submitButtonText={content.nextButtonText}
        submitButtonColor="dark"
        submitButtonSize="medium"
      />
    </Box>
  );
};

export default YourDetailsStep;
