import { QuoteSession } from 'src/types/QuoteSession';
import * as Yup from 'yup';
import { AboutYouSchema } from './AboutYouSchema';

/**
 * Validation schema for the Edit Details and Add Another Person pages
 */
export const ManageApplicantSchema: Yup.ObjectSchema<
  Pick<QuoteSession, 'applicantDetails'>
> = AboutYouSchema.pick(['applicantDetails']);
