import DatePicker, { DatePickerProps } from '@nib-components/date-picker';
import { FormControl } from '@nib-components/form-control';
import { addDays, format, parse } from 'date-fns';
import { useField, useFormikContext } from 'formik';
import React from 'react';
import { useAutocomplete } from 'src/hooks/useAutocomplete';
import { PaymentFrequency } from 'src/types/QuoteSession';
import {
  getLatestFirstPaymentDate,
  isValidFirstPaymentDate,
} from 'src/utils/buyNowUtils';
import { FormTextboxProps } from '../form/FormTextbox';

export const FIRST_PAYMENT_DATE_FORMAT = 'dd/MM/yyyy';

export type FirstPaymentDateFieldProps = FormTextboxProps &
  DatePickerProps & {
    paymentFrequency: PaymentFrequency;
  };

const FirstPaymentDateField = ({
  isOptional,
  label,
  name,
  help,
  maxLength,
  paymentFrequency,
  ...otherProps
}: FirstPaymentDateFieldProps) => {
  const { setFieldValue, setFieldTouched, validateForm } = useFormikContext();
  const [field, meta] = useField<string>({ name });

  // After setting a value, we need to tell the form to revalidate and mark the field
  // as touched.  The user can use the calendar picker to set the value without interacting
  // with the field itself, so the usual onBlur won't always trigger the validation.
  const [shouldTouchAndValidate, touchAndValidate] =
    React.useState<boolean>(false);
  React.useEffect(() => {
    if (!shouldTouchAndValidate) {
      return;
    }
    touchAndValidate(false);
    validateForm();
    setFieldTouched(field.name, true);
  }, [field.name, setFieldTouched, shouldTouchAndValidate, validateForm]);

  // onChange is not used, we write our own below
  const { onChange: _onChange, value: formikValue, ...fieldProps } = field;

  // Convert formik value into Date object needed by component.
  let formikDate = formikValue
    ? parse(formikValue, FIRST_PAYMENT_DATE_FORMAT, new Date())
    : undefined;
  if (formikDate && isNaN(formikDate.valueOf())) {
    formikDate = undefined;
  }

  return (
    <FormControl
      id={field.name}
      name={field.name}
      label={label}
      help={help}
      valid={meta.error === undefined}
      validated={meta.touched}
      error={meta.error}
      isEmptyAndOptional={isOptional && !formikDate}
      {...otherProps}
    >
      <DatePicker
        dateFormat="dd/MM/yyyy"
        hasMask={true}
        selected={formikDate}
        // On change, set the field value into formik as a formatted string
        onChange={(d: Date) => {
          setFieldValue(field.name, format(d, FIRST_PAYMENT_DATE_FORMAT));
          touchAndValidate(true);
        }}
        // Disable typing into the field using the keyboard
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
          e.preventDefault();
        }}
        autoComplete={useAutocomplete()}
        maxLength={maxLength}
        showMonthDropdown={false}
        // Only allow selecting valid dates
        filterDate={(date: Date) =>
          isValidFirstPaymentDate(date, paymentFrequency)
        }
        // Only show valid months by setting min and max date
        minDate={addDays(new Date(), 1)}
        maxDate={getLatestFirstPaymentDate(paymentFrequency)}
        {...fieldProps}
      />
    </FormControl>
  );
};

export default FirstPaymentDateField;
