import React from 'react';

interface HeightMatchingGroupProps {
  /** The CSS selector for descendants that should have their heights matched, eg. '.match-height'  */
  selector: string;
}

/**
 * Utility to match the heights of all descendants matching the given CSS selector.
 *
 * If the contents of any node is changed, heights will automatically be recalculated and
 * reset on all matching children.
 *
 * Usage:
 *
 *     <HeightMatchingGroup selector=".foo">
 *       <div className="foo">
 *         Item 1
 *       </div>
 *       <div className="container">
 *         <div className="foo">
 *           Item 2
 *         </div>
 *       </div>
 *     </HeightMatchingGroup>
 */
const HeightMatchingGroup = ({
  selector,
  children,
}: React.PropsWithChildren<HeightMatchingGroupProps>) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const scrollPositionRef = React.useRef<number | null>(null);

  React.useEffect(() => {
    if (!ref.current) {
      return;
    }

    const matchHeights = (container: HTMLDivElement) => {
      if (!container) {
        return;
      }

      // Store the current scroll position
      scrollPositionRef.current = window.scrollY;
      // Find all descendants matching the selector
      const els = Array.from(container.querySelectorAll<HTMLElement>(selector));
      // If there's only one, no need to do anything
      if (els.length === 1) {
        return;
      }
      // Set their heights to empty so they take up as much space as they need
      els.forEach((el) => {
        el.style.height = '';
      });
      // Find the tallest one
      const maxHeight = els
        .map((el) => el.scrollHeight)
        .reduce((pre, cur) => Math.max(pre, cur), -Infinity);
      // Set everything to match the tallest one
      els.forEach((el) => {
        el.style.height = `${maxHeight}px`;
      });

      // Restore the previous scroll position
      if (scrollPositionRef.current !== null) {
        window.scrollTo(0, scrollPositionRef.current);
        scrollPositionRef.current = null;
      }
    };

    // Set heights on load
    matchHeights(ref.current);

    // Event handler to set heights
    const handleChange = (e: any) => {
      if (!ref.current) {
        return;
      }
      matchHeights(ref.current);
    };

    // passive:true tells browsers that we don't need to block the page for scroll events
    // https://github.com/WICG/EventListenerOptions/blob/gh-pages/explainer.md
    window.addEventListener('resize', handleChange, { passive: true }); // Catches page width resizes
    window.addEventListener('transitionend', handleChange, { passive: true }); // Catches the end of CSS transitions

    // Catches adding/removing nodes that could change the length of the container's contents
    const observer = new MutationObserver(handleChange);
    observer.observe(ref.current, {
      subtree: true, // also look at all descendant nodes
      childList: true, // check for changes to the children of each node
      attributes: true, // check for changes to attributes
      attributeFilter: ['aria-expanded'], // only look at the 'aria-expanded' attribute - 'height' causes an infinite loop
    });

    return () => {
      // Clean up event listeners when the component is unmounted
      window.removeEventListener('resize', handleChange);
      window.removeEventListener('transitionend', handleChange);
      observer.disconnect();
    };
  }, [selector, ref]);

  return <div ref={ref}>{children}</div>;
};

export default HeightMatchingGroup;
