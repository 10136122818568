import { HelpSystemIcon } from '@nib/icons';
import Tooltip from '@nib/tooltip';

interface SmokerInfoIconProps {
  /** Unique-to-the-page key, so our tooltip handle pops up the correct tooltip */
  id: string;
  tooltipContents: string;
}

const SmokerInfoIcon = ({ id, tooltipContents }: SmokerInfoIconProps) => {
  return (
    <Tooltip
      id={id}
      mode="dark"
      placement="top"
      tooltipContents={tooltipContents}
    >
      {({ triggerProps }) => (
        <span {...triggerProps} className="SmokerInfoIconTrigger">
          {/* The SmokerInfoIconTrigger class is targeted by e2e tests*/}
          <HelpSystemIcon size="xs" />{' '}
        </span>
      )}
    </Tooltip>
  );
};

export default SmokerInfoIcon;
