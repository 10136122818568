import { hotjar } from 'react-hotjar';
import config from './utils/env';

const initialiseHotjar = () => {
  if (config.hotjarId && config.hotjarVersion) {
    hotjar.initialize(config.hotjarId, config.hotjarVersion);
  }
};

export default initialiseHotjar;
