import Heading from '@nib-components/heading';
import { Box } from '@nib/layout';
import { CoverBenefitHighlights } from 'src/types/Content/ChooseYourCoverContent';
import config from 'src/utils/env';
import styled from 'styled-components';
import { StandardCoverIcon } from './BenefitIcons';

export const COVER_HIGHLIGHTS_LIST_CLASSNAME = 'product-highlights-list';

const Highlights = styled('ul')`
  padding-left: 0;
`;

const Highlight = styled('li')`
  list-style-type: none;
  margin: 0 0 1rem 0;
  padding: 0 0 0 2rem;
  position: relative;
`;

const HighlightIconContainer = styled('div')`
  position: absolute;
  left: 0;
  top: 0.125rem;
`;

interface CoverHighlightsListProps {
  highlights: CoverBenefitHighlights;
}

const CoverHighlightsList = ({ highlights }: CoverHighlightsListProps) => {
  return (
    <Box
      paddingLeft={4}
      paddingRight={4}
      data-testid={`product-card-highlights`}
      className={COVER_HIGHLIGHTS_LIST_CLASSNAME}
    >
      <Heading
        component="h5"
        size={5}
        color={config.brand.customisation?.headersColor}
      >
        {highlights.title}
      </Heading>
      <Highlights>
        {highlights.benefits.map((benefit) => (
          <Highlight key={benefit}>
            <HighlightIconContainer>
              <StandardCoverIcon size="xs" />
            </HighlightIconContainer>
            {benefit}
          </Highlight>
        ))}
      </Highlights>
    </Box>
  );
};

export default CoverHighlightsList;
