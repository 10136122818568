import Card, { CardProps } from '@nib/card';
import React from 'react';
import config from 'src/utils/env';
import styled from 'styled-components';

const StyledCard = styled(Card)`
  h3 {
    color: ${config.brand.customisation?.headersColor ?? 'inherit'};
  }
`;

const BrandedCard: React.FC<CardProps> = ({ ...props }) => {
  return <StyledCard {...props}></StyledCard>;
};

export default BrandedCard;
