import { SmallHeader } from '@nib-components/header';
import config from '../utils/env';

export const Header = () => {
  return (
    <SmallHeader
      invert={config.brand.content.header.invertHeader}
      logoLink={config.brand.content.header.logoLinkUrl}
    />
  );
};
