import { SecondaryButton } from '@nib-components/button';
import { AddSystemIcon } from '@nib/icons';
import { Box, Container, Inline, Stack } from '@nib/layout';
import { clone } from 'ramda';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import EmailQuoteButton from 'src/components/EmailQuote/EmailQuoteButton';
import BrandedStyleHeader from 'src/components/heading/BrandedStyleHeader';
import TitlePanel from 'src/components/heading/TitlePanel';
import HelmetComponent from 'src/components/HelmetComponent/HelmetComponent';
import BottomButtons from 'src/components/navigation/BottomButtons';
import NeedAdviceButton from 'src/components/NeedAdvice/NeedAdviceButton';
import PageBackground from 'src/components/PageBackground';
import ImportantInformation from 'src/components/QuoteSummary/ImportantInformation';
import PromoCode from 'src/components/QuoteSummary/PromoCode';
import QuoteSummaryCard from 'src/components/QuoteSummary/QuoteSummaryCard';
import SpecialOfferAlertWrapper from 'src/components/SpecialOffer/SpecialOfferAlertWrapper';
import { PriceContext } from 'src/contexts/PriceContext';
import {
  useGetSpecialOfferQuery,
  useValidateSpecialOfferQuery,
} from 'src/services/join/joinApi';
import priceApiUtils from 'src/services/price/priceApiUtils';
import { FormPage } from 'src/types/FormPage';
import { FormPageProps } from 'src/types/FormPageProps';
import { ApplicantDetails } from 'src/types/QuoteSession';
import config from 'src/utils/env';
import gtmUtils from 'src/utils/gtmUtils';
import {
  createValidateSpecialOfferRequest,
  isEligibleForSpecialOffer,
} from 'src/utils/joinApiUtils';
import { getQuoteSession } from 'src/utils/localStorageUtils';
import Banner from '../components/Banner';

const content = config.brand.content.quoteSummary;

type QuoteSummaryPageProps = FormPageProps & {
  resumedQuote: boolean;
};

const QuoteSummaryPage = (props: QuoteSummaryPageProps) => {
  const { onSubmit, resumedQuote } = props;
  const quoteSession = clone(props.quoteSession);

  const navigate = useNavigate();
  const { data: getSpecialOfferResponse } = useGetSpecialOfferQuery();
  const { data: validateSpecialOfferResponse } = useValidateSpecialOfferQuery(
    createValidateSpecialOfferRequest(quoteSession)
  );

  const { data: priceData } = React.useContext(PriceContext);

  useEffect(() => {
    gtmUtils.viewQuoteSummary(quoteSession.applicantDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (priceData && getSpecialOfferResponse && validateSpecialOfferResponse) {
      gtmUtils.viewCart(
        quoteSession,
        priceData,
        getSpecialOfferResponse,
        validateSpecialOfferResponse
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceData, getSpecialOfferResponse, validateSpecialOfferResponse]);

  // Find the customised product types for each applicant
  const getProductCodes = (applicant: ApplicantDetails): string[] => {
    const policyDetails = quoteSession.memberPolicyDetails[applicant.id];
    const productCodes: string[] = [];
    if (policyDetails.hospitalProductCode) {
      productCodes.push(policyDetails.hospitalProductCode);
    }
    if (policyDetails.everydayProductCode) {
      productCodes.push(policyDetails.everydayProductCode);
    }
    return productCodes;
  };

  // Find the customised excess for each applicant
  const getExcess = (applicant: ApplicantDetails): number | undefined => {
    const policyDetails = quoteSession.memberPolicyDetails[applicant.id];
    if (!policyDetails.hospitalProductCode) {
      return undefined;
    }
    const excess = parseInt(policyDetails.excess);
    if (isNaN(excess)) {
      return undefined;
    }
    return excess;
  };

  // Find the customised non-PHARMAC Plus option for each applicant
  const getNonPharmacPlus = (
    _applicant: ApplicantDetails
  ): number | undefined => {
    const nonPharmacPlus = parseInt(
      quoteSession.memberPolicyDetails[_applicant.id].nonPharmacPlus
    );
    if (isNaN(nonPharmacPlus)) {
      return undefined;
    }
    return nonPharmacPlus;
  };

  const multiplePeople = quoteSession.applicantDetails.length > 1;
  const subTitle = multiplePeople
    ? content.subTitleMultiplePeople
    : content.subTitle;
  const resumedQuoteSubtitle =
    config.brand.content.resumeQuote.resumedQuoteLabel;

  const subTitleContent = resumedQuote ? resumedQuoteSubtitle : subTitle;

  return (
    <Box>
      <Banner />
      <HelmetComponent
        content={config.brand.content.quoteSummary.helmet}
        multiplePeople={multiplePeople}
      />
      <TitlePanel
        title={multiplePeople ? content.titleMultiplePeople : content.title}
        subTitle={subTitleContent}
        showSpecialOffer={false}
      />
      <PageBackground formMode="light">
        <Box padding={{ xs: 4, md: 6 }}>
          <Container>
            <Stack space={{ xs: 5, md: 6 }}>
              {quoteSession.applicantDetails.map((applicant, i) => {
                // Find the display name
                const name = applicant.firstName
                  ? applicant.firstName
                  : `Person ${i + 1}`;
                const price = priceData
                  ? priceApiUtils.getApplicantTotalPrice(
                      applicant,
                      getQuoteSession()!,
                      priceData
                    )
                  : 0;
                return (
                  <QuoteSummaryCard
                    key={`quote-summary-card-${i}`}
                    name={name}
                    price={price}
                    frequency={quoteSession.paymentDetails.frequency}
                    age={applicant.age}
                    gender={applicant.gender}
                    smoker={applicant.smoker === 'Yes'}
                    productCodes={getProductCodes(applicant)}
                    excess={getExcess(applicant)}
                    nonPharmacPlus={getNonPharmacPlus(applicant)}
                    hasSpecialOffer={isEligibleForSpecialOffer(
                      applicant.id,
                      validateSpecialOfferResponse
                    )}
                    canDelete={quoteSession.applicantDetails.length > 1}
                    onDelete={() => {
                      gtmUtils.removePerson(FormPage.TailorYourQuote);
                      quoteSession.removeApplicant(applicant);
                      onSubmit(FormPage.TailorYourQuote, quoteSession);
                    }}
                    onEditDetails={() =>
                      navigate(
                        `/edit-applicant/${FormPage.TailorYourQuote}/${applicant.id}`
                      )
                    }
                    onEditCover={() =>
                      navigate(
                        `/edit-cover/${FormPage.TailorYourQuote}/${applicant.id}`
                      )
                    }
                  />
                );
              })}
              <SpecialOfferAlertWrapper />
              <SecondaryButton
                iconPlacement="left"
                icon={AddSystemIcon}
                onClick={() => {
                  navigate(`/add-applicant/${FormPage.TailorYourQuote}`);
                }}
                disabled={quoteSession.applicantDetails.length >= 26}
              >
                {content.addButton}
              </SecondaryButton>
              <BrandedStyleHeader>
                <ImportantInformation />
              </BrandedStyleHeader>
              <PromoCode quoteSession={quoteSession} onSubmit={onSubmit} />
              <form
                onSubmit={() => {
                  onSubmit(FormPage.BuyNow);
                }}
              >
                <Inline space={{ xs: 2, md: 4 }}>
                  <EmailQuoteButton></EmailQuoteButton>
                  <BottomButtons submitButtonText={content.nextButton} />
                </Inline>
              </form>
            </Stack>
            <NeedAdviceButton className="fixedFloating" />
          </Container>
        </Box>
      </PageBackground>
    </Box>
  );
};

export default QuoteSummaryPage;
