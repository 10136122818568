import {
  differenceInYears,
  format,
  formatISO,
  parse,
  startOfDay,
  subMonths,
  subYears,
} from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

export const NZ_TIMEZONE = 'Pacific/Auckland';
export const DATE_FORMAT = 'yyyy-MM-dd';
// set to 6months ago to ensure billing can be calculated correctly.
const monthsAgo = 6;

class DateUtils {
  public getNominalBirthdateForAge(yearsAgo: number): string {
    const startOfTodayInNZ = this.getStartOfDay();

    const sixMonthsFromTodayInNZ = subMonths(startOfTodayInNZ, monthsAgo);
    const dateYearsAgoInNZ = subYears(sixMonthsFromTodayInNZ, yearsAgo);

    return dateYearsAgoInNZ.toISOString();
  }

  public getCurrentDate(): string {
    const nowInNZ = utcToZonedTime(new Date(), NZ_TIMEZONE);
    return format(nowInNZ, DATE_FORMAT);
  }

  public getCurrentDateTimeIso(): string {
    const nowInNZ = utcToZonedTime(new Date(), NZ_TIMEZONE);
    return formatISO(nowInNZ);
  }

  /**
   * Convert a date from the format we use in the UI and store
   * in local storage to the format we need when submitting
   * to the submission service.
   *
   * @param inputDate A date in dd/mm/yyyy format
   * @returns A date in yyyy-MM-dd format
   */
  public formatUiDateToISO(inputDate: string): string {
    const parsedDate = parse(inputDate, 'dd/MM/yyyy', new Date());
    return format(parsedDate, 'yyyy-MM-dd');
  }

  /**
   * Convert a date from the format we use in the UI and store
   * in local storage to the format we need when submitting
   * to GTM/GA.
   *
   * @param inputDate A date in dd/mm/yyyy format
   * @returns A date in yyyyMMdd format
   */
  public formatUiDateToGtm(inputDate: string): string {
    const parsedDate = parse(inputDate, 'dd/MM/yyyy', new Date());
    return format(parsedDate, 'yyyyMMdd');
  }

  /**
   * Get the start of a date in NZ timezone, as a Date object.
   * Returns today by default.
   * If eg. it is a different date in NZ, will return the start
   */
  public getStartOfDay(date: Date = new Date()): Date {
    const dateInNZ = utcToZonedTime(date, NZ_TIMEZONE);
    return startOfDay(dateInNZ);
  }

  /**
   *
   * @param dateObj
   * @return The age indicated by the given date of birth
   */
  public getAgeForDateOfBirth(dateObj: Date): number {
    const dob = startOfDay(dateObj);
    const compare = utcToZonedTime(this.getStartOfDay(), NZ_TIMEZONE);
    return differenceInYears(compare, dob);
  }

  /**
   * Get a JS Date object with NZ timezone from a 'dd/mm/yyyy' format string.
   * @param dateString
   * @return Returns a Date object, or null if the format isn't dd/mm/yyyy or the date is badly malformed eg. '99/99/9999'
   */
  public getDateObject(dateString: string): Date | null {
    // Check format is correct
    const dateMatch = dateString.match(/^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/);
    if (!dateMatch) {
      return null;
    }
    const dateObj = utcToZonedTime(
      new Date(`${dateMatch[3]}-${dateMatch[2]}-${dateMatch[1]}`),
      NZ_TIMEZONE
    );
    if (isNaN(dateObj.valueOf())) {
      return null;
    }
    // 31 Feb eg. '31/02/1999' will output a Date object as 2 March.
    // Check that the new Date object actually matches what was typed.
    if (
      !(
        dateObj.getDate() === parseInt(dateMatch[1]) &&
        dateObj.getMonth() === parseInt(dateMatch[2]) - 1 && // JS Date.getMonth is 0-indexed :-S
        dateObj.getFullYear() === parseInt(dateMatch[3])
      )
    ) {
      return null;
    }
    return dateObj;
  }
}

const dateUtils = new DateUtils();
export default dateUtils;
