import { PrimaryButton } from '@nib-components/button';
import Heading from '@nib-components/heading';
import { Box, Stack } from '@nib/layout';
import Loader from '@nib/loader';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomisedForm from 'src/components/form/CustomisedForm';
import FormRadioGroup from 'src/components/form/FormRadioGroup';
import FormTextarea from 'src/components/form/FormTextArea';
import FormTextbox from 'src/components/form/FormTextbox';
import MarkdownContent from 'src/components/MarkdownContent';
import PageBackground from 'src/components/PageBackground';
import ResumeQuoteModal from 'src/components/ResumeQuote/ResumeQuoteModal';
import { useGetQuoteQuery } from 'src/services/join/joinApi';
import {
  JoinApiGetQuoteResponse,
  JoinApiRequestCallbackPreferredCallbackDay,
  JoinApiRequestCallbackPreferredCallbackTimeRange,
} from 'src/services/join/joinApiTypes';
import { useRequestCallback } from 'src/store/hooks/useRequestCallback';
import { QuoteSession } from 'src/types/QuoteSession';
import emailQuoteUtils from 'src/utils/emailQuoteUtils';
import config from 'src/utils/env';
import { createJoinApiGetQuoteOptions } from 'src/utils/joinApiUtils';
import { getQuoteSession, saveQuoteSession } from 'src/utils/localStorageUtils';
import { NeedAdviceFormValuesSchema } from 'src/utils/validation/schema/NeedAdviceFormValuesSchema';
import styled from 'styled-components';

const content = config.brand.content.needAdvice;

export interface ThankYouProps {
  getQuoteQueryResponse: JoinApiGetQuoteResponse | undefined;
  quoteLoaded: boolean;
  onQuoteLoaded: (quoteSession: QuoteSession) => void;
  onStartOverQuote: () => void;
}

const ThankYou = (props: ThankYouProps) => {
  const {
    getQuoteQueryResponse,
    quoteLoaded,
    onQuoteLoaded,
    onStartOverQuote,
  } = props;
  const navigate = useNavigate();
  const { search } = useLocation();
  const [showModal, setShowModal] = useState<boolean>(false);

  const resumeUrl = '/resume' + search;
  const quoteSession = getQuoteSession();

  const handleResume = async () => {
    const expired = getQuoteQueryResponse?.data.expired;
    if (expired) {
      setShowModal(true);
    } else {
      if (quoteSession) {
        onQuoteLoaded(quoteSession);
      } else {
        navigate(resumeUrl);
      }
    }
  };

  return (
    <Box>
      <Stack space={6}>
        <Heading size={2}>{content.thanksTitle}</Heading>
        <MarkdownContent content={content.thanksDetail} />
        <PrimaryButton
          type="submit"
          size="large"
          fullWidth={{ xs: true, md: false }}
          onClick={() => {
            handleResume();
          }}
        >
          {content.continueQuoteButtonText}
        </PrimaryButton>
      </Stack>
      {showModal && (
        <ResumeQuoteModal
          quoteLoaded={quoteLoaded}
          onGetUpdatedQuote={() => {
            if (quoteSession) {
              onQuoteLoaded(quoteSession);
            } else if (getQuoteQueryResponse) {
              const { quoteDetails } = getQuoteQueryResponse.data;
              const quoteSession =
                emailQuoteUtils.createQuoteSession(quoteDetails);
              saveQuoteSession(quoteSession);
              onQuoteLoaded(quoteSession);
            }
          }}
          onStartOverQuote={() => {
            onStartOverQuote();
          }}
        />
      )}
    </Box>
  );
};

const CenteredContainer = styled('div')`
  display: flex;
  justify-content: center;
`;

const StyledCustomisedForm = styled(CustomisedForm)`
  padding: 0px;
`;

export interface NeedAdviceFormValues {
  firstName: string;
  phoneNumber: string;
  preferredCallbackDay: string;
  preferredCallbackTimeRange: string;
  notes?: string;
}

const BookACallPage = ({
  onQuoteLoaded,
  onStartOverQuote,
}: {
  onQuoteLoaded: (quoteSession: QuoteSession) => void;
  onStartOverQuote: () => void;
}) => {
  const initialValues: NeedAdviceFormValues = {
    firstName: '',
    phoneNumber: '',
    preferredCallbackDay: 'AsSoonAsPossible',
    preferredCallbackTimeRange: 'AnyTime',
  };
  const [getQuoteHandled, setGetQuoteHandled] = useState(false);
  const [quoteLoaded, setQuoteLoaded] = useState<boolean>(false);
  const [formValues, setFormValues] =
    useState<NeedAdviceFormValues>(initialValues);
  const [showThankYou, setShowThankYou] = useState(false);
  const navigate = useNavigate();
  const { search } = useLocation();
  const location = useLocation();
  const [isLoading, setIsLoading] = React.useState(false);
  const createCallBack = useRequestCallback();

  const joinId = new URLSearchParams(search).get('id')!;
  const version = new URLSearchParams(search).get('v')!;

  const returnUrl = location.pathname + search;
  const encodedReturnURL = encodeURIComponent(returnUrl);

  const handleSubmit = async (values: NeedAdviceFormValues) => {
    try {
      const bookACallPageRequest = true;
      setIsLoading(true);
      const quoteSession = getQuoteSession() ?? undefined;
      await createCallBack(
        values.firstName,
        values.phoneNumber,
        bookACallPageRequest,
        values.preferredCallbackDay,
        values.preferredCallbackTimeRange,
        values.notes,
        quoteSession
      );
      setIsLoading(false);
      setShowThankYou(true);
    } catch (error) {
      console.warn(
        'Failed to request call back on book a call ... navigating to the error page'
      );
      navigate(`/error?returnURL=${encodedReturnURL}`);
    }
  };

  const {
    data: getQuoteQueryResponse,
    error,
    isSuccess,
    isLoading: isLoadingGetQuote,
  } = useGetQuoteQuery(createJoinApiGetQuoteOptions(joinId, +version));

  if (error && !getQuoteHandled) {
    setGetQuoteHandled(true);
    if ('status' in error) {
      const { status } = error;
      if (status === 404) {
        setQuoteLoaded(false);
        setFormValues({
          firstName: '',
          phoneNumber: '',
          preferredCallbackDay: 'AsSoonAsPossible',
          preferredCallbackTimeRange: 'AnyTime',
        });
      } else {
        console.warn(
          'Failed to get quote on book a call ... navigating to the error page'
        );
        navigate(`/error?returnURL=${encodedReturnURL}`);
      }
    }
  }
  if (isSuccess && !getQuoteHandled) {
    setGetQuoteHandled(true);
    if (isSuccess) {
      const { quoteDetails, expired } = getQuoteQueryResponse.data;
      const policyOwner = quoteDetails.applicants.find(
        (applicant) => applicant.isPolicyOwner
      );
      setQuoteLoaded(true);
      const quoteSession = emailQuoteUtils.createQuoteSession(quoteDetails);
      emailQuoteUtils.saveResumedQuoteDetails(joinId, quoteDetails, expired);
      saveQuoteSession(quoteSession);

      setFormValues({
        firstName: policyOwner?.firstName ?? '',
        phoneNumber: policyOwner?.phone ?? '',
        preferredCallbackDay: 'AsSoonAsPossible',
        preferredCallbackTimeRange: 'AnyTime',
      });
    }
  }

  return (
    <>
      <PageBackground formMode="light">
        <CenteredContainer>
          <Box padding={8}>
            {showThankYou ? (
              <ThankYou
                getQuoteQueryResponse={getQuoteQueryResponse}
                quoteLoaded={quoteLoaded}
                onQuoteLoaded={onQuoteLoaded}
                onStartOverQuote={onStartOverQuote}
              />
            ) : (
              <>
                <Box maxWidth="600px">
                  <Stack space={6}>
                    <Heading size={2}>{content.title}</Heading>
                    <MarkdownContent content={content.mainMessage} />
                    <MarkdownContent content={content.promptToCall} />
                  </Stack>
                </Box>
                <Box maxWidth="600px">
                  <Formik
                    enableReinitialize={true}
                    initialValues={formValues}
                    onSubmit={handleSubmit}
                    validationSchema={NeedAdviceFormValuesSchema}
                    /* Without validateOnBlur=false, validation runs twice and the second time has the old value */
                    validateOnBlur={false}
                  >
                    {({ isSubmitting, handleReset, handleSubmit }) => {
                      return isLoadingGetQuote ? (
                        <>
                          <Box padding={8} textAlign={'center'}>
                            <Loader size="xl" />
                          </Box>
                        </>
                      ) : (
                        <StyledCustomisedForm
                          id="needAdvice"
                          name="needAdvice"
                          onReset={handleReset}
                          onSubmit={handleSubmit}
                          formMode={'light'}
                        >
                          <Stack space={6}>
                            <FormTextbox
                              label={content.nameLabel}
                              name="firstName"
                              maxLength={30}
                              formMode="light"
                              autoComplete="first-name"
                            />
                            <FormTextbox
                              label={content.phoneNumberLabel}
                              name="phoneNumber"
                              maxLength={30}
                              formMode="light"
                              type="tel"
                              autoComplete="tel"
                            />
                            <>
                              <FormRadioGroup
                                label={content.chooseDayToCallLabel}
                                name={'preferredCallbackDay'}
                                formMode="light"
                                options={
                                  JoinApiRequestCallbackPreferredCallbackDay
                                }
                              />
                              <FormRadioGroup
                                label={content.chooseTimeToCallLabel}
                                name={'preferredCallbackTimeRange'}
                                formMode="light"
                                options={
                                  JoinApiRequestCallbackPreferredCallbackTimeRange
                                }
                              />
                              <FormTextarea
                                name="notes"
                                label={content.notesLabel}
                                formMode="light"
                                isOptional={true}
                                tag="optional"
                              />
                              <MarkdownContent
                                content={content.callAtBestTimeMessage}
                              />
                            </>
                            <PrimaryButton
                              disabled={isSubmitting}
                              onClick={handleSubmit}
                            >
                              {content.bookCallButtonText}
                            </PrimaryButton>
                          </Stack>
                          <MarkdownContent
                            content={content.promptForFinancialAdvice}
                          />

                          {isLoading && <Loader />}
                        </StyledCustomisedForm>
                      );
                    }}
                  </Formik>
                </Box>
              </>
            )}
          </Box>
        </CenteredContainer>
      </PageBackground>
    </>
  );
};

export default BookACallPage;
