import { colorDark, linkLoud } from '@nib-components/theme';
import { Box } from '@nib/layout';
import React from 'react';
import HeightMatchingGroup from 'src/components/HeightMatchingGroup';
import { ProductConfig } from 'src/types/ProductConfig';
import { PaymentFrequency } from 'src/types/QuoteSession';
import styled from 'styled-components';
import { mx, p } from 'styled-components-spacing';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { COVER_HIGHLIGHTS_LIST_CLASSNAME } from './CoverHighlightsList';
import ProductCard, { PRODUCT_CARD_CLASSNAME } from './ProductCard';

interface ProductCardListProps {
  selected: string | undefined;
  onSelect: (productCode: string) => void;
  // Manages state of frequency selects
  frequency: PaymentFrequency;
  setFrequency: (f: PaymentFrequency) => void;
  products: ProductConfig[];
}

// Puts bullet pagination at the top of the swiper instead of the bottom
const StyledSwiper = styled('div')`
  & .swiper {
    display: flex;
    flex-direction: column-reverse;
  }
`;

const StyledPagination = styled.div`
  position: unset !important;
  margin-bottom: 1rem;

  .swiper-pagination-bullet {
    ${p(1)}
    ${mx(2)}
    background-color: ${colorDark};
  }
  .swiper-pagination-bullet-active {
    background-color: ${linkLoud};
  }
`;

export const ProductCardList = (props: ProductCardListProps) => {
  const { selected, onSelect, frequency, setFrequency, products } = props;

  // Benefit parent expander expansion is shared between all product cards in a group
  const [benefitsVisible, setBenefitsVisible] = React.useState<boolean>(false);

  // Benefit Accordion expansion is shared by all product cards in a group
  const [expandedIndex, setExpandedIndex] = React.useState<number | undefined>(
    undefined
  );

  return (
    <HeightMatchingGroup selector={`.${PRODUCT_CARD_CLASSNAME}`}>
      <HeightMatchingGroup selector={`.${COVER_HIGHLIGHTS_LIST_CLASSNAME}`}>
        <Box marginTop={4}>
          <StyledSwiper>
            <Swiper
              modules={[Pagination]}
              pagination={{
                el: '.swiper-pagination',
                clickable: true,
                type: 'bullets',
              }}
              slidesPerView={products.length === 1 ? 1 : 1.2}
              breakpoints={{
                800: {
                  slidesPerView: 2,
                },
              }}
              spaceBetween={24}
            >
              <StyledPagination className="swiper-pagination" />
              {products.length &&
                products.map((product) => (
                  <SwiperSlide key={product.productDetails.code}>
                    <ProductCard
                      data-testid={`product-card-item-${product.productDetails.code}`}
                      product={product}
                      // Controls the 'Select this plan' primary button
                      selected={selected === product.productDetails.code}
                      onSelect={() => {
                        onSelect(product.productDetails.code);
                      }}
                      frequency={frequency}
                      setFrequency={setFrequency}
                      // Controls the 'View more details' expander
                      isExpanded={benefitsVisible}
                      onExpand={(expanded) => setBenefitsVisible(expanded)}
                      // Controls the individual benefit expanders
                      expandedBenefitIndex={expandedIndex}
                      setExpandedBenefitIndex={setExpandedIndex}
                    />
                  </SwiperSlide>
                ))}
            </Swiper>
          </StyledSwiper>
        </Box>
      </HeightMatchingGroup>
    </HeightMatchingGroup>
  );
};
