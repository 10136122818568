import { FieldsetFormControl } from '@nib-components/form-control';
import Radio from '@nib-components/radio';
import { Box, Tiles } from '@nib/layout';
import { Nullable } from '@nib/layout/dist/@types/utils';
import Loader from '@nib/loader';
import { addDays, format, parse, startOfTomorrow } from 'date-fns';
import { useFormikContext } from 'formik';
import { clone } from 'ramda';
import React from 'react';
import { PriceContext } from 'src/contexts/PriceContext';
import { useQuoteSession } from 'src/contexts/QuoteSessionProvider';
import priceApiUtils from 'src/services/price/priceApiUtils';
import { BuyNowFormData } from 'src/types/BuyNowForm';
import { FormPage } from 'src/types/FormPage';
import {
  PaymentFrequency,
  PaymentMethod,
  QuoteSession,
} from 'src/types/QuoteSession';
import { isValidFirstPaymentDate } from 'src/utils/buyNowUtils';
import { formatCurrency } from 'src/utils/formatters/formatCurrency';
import { CREDIT_CARD_INVALID_PAYMENT_FREQUENCIES } from 'src/utils/formPageUtils';
import styled from 'styled-components';
import { FIRST_PAYMENT_DATE_FORMAT } from './FirstPaymentDateField';

const FullWidthRadio = styled(Radio)`
  display: block;
  margin-bottom: 0;
`;

interface PaymentFrequencyFieldProps {
  label: string;
  name: string;
  columns?: Nullable<0 | 1 | 4 | 2 | 3 | 5 | 6>;
  // Payment details affect the top header, so we need to be able to push these values into
  // the top-level Quote Session immediately instead of into the local form state.
  onSubmit: (nextPageId: FormPage | null, quoteSession?: QuoteSession) => void;
}

const PaymentFrequencyField = (props: PaymentFrequencyFieldProps) => {
  const { label, name, columns = 1, onSubmit } = props;
  const { data, isFetching } = React.useContext(PriceContext);
  const quoteSession = useQuoteSession();
  const { values } = useFormikContext<BuyNowFormData>();

  // Convert array of payment frequency values into radiogroup options
  const paymentFrequencyOptions = Object.values(PaymentFrequency)
    .filter(
      (f) =>
        // For Direct Debit, all options are OK
        quoteSession.paymentDetails.paymentMethod ===
          PaymentMethod.DirectDebit ||
        // For Credit Card, filter out invalid options
        !CREDIT_CARD_INVALID_PAYMENT_FREQUENCIES.includes(f)
    )
    .map((paymentFrequencyOption) => {
      // Use a temp session with the payment frequency set to calculate the pricing
      const tempQuoteSession = clone(quoteSession);
      tempQuoteSession.paymentDetails.frequency = paymentFrequencyOption;
      const price = priceApiUtils.getPolicyTotalPriceForQuoteSession(
        data,
        tempQuoteSession
      );
      return {
        value: paymentFrequencyOption,
        price: price,
        isFetching: isFetching,
      };
    });

  return (
    <FieldsetFormControl id={name} name={name} label={label} formMode="light">
      <Tiles space={{ xs: 4, md: 5 }} columns={{ md: columns }}>
        {paymentFrequencyOptions.map((option) => (
          <Box key={`paymentFrequency-${option.value}`} width="100%">
            <FullWidthRadio
              label={option.value}
              subtitle={
                option.isFetching ? (
                  <Loader size="xs" />
                ) : (
                  formatCurrency(option.price)
                )
              }
              id={`paymentFrequency-${option.value}`}
              selected={quoteSession.paymentDetails.frequency === option.value}
              value={option.value}
              onClick={() => {
                // Push frequency changes directly into the quote session, so they
                // can update the top header.
                const newQuoteSession = clone(quoteSession);
                // Include local form values, so when Formik reinitialises we do not lose
                // our local changes.
                newQuoteSession.paymentDetails = values.paymentDetails;
                // Add our new frequency value
                newQuoteSession.paymentDetails.frequency = option.value;
                // Check we have a valid first payment date, and adjust it if needed.
                if (
                  newQuoteSession.paymentDetails.firstPaymentDate &&
                  !isValidFirstPaymentDate(
                    parse(
                      newQuoteSession.paymentDetails.firstPaymentDate,
                      FIRST_PAYMENT_DATE_FORMAT,
                      new Date()
                    ),
                    newQuoteSession.paymentDetails.frequency
                  )
                ) {
                  // Find a date we can set; start tomorrow, and try successive days
                  // until we find one that's allowed
                  let newFirstPaymentDate = startOfTomorrow();
                  while (
                    !isValidFirstPaymentDate(
                      newFirstPaymentDate,
                      newQuoteSession.paymentDetails.frequency
                    )
                  ) {
                    newFirstPaymentDate = addDays(newFirstPaymentDate, 1);
                  }
                  newQuoteSession.paymentDetails.firstPaymentDate = format(
                    newFirstPaymentDate,
                    FIRST_PAYMENT_DATE_FORMAT
                  );
                }
                onSubmit(null, newQuoteSession);
              }}
              inline={false}
            />
          </Box>
        ))}
      </Tiles>
    </FieldsetFormControl>
  );
};

export default PaymentFrequencyField;
