import { NeedAdviceFormValues } from 'src/components/NeedAdvice/NeedAdviceModal';
import config from 'src/utils/env';
import * as Yup from 'yup';
import { validatePersonalName } from '../validation';
import PhoneSchema from './PhoneSchema';

const messages = config.brand.content.validation;

export const NeedAdviceFormValuesSchema: Yup.ObjectSchema<NeedAdviceFormValues> =
  Yup.object().shape({
    firstName: Yup.string()
      .ensure()
      .required(messages.name.required)
      .max(30, messages.name.maxLength)
      .test(validatePersonalName(messages.name.format)),
    phoneNumber: PhoneSchema.required(messages.phoneNeedAdvice.required),
    preferredCallbackDay: Yup.string().ensure(),
    preferredCallbackTimeRange: Yup.string().ensure(),
    notes: Yup.string().ensure().max(1000, messages.notes.maxLength),
  });
