import { useFormikContext } from 'formik';
import { path } from 'ramda';
import React from 'react';
import FormTextbox, { FormTextboxProps } from '../form/FormTextbox';

// Convert applicants.n.age => applicants.n.smoker
function getSmokerName(ageName: string): string {
  const smokerPath = [...ageName.split('.')];
  smokerPath[smokerPath.length - 1] = 'smoker';
  return smokerPath.join('.');
}

/**
 * Updates the smoker field based on the value of age entered.
 */
const AgeFormField = (props: FormTextboxProps) => {
  const { values, touched, setFieldValue } = useFormikContext();

  const agePath = props.name.split('.');
  const ageValue: number | undefined = parseInt(path(agePath, values) ?? '');
  const ageTouched: boolean | undefined = path(agePath, touched);

  const smokerName = getSmokerName(props.name);

  React.useEffect(() => {
    if (ageTouched && !isNaN(ageValue) && ageValue < 18) {
      setFieldValue(smokerName, 'No');
    }
  }, [setFieldValue, smokerName, ageValue, ageTouched]);

  return <FormTextbox {...props} />;
};

export default AgeFormField;
