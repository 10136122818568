import { addDays, endOfMonth, isAfter, isBefore, parse } from 'date-fns';
import { FIRST_PAYMENT_DATE_FORMAT } from 'src/components/BuyNow/FirstPaymentDateField';
import { BuyNowFormData, BuyNowFormDataItem } from 'src/types/BuyNowForm';
import {
  PaymentFrequency,
  PaymentMethod,
  QuoteSession,
} from 'src/types/QuoteSession';
import dateUtils from './dateUtils';
import config from './env';

/**
 * Function to determine whether a date is a valid first payment date for a given payment frequency.
 * @param date
 * @param paymentFrequency
 * @returns True if the date is a valid first payment date.
 */
export const isValidFirstPaymentDate = (
  date: Date,
  paymentFrequency: PaymentFrequency
): boolean => {
  if (
    [PaymentFrequency.Weekly, PaymentFrequency.Fortnightly].includes(
      paymentFrequency
    )
  ) {
    // For Weekly/Fortnightly frequencies,
    // exclude Sunday (0) and Saturday (6)
    const dayOfWeek = date.getDay();
    if (dayOfWeek === 0 || dayOfWeek === 6) {
      return false;
    }
  } else {
    // For all other frequencies, exclude 29th/30th/31st
    const dayOfMonth = date.getDate();
    if (dayOfMonth > 28) {
      return false;
    }
  }
  // First available date is tomorrow
  if (isBefore(date, dateUtils.getStartOfDay(addDays(new Date(), 1)))) {
    return false;
  }

  if (isAfter(date, getLatestFirstPaymentDate(paymentFrequency))) {
    return false;
  }
  // No problem, this date can be selected.
  return true;
};

export const getEarliestFirstPaymentDate = (
  paymentFrequency: PaymentFrequency
): Date => {
  let candidateDate = dateUtils.getStartOfDay(addDays(new Date(), 1));
  while (!isValidFirstPaymentDate(candidateDate, paymentFrequency)) {
    candidateDate = addDays(candidateDate, 1);
  }
  return candidateDate;
};

export const getLatestFirstPaymentDate = (
  paymentFrequency: PaymentFrequency
): Date => {
  // Last available date depends on the frequency
  const policyStartDate = dateUtils.getStartOfDay();
  switch (paymentFrequency) {
    case PaymentFrequency.Weekly:
      return addDays(policyStartDate, 7);
    case PaymentFrequency.Fortnightly:
      return addDays(policyStartDate, 14);
    case PaymentFrequency.Monthly:
    case PaymentFrequency.Quarterly:
    case PaymentFrequency.HalfYearly:
    case PaymentFrequency.Yearly:
      return addDays(policyStartDate, 30);
  }
};

/**
 * Returns whether the current data would result in a double deduction.
 */
export const hasDoubleDeduction = (
  firstPaymentDateString: string,
  paymentFrequency: PaymentFrequency
): boolean => {
  const firstPaymentDate = dateUtils.getStartOfDay(
    parse(firstPaymentDateString, FIRST_PAYMENT_DATE_FORMAT, new Date())
  );
  const policyStartDate = dateUtils.getStartOfDay();
  // The date after which a double deduction will be incurred
  let doubleDeductionFromDate: Date;
  switch (paymentFrequency) {
    case PaymentFrequency.Weekly:
    case PaymentFrequency.Fortnightly:
      // Payment date on the last allowed day: Double deduction
      doubleDeductionFromDate = addDays(
        getLatestFirstPaymentDate(paymentFrequency),
        -1
      );
      break;
    case PaymentFrequency.Monthly:
      // Payment date not in same calendar month - double deduction
      doubleDeductionFromDate = endOfMonth(policyStartDate);
      break;
    case PaymentFrequency.Quarterly:
    case PaymentFrequency.HalfYearly:
    case PaymentFrequency.Yearly:
      // For other frequencies we show a different message.
      return false;
  }
  return isAfter(firstPaymentDate, doubleDeductionFromDate);
};

export const transformPartialQuoteSessionToFormData = (
  quoteSession: Pick<
    QuoteSession,
    | 'applicantDetails'
    | 'applicantExtraDetails'
    | 'memberPolicyDetails'
    | 'declaration'
    | 'adviserDeclaration'
    | 'yourDetailsDeclaration'
    | 'directDebitAdviserDeclaration'
    | 'creditCardAdviserDeclaration'
    | 'paymentDetails'
    | 'buyNowStep'
    | 'hasMember'
    | 'externalMemberApplicant'
    | 'externalMemberNumber'
  >
): BuyNowFormData => {
  const formData: BuyNowFormDataItem[] = [];
  quoteSession.applicantDetails.forEach((applicant) => {
    formData.push({
      ...applicant,
      ...quoteSession.applicantExtraDetails[applicant.id],
      isGuardian: applicant.isGuardian ?? false,
      hasHospitalCover:
        !!quoteSession.memberPolicyDetails[applicant.id].hospitalProductCode,
    });
  });
  return {
    applicantExtraDetails: formData,
    declaration: !config.brand.hasAdviser
      ? quoteSession.declaration
      : undefined,
    adviserDeclaration: config.brand.hasAdviser
      ? quoteSession.adviserDeclaration
      : undefined,
    yourDetailsDeclaration: config.brand.hasAdviser
      ? quoteSession.yourDetailsDeclaration
      : undefined,
    directDebitAdviserDeclaration: config.brand.hasAdviser
      ? quoteSession.directDebitAdviserDeclaration
      : undefined,
    creditCardAdviserDeclaration: config.brand.hasAdviser
      ? quoteSession.creditCardAdviserDeclaration
      : undefined,
    paymentDetails: quoteSession.paymentDetails,
    buyNowStep: quoteSession.buyNowStep,
    hasMember: quoteSession.hasMember,
    // If we only have one applicant, set the external member
    // to this applicant by default
    externalMemberApplicant:
      quoteSession.applicantDetails.length === 1
        ? quoteSession.applicantDetails[0].id
        : quoteSession.externalMemberApplicant,
    externalMemberNumber: quoteSession.externalMemberNumber,
  };
};

export const transformFormDataToPartialQuoteSession = (
  formData: BuyNowFormData
): Pick<
  QuoteSession,
  | 'applicantDetails'
  | 'applicantExtraDetails'
  | 'declaration'
  | 'adviserDeclaration'
  | 'yourDetailsDeclaration'
  | 'directDebitAdviserDeclaration'
  | 'creditCardAdviserDeclaration'
  | 'paymentDetails'
  | 'buyNowStep'
  | 'hasMember'
  | 'externalMemberApplicant'
  | 'externalMemberNumber'
> => {
  const quoteSession: Pick<
    QuoteSession,
    | 'applicantDetails'
    | 'applicantExtraDetails'
    | 'declaration'
    | 'adviserDeclaration'
    | 'yourDetailsDeclaration'
    | 'directDebitAdviserDeclaration'
    | 'creditCardAdviserDeclaration'
    | 'paymentDetails'
    | 'buyNowStep'
    | 'hasMember'
    | 'externalMemberApplicant'
    | 'externalMemberNumber'
  > = {
    applicantDetails: [],
    applicantExtraDetails: {},
    paymentDetails: formData.paymentDetails,
    buyNowStep: formData.buyNowStep,
    hasMember: formData.hasMember,
    externalMemberApplicant: formData.externalMemberApplicant,
    externalMemberNumber: formData.externalMemberNumber,
  };
  formData.applicantExtraDetails.forEach((applicantData) => {
    // Separate combined object back out into applicantDetails and applicantExtraDetails
    const {
      id,
      firstName,
      age,
      phone,
      gender,
      smoker,
      isPolicyOwner,
      isGuardian,
      ...applicantExtraDetails
    } = applicantData;
    quoteSession.applicantDetails.push({
      id,
      firstName,
      age,
      phone,
      gender,
      smoker,
      isPolicyOwner,
      isGuardian,
    });
    quoteSession.applicantExtraDetails[id] = applicantExtraDetails;
    if (config.brand.hasAdviser) {
      quoteSession.adviserDeclaration = formData.adviserDeclaration;
      if (
        quoteSession.paymentDetails.paymentMethod === PaymentMethod.DirectDebit
      ) {
        quoteSession.directDebitAdviserDeclaration =
          formData.directDebitAdviserDeclaration;
      }
      if (
        quoteSession.paymentDetails.paymentMethod === PaymentMethod.CreditCard
      ) {
        quoteSession.creditCardAdviserDeclaration =
          formData.creditCardAdviserDeclaration;
      }
    } else {
      quoteSession.declaration = formData.declaration;
    }
  });
  return quoteSession;
};
