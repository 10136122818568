import HeaderFooterLayout from '@nib-components/header-footer-layout';
import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Footer } from './Footer';
import { Header } from './Header';

const AppContainer = () => {
  const { pathname } = useLocation();

  React.useEffect(() => {
    // Scroll to the top of the page whenever we nagivate
    //
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <HeaderFooterLayout header={<Header />} footer={<Footer />}>
      <Outlet />
    </HeaderFooterLayout>
  );
};

export default AppContainer;
