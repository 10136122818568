import { PrimaryButton, SecondaryButton } from '@nib-components/button';
import { ChevronRightSystemIcon, IconType } from '@nib/icons';
import { Box, Inline } from '@nib/layout';
import {
  Nullable,
  ResponsiveOrStaticProp,
} from '@nib/layout/dist/@types/utils';

interface BottomButtonsProps {
  submitButtonText: string;
  submitButtonColor?: 'light' | 'dark' | 'destructive';
  submitButtonSize?: ResponsiveOrStaticProp<
    Nullable<'small' | 'medium' | 'large'>
  >;
  submitButtonIcon?: IconType;
  cancelButtonText?: string;
  cancelButtonColor?: 'light' | 'dark' | 'destructive';
  cancelButtonSize?: ResponsiveOrStaticProp<
    Nullable<'small' | 'medium' | 'large'>
  >;
  hideSubmitButtonIcon?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  onCancel?: () => void;
}

const BottomButtons = (props: BottomButtonsProps) => {
  return (
    <Box marginBottom={{ xs: 4, md: 0 }} className="bottomButtons">
      <Inline space={4} collapseBelow="md">
        {props.cancelButtonText && (
          <SecondaryButton
            type="button"
            fullWidth={{ xs: true, md: false }}
            size={props.cancelButtonSize ?? { xs: 'small', md: 'medium' }}
            onClick={props.onCancel}
            color={props.cancelButtonColor}
            disabled={props.disabled}
          >
            {props.cancelButtonText}
          </SecondaryButton>
        )}
        <PrimaryButton
          // Hide the button if instructed; otherwise, use the provided icon,
          // falling back to the right-chevron
          icon={
            props.hideSubmitButtonIcon
              ? null
              : props.submitButtonIcon ?? ChevronRightSystemIcon
          }
          // If we specify an icon, put it on the left.
          // Otherwise, the right-chevron appears on the right.
          iconPlacement={props.submitButtonIcon ? 'left' : 'right'}
          type="submit"
          size={props.submitButtonSize ?? 'large'}
          fullWidth={{ xs: true, md: false }}
          color={props.submitButtonColor}
          disabled={props.disabled}
          isLoading={props.isLoading}
        >
          {props.submitButtonText}
        </PrimaryButton>
      </Inline>
    </Box>
  );
};

export default BottomButtons;
