import {
  ApplicantDetails,
  ApplicantExtraDetails,
  PaymentFrequency,
  PaymentMethod,
  QuoteSession,
} from 'src/types/QuoteSession';
import { generateCorrelationId } from 'src/utils/correlationUtils';
import dateUtils from 'src/utils/dateUtils';
import { getJoinApiBrand } from 'src/utils/joinApiUtils';
import {
  getAdviserDetails,
  getDefaultCorrelationid,
  getJoinId,
  getOriginatingBrand,
} from 'src/utils/localStorageUtils';
import { getProductByCode } from 'src/utils/productUtils';
import { JoinApiBrand } from '../join/joinApiTypes';
import { PriceApiFetchPriceResponse } from '../price/priceApiTypes';
import priceApiUtils from '../price/priceApiUtils';
import {
  JoinApiAdviserDetails,
  JoinSubmission,
  JoinSubmissionAddonProduct,
  JoinSubmissionContactDetails,
  JoinSubmissionIndividualDetails,
  JoinSubmissionMemberDetails,
  JoinSubmissionOptions,
  JoinSubmissionParty,
  JoinSubmissionPayment,
  JoinSubmissionPaymentFrequency,
  JoinSubmissionPolicy,
  JoinSubmissionPolicyAddress,
  JoinSubmissionProduct,
} from './submissionApiTypes';

export class SubmissionApiUtils {
  createSubmissionOptions(
    quoteSession: QuoteSession,
    priceData: PriceApiFetchPriceResponse,
    effectiveCampaignCode: string | null
  ): JoinSubmissionOptions {
    const options: JoinSubmissionOptions = {
      correlationId: generateCorrelationId(),
      joinSubmission: this.getJoinSubmission(
        quoteSession,
        effectiveCampaignCode,
        priceData
      ),
    };
    return options;
  }

  getJoinSubmission(
    quoteSession: QuoteSession,
    effectiveCampaignCode: string | null,
    priceData: PriceApiFetchPriceResponse
  ): JoinSubmission {
    return {
      metadata: {
        correlationId: this.getJoinMetadataCorrelationId(),
        version: 1,
      },
      data: {
        brand: this.getSubmissionBrand(),
        uiBrand: this.getSubmissionUiBrand(),
        policy: this.getJoinSubmissionPolicy(
          quoteSession,
          effectiveCampaignCode
        ),
        parties: this.getJoinSubmissionParties(quoteSession, priceData),
        payment: this.getJoinSubmissionPayment(quoteSession, priceData),
        adviserDetails: this.getSubmissionAdviserDetails(quoteSession),
      },
    };
  }

  getSubmissionBrand(): JoinApiBrand {
    return getOriginatingBrand() || getJoinApiBrand();
  }

  getSubmissionUiBrand(): JoinApiBrand {
    return getJoinApiBrand();
  }

  getSubmissionAdviserDetails(
    quoteSession: QuoteSession
  ): JoinApiAdviserDetails | null {
    // If we are on AACN ... or on AA with a resumed quote from AACN ...
    // then the adviserDetails should be in the QuoteSession
    //
    if (quoteSession.adviserDetails) {
      return {
        uan: quoteSession.adviserDetails.uan,
        agreementId: quoteSession.adviserDetails.agreementId,
      };
    }

    // If there are no adviserDetails in the QuoteSession ... there is still a chance
    // that there may be some adviserDetails that we stored in local storage in AA
    // when we resumed a quote from AACN.  If there are ... then use them.
    //
    const savedAdviserDetails = getAdviserDetails();
    if (savedAdviserDetails) {
      return {
        uan: savedAdviserDetails.uan,
        agreementId: savedAdviserDetails.agreementId,
      };
    }

    // Otherwise, this is a "normal" join ... nib or AA ... with no adviser
    return null;
  }

  getJoinMetadataCorrelationId(): string {
    // If the Join API is ever down, it is possible that a user could
    // go through the whole flow and submit a join.
    // In this case, we wouldn't have a saved join id since all of our calls
    // to persist the cart would have failed.
    // In this case, we need to pass some kind of guid so that when the orchestration
    // calls Redshift to create the policy in BOA it doesn't fail.
    // We use the "default correlation id" for this as the next best thing
    // as this id should be searchable in SumoLogic to find logging related to this join.
    // This id is generated automatically by the FE the first time it tries to log
    // anything so should always be set by the time we get to this point.
    //
    return getJoinId() || getDefaultCorrelationid()!;
  }

  getJoinSubmissionPolicy(
    quoteSession: QuoteSession,
    effectiveCampaignCode: string | null
  ): JoinSubmissionPolicy {
    const policy: JoinSubmissionPolicy = {
      effectiveDate: dateUtils.getCurrentDate(),
      policyAddress: this.getPolicyAddress(quoteSession),
      promoCode: quoteSession.promoCode,
      campaignCodes: [],
    };
    if (effectiveCampaignCode) {
      policy.campaignCodes = [effectiveCampaignCode];
    }
    if (quoteSession.hasMember && quoteSession.externalMemberNumber) {
      // Strip spaces from membership number before sending
      policy.externalMemberNumber = quoteSession.externalMemberNumber.replace(
        /\s/g,
        ''
      );
    }
    return policy;
  }

  getPolicyAddress(quoteSession: QuoteSession): JoinSubmissionPolicyAddress {
    const extraDetails = this.getNominatedOwnerExtraDetails(quoteSession);
    return {
      streetAddress: extraDetails.addressLine1,
      suburb: extraDetails.addressLine2,
      city: extraDetails.addressLine3,
      postCode: extraDetails.addressLine4,
      country: extraDetails.addressLine5,
    };
  }

  getNominatedOwnerExtraDetails(
    quoteSession: QuoteSession
  ): ApplicantExtraDetails {
    const nominatedOwner = quoteSession.getNominatedOwner();
    const extraDetails = quoteSession.applicantExtraDetails[nominatedOwner.id];
    return extraDetails;
  }

  getJoinSubmissionParties(
    quoteSession: QuoteSession,
    priceData: PriceApiFetchPriceResponse
  ): JoinSubmissionParty[] {
    return quoteSession.applicantDetails.map((applicantDetails) => {
      return this.getParty(applicantDetails, quoteSession, priceData);
    });
  }

  getParty(
    applicantDetails: ApplicantDetails,
    quoteSession: QuoteSession,
    priceData: PriceApiFetchPriceResponse
  ): JoinSubmissionParty {
    const extraDetails =
      quoteSession.applicantExtraDetails[applicantDetails.id];

    const party: JoinSubmissionParty = {
      isPolicyHolder: applicantDetails.isPolicyOwner,
      individualDetails: this.getPartyIndividualDetails(
        applicantDetails,
        extraDetails
      ),
      memberDetails: this.getPartyMemberDetails(
        applicantDetails,
        quoteSession,
        priceData
      ),
    };
    if (applicantDetails.isPolicyOwner) {
      party.contactDetails = this.getPartyContactDetails(
        applicantDetails,
        extraDetails
      );
    }
    return party;
  }

  getPartyIndividualDetails(
    applicantDetails: ApplicantDetails,
    extraDetails: ApplicantExtraDetails
  ): JoinSubmissionIndividualDetails {
    return {
      firstName: applicantDetails.firstName,
      lastName: extraDetails.surname,
      dateOfBirth: dateUtils.formatUiDateToISO(extraDetails.dateOfBirth),
    };
  }

  getPartyContactDetails(
    applicantDetails: ApplicantDetails,
    extraDetails: ApplicantExtraDetails
  ): JoinSubmissionContactDetails {
    return {
      email: extraDetails.email,
      phoneNumber: applicantDetails.phone,
    };
  }

  getPartyMemberDetails(
    applicantDetails: ApplicantDetails,
    quoteSession: QuoteSession,
    priceData: PriceApiFetchPriceResponse
  ): JoinSubmissionMemberDetails {
    return {
      gender: applicantDetails.gender as 'Male' | 'Female',
      isSmoker: this.getPartyMemberSmoker(applicantDetails),
      products: this.getPartyProducts(
        applicantDetails.id,
        quoteSession,
        priceData
      ),
    };
  }

  getPartyMemberSmoker(applicantDetails: ApplicantDetails): boolean {
    return applicantDetails.smoker === 'Yes';
  }

  getPartyProducts(
    applicantId: string,
    quoteSession: QuoteSession,
    priceData: PriceApiFetchPriceResponse
  ): JoinSubmissionProduct[] {
    const products: JoinSubmissionProduct[] = [
      this.getEverydayPartyProduct(applicantId, quoteSession, priceData),
      this.getHospitalPartyProduct(applicantId, quoteSession, priceData),
    ].filter(Boolean) as JoinSubmissionProduct[];

    return products;
  }

  getEverydayPartyProduct(
    applicantId: string,
    quoteSession: QuoteSession,
    priceData: PriceApiFetchPriceResponse
  ): JoinSubmissionProduct | null {
    const productCode =
      quoteSession.memberPolicyDetails[applicantId].everydayProductCode;
    if (!productCode) {
      return null; // No everyday cover for this applicant
    }
    const benefitResult = priceApiUtils.getBenefitResult(
      applicantId,
      productCode,
      priceData
    );
    return {
      productDocumentCode:
        this.getProductDocumentCodeForTopLevelProduct(productCode),
      boaBenefitCode: benefitResult.id,
    };
  }

  getProductDocumentCodeForTopLevelProduct(productCode: string): string {
    const productConfig = getProductByCode(productCode);
    const productDocumentCode =
      productConfig?.productDetails?.productDocumentCode;
    if (!productDocumentCode) {
      throw Error(
        `getProductDocumentCodeForTopLevelProduct: unable to find product document code for top level product: ${productCode}`
      );
    }
    return productDocumentCode;
  }

  getHospitalPartyProduct(
    applicantId: string,
    quoteSession: QuoteSession,
    priceData: PriceApiFetchPriceResponse
  ): JoinSubmissionProduct | null {
    const productCode =
      quoteSession.memberPolicyDetails[applicantId].hospitalProductCode;
    if (!productCode) {
      return null; // No hospital cover for this applicant
    }
    const hospitalOption = priceApiUtils.getSelectedHospitalExcessProductOption(
      applicantId,
      quoteSession
    );
    if (!hospitalOption) {
      throw new Error(
        `getHospitalPartyProduct: unable to find selected hospital excess product option ` +
          `for applicantId: ${applicantId} productCode; ${productCode}`
      );
    }
    const benefitResult = priceApiUtils.getBenefitResult(
      applicantId,
      hospitalOption.code,
      priceData
    );
    const product: JoinSubmissionProduct = {
      productDocumentCode:
        this.getProductDocumentCodeForTopLevelProduct(productCode),
      boaBenefitCode: benefitResult.id,
    };
    const nppAddon = this.getNonPharmacPlusPartyAddon(
      applicantId,
      quoteSession,
      priceData
    );
    if (nppAddon) {
      product.addons = [nppAddon];
    }
    return product;
  }

  getNonPharmacPlusPartyAddon(
    applicantId: string,
    quoteSession: QuoteSession,
    priceData: PriceApiFetchPriceResponse
  ): JoinSubmissionAddonProduct | null {
    const hospitalProductCode =
      quoteSession.memberPolicyDetails[applicantId].hospitalProductCode;
    if (!hospitalProductCode) {
      return null; // No hospital cover for this applicant
    }
    const policyDetails = quoteSession.getApplicantPolicyDetails(applicantId);
    if (+policyDetails.nonPharmacPlus === 0) {
      return null;
    }
    const nonPharmacPlusProductCode =
      priceApiUtils.getSelectedNonPharmacPlusProductCode(
        applicantId,
        quoteSession
      );
    if (!nonPharmacPlusProductCode) {
      throw new Error(
        `getHospitalPartyProduct: unable to find selected non pharmac plus product code` +
          `for applicantId: ${applicantId} value: ${policyDetails.nonPharmacPlus}`
      );
    }
    const benefitResult = priceApiUtils.getBenefitResult(
      applicantId,
      nonPharmacPlusProductCode,
      priceData
    );
    return {
      productDocumentCode:
        this.getNonPharmacPlusProductDocumentCode(hospitalProductCode),
      boaBenefitCode: benefitResult.id,
    };
  }

  getNonPharmacPlusProductDocumentCode(hospitalProductCode: string) {
    const hospitalProductConfig = getProductByCode(hospitalProductCode);
    const productDocumentCode =
      hospitalProductConfig?.nonPharmacPlusProductDocumentCode;
    if (!productDocumentCode) {
      throw Error(
        `getNonPharmacPlusTopLevelProductCode: unable to find non pharmac plus product document code for hospital product: ${hospitalProductCode}`
      );
    }
    return productDocumentCode;
  }

  getJoinSubmissionPayment(
    quoteSession: QuoteSession,
    priceData: PriceApiFetchPriceResponse
  ): JoinSubmissionPayment {
    const payment: JoinSubmissionPayment = {
      paymentMethod: quoteSession.paymentDetails.paymentMethod,
      paymentFrequency: this.getJoinSubmissionPaymentFrequency(
        quoteSession.paymentDetails.frequency
      ),
      quotedPrice: priceApiUtils.getPolicyTotalPrice(priceData),
      firstPaymentDate: dateUtils.formatUiDateToISO(
        quoteSession.paymentDetails.firstPaymentDate
      ),
    };
    if (
      quoteSession.paymentDetails.paymentMethod === PaymentMethod.DirectDebit
    ) {
      payment.directDebitDetails = {
        bankName: quoteSession.paymentDetails.bankName,
        accountName: quoteSession.paymentDetails.accountName,
        bankAccountNumber: quoteSession.paymentDetails.accountNumber,
      };
    } else {
      if (!quoteSession.paymentDetails.sessionId) {
        throw new Error(
          'getJoinSubmissionPayment not able to find credit card details'
        );
      }
      payment.creditCardDetails = {
        sessionId: quoteSession.paymentDetails.sessionId,
      };
    }

    return payment;
  }

  getJoinSubmissionPaymentFrequency(
    frequency: PaymentFrequency
  ): JoinSubmissionPaymentFrequency {
    const mapping: Record<PaymentFrequency, JoinSubmissionPaymentFrequency> = {
      Weekly: 'Weekly',
      Fortnightly: 'Fortnightly',
      Monthly: 'Monthly',
      Quarterly: 'Quarterly',
      'Half Yearly': 'HalfYearly',
      Yearly: 'Yearly',
    };
    return mapping[frequency];
  }
}

const submissionApiUtils = new SubmissionApiUtils();
export default submissionApiUtils;
