import Alert from '@nib-components/alert';
import { Box, Stack } from '@nib/layout';
import { clone } from 'ramda';
import { useContext, useEffect, useState } from 'react';
import { PriceContext } from 'src/contexts/PriceContext';
import { useQuoteSession } from 'src/contexts/QuoteSessionProvider';
import {
  useGetSpecialOfferQuery,
  useValidateSpecialOfferQuery,
} from 'src/services/join/joinApi';
import { FormPage } from 'src/types/FormPage';
import {
  PaymentDetails,
  PaymentFrequency,
  PaymentMethod,
  QuoteSession,
} from 'src/types/QuoteSession';
import config from 'src/utils/env';
import { CREDIT_CARD_INVALID_PAYMENT_FREQUENCIES } from 'src/utils/formPageUtils';
import gtmUtils from 'src/utils/gtmUtils';
import { createValidateSpecialOfferRequest } from 'src/utils/joinApiUtils';
import FormRadioGroup from '../form/FormRadioGroup';
import MarkdownContent from '../MarkdownContent';
import CreditCardForm from './CreditCardForm';
import DirectDebitForm from './DirectDebitForm';

const content = config.brand.content.buyNow.step4;

interface PaymentDetailsStepProps {
  // The local form values. Can be changed without affecting the top-level Quote Session
  // stored in local storage.
  paymentDetails: PaymentDetails;
  // Payment details affect the top header, so we need to be able to push these values into
  // the top-level Quote Session immediately instead of into the local form state.
  onSubmit: (nextPageId: FormPage | null, quoteSession?: QuoteSession) => void;
  canSubmit: boolean;
}

/**
 * The final step of the Buy Now form.
 *
 * This step differs from others in that changes are propagated directly to the quote session,
 * which then reinitializes the form. This is done to ensure changes to payment frequency and
 * payment method (Direct Debit / Credit Card) affect the top header immediately.
 */
const PaymentDetailsStep = (props: PaymentDetailsStepProps) => {
  const { paymentDetails, canSubmit, onSubmit } = props;
  const [showTotalChangeAlert, setShowTotalChangeAlert] = useState(false);
  const [previousPaymentFrequency, setPreviousPaymentFrequency] = useState('');

  // The top-level Quote Session saved in local storage.
  const quoteSession = useQuoteSession();

  const { data: priceData } = useContext(PriceContext);
  const { data: getSpecialOfferResponse } = useGetSpecialOfferQuery();
  const { data: validateSpecialOfferResponse } = useValidateSpecialOfferQuery(
    createValidateSpecialOfferRequest(quoteSession)
  );

  const [addPaymentInfoReported, setAddPaymentInfoReported] = useState(false);
  useEffect(() => {
    if (priceData && getSpecialOfferResponse && validateSpecialOfferResponse) {
      // Don't generate the events if we have already done so.
      // Otherwise, if they switch DD to CC etc this can be generated multiple times
      //
      if (!addPaymentInfoReported) {
        gtmUtils.viewPaymentDetailsStep();
        gtmUtils.addPaymentInfo(
          quoteSession,
          priceData,
          getSpecialOfferResponse,
          validateSpecialOfferResponse
        );
        setAddPaymentInfoReported(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceData, getSpecialOfferResponse, validateSpecialOfferResponse]);

  return (
    <Stack space={5}>
      {showTotalChangeAlert ? (
        <Alert type="info" variation="soft" fullWidth={false}>
          <Box marginBottom={4}>
            <MarkdownContent
              content={content.paymentChangeAlertLabel.replace(
                '{previousFrequency}',
                previousPaymentFrequency
              )}
            />
          </Box>
        </Alert>
      ) : (
        <></>
      )}

      <FormRadioGroup
        label={content.paymentMethodLabel}
        name="paymentDetails.paymentMethod"
        options={{
          [PaymentMethod.DirectDebit]: content.paymentMethodDirectDebitLabel,
          [PaymentMethod.CreditCard]: content.paymentMethodCreditCardLabel,
        }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          // Push changes into top-level quote session. This will update
          // the top header, and propagate back down into the form to update
          // the form state.
          const newQuoteSession = clone(quoteSession);
          // Add the local form values. If we don't do this, Formik will reinitialise with
          // the empty values that are in the quote session and we will lose any changes.
          setPreviousPaymentFrequency(quoteSession.paymentDetails.frequency);
          newQuoteSession.paymentDetails = paymentDetails;
          // Add the new value for payment method
          newQuoteSession.paymentDetails.paymentMethod = e.target
            .value as PaymentMethod;
          // If we've chosen Credit Card payment, ensure we have a valid frequency
          if (
            newQuoteSession.paymentDetails.paymentMethod ===
              PaymentMethod.CreditCard &&
            CREDIT_CARD_INVALID_PAYMENT_FREQUENCIES.includes(
              newQuoteSession.paymentDetails.frequency
            )
          ) {
            setShowTotalChangeAlert(true);
            newQuoteSession.paymentDetails.frequency = PaymentFrequency.Monthly;
          } else {
            setShowTotalChangeAlert(false);
          }

          onSubmit(null, newQuoteSession);
        }}
      />
      {paymentDetails.paymentMethod === PaymentMethod.CreditCard && (
        <CreditCardForm
          paymentDetails={paymentDetails}
          onSubmit={onSubmit}
          canSubmit={canSubmit}
        />
      )}
      {paymentDetails.paymentMethod === PaymentMethod.DirectDebit && (
        <DirectDebitForm
          paymentDetails={paymentDetails}
          onSubmit={onSubmit}
          canSubmit={canSubmit}
        />
      )}
    </Stack>
  );
};

export default PaymentDetailsStep;
