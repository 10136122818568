import Copy from '@nib-components/copy';
import Divider from '@nib-components/divider';
import Heading from '@nib-components/heading';
import Card from '@nib/card';
import {
  ChevronDownSystemIcon,
  ChevronUpSystemIcon,
  TickSystemIcon,
} from '@nib/icons';
import { Box, Stack } from '@nib/layout';
import Loader from '@nib/loader';
import React from 'react';
import { ProductConfig } from 'src/types/ProductConfig';
import { PaymentFrequency } from 'src/types/QuoteSession';
import config from 'src/utils/env';
import { formatCurrency } from 'src/utils/formatters/formatCurrency';
import { getProductByCode } from 'src/utils/productUtils';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

const content = config.brand.content.quoteSummary;

// Allow behaviour like vertical stacks, using DL/DD/DT elements
const QuoteSummaryCardDetails = styled.dl`
  display: flex;
  flex-flow: column;
  margin: 0;
  &.noCollapse {
    flex-flow: row wrap;
  }
`;
const QuoteSummaryCardDetailTerm = styled.dt`
  flex-basis: 50%;
  margin: 0 0 0.25rem 0;
  font-weight: bold;
  color: ${config.brand.customisation?.headersColor ?? 'inherit'};
`;
const QuoteSummaryCardDetailValue = styled.dd`
  flex-basis: calc(50% - 2rem);
  flex-grow: 1;
  margin: 0 0 0.25rem 0;
`;

// Allow behaviour like accordion in mobile view
const CardAccordionHeadingContainer = styled('div')`
  position: relative;
  padding-right: 2rem;
  ${breakpoint('lg')`
    position: inherit;
    padding-right: 0;
  `}
`;
const CardAccordionToggleIcon = styled('div')`
  position: absolute;
  right: 0.5rem;
  top: -4px;
  ${breakpoint('lg')`
    display: none;
  `}
`;
const CardAccordionBodyContainer = styled('div')<{ isExpanded: boolean }>`
  display: ${(props) => (props.isExpanded ? 'block' : 'none')};
  ${breakpoint('lg')`
    display: block;
  `}
`;

const SummaryCardHeader = ({
  name,
  price,
  frequency,
  productCodes,
}: {
  name: string;
  price: number;
  frequency: PaymentFrequency;
  productCodes: string[];
}) => {
  if (productCodes.length === 0) {
    return (
      <Heading
        component="h3"
        size={{ xs: 5, md: 4 }}
        color={config.brand.customisation?.headersColor}
      >
        {name}, {content.summaryCard.noCoverText}
      </Heading>
    );
  } else {
    return (
      <Heading
        component="h3"
        size={{ xs: 5, md: 4 }}
        color={config.brand.customisation?.headersColor}
      >
        {name}, {price ? formatCurrency(price) : <Loader />} {frequency}
      </Heading>
    );
  }
};

interface ApplicantSummaryCardProps {
  name: string;
  price: number;
  frequency: PaymentFrequency;
  age: string;
  gender: string;
  smoker: boolean;
  productCodes: string[];
  excess?: number;
  nonPharmacPlus?: number;
  hasSpecialOffer: boolean;
}

const ApplicantSummaryCard = (props: ApplicantSummaryCardProps) => {
  const {
    name,
    price,
    frequency,
    age,
    gender,
    smoker,
    productCodes,
    excess,
    nonPharmacPlus,
    hasSpecialOffer,
  } = props;

  // Mobile view card acts like an accordion
  const [isExpanded, setExpanded] = React.useState<boolean>(false);

  // Find the selected products string
  const selectedProducts: ProductConfig[] = productCodes
    .map(getProductByCode)
    .filter(isProductConfig);
  const productString = selectedProducts
    .map((p) => p.productDetails.name)
    .join(' + ');

  // Prepare the excess and non-PHARMAC text components
  const excessDescription =
    excess === undefined
      ? ''
      : content.summaryCard.excessText.replace(
          '{amount}',
          formatCurrency(excess, false)
        );
  const nppDescription =
    nonPharmacPlus === undefined
      ? ''
      : content.summaryCard.nonPharmacPlusText.replace(
          '{amount}',
          formatCurrency(nonPharmacPlus, false)
        );

  return (
    <Card padding={{ xs: 1, md: 3 }}>
      <Card.Content title="">
        <CardAccordionHeadingContainer>
          <Box paddingHorizontal={2} onClick={() => setExpanded(!isExpanded)}>
            <Stack space={3}>
              <SummaryCardHeader
                name={name}
                price={price}
                frequency={frequency}
                productCodes={productCodes}
              />
              <CardAccordionBodyContainer isExpanded={isExpanded}>
                <Copy>
                  {age}, {gender}
                  {smoker ? ', Smoker' : ''}
                </Copy>
              </CardAccordionBodyContainer>
            </Stack>
            <CardAccordionToggleIcon>
              {isExpanded ? <ChevronUpSystemIcon /> : <ChevronDownSystemIcon />}
            </CardAccordionToggleIcon>
          </Box>
        </CardAccordionHeadingContainer>
        {productCodes.length && (
          <CardAccordionBodyContainer isExpanded={isExpanded}>
            <Box paddingHorizontal={2} paddingBottom={2}>
              <Stack space={3}>
                <Divider />
                <Box
                  paddingTop={
                    nonPharmacPlus !== undefined && nonPharmacPlus > 0 ? 0 : 2
                  }
                >
                  <QuoteSummaryCardDetails>
                    <QuoteSummaryCardDetailTerm>
                      {productString}
                    </QuoteSummaryCardDetailTerm>
                    <QuoteSummaryCardDetailValue>
                      {excessDescription}
                    </QuoteSummaryCardDetailValue>
                    {nonPharmacPlus !== undefined && nonPharmacPlus > 0 && (
                      <>
                        <QuoteSummaryCardDetailTerm>
                          {content.nonParmacPlusLabel}
                        </QuoteSummaryCardDetailTerm>
                        <QuoteSummaryCardDetailValue>
                          {nppDescription}
                        </QuoteSummaryCardDetailValue>
                      </>
                    )}
                  </QuoteSummaryCardDetails>
                </Box>
                {hasSpecialOffer && <Divider />}
                {hasSpecialOffer && (
                  <QuoteSummaryCardDetails className="noCollapse">
                    <QuoteSummaryCardDetailTerm>
                      {content.specialOfferLabel}
                    </QuoteSummaryCardDetailTerm>
                    <QuoteSummaryCardDetailValue>
                      <Box display="inline" marginRight={3}>
                        <TickSystemIcon size="xs" />
                      </Box>
                      {content.specialOfferApplied}
                    </QuoteSummaryCardDetailValue>
                  </QuoteSummaryCardDetails>
                )}
              </Stack>
            </Box>
          </CardAccordionBodyContainer>
        )}
      </Card.Content>
    </Card>
  );
};

export default ApplicantSummaryCard;

// Type guard to let us convert (ProductConfig | undefined)[] => ProductConfig[]
const isProductConfig = (
  item: ProductConfig | undefined
): item is ProductConfig => {
  return item !== undefined;
};
