import { Box } from '@nib/layout';

const VerticalIndentBar = () => {
  return (
    <Box marginRight={{ xs: 0, md: 5 }}>
      <Box height="100%" background="light" width={{ xs: 0, md: '0.25rem' }}>
        &nbsp;
      </Box>
    </Box>
  );
};

export default VerticalIndentBar;
