import { hospitalTiers } from '@nib-components/colors';
import { colorError, colorSuccess } from '@nib-components/theme';
import { CloseSystemIcon, StarSystemIcon, TickSystemIcon } from '@nib/icons';
import styled from 'styled-components';

export const NoneCoverIcon = styled(CloseSystemIcon)`
  color: ${(props) => colorError(props)};
  padding-top: 3px;
  width: 16px;
`;
export const StandardCoverIcon = styled(TickSystemIcon)`
  color: ${(props) => colorSuccess(props)};
  padding-top: 3px;
  width: 16px;
`;
export const EnhancedCoverIcon = styled(StarSystemIcon)`
  color: ${hospitalTiers.gold};
  padding-top: 3px;
  width: 16px;
`;
