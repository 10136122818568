import FeaturePanel from '@nib-components/feature-panel';
import Link from '@nib-components/link';
import {
  DownloadAppGraphicIcon,
  HealthManagementGraphicIcon,
  LifeInsuranceGraphicIcon,
} from '@nib/icons';
import { Box } from '@nib/layout';
import MobileAppStores from 'src/components/Welcome/MobileAppStores';
import { WelcomeFeaturePanelContentNib } from 'src/types/Content/WelcomeFeaturePanelContent';
import config from 'src/utils/env';
import styled from 'styled-components';
import EmailHeartIcon from './IconComponents/EmailHeartIcon';
import RatingIcon from './IconComponents/RatingIcon';

const BottomFeaturePanel = styled(FeaturePanel)`
  padding-top: 0rem;
`;

const YourRatingPanel = styled(FeaturePanel.Feature)`
  padding-top: 30px;
`;

const FeaturePanelsForNib = () => {
  let nibFeaturePanel: WelcomeFeaturePanelContentNib;

  nibFeaturePanel = config.brand.content.welcome
    .featurePanel as WelcomeFeaturePanelContentNib;

  return (
    <>
      <FeaturePanel
        title={config.brand.content.welcome.featurePanelTitle}
        space={2}
        iconFill="trueGreen"
      >
        <FeaturePanel.Feature
          icon={EmailHeartIcon}
          title={nibFeaturePanel.checkYourEmailFeature.featureTitle}
        >
          {nibFeaturePanel.checkYourEmailFeature.featureInfo}
        </FeaturePanel.Feature>
        <FeaturePanel.Feature
          icon={DownloadAppGraphicIcon}
          iconAccentFill="sageGreen"
          title={nibFeaturePanel.downloadTheApp.featureTitle}
        >
          {nibFeaturePanel.downloadTheApp.featureInfo}
          <Box padding={1}>
            <MobileAppStores />
          </Box>
        </FeaturePanel.Feature>
        <YourRatingPanel
          icon={RatingIcon}
          title={nibFeaturePanel.yourExperience.featureTitle}
        >
          {nibFeaturePanel.yourExperience.featureInfo}{' '}
          <Link
            href={nibFeaturePanel.yourExperience.featureURLData.url}
            target="_blank"
            rel="noreferrer noopener"
          >
            {nibFeaturePanel.yourExperience.featureURLData.urlText}
          </Link>
        </YourRatingPanel>
      </FeaturePanel>
      <BottomFeaturePanel space={2} iconFill="trueGreen">
        <FeaturePanel.Feature
          icon={HealthManagementGraphicIcon}
          title={nibFeaturePanel.healthManagement.featureTitle}
        >
          {nibFeaturePanel.healthManagement.featureInfo}
          <br />
          <br />
          <Link
            href={nibFeaturePanel.healthManagement.featureURLData.url}
            target="_blank"
            rel="noreferrer noopener"
          >
            {nibFeaturePanel.healthManagement.featureURLData.urlText}
          </Link>
        </FeaturePanel.Feature>
        <FeaturePanel.Feature
          icon={LifeInsuranceGraphicIcon}
          title={nibFeaturePanel.lifeAndLivingInsurance.featureTitle}
        >
          {nibFeaturePanel.lifeAndLivingInsurance.featureInfo}
          <br />
          <br />
          <Link
            href={nibFeaturePanel.lifeAndLivingInsurance.featureURLData.url}
            target="_blank"
            rel="noreferrer noopener"
          >
            {nibFeaturePanel.lifeAndLivingInsurance.featureURLData.urlText}
          </Link>
        </FeaturePanel.Feature>
      </BottomFeaturePanel>
    </>
  );
};

export default FeaturePanelsForNib;
