import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/dist/query';
import {
  MutationLifecycleApi,
  QueryLifecycleApi,
} from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { generateCorrelationId } from 'src/utils/correlationUtils';
import { logError, logInfo } from 'src/utils/logUtils';
import {
  SpecialOfferResponse,
  ValidateSpecialOfferRequest,
  ValidateSpecialOfferResponse,
} from './contentfulApiTypes';
import {
  JoinApiCreateCartOptions,
  JoinApiCreateCartResponse,
  JoinApiCreateQuoteOptions,
  JoinApiCreateQuoteResponse,
  JoinApiGetQuoteOptions,
  JoinApiGetQuoteResponse,
  JoinApiRequestCallbackOptions,
  JoinApiRequestCallbackResponse,
  JoinApiUpdateCartOptions,
  JoinApiUpdateCartResponse,
  JoinApiValidateAdviserNumberOptions,
  JoinApiValidateAdviserNumberResponse,
  JoinApiValidatePromoCodeOptions,
  JoinApiValidatePromoCodeResponse,
} from './joinApiTypes';

export const onCreateCartQueryStarted = async (
  props: JoinApiCreateCartOptions,
  api: MutationLifecycleApi<
    JoinApiCreateCartOptions,
    BaseQueryFn<
      string | FetchArgs,
      unknown,
      FetchBaseQueryError,
      {},
      FetchBaseQueryMeta
    >,
    JoinApiCreateCartResponse,
    'joinApi'
  >
) => {
  const { correlationId } = props;
  const { queryFulfilled } = api;
  logInfo({
    message: 'sending POST CART',
    correlationId,
  });

  try {
    const { data: response } = await queryFulfilled;
    const joinId = response.data;
    logInfo({
      message: 'POST CART succeeded',
      data: { joinId: joinId },
      correlationId,
    });
  } catch (err) {
    logError({
      message: 'POST CART failed',
      data: { error: (err as any)?.error },
      correlationId,
    });
  }
};

export const onUpdateCartQueryStarted = async (
  props: JoinApiUpdateCartOptions,
  api: MutationLifecycleApi<
    JoinApiUpdateCartOptions,
    BaseQueryFn<
      string | FetchArgs,
      unknown,
      FetchBaseQueryError,
      {},
      FetchBaseQueryMeta
    >,
    JoinApiUpdateCartResponse,
    'joinApi'
  >
) => {
  const { correlationId, joinId } = props;
  const data = {
    joinId,
  };
  const { queryFulfilled } = api;
  logInfo({
    message: 'sending PUT CART',
    data,
    correlationId,
  });

  try {
    await queryFulfilled;
    logInfo({
      message: 'PUT CART succeeded',
      data,
      correlationId,
    });
  } catch (err) {
    logError({
      message: 'PUT CART failed',
      data: { ...data, error: (err as any)?.error },
      correlationId,
    });
  }
};

export const onCreateQuoteQueryStarted = async (
  props: JoinApiCreateQuoteOptions,
  api: MutationLifecycleApi<
    JoinApiCreateQuoteOptions,
    BaseQueryFn<
      string | FetchArgs,
      unknown,
      FetchBaseQueryError,
      {},
      FetchBaseQueryMeta
    >,
    JoinApiCreateQuoteResponse,
    'joinApi'
  >
) => {
  const { correlationId } = props;
  const { queryFulfilled } = api;
  logInfo({
    message: 'sending POST QUOTE',
    correlationId,
  });

  try {
    const { data: response } = await queryFulfilled;
    const joinId = response.data;
    logInfo({
      message: 'POST QUOTE succeeded',
      data: { joinId: joinId },
      correlationId,
    });
  } catch (err) {
    logError({
      message: 'POST QUOTE failed',
      data: { error: (err as any)?.error },
      correlationId,
    });
  }
};

export const onGetSpecialOfferQueryStarted = async (
  props: void,
  api: QueryLifecycleApi<
    void,
    BaseQueryFn<
      string | FetchArgs,
      unknown,
      FetchBaseQueryError,
      {},
      FetchBaseQueryMeta
    >,
    SpecialOfferResponse,
    'joinApi'
  >
) => {
  const { queryFulfilled } = api;
  const correlationId = generateCorrelationId();
  logInfo({
    message: 'Start Get SpecialOffer query',
    correlationId: correlationId,
  });
  try {
    await queryFulfilled;
    logInfo({
      message: 'Get SpecialOffer succeeded',
      correlationId: correlationId,
    });
  } catch (err) {
    logError({
      message: 'Get SpecialOffer failed',
      data: { error: (err as any)?.error },
      correlationId: correlationId,
    });
  }
};

export const onValidateSpecialOfferQueryStarted = async (
  props: ValidateSpecialOfferRequest,
  api: QueryLifecycleApi<
    ValidateSpecialOfferRequest,
    BaseQueryFn<
      string | FetchArgs,
      unknown,
      FetchBaseQueryError,
      {},
      FetchBaseQueryMeta
    >,
    ValidateSpecialOfferResponse,
    'joinApi'
  >
) => {
  const { queryFulfilled } = api;
  const correlationId = generateCorrelationId();
  logInfo({
    message: 'Sending POST to validate special offer',
    correlationId: correlationId,
  });
  try {
    await queryFulfilled;
    logInfo({
      message: 'POST to validate special offer succeeded',
      correlationId: correlationId,
    });
  } catch (err) {
    logError({
      message: 'POST to validate special offer failed',
      data: { error: (err as any)?.error },
      correlationId: correlationId,
    });
  }
};

export const onRequestCallbackQueryStarted = async (
  props: JoinApiRequestCallbackOptions,
  api: MutationLifecycleApi<
    JoinApiRequestCallbackOptions,
    BaseQueryFn<
      string | FetchArgs,
      unknown,
      FetchBaseQueryError,
      {},
      FetchBaseQueryMeta
    >,
    JoinApiRequestCallbackResponse,
    'joinApi'
  >
) => {
  const { correlationId, joinId } = props;
  const { queryFulfilled } = api;
  const actionDescription = joinId
    ? `POST Callback joinId: ${joinId}`
    : 'POST Callback';
  logInfo({
    message: `sending ${actionDescription}`,
    correlationId,
  });

  try {
    await queryFulfilled;
    logInfo({
      message: `${actionDescription} succeeded`,
      correlationId,
    });
  } catch (err) {
    logError({
      message: `${actionDescription} failed`,
      data: { error: (err as any)?.error },
      correlationId,
    });
  }
};

export const onGetQuoteQueryStarted = async (
  props: JoinApiGetQuoteOptions,
  api: QueryLifecycleApi<
    JoinApiGetQuoteOptions,
    BaseQueryFn<
      string | FetchArgs,
      unknown,
      FetchBaseQueryError,
      {},
      FetchBaseQueryMeta
    >,
    JoinApiGetQuoteResponse,
    'joinApi'
  >
) => {
  const { joinId, quoteIndex } = props;
  const correlationId = generateCorrelationId();
  const { queryFulfilled } = api;
  const actionDescription = `GET Quote joinId: ${joinId} quoteIndex: ${quoteIndex}`;
  logInfo({
    message: `sending ${actionDescription}`,
    correlationId,
  });

  try {
    await queryFulfilled;
    logInfo({
      message: `${actionDescription} succeeded`,
      correlationId,
    });
  } catch (err) {
    logError({
      message: `${actionDescription} failed`,
      data: { error: (err as any)?.error },
      correlationId,
    });
  }
};

export const onValidatePromoCodeQueryStarted = async (
  props: JoinApiValidatePromoCodeOptions,
  api: QueryLifecycleApi<
    JoinApiValidatePromoCodeOptions,
    BaseQueryFn<
      string | FetchArgs,
      unknown,
      FetchBaseQueryError,
      {},
      FetchBaseQueryMeta
    >,
    JoinApiValidatePromoCodeResponse,
    'joinApi'
  >
) => {
  const { queryFulfilled } = api;
  const correlationId = generateCorrelationId();
  logInfo({
    message: 'Sending POST to validate promo code',
    correlationId: correlationId,
  });
  try {
    await queryFulfilled;
    logInfo({
      message: 'POST to validate promo code succeeded',
      correlationId: correlationId,
    });
  } catch (err) {
    logError({
      message: 'POST to validate promo code failed',
      data: { error: (err as any)?.error },
      correlationId: correlationId,
    });
  }
};

export const onValidateAdviserNumberQueryStarted = async (
  props: JoinApiValidateAdviserNumberOptions,
  api: QueryLifecycleApi<
    JoinApiValidateAdviserNumberOptions,
    BaseQueryFn<
      string | FetchArgs,
      unknown,
      FetchBaseQueryError,
      {},
      FetchBaseQueryMeta
    >,
    JoinApiValidateAdviserNumberResponse,
    'joinApi'
  >
) => {
  const { queryFulfilled } = api;
  const correlationId = generateCorrelationId();
  logInfo({
    message: 'Sending POST to validate adviser number',
    correlationId: correlationId,
  });
  try {
    await queryFulfilled;
    logInfo({
      message: 'POST to validate adviser number succeeded',
      correlationId: correlationId,
    });
  } catch (err) {
    logError({
      message: 'POST to validate adviser number failed',
      data: { error: (err as any)?.error },
      correlationId: correlationId,
    });
  }
};
