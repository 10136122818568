import Heading from '@nib-components/heading';
import { Box, Container } from '@nib/layout';
import React from 'react';
import { ApplicantIdContext } from 'src/contexts/ApplicantIdContext';
import { PriceContext } from 'src/contexts/PriceContext';
import priceApiUtils from 'src/services/price/priceApiUtils';
import { QuoteSession } from 'src/types/QuoteSession';
import config from 'src/utils/env';
import { formatCurrency } from 'src/utils/formatters/formatCurrency';
import PageBackground from '../PageBackground';

export interface ApplicantHeadingProps {
  quoteSession: QuoteSession;
}

const ApplicantHeading = ({ quoteSession }: ApplicantHeadingProps) => {
  const { applicantId } = React.useContext(ApplicantIdContext);
  const { data: priceData } = React.useContext(PriceContext);

  const applicant = quoteSession.applicantDetails.find(
    (a) => a.id === applicantId
  );
  if (!applicant) {
    throw new Error(
      `ApplicantHeading could not find applicant with id: ${applicantId}`
    );
  }
  let name = applicant?.firstName.trim();
  if (!name) {
    const applicantIndex = quoteSession.applicantDetails.findIndex(
      (a) => a.id === applicantId
    );
    name = `Person ${applicantIndex + 1}`;
  }
  let price = 0;
  if (priceData) {
    price = priceApiUtils.getApplicantTotalPrice(
      applicant,
      quoteSession,
      priceData
    );
  }

  return (
    <PageBackground formMode="light">
      <Box padding={4}>
        <Container>
          <Heading
            component="p"
            size={{ xs: 3, md: 2 }}
            color={config.brand.customisation?.headersColor}
            data-testid="applicant-heading"
          >
            {name}
            {price !== 0 &&
              ', ' +
                formatCurrency(price) +
                ' ' +
                quoteSession.paymentDetails.frequency}
          </Heading>
        </Container>
      </Box>
    </PageBackground>
  );
};

export default ApplicantHeading;
