import Copy from '@nib-components/copy';
import Modal from '@nib-components/modal';
import InfoBox from '@nib/info-box';
import { Box, Column, Columns, Stack } from '@nib/layout';
import { format } from 'date-fns';
import React from 'react';
import { useQuoteSession } from 'src/contexts/QuoteSessionProvider';
import { FormPage } from 'src/types/FormPage';
import {
  PaymentDetails,
  PaymentFrequency,
  QuoteSession,
} from 'src/types/QuoteSession';
import config from 'src/utils/env';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import FormCheckbox from '../form/FormCheckbox';
import FormTextbox from '../form/FormTextbox';
import BrandedStyleHeader from '../heading/BrandedStyleHeader';
import MarkdownContent from '../MarkdownContent';
import BottomButtons from '../navigation/BottomButtons';
import AdviserDeclaration from './AdviserDeclaration';
import DoubleDeductionWarning from './DoubleDeductionWarning';
import FirstPaymentDateField from './FirstPaymentDateField';
import FirstPaymentDateWarning from './FirstPaymentDateWarning';
import PaymentFrequencyField from './PaymentFrequencyField';

const MessagePositioner = styled('div')`
  ${breakpoint('md')`
    margin-top: -0.75rem;
  `}
`;

// An invisible box allows us to turn things on and off while
// allowing them to take up the same amount of space in the layout.
const InvisibleBox = styled('span')<{ invisible: boolean }>`
  visibility: ${(props) => (props.invisible ? 'hidden' : 'visible')};
`;

const content = config.brand.content.buyNow.step4.directDebit;

interface DirectDebitFormProps {
  // The local form values. Can be changed without affecting the top-level Quote Session
  // stored in local storage.
  paymentDetails: PaymentDetails;
  // Payment details affect the top header, so we need to be able to push these values into
  // the top-level Quote Session immediately instead of into the local form state.
  onSubmit: (nextPageId: FormPage | null, quoteSession?: QuoteSession) => void;
  canSubmit: boolean;
}

const DirectDebitForm = (props: DirectDebitFormProps) => {
  const { onSubmit, canSubmit, paymentDetails } = props;
  const quoteSession = useQuoteSession();
  const [showTermsAndConditionsModal, setShowTermsAndConditionsModal] =
    React.useState<boolean>(false);

  // Respond to events in markdown link
  React.useEffect(() => {
    const showTermsAndConditions = () => setShowTermsAndConditionsModal(true);
    window.addEventListener('termsAndConditions', showTermsAndConditions);
    return () => {
      window.removeEventListener('termsAndConditions', showTermsAndConditions);
    };
  }, []);

  // Replace the date into the 'What to expect' info
  const whatToExpectInfo = content.whatToExpectInfo.replace(
    '{date}',
    format(new Date(), 'do MMM yyyy')
  );

  return (
    <Box data-testid="direct-debit-form">
      <Stack space={5}>
        <InvisibleBox invisible={!config.brand.hasDirectDebitDiscount}>
          <Copy>{content.discountMessage}</Copy>
        </InvisibleBox>
        <Box marginBottom={7}>
          <PaymentFrequencyField
            label={content.paymentFrequencyLabel}
            name="paymentDetails.frequency"
            onSubmit={onSubmit}
            columns={2}
          />
        </Box>
        <Box>
          <Columns space={5} collapseBelow="md">
            <Column width="1/2">
              <Stack space={5}>
                <FirstPaymentDateField
                  label={content.firstPaymentDateLabel}
                  name="paymentDetails.firstPaymentDate"
                  paymentFrequency={quoteSession.paymentDetails.frequency}
                />
                <DoubleDeductionWarning
                  firstPaymentDate={paymentDetails.firstPaymentDate}
                  paymentFrequency={paymentDetails.frequency}
                  doubleDeductionAvoidableInfo={
                    config.brand.content.buyNow.step4
                      .doubleDeductionAvoidableInfo
                  }
                  doubleDeductionUnavoidableInfo={
                    config.brand.content.buyNow.step4
                      .doubleDeductionUnavoidableInfo
                  }
                />
              </Stack>
            </Column>
            <Column width="1/2">
              <FormTextbox
                label={content.bankNameLabel}
                name="paymentDetails.bankName"
                maxLength={40}
                autoComplete={'on'}
              />
            </Column>
          </Columns>
        </Box>
        {[
          PaymentFrequency.Quarterly,
          PaymentFrequency.HalfYearly,
          PaymentFrequency.Yearly,
        ].includes(paymentDetails.frequency) && (
          <MessagePositioner>
            <FirstPaymentDateWarning />
          </MessagePositioner>
        )}
        <Box>
          <Columns space={5} collapseBelow="md">
            <Column width="1/2">
              <FormTextbox
                label={content.accountNameLabel}
                name="paymentDetails.accountName"
                maxLength={40}
                autoComplete={'on'}
              />
            </Column>
            <Column width="1/2">
              <FormTextbox
                label={content.accountNumberLabel}
                name="paymentDetails.accountNumber"
                maxLength={20}
                // spec for 'cc-number' is:
                // A credit card number or other number identifying a payment method, such as an account number.
                // @see https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
                autoComplete={'cc-number'}
              />
            </Column>
          </Columns>
        </Box>
        {config.brand.hasAdviser && (
          <AdviserDeclaration
            prefix="directDebitAdviserDeclaration"
            content={content.adviserDeclaration!}
          />
        )}
        <BrandedStyleHeader>
          <InfoBox backgroundColor="white" title={content.whatToExpectTitle}>
            <MarkdownContent content={whatToExpectInfo} />
          </InfoBox>
        </BrandedStyleHeader>

        {!config.brand.hasAdviser && (
          <Box maxWidth="600px">
            <MarkdownContent
              content={content.termsAndConditionsInfo}
              linkEvent="termsAndConditions"
            />
          </Box>
        )}
        {!config.brand.hasAdviser && (
          <FormCheckbox
            name="paymentDetails.termsAndConditions"
            label={content.termsAndConditionsLabel}
          />
        )}
        <BottomButtons
          submitButtonText={content.nextButtonText}
          disabled={!canSubmit}
          isLoading={!canSubmit}
        />
      </Stack>
      <Modal
        visible={showTermsAndConditionsModal}
        onClose={() => setShowTermsAndConditionsModal(false)}
        title={content.termsAndConditionsModalTitle}
      >
        <MarkdownContent
          content={content.termsAndConditionsModalContent}
          linkEvent="termsAndConditions"
        />
      </Modal>
    </Box>
  );
};

export default DirectDebitForm;
