//error messagesvalidateBankAccount
// 1. Account number was not specified.
// 2. Account number is too short
// 3. Bank is not specified
// 4. Bank must be between 1 and 2 characters long
// 5. Bank must be a valid number
// 6. Branch is not specified
// 7. Branch must be between 1 and 4 characters long
// 8. Branch must be a valid number
// 9. Branch number specified is not valid for this bank
// 10.The bank number specified is not valid
// 11.The account number specified is not long enough
// 12.The base account number (3rd component) is incorrect, please check that you have entered it
// correctly.

var basicBankNumberRegex = /^[0-9-]+$/;

export function between(value, lower, upper) {
  return value > lower && value < upper;
}

export function notBetween(value, lower, upper) {
  return value <= lower || value >= upper;
}

export function constructBankAccount(bank, branch, baseAccount, suffix) {
  return `${bank}-${branch}-${baseAccount}-${suffix}`;
}

export function padLeft(n, width, z) {
  const padding = z || '0';
  const val = String(n);
  return val.length >= width
    ? val
    : new Array(width - val.length + 1).join(padding) + val;
}

export const buildDisplayAccountNumber = (accountDetails) => {
  const bank = Number(accountDetails.bank);
  const branch = Number(accountDetails.branch);
  const baseAccount = Number(accountDetails.baseNumber);
  const suffix = Number(accountDetails.suffix);

  return constructBankAccount(
    padLeft(bank, 2),
    padLeft(branch, 4),
    padLeft(baseAccount, 7),
    padLeft(suffix, 3)
  );
};
export const showError = (val) => {
  switch (val) {
    case 1:
      return 'Account number was not specified.';
    case 2:
      return 'Account number is too short';
    case 3:
      return 'Bank is not specified';
    case 4:
      return 'Bank must be 2 characters long';
    case 5:
      return 'Bank must be a valid number';
    case 6:
      return 'Branch is not specified';
    case 7:
      return 'Branch number should exactly have 4 numbers';
    case 8:
      return 'Branch must be a valid number';
    case 9:
      return 'Branch number specified is not valid for this bank';
    case 10:
      return 'The bank number specified is not valid';
    case 11:
      return 'The account number specified is not long enough';
    case 12:
      return 'The base account number (3rd component) is incorrect, please check that you have entered it correctly.';
    case 13:
      return 'Invalid account number';
    case 14:
      return 'Invalid account suffix';
    default:
      return '';
  }
};

export const splitBankAccountNumber = (v, allowVariableLength) => {
  const result = { success: false, error: 2 };
  if (!v || v.length === 0) return result;
  if (v.search('-') === -1) {
    const numericAccountNumber = v.replace(/\D/g, '');
    if (numericAccountNumber.length < 14 && allowVariableLength !== true)
      return result;
    //Bank account numbers can consty in length from 14 characters to 18 characters
    //e.g. 12-1234-12345678-1234 = 18 characters, or 12-1234-1234567-1 = 14 characters
    //we need to reformat all numbers to display is a consistent manner,
    //but without having to put in a lot of padding as this is meaningless to the user

    // Add in some hyphens if they aren't there already:
    let newValue = '';
    for (let i = 0; i < numericAccountNumber.length; i++) {
      if (!numericAccountNumber[i]) break;
      /* eslint-disable default-case */
      switch (i) {
        case 2:
          result.bank = newValue;
          newValue = '';
          break;
        case 6:
          result.branch = newValue;
          newValue = '';
          break;
        case 13:
          result.baseNumber = newValue;
          newValue = '';
          break;
      }
      /* eslint-enable */
      newValue += numericAccountNumber[i];
    }
    if (newValue.length > 0) {
      result.suffix = newValue;
      result.success = true;
    }
  } else {
    const pieces = v.split('-');
    if (pieces.length > 0) result.bank = pieces[0];
    if (pieces.length > 1) result.branch = pieces[1];
    if (pieces.length > 2) result.baseNumber = pieces[2];
    if (pieces.length > 3) result.suffix = pieces[3];
    // result.success = pieces.length > 3;
    result.success = pieces.length >= 5 ? false : true;
    if (!result.success) result.error = 13;
  }
  if (result.success === true) result.error = '';
  return result;
};

function build18DigitAccountNumber(accountComponents) {
  return (
    padLeft(accountComponents.bank, 2) +
    padLeft(accountComponents.branch, 4) +
    padLeft(accountComponents.baseNumber, 8) +
    padLeft(accountComponents.suffix, 4)
  );
}

export function validateBankBranch(components) {
  const result = {
    branch: components.branch,
    isValid: false,
    error: '',
    algorithm: '',
  };

  if (!components.bank) {
    result.error = 3;
    return result;
  }

  if (components.bank.length !== 2) {
    result.error = 4;
    return result;
  }

  const bankNumber = Number(components.bank);
  if (isNaN(bankNumber)) {
    result.error = 5;
    return result;
  }

  if (!components.branch) {
    result.error = 6;
    return result;
  }
  if (components.branch.length !== 4) {
    result.error = 7;
    return result;
  }

  const branchNumber = Number(components.branch);
  if (isNaN(branchNumber)) {
    result.error = 8;
    return result;
  }

  const simpleBankBranchValidations = {
    13: [4900, 4999],
    14: [4700, 4799],
    15: [3900, 3999],
    16: [4400, 4499],
    17: [3300, 3399],
    18: [3500, 3599],
    19: [4600, 4649],
    20: [4100, 4199],
    21: [4800, 4899],
    22: [4000, 4049],
    23: [3700, 3799],
    24: [4300, 4349],
    27: [3800, 3849],
    30: [2900, 2949],
    35: [2400, 2499],
    38: [8999, 9500],
  };

  let algorithm = '';
  switch (bankNumber) {
    case 1:
      if (
        between(branchNumber, 999, 1100) ||
        between(branchNumber, 1199, 1800) ||
        branchNumber > 1899
      ) {
        result.error = 9;
        return result;
      }
      break;
    case 2:
      if (between(branchNumber, 999, 1200) || branchNumber > 1299) {
        result.error = 9;
        return result;
      }
      break;
    case 3:
      if (
        between(branchNumber, 999, 1300) ||
        between(branchNumber, 1399, 1500) ||
        between(branchNumber, 1599, 1700) ||
        between(branchNumber, 1799, 1900) ||
        branchNumber > 1999
      ) {
        result.error = 9;
        return result;
      }
      break;
    case 6:
      if (between(branchNumber, 999, 1400) || branchNumber > 1499) {
        result.error = 9;
        return result;
      }
      break;
    case 8:
      if (notBetween(branchNumber, 6500, 6599)) {
        result.error = 9;
        return result;
      }
      algorithm = 'D';
      break;
    case 9:
      if (branchNumber !== 0) {
        result.error = 9;
        return result;
      }
      algorithm = 'E';
      break;
    case 11:
      if (
        branchNumber < 5000 ||
        between(branchNumber, 6499, 6600) ||
        branchNumber > 8999
      ) {
        result.error = 9;
        return result;
      }
      break;
    case 12:
      if (
        branchNumber < 3000 ||
        between(branchNumber, 3299, 3400) ||
        between(branchNumber, 3499, 3600) ||
        branchNumber > 3699
      ) {
        result.error = 9;
        return result;
      }
      break;
    case 13:
    case 14:
    case 15:
    case 16:
    case 17:
    case 18:
    case 19:
    case 20:
    case 21:
    case 22:
    case 23:
    case 24:
    case 27:
    case 30:
    case 35:
    case 38:
      if (
        notBetween(
          branchNumber,
          simpleBankBranchValidations[components.bank][0],
          simpleBankBranchValidations[components.bank][1]
        )
      ) {
        result.error = 9;
        return result;
      }
      break;
    case 25:
      if (notBetween(branchNumber, 2500, 2599)) {
        result.error = 9;
        return result;
      }

      algorithm = 'F';
      break;
    case 26:
      if (notBetween(branchNumber, 2600, 2699)) {
        result.error = 9;
        return result;
      }

      algorithm = 'G';
      break;
    case 28:
      if (notBetween(branchNumber, 2100, 2149)) {
        result.error = 9;
        return result;
      }

      algorithm = 'G';
      break;
    case 29:
      if (notBetween(branchNumber, 2150, 2299)) {
        result.error = 9;
        return result;
      }

      algorithm = 'G';
      break;
    case 31:
      if (notBetween(branchNumber, 2800, 2849)) {
        result.error = 9;
        return result;
      }

      algorithm = 'X';
      break;
    case 33:
      if (notBetween(branchNumber, 6700, 6799)) {
        result.error = 9;
        return result;
      }

      algorithm = 'F';
      break;

    default:
      //result.error = 'The bank number specified (' + components.bank + ') is not valid';
      result.error = 10;
      return result;
  }

  if (algorithm === '') {
    if (components.baseNumber < 990000) {
      algorithm = 'A';
    } else {
      algorithm = 'B';
    }
  }

  result.isValid = true;
  result.algorithm = algorithm;

  return result;
}
/* eslint-enable */

function validateAccountNumberAndSuffix(components) {
  const result = {
    suffix: components.suffix,
    baseNumber: components.baseNumber,
    isValid: false,
    error: '',
    algorithm: '',
  };

  if (
    !components.suffix ||
    components.suffix.length === 0 ||
    components.suffix.length > 3
  ) {
    result.error = 14;
    return result;
  }

  if (components.baseNumber.length !== 7) {
    result.error = 13;
    return result;
  }

  result.isValid = true;
  return result;
}

function validateAccountNumberWithAlgorithm(accountNumber, algorithm) {
  const result = { accountNumber, isValid: false, error: '' };

  const algorthmSettings = {
    A: {
      mod: 11,
      weights: [0, 0, 6, 3, 7, 9, 0, 0, 10, 5, 8, 4, 2, 1, 0, 0, 0, 0],
    },
    B: {
      mod: 11,
      weights: [0, 0, 0, 0, 0, 0, 0, 0, 10, 5, 8, 4, 2, 1, 0, 0, 0, 0],
    },
    C: {
      mod: 11,
      weights: [3, 7, 0, 0, 0, 0, 9, 1, 10, 5, 3, 4, 2, 1, 0, 0, 0, 0],
    },
    D: {
      mod: 11,
      weights: [0, 0, 0, 0, 0, 0, 0, 7, 6, 5, 4, 3, 2, 1, 0, 0, 0, 0],
    },
    E: {
      mod: 11,
      weights: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5, 4, 3, 2, 0, 0, 0, 0],
    },
    F: {
      mod: 10,
      weights: [0, 0, 0, 0, 0, 0, 0, 1, 7, 3, 1, 7, 3, 1, 0, 0, 0, 0],
    },
    G: {
      mod: 10,
      weights: [0, 0, 0, 0, 0, 0, 0, 1, 3, 7, 1, 3, 7, 1, 0, 3, 7, 1],
    },
    X: {
      mod: 1,
      weights: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
  };

  if (accountNumber.length !== 18) {
    result.error = 11;
    return result;
  }

  const weights = algorthmSettings[algorithm].weights;

  let total = 0;
  for (let i = 0; i < 18; i++) {
    let sum = Number(accountNumber[i]) * weights[i];
    if (algorithm === 'E' || algorithm === 'G') {
      let toAdd = sum % 10;
      if (sum > 10) {
        toAdd += (sum - toAdd) / 10;
      }

      sum = toAdd;
      toAdd = sum % 10;
      if (sum > 10) {
        toAdd += (sum - toAdd) / 10;
      }

      total += toAdd;
    } else total = total + sum;
  }

  total = total % algorthmSettings[algorithm].mod;

  if (total === 0) {
    result.isValid = true;
  } else {
    result.error = 12;
  }

  return result;
}

export const validateBankAccount = (accountNumber) => {
  const result = {
    inputAccountNumber: accountNumber,
    success: false,
    error: '',
    components: {},
  };

  if (basicBankNumberRegex.test(accountNumber) === false) {
    result.error = 13;
    return result;
  }

  const accountComponents = splitBankAccountNumber(accountNumber);
  result.components = accountComponents;

  if (!accountComponents.success) {
    result.error = accountComponents.error;
    return result;
  }

  result.formattedAccountNumber = buildDisplayAccountNumber(accountComponents);

  const validatedBranch = validateBankBranch(accountComponents);

  if (!validatedBranch.isValid) {
    result.error = validatedBranch.error;
    return result;
  }

  const validatedAccountAndSuffix =
    validateAccountNumberAndSuffix(accountComponents);
  if (!validatedAccountAndSuffix.isValid) {
    result.error = validatedAccountAndSuffix.error;
    return result;
  }

  const tmpAccountNumber = build18DigitAccountNumber(accountComponents);
  const algorithmResult = validateAccountNumberWithAlgorithm(
    tmpAccountNumber,
    validatedBranch.algorithm
  );

  if (!algorithmResult.isValid) {
    result.error = algorithmResult.error;
  } else {
    result.success = true;
  }

  return result;
};

export const constructBankAccountNumber = (v) => {
  const result = { success: false, error: 1, formattedAccountNumber: '' };
  if (!v || v.length === 0) return result;
  const bankDetails = validateBankAccount(v);
  result.formattedAccountNumber = bankDetails.formattedAccountNumber;
  if (!bankDetails.success) {
    result.error = bankDetails.error;
    return result;
  }
  result.success = true;
  result.error = '';
  return result;
};
