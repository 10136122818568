import { SmallFooter } from '@nib-components/footer';
import styled from 'styled-components';
import config from '../utils/env';
import MarkdownContent from './MarkdownContent';

// Get rid of the margins that default paragraph tags have
const FooterCopyrightText = styled('div')`
  p {
    margin: 0;
  }
`;

export const Footer = () => {
  const copyrightText = config.brand.content.footer.copyrightText.replace(
    '{year}',
    new Date().getFullYear().toString()
  );

  return (
    <SmallFooter
      variation="nz"
      config={{
        // Copied and adapted from https://github.com/nib-group/nz-provider-portal/blob/master/src/components/common/AppContainer/index.ts
        // which was itself copied and adapted from https://github.com/nib-group/design-system/blob/master/packages/footer/src/config/nz.ts
        id: 'small-footer-nz',
        copyrightText: (
          <FooterCopyrightText>
            <MarkdownContent content={copyrightText} />
          </FooterCopyrightText>
        ),
        links: [
          {
            id: 'ga-footer-nz-terms',
            title: 'Terms & Conditions',
            url: config.brand.content.footer.termsAndConditionsLink,
          },
          {
            id: 'ga-footer-nz-privacy',
            title: 'Privacy Policy',
            url: config.brand.content.footer.privacyPolicyLink,
          },
        ],
      }}
    />
  );
};
