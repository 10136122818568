import { useFormikContext } from 'formik';
import React from 'react';
import scrollIntoView from 'scroll-into-view-if-needed';

function findAncestor(
  el: HTMLElement | null,
  tagName: string
): HTMLElement | null {
  if (!el) {
    return null;
  }
  while (
    (el = el.parentElement) &&
    el.tagName.toLowerCase() !== tagName.toLowerCase() &&
    el.tagName.toLowerCase() !== 'body'
  );
  if (el?.tagName.toLowerCase() === 'body') {
    return null;
  }
  return el;
}

/**
 * Invisible component that scrolls the user to the first field with validation errors
 * when the form is updated.
 *
 * @see https://dev.to/diegocasmo/scroll-to-input-on-formik-failed-submission-1c3c
 */
const ScrollToFieldError = () => {
  const { submitCount, isValid } = useFormikContext();

  React.useEffect(() => {
    if (isValid) {
      return;
    }
    // Find the first validation error on the screen
    const errorElement: HTMLDivElement | null =
      document.querySelector(`[id^='error-error-']`);
    if (!errorElement) {
      return;
    }
    // Find field this is attached to
    const fieldName = errorElement.id.replace(/^error-error-/, '');
    const fieldElement: HTMLInputElement | null = document.querySelector(
      `input[name='${fieldName}']`
    );
    let scrollElement: HTMLElement | null = null;
    if (!fieldElement) {
      // If there's no matching field, scroll to the error message itself
      scrollElement = errorElement;
    } else {
      // If it's a radio, find the parent fieldset; for other types of field, find the matching label
      switch (fieldElement.type) {
        case 'radio':
          scrollElement = findAncestor(fieldElement, 'fieldset');
          break;
        default:
          scrollElement = document.querySelector(`label[for='${fieldName}']`);
      }
      // If we didn't find the top of the field, scroll to the input
      if (!scrollElement) {
        scrollElement = fieldElement;
      }
    }

    setTimeout(() => {
      if (scrollElement) {
        scrollIntoView(scrollElement, {
          behavior: 'auto',
          scrollMode: 'if-needed',
          block: 'start',
        });
      }
    }, 100);
  }, [submitCount, isValid]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

export default ScrollToFieldError;
