import { StarGraphicIcon } from '@nib/icons';
import { Box } from '@nib/layout';

const RatingIcon = () => {
  return (
    <Box display="flex" justifyContent="center">
      <svg
        width="50"
        height="41"
        viewBox="0 0 53 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.6593 1.18542L10.4679 9.49674L2.46385 10.2897C1.67506 10.3551 0.982893 10.8839 0.706816 11.6425C0.430739 12.4012 0.621194 13.2511 1.19462 13.8194L7.82164 20.3828L5.36786 29.2963C5.15544 30.0961 5.42892 30.9367 6.06417 31.4528C6.69943 31.9688 7.5783 32.0642 8.30951 31.6966L16.5174 27.6317L24.7427 31.7046C25.4684 32.0695 26.3473 31.9741 26.9825 31.4581C27.6178 30.942 27.8913 30.1014 27.6812 29.3103L25.225 20.3878L31.8468 13.8247C32.4202 13.2565 32.6107 12.4065 32.3346 11.6478C32.0585 10.8892 31.3663 10.3604 30.5618 10.2936L22.5733 9.50204L18.3781 1.19056L18.3754 1.18542C18.0184 0.488034 17.3009 0.0493164 16.5174 0.0493164C15.7339 0.0493164 15.0164 0.488034 14.6593 1.18542Z"
          fill="#144A38"
        />
      </svg>
      <svg
        width="50"
        height="41"
        viewBox="0 0 53 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.6593 1.18542L10.4679 9.49674L2.46385 10.2897C1.67506 10.3551 0.982893 10.8839 0.706816 11.6425C0.430739 12.4012 0.621194 13.2511 1.19462 13.8194L7.82164 20.3828L5.36786 29.2963C5.15544 30.0961 5.42892 30.9367 6.06417 31.4528C6.69943 31.9688 7.5783 32.0642 8.30951 31.6966L16.5174 27.6317L24.7427 31.7046C25.4684 32.0695 26.3473 31.9741 26.9825 31.4581C27.6178 30.942 27.8913 30.1014 27.6812 29.3103L25.225 20.3878L31.8468 13.8247C32.4202 13.2565 32.6107 12.4065 32.3346 11.6478C32.0585 10.8892 31.3663 10.3604 30.5618 10.2936L22.5733 9.50204L18.3781 1.19056L18.3754 1.18542C18.0184 0.488034 17.3009 0.0493164 16.5174 0.0493164C15.7339 0.0493164 15.0164 0.488034 14.6593 1.18542Z"
          fill="#144A38"
        />
      </svg>{' '}
      <svg
        width="50"
        height="41"
        viewBox="0 0 53 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.6593 1.18542L10.4679 9.49674L2.46385 10.2897C1.67506 10.3551 0.982893 10.8839 0.706816 11.6425C0.430739 12.4012 0.621194 13.2511 1.19462 13.8194L7.82164 20.3828L5.36786 29.2963C5.15544 30.0961 5.42892 30.9367 6.06417 31.4528C6.69943 31.9688 7.5783 32.0642 8.30951 31.6966L16.5174 27.6317L24.7427 31.7046C25.4684 32.0695 26.3473 31.9741 26.9825 31.4581C27.6178 30.942 27.8913 30.1014 27.6812 29.3103L25.225 20.3878L31.8468 13.8247C32.4202 13.2565 32.6107 12.4065 32.3346 11.6478C32.0585 10.8892 31.3663 10.3604 30.5618 10.2936L22.5733 9.50204L18.3781 1.19056L18.3754 1.18542C18.0184 0.488034 17.3009 0.0493164 16.5174 0.0493164C15.7339 0.0493164 15.0164 0.488034 14.6593 1.18542Z"
          fill="#144A38"
        />
      </svg>{' '}
      <svg
        width="50"
        height="41"
        viewBox="0 0 53 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.6593 1.18542L10.4679 9.49674L2.46385 10.2897C1.67506 10.3551 0.982893 10.8839 0.706816 11.6425C0.430739 12.4012 0.621194 13.2511 1.19462 13.8194L7.82164 20.3828L5.36786 29.2963C5.15544 30.0961 5.42892 30.9367 6.06417 31.4528C6.69943 31.9688 7.5783 32.0642 8.30951 31.6966L16.5174 27.6317L24.7427 31.7046C25.4684 32.0695 26.3473 31.9741 26.9825 31.4581C27.6178 30.942 27.8913 30.1014 27.6812 29.3103L25.225 20.3878L31.8468 13.8247C32.4202 13.2565 32.6107 12.4065 32.3346 11.6478C32.0585 10.8892 31.3663 10.3604 30.5618 10.2936L22.5733 9.50204L18.3781 1.19056L18.3754 1.18542C18.0184 0.488034 17.3009 0.0493164 16.5174 0.0493164C15.7339 0.0493164 15.0164 0.488034 14.6593 1.18542Z"
          fill="#144A38"
        />
      </svg>
      <StarGraphicIcon color={'#144A38'} size={30} style={{ marginTop: 5 }} />
    </Box>
  );
};

export default RatingIcon;
