import { BaseQueryFn, createApi } from '@reduxjs/toolkit/query/react';
import type { AxiosError } from 'axios';
import {
  ContentfulGetEntryRequest,
  ContentfulGetEntryResponse,
} from './contentfulApiTypes';
import contentfulClient from './contentfulClient';
import { onContentfulGetEntryQueryStarted } from './eventHandlers';

const contentfulBaseQuery =
  (): BaseQueryFn<ContentfulGetEntryRequest, ContentfulGetEntryResponse> =>
  async ({ id, query }) => {
    try {
      const result = await contentfulClient.withoutUnresolvableLinks.getEntry(
        id,
        query
      );
      return { data: result };
    } catch (error) {
      const err = error as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

const contentfulApi = createApi({
  reducerPath: 'contentfulApi',
  baseQuery: contentfulBaseQuery(),
  endpoints(builder) {
    return {
      getEntry: builder.query<
        ContentfulGetEntryResponse,
        ContentfulGetEntryRequest
      >({
        query: (request) => request,
        onQueryStarted: onContentfulGetEntryQueryStarted,
      }),
    };
  },
});

export const { useGetEntryQuery } = contentfulApi;

export { contentfulApi };
