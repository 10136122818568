import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import config from '../../utils/env';
import { prepareHeaders } from '../serviceHelpers';

export interface LogRequest {
  logLevel?: string;
  correlationId?: string;
  message: string;
  data?: any;
}

interface LogResponse {
  message: string;
}

export const logApi = createApi({
  reducerPath: 'logApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.loggingApiRoot}`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    /**
     * Log a message and optional data to Sumo Logic.
     *
     * Don't use this API!
     *
     * Instead, see utils/logging for logging functions and other useful stuff.
     */
    log: builder.mutation<LogResponse, LogRequest>({
      query: ({ logLevel = 'Information', correlationId, message, data }) => ({
        url: '/api/log',
        method: 'POST',
        headers: {
          'x-api-key': config.loggingApiKey,
          'x-correlation-id': correlationId,
        },
        body: {
          ApplicationId: config.loggingApplicationId,
          LogLevel: logLevel,
          Payload: serializeLogPayload(message, data),
        },
      }),
    }),
  }),
});

/**
 * Serialize data to send to the log API. JSON.stringify can throw exceptions
 * (if the data is not serializable), which we must avoid when logging.
 */
const serializeLogPayload = (message: string, data: any) => {
  try {
    return JSON.stringify({ message, data });
  } catch {
    return JSON.stringify({
      message,
      data: '<data could not be serialized>',
    });
  }
};

// We don't use the hook to do logging because it causes unnecessary re-renders:
// https://redux-toolkit.js.org/rtk-query/api/created-api/hooks#usemutation
