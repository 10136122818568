import Alert from '@nib-components/alert';
import Copy from '@nib-components/copy';
import {
  AccidentGraphicIcon,
  DoctorGraphicIcon,
  HospitalGraphicIcon,
} from '@nib/icons';
import { Column, Columns, Stack } from '@nib/layout';
import UtilityButton from '@nib/utility-button';
import { ProductType } from 'src/types/ProductConfig';
import config from 'src/utils/env';
import { planConfig } from 'src/utils/productUtils';

const content = config.brand.content.chooseYourCover.step1;

interface ChoosePlanOptionStepProps {
  canSelectHospital: boolean;
  value: ProductType[];
  onChange: (productTypes: ProductType[]) => void;
}

/**
 * An option we can select in this form step
 */
interface PlanType {
  value: ProductType[];
  icon: React.FC;
  title: string;
  subTitle: string;
  disabled: boolean;
}

const ChoosePlanOptionsStep = (props: ChoosePlanOptionStepProps) => {
  const { canSelectHospital, value, onChange } = props;

  // Which options should we show for plan selection
  const hasEveryday = planConfig.hasMemberProducts
    ? planConfig.everydayProducts.memberProducts.length > 0 ||
      planConfig.everydayProducts.nonMemberProducts.length > 0
    : planConfig.everydayProducts.length > 0;
  const hasHospital = planConfig.hasMemberProducts
    ? planConfig.hospitalProducts.memberProducts.length > 0 ||
      planConfig.hospitalProducts.nonMemberProducts.length > 0
    : planConfig.hospitalProducts.length > 0;

  const planTypes: PlanType[] = [];
  if (hasEveryday && hasHospital) {
    planTypes.push({
      value: ['Hospital', 'Everyday'],
      icon: AccidentGraphicIcon,
      title: content.bothTitle,
      subTitle: content.bothText,
      disabled: !canSelectHospital,
    });
  }
  if (hasHospital) {
    planTypes.push({
      value: ['Hospital'],
      icon: HospitalGraphicIcon,
      title: content.hospitalTitle,
      subTitle: content.hospitalText,
      disabled: !canSelectHospital,
    });
  }
  if (hasEveryday) {
    planTypes.push({
      value: ['Everyday'],
      icon: DoctorGraphicIcon,
      title: content.everydayTitle,
      subTitle: content.everydayText,
      disabled: false,
    });
  }

  return (
    <Stack space={5}>
      {!canSelectHospital && (
        <Alert type="info" variation="soft" role="alert" fullWidth={false}>
          <Copy measure={true}>{content.ageOver75InfoText}</Copy>
        </Alert>
      )}
      <Columns space={4} collapseBelow="xl">
        {planTypes.map((plan) => (
          <Column
            key={`PlanOption-${plan.value.join('-')}`}
            data-testid={`plan-option-${plan.value.join('-')}`}
            width={
              planTypes.length <= 1
                ? 'content'
                : (`1/${planTypes.length}` as '1/2' | '1/3')
            }
            flex
          >
            <UtilityButton
              href="#"
              icon={plan.icon}
              showBorder
              type="button"
              selected={
                // simple array equality; assumes arrays are in the same order
                value.length === plan.value.length &&
                value.every((n) => plan.value.includes(n))
              }
              disabled={plan.disabled}
              onClick={() => {
                onChange(plan.value);
              }}
            >
              <UtilityButton.Title>{plan.title}</UtilityButton.Title>
              <UtilityButton.SubTitle>{plan.subTitle}</UtilityButton.SubTitle>
            </UtilityButton>
          </Column>
        ))}
      </Columns>
    </Stack>
  );
};

export default ChoosePlanOptionsStep;
