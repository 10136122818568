import Expander from '@nib-components/expander';
import { FieldsetFormControl } from '@nib-components/form-control';
import Link from '@nib-components/link';
import RadioGroup from '@nib-components/radio-group';
import { Box, Stack } from '@nib/layout';
import React from 'react';
import { ApplicantIdContext } from 'src/contexts/ApplicantIdContext';
import { PriceContext } from 'src/contexts/PriceContext';
import { PriceApiFetchPriceResponse } from 'src/services/price/priceApiTypes';
import priceApiUtils from 'src/services/price/priceApiUtils';
import { ProductOption } from 'src/types/ProductConfig';
import { PaymentFrequency } from 'src/types/QuoteSession';
import config from 'src/utils/env';
import { formatCurrency } from 'src/utils/formatters/formatCurrency';
import { getQuoteSession } from 'src/utils/localStorageUtils';
import MarkdownContent from '../MarkdownContent';

const content = config.brand.content.chooseYourCover.step4;

const ZERO_OPTION_VALUE = '0';

interface ChooseNonPharmacPlusStepProps {
  nonPharmacPlusOptions: ProductOption[];
  frequency: PaymentFrequency;
  selected: string;
  onSelect: (productOptionCode: string) => void;
}

const renderPrice = (
  value: number,
  priceData: PriceApiFetchPriceResponse | undefined,
  applicantId: string | null
) => {
  if (!priceData) {
    return '';
  }
  let price: number;
  if (applicantId) {
    price = priceApiUtils.getApplicantNonPharmacPlusOptionPrice(
      applicantId,
      value,
      getQuoteSession()!,
      priceData
    );
  } else {
    price = priceApiUtils.getTotalNonPharmacPlusOptionPrice(value, priceData);
  }
  return `+${formatCurrency(price)}`;
};

const ChooseNonPharmacPlusStep = (props: ChooseNonPharmacPlusStepProps) => {
  const { nonPharmacPlusOptions, frequency, selected, onSelect } = props;
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const { applicantId } = React.useContext(ApplicantIdContext);
  const { data: priceData } = React.useContext(PriceContext);

  // Convert object list to radiogroup options
  const options: Record<string, { label: string; subtitle: string }> = {};
  nonPharmacPlusOptions.forEach((option) => {
    options[option.value.toString()] = {
      label: formatCurrency(option.value, false),
      subtitle: `${renderPrice(
        option.value,
        priceData,
        applicantId
      )} ${frequency}`,
    };
  });
  options[ZERO_OPTION_VALUE] = {
    label: content.noneOptionText,
    subtitle: '',
  };

  const linkProps = { 'aria-expanded': expanded ? 'true' : 'false' };

  return (
    <Stack space={4}>
      <MarkdownContent content={content.introContent} />
      <Box>
        <Link onClick={() => setExpanded(!expanded)} {...linkProps}>
          {content.learnMoreLabel}
        </Link>
        <Expander expanded={expanded}>
          <MarkdownContent content={content.learnMoreContent} />
        </Expander>
      </Box>
      <FieldsetFormControl
        id="nonPharmacPlus"
        label=""
        width="100%"
        name="nonPharmacPlus"
      >
        <RadioGroup
          options={options}
          value={selected}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            onSelect(e.target.value);
          }}
        />
      </FieldsetFormControl>
    </Stack>
  );
};

export default ChooseNonPharmacPlusStep;
