import { v4 as uuidv4 } from 'uuid';
import {
  getDefaultCorrelationid,
  getJoinId,
  saveDefaultCorrelationId,
} from './localStorageUtils';

/**
 * Generate a two-part correlation id of the form {base-correlation-id}:{request-specific-correlation-id}
 * For any API requests that occur before we have a server-generated joinId, the base-correlation-id will
 * be set to a browser-generated uuid value that will be consistent across multiple requests.
 * Once we have a server-generated joinId (from POST CART), we will use that value going forward as the
 * base-correlation-id.
 * @returns a correlation-id string that can be used to populate the x-correlation-id header.
 */
export const generateCorrelationId = () => {
  const joinId = getJoinId();
  if (joinId) {
    return `${joinId}:${uuidv4()}`;
  }

  // No joinId yet.  Use our default correlation id.
  //
  let defaultCorrelationId = getDefaultCorrelationid();
  if (!defaultCorrelationId) {
    // First time we needed a defaultCorrelationId.  Generate it.
    //
    defaultCorrelationId = uuidv4();
    saveDefaultCorrelationId(defaultCorrelationId);
  }
  return `${defaultCorrelationId}:${uuidv4()}`;
};
