import Link from '@nib-components/link';
import { ContentfulTextLinkProps } from '../types';

const TextLink = (props: ContentfulTextLinkProps) => {
  return (
    <Link
      href={props.fields.url}
      target={props.fields.openInNewWindow ? '_blank' : undefined}
    >
      {props.fields.text}
    </Link>
  );
};

export default TextLink;
