import Card from '@nib/card';
import Loader from '@nib/loader';
import { SpecialOfferResponseData } from 'src/services/join/contentfulApiTypes';
import { useGetSpecialOfferQuery } from 'src/services/join/joinApi';
import styled from 'styled-components';
import SpecialOffer from './SpecialOffer';

interface SpecialOfferCardProps {
  currentJourney?: number;
}

const Card1 = styled(Card)`
  margin-top: 0.5rem;
`;

const SpecialOfferCard = (props: SpecialOfferCardProps) => {
  const { isLoading, currentData, isError } = useGetSpecialOfferQuery();

  if (isError) return null;

  if (isLoading) {
    return <Loader size="lg" />;
  }

  if (!currentData?.data) return null;

  let specialOffer: SpecialOfferResponseData = currentData?.data;

  return (
    <Card1>
      <SpecialOffer
        specialOffer={specialOffer}
        currentJourney={props.currentJourney}
      />
    </Card1>
  );
};

export default SpecialOfferCard;
