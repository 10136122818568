import {
  useCreateCartMutation,
  useUpdateCartMutation,
} from 'src/services/join/joinApi';
import { JoinApiCartDetails } from 'src/services/join/joinApiTypes';
import { PriceApiFetchPriceResponse } from 'src/services/price/priceApiTypes';
import { QuoteSession } from 'src/types/QuoteSession';
import { generateCorrelationId } from 'src/utils/correlationUtils';
import { createCartDetails } from 'src/utils/joinApiUtils';
import joinIdUtils from 'src/utils/joinIdUtils';
import { getJoinId, saveJoinId } from 'src/utils/localStorageUtils';
import { usePriceData } from './usePriceData';

const getPriceData = async (
  priceDataPromise: Promise<PriceApiFetchPriceResponse>
) => {
  try {
    return await priceDataPromise;
  } catch (error) {
    console.warn('Failed to get price data in save cart...');
    throw new Error('Failed to get price data in save cart');
  }
};

/**
 * Custom hook for creating or updating a cart through the join API.
 * The first time the returned saveCart function is called, it will POST
 * to the join API and create a new cart record in DynamoDB.  It will also
 * persist the returned join API to local storage and use it in any subsequent
 * calls to update the cart (by generating PUT requests to the join API).
 *
 * @returns {function} saveCart function that can be used to create/update a cart.
 */
export function useSaveCart() {
  const [createCart] = useCreateCartMutation();
  const [updateCart] = useUpdateCartMutation();
  const getPriceApiData = usePriceData();

  const saveCart = async (
    quoteSession: QuoteSession,
    throwErrorOnFailure = false
  ): Promise<void> => {
    let cartDetails: JoinApiCartDetails;
    // if completed pages is zero ... it means that the user hasn't saved ANY details ... and we have nothing to base pricing on
    // therefore we do not request the priceData
    if (quoteSession.completedPages.length >= 1) {
      const priceDataPromise = getPriceApiData(quoteSession);
      const priceData = await getPriceData(priceDataPromise);
      cartDetails = createCartDetails(quoteSession, priceData);
    } else {
      cartDetails = createCartDetails(quoteSession);
    }

    return joinIdUtils
      .applyCart(() => upsertCart(cartDetails))
      .catch((error) => {
        console.error(
          `useSaveCart caught error from joinIdUtils.applyCart`,
          error
        );
        if (throwErrorOnFailure) {
          throw error;
        }
        // If throwErrorOnFailure is false ... we don't rethrow the error
        // because 1) nobody will be listening for it and 2) it will
        // cause unhandled promise rejection errors on the console
        // (and error screens in dev mode)
        //
      });
  };

  const upsertCart = async (cartDetails: JoinApiCartDetails) => {
    const joinId = getJoinId();
    if (joinId) {
      return update(joinId, cartDetails);
    } else {
      return create(cartDetails);
    }
  };

  const create = async (cartDetails: JoinApiCartDetails) => {
    return createCart({
      cartDetails,
      correlationId: generateCorrelationId(),
    })
      .unwrap()
      .then(({ data: joinId }) => {
        saveJoinId(joinId);
      })
      .catch((error) => {
        console.error(`useSaveCart caught error creating cart`, error);
        throw error;
      });
  };

  const update = async (
    joinId: string,
    cartDetails: JoinApiCartDetails
  ): Promise<void> => {
    return updateCart({
      joinId,
      cartDetails,
      correlationId: generateCorrelationId(),
    })
      .unwrap()
      .then(() => undefined)
      .catch((error) => {
        console.error(`useSaveCart caught error updating cart`, error);
        throw error;
      });
  };

  return saveCart;
}
