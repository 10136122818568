import Copy from '@nib-components/copy';
import Heading from '@nib-components/heading';
import { Box, Hidden, Section, SectionProps, Stack } from '@nib/layout';
import config from 'src/utils/env';
import styled from 'styled-components';

// Allows image to be aligned to the bottom right of the container
const ImageContainer = styled('div')`
  position: relative;
`;

// Positions Image at bottom right of container.
// Bottom negative margin equals that of the parent <Container>, to align image against the bottom border,
// outside the bounds of its parent.
const ImagePositioner = styled('div')`
  position: absolute;
  right: 0;
  bottom: -4rem;
`;

interface HeadingPanelProps extends SectionProps {
  title: string;
  subTitle?: string;
  /** Image should be approx 1:1 ratio */
  image?: {
    src: string;
    altText: string;
    /** Image width is in rems */
    width: number;
  };
}

/**
 * Displays a responsive heading panel, with optional subtitle and image.
 */
const HeadingPanel = (props: HeadingPanelProps) => {
  const { title, subTitle, image, ...sectionProps } = props;

  // Find breakpoints based on image width.
  const imageWidth = image
    ? { lg: `${image.width * 0.8}rem`, xl: `${image.width}rem` }
    : undefined;
  const textWidth = image
    ? {
        xs: '100%',
        lg: `${40 - image.width * 0.8}rem`,
        xl: `${50 - image.width}rem`,
        xxl: `${64 - image.width}rem`,
      }
    : undefined;

  return (
    <Section background="sageGreen60" {...sectionProps}>
      <ImageContainer>
        <Box width={textWidth}>
          <Stack space={{ xs: 2, md: 4 }}>
            <Heading
              component="h1"
              size={{ xs: 3, md: 2, xl: 1 }}
              color={config.brand.customisation?.headersColor}
            >
              {title}
            </Heading>
            {subTitle && <Copy measure={true}>{subTitle}</Copy>}
          </Stack>
        </Box>
        {/* Box width approximates image width; images should be around 1:1 ratio */}
        {image && (
          <Box width={imageWidth}>
            <Hidden below="lg">
              <ImagePositioner>
                <Box height={imageWidth}>
                  <img
                    src={image.src}
                    alt={image.altText}
                    width="auto"
                    height="100%"
                  />
                </Box>
              </ImagePositioner>
            </Hidden>
          </Box>
        )}
      </ImageContainer>
    </Section>
  );
};

export default HeadingPanel;
