const consoleErrorsToSuppress: string[] = [
  'Warning: Function components cannot be given refs. Attempts to access this ref will fail.', // Choose your cover after choose plan options
  'The prop `href` is marked as required in `ForwardRef`, but its value is `undefined`', // ChooseNonPharmacPlusStep
  'Warning: React does not recognize the `%s` prop on a DOM element', // `collapseTitle` or `stickyHeader` -- mesh modals
  'Warning: Using UNSAFE_componentWillMount in strict mode is not recommended and may indicate bugs in your code', // react-helmet?
  'Invalid prop `children` supplied to `Card.Content`, expected a ReactNode', // Special Offer
  'Warning: Failed prop type: Invalid prop `width` supplied to `Column`', // Special offer
  'Warning: Failed prop type: Invalid prop `children` supplied to `Stack`, expected a ReactNode', // Special offer
  'Warning: validateDOMNesting(...): %s cannot appear as a descendant of <%s>', // <p> of <p> or <div> of <p> // Special offer
  'Warning: Failed prop type: Invalid prop `icon` of type `object` supplied to `Accordion.Item`, expected `function`', // CoverBenefitsList
  'Invalid prop `label` of type `object` supplied to `FieldsetFormControl`, expected `string`', // ?
  'Check the render method of `CoverBenefitsList', // Choose Your cover
  'Invalid prop `width` supplied to `Box`, expected one of type [string, string]', // VerticalIndentBar on BuyNow
  'The prop `value` is marked as required in `Checkbox`, but its value is `undefined`', // On Declarations on BuyNow
  'Invalid prop `label` of type `object` supplied to `Checkbox`, expected `string`.', // On Declarations on BuyNow
  'Warning: Failed prop type: The prop `value` is marked as required in `ToggleInput`, but its value is `undefined`', // Declarations / BuyNow
  'Warning: Failed prop type: Invalid prop `label` of type `object` supplied to `ToggleInput`, expected `string`', // Declarations / BuyNow
  'Warning: Failed prop type: Invalid prop `subtitle` of type `object` supplied to `Radio`, expected `string`', // Direct Debit
  'Warning: Failed prop type: The prop `name` is marked as required in `ToggleInput`, but its value is `undefined`', // Direct Debit
  'Warning: Failed prop type: Invalid prop `subtitle` of type `object` supplied to `ToggleInput`, expected `string`', // Direct Debit
  'Warning: Received `%s` for a non-boolean attribute `%s', // Direct Debit
  'Invalid prop `label` of type `object` supplied to `FieldsetFormControl`, expected `string`', // Direct Debit
  'Warning: A component is changing a controlled input of type %s to be uncontrolled', // First time through add another person from header
  'Warning: Cannot update a component (`%s`) while rendering a different component (`%s`)', // On Choose your cover, expand a benefit when another is already expanded
];

const shouldLog = function (...args: any[]) {
  const errorMessage = args.join(' ');
  for (const errorToSuppress of consoleErrorsToSuppress) {
    if (errorMessage.includes(errorToSuppress)) {
      return false;
    }
  }
  return true;
};

/**
 * Hook the console.error method and filter out any "known" errors so we can
 * see the forest for the trees in dev mode on the browser console.
 *
 * Over time, we should be able to actually resolve the vast majority of the "known" errors
 * and remove them from the "consoleErrorsToSuppress" list above.
 * However, there may be some errors that are not practical to resolve but are not actually
 * harmful (e.g. some errors from mesh about unknown properties being passed to HTML elements).
 */
export const filterConsoleErrors = () => {
  const originalConsoleError = console.error;

  console.error = function (...args) {
    if (shouldLog(args)) {
      originalConsoleError(...args);
    }
  };
};
