type Operation = () => Promise<any>;

export class JoinIdUtils {
  private queuedOperationPromise: Promise<void> | null = null;
  private queuedOperation: Operation | null = null;

  /**
   * Apply a POST or PUT operation to upsert a cart ... ensuring that requests
   * are serialized ... so that, even if the caller does not wait for the returned
   * promise to be fulfilled, subsequent requests are queued until any pending
   * request has completed.
   * @param operation
   * @returns
   */
  applyCart(operation: Operation): Promise<void> {
    if (this.queuedOperationPromise) {
      if (this.queuedOperation) {
        console.log(`JoinIdUtils replacing queued operation`);
      } else {
        console.log(`JoinIdUtils queueing operation`);
      }
      this.queuedOperation = operation;
      return this.queuedOperationPromise;
    }
    return this.runOperation(operation);
  }

  private runOperation(operation: Operation): Promise<void> {
    const currentOperationPromise = operation();
    this.queuedOperationPromise = currentOperationPromise
      // It's important to attach catch before then here.
      // Otherwise, if errors are thrown in the then block
      // and caught by the catch block ... and there are no more
      // queued operations, then we can end up swallowing the error
      //
      .catch((error) => {
        return this.runQueuedOperation();
      })
      .then(() => {
        return this.runQueuedOperation();
      });
    return currentOperationPromise;
  }

  private runQueuedOperation(): Promise<void> {
    if (!this.queuedOperation) {
      this.queuedOperationPromise = null;
      return Promise.resolve();
    }
    const operation = this.queuedOperation;
    this.queuedOperation = null;
    console.log(`JoinIdUtils running queued operation`);
    return this.runOperation(operation);
  }
}

const joinIdUtils = new JoinIdUtils();

export default joinIdUtils;
