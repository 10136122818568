import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { priceApi, useFetchPriceV2Query } from 'src/services/price/priceApi';
import priceApiUtils from 'src/services/price/priceApiUtils';
import { useAppDispatch } from 'src/store/hooks';
import { QuoteSession } from 'src/types/QuoteSession';
import { PriceContext } from './PriceContext';

interface PriceProviderProps {
  quoteSession: QuoteSession;
}

export const PriceProvider: React.FC<PriceProviderProps> = ({
  quoteSession,
  children,
}) => {
  const { data, isFetching, isError } = useFetchPriceV2Query(
    priceApiUtils.createFetchPriceRequestV2(quoteSession)
  );

  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (isError && location.pathname !== '/error') {
      // Reset the API state so that we try again next time we render this component
      dispatch(priceApi.util.resetApiState());
      console.warn('Failed in price provider ... navigating to the error page');
      navigate(`/error?returnURL=${location.pathname}`);
    }
  }, [dispatch, isError, location, navigate]);

  return (
    <PriceContext.Provider value={{ isFetching, data }}>
      {children}
    </PriceContext.Provider>
  );
};
