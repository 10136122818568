import Link from '@nib-components/link';
import { Box } from '@nib/layout';
import config from 'src/utils/env';
import AppStore from '../../utils/assets/welcome/images/AppStore.png';
import PlayStore from '../../utils/assets/welcome/images/PlayStore.png';

const MobileAppStores = () => {
  return (
    <Box textAlign="center" marginTop={5}>
      <Link href={config.brand.content.welcome.appStoreUrl}>
        <img
          src={AppStore}
          style={{
            maxWidth: '135px',
            height: '40px',
            padding: '2%',
          }}
          alt={config.brand.content.welcome.appStoreAltText}
        />
      </Link>
      <Link href={config.brand.content.welcome.googlePlayStoreUrl}>
        <img
          src={PlayStore}
          style={{
            maxWidth: '135px',
            height: '40px',
            padding: '2%',
          }}
          alt={config.brand.content.welcome.googlePlayStoreAltText}
        />
      </Link>
    </Box>
  );
};

export default MobileAppStores;
