import { Box } from '@nib/layout';
import Loader from '@nib/loader';
import { SpecialOfferResponseData } from 'src/services/join/contentfulApiTypes';
import { useGetSpecialOfferQuery } from 'src/services/join/joinApi';
import SpecialOfferInfoBox from './SpecialOfferInfoBox';

const SpecialOfferAlertWrapper = () => {
  const { isLoading, currentData, isError } = useGetSpecialOfferQuery();

  if (isError) return null;

  if (isLoading) {
    return <Loader size="lg" />;
  }

  if (!currentData?.data) return null;

  let specialOffer: SpecialOfferResponseData = currentData?.data;

  return (
    <Box>
      <SpecialOfferInfoBox specialOffer={specialOffer} />
    </Box>
  );
};

export default SpecialOfferAlertWrapper;
