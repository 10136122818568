import * as contentful from 'contentful';
import config from 'src/utils/env';

const contentfulClient = contentful.createClient({
  space: config.contentfulSpace,
  environment: config.contentfulEnvironment,
  accessToken: config.contentfulAccessToken,
});

export default contentfulClient;
