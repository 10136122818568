import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FormPage } from 'src/types/FormPage';
import { QuoteSession } from 'src/types/QuoteSession';
import {
  findFurthestValidPage,
  getFormPageByIndex,
} from 'src/utils/formPageUtils';

interface FormRouteProps {
  quoteSession: QuoteSession;
  setCompletedPage?: (formPage: FormPage) => void;
  formPage: FormPage;
}

/**
 * A route guard that imposes validation rules before rendering a screen, to ensure
 * the user can't skip steps or end up on a screen they don't have data for.
 */
const FormRoute = (
  props: React.PropsWithChildren<FormRouteProps>
): JSX.Element => {
  const { quoteSession, setCompletedPage, formPage, children } = props;

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const params = useParams();

  const [redirectTo, setRedirectTo] = React.useState<string>();

  React.useEffect(() => {
    if (!redirectTo || redirectTo === pathname) {
      return;
    }
    // Replaces the new URL as the current URL, instead of adding to the history stack.
    navigate(redirectTo, { replace: true });
    setRedirectTo(undefined);
  }, [navigate, pathname, redirectTo]);

  // If we have any applicants who must tailor their quote, show the Choose
  // Cover screen for that user.
  const showTailorQuoteFor = quoteSession.applicantDetails.find((a) =>
    // They have hospital cover and >75yo.
    // If we're on AboutYou or ChooseYourCover, empty policies are OK.
    quoteSession.mustTailorQuote(a, formPage < FormPage.TailorYourQuote)
  );
  let mandatoryRedirectPathname: string | undefined;
  if (showTailorQuoteFor) {
    mandatoryRedirectPathname = `/edit-cover/${formPage}/${showTailorQuoteFor.id}`;
    if (
      pathname !== mandatoryRedirectPathname &&
      redirectTo !== mandatoryRedirectPathname
    ) {
      setRedirectTo(mandatoryRedirectPathname);
      return <></>;
    }
  }

  // If we're redirecting to somewhere, we don't want to re-run the below logic.
  if (redirectTo) {
    return <></>;
  }

  // If we're trying to see a page we're not allowed to yet, kick the user
  // back to the latest page they are allowed to see.
  if (!mandatoryRedirectPathname) {
    const furthestPage = findFurthestValidPage(
      formPage,
      quoteSession,
      !!params.applicantId
    );
    if (furthestPage < formPage) {
      setRedirectTo(getFormPageByIndex(furthestPage)!.path);
      return <></>;
    }
    // If we're on a page we are allowed to be viewing, make sure the previous page
    // is added to the completed pages array before rendering (except About You)
    if (
      setCompletedPage &&
      formPage &&
      !quoteSession.completedPages.includes(formPage - 1)
    ) {
      setCompletedPage(formPage - 1);
      return <></>;
    }
  }

  return <>{children}</>;
};

export default FormRoute;
