import Modal, { ModalProps } from '@nib-components/modal';
import React from 'react';
import config from 'src/utils/env';
import styled from 'styled-components';

const StyleModal = styled(Modal)`
  h3 {
    color: ${config.brand.customisation?.headersColor ?? 'inherit'};
  }
`;

const BrandedModal: React.FC<ModalProps> = ({ ...props }) => {
  return <StyleModal {...props}></StyleModal>;
};

export default BrandedModal;
