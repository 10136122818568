import { EmailGraphicIcon } from '@nib/icons';
import { Box, Stack } from '@nib/layout';
import Loader from '@nib/loader';
import { Formik } from 'formik';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuoteSession } from 'src/contexts/QuoteSessionProvider';
import { useSaveQuote } from 'src/store/hooks/useSaveQuote';
import config from 'src/utils/env';
import gtmUtils from 'src/utils/gtmUtils';
import { EmailQuoteFormValuesSchema } from 'src/utils/validation/schema/EmailQuoteFormValuesSchema';
import BrandedModal from '../BrandedModal';
import FormCheckbox from '../form/FormCheckbox';
import FormTextbox from '../form/FormTextbox';
import MarkdownContent from '../MarkdownContent';
import BottomButtons from '../navigation/BottomButtons';

const content = config.brand.content.emailQuote;

const QuoteSent = () => {
  return (
    <Box>
      <MarkdownContent content={content.sentDetail} />
    </Box>
  );
};

interface EmailQuoteModalProps {
  onClose: () => void;
}

export interface EmailQuoteFormValues {
  firstName: string;
  phoneNumber: string;
  email: string;
  hasAgreedToBeContacted: boolean;
}

const EmailQuoteModal = (props: EmailQuoteModalProps) => {
  const { onClose } = props;
  const [showSentQuote, setshowSentQuote] = React.useState(false);
  const quoteSession = useQuoteSession();
  const navigate = useNavigate();
  const location = useLocation();
  const saveQuote = useSaveQuote();

  const applicant = quoteSession.getNominatedOwner();
  const initialValues: EmailQuoteFormValues = {
    firstName: applicant.firstName,
    phoneNumber: applicant.phone,
    email: quoteSession.applicantExtraDetails[applicant.id].email,
    hasAgreedToBeContacted: true,
  };

  const handleSubmit = async (values: EmailQuoteFormValues) => {
    try {
      // send quote to back end and email
      await saveQuote(
        quoteSession,
        values.firstName,
        values.email,
        !values.hasAgreedToBeContacted,
        values.phoneNumber
      );
      setshowSentQuote(true);
      gtmUtils.submitSendQuote(
        values.firstName,
        values.phoneNumber,
        values.email
      );
    } catch (error) {
      console.warn(
        'Failed to save quote on email a quote ... navigating to the error page'
      );
      navigate(`/error?returnURL=${location.pathname}`);
    }
  };

  return (
    <BrandedModal
      visible={true}
      title={showSentQuote ? content.sentTitle : content.title}
      onClose={onClose}
      closeOnClick={showSentQuote ? true : false}
      closeOnEsc={true}
    >
      {showSentQuote ? (
        <QuoteSent />
      ) : (
        <>
          <MarkdownContent content={content.mainMessage} />
          <Box>
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={EmailQuoteFormValuesSchema}
              /* Without validateOnBlur=false, validation runs twice and the second time has the old value */
              validateOnBlur={false}
            >
              {({ isSubmitting, handleReset, handleSubmit }) => {
                return (
                  <form
                    id="emailQuote"
                    name="emailQuote"
                    onReset={handleReset}
                    onSubmit={handleSubmit}
                  >
                    <Stack space={6}>
                      <FormTextbox
                        label={content.nameLabel}
                        name="firstName"
                        tag="optional"
                        maxLength={30}
                        autoComplete={'given-name'}
                      />
                      <FormTextbox
                        label={content.emailLabel}
                        name="email"
                        maxLength={50}
                        formMode="light"
                        type="email"
                        autoComplete={'email'}
                      />
                      <FormTextbox
                        label={content.phoneNumberLabel}
                        name="phoneNumber"
                        tag="optional"
                        maxLength={30}
                        inputMode="tel"
                        stripWhitespace={true}
                        type="tel"
                        autoComplete={'tel-national'}
                      />
                      <BottomButtons
                        disabled={isSubmitting}
                        cancelButtonText={content.cancelButtonText}
                        onCancel={() => onClose()}
                        submitButtonText={content.emailQuoteButton}
                        hideSubmitButtonIcon={false}
                        cancelButtonSize="medium"
                        submitButtonSize="medium"
                        submitButtonColor="dark"
                        submitButtonIcon={EmailGraphicIcon}
                      />
                      <FormCheckbox
                        name="hasAgreedToBeContacted"
                        label={content.agreedToBeContactedText}
                        formMode="white"
                      />
                      {isSubmitting && <Loader size="lg" />}
                    </Stack>
                  </form>
                );
              }}
            </Formik>
          </Box>
        </>
      )}
    </BrandedModal>
  );
};

export default EmailQuoteModal;
