import { Block, BLOCKS, Inline } from '@contentful/rich-text-types';
import { ReactNode } from 'react';

export const Paragraph = (
  node: Block | Inline,
  children: ReactNode
): JSX.Element =>
  node.content.some((content) => content.nodeType === BLOCKS.EMBEDDED_ENTRY) ? (
    <div>{children}</div>
  ) : (
    <p>{children}</p>
  );
