/**
 * @file The state stored for the user while filling in the form.
 */

import { clone } from 'ramda';
import dateUtils from 'src/utils/dateUtils';
import config from 'src/utils/env';
import {
  everydayProducts,
  getProductByCode,
  hospitalProducts,
  isValidMemberProductCode,
} from 'src/utils/productUtils';
import {
  createApplicant,
  createApplicantExtraDetails,
  createPolicyDetails,
  policyDetailsAreEqual,
} from 'src/utils/quoteUtils';
import { BuyNowAdviserDeclarationContent } from './Content/BuyNowContent';
import { FormPage } from './FormPage';
import { ProductConfig, ProductOption, ProductType } from './ProductConfig';

export interface AdviserDetails {
  /** The number the user types into the Adviser Number field */
  adviserNumber: string;
  /** The translated UAN/Adviser Number returned from the API */
  uan: string;
  /** The Agreement ID returned from the API */
  agreementId: string;
  /** Whether the user has read the About You declaration */
  hasReadDeclaration: boolean;
}

export interface AdviserDeclaration {
  /** Details for the Buy Now Adviser Declarations section */
  group: {
    question: string[];
  }[];
}

export interface YourDetailsDeclaration {
  /** Whether the user has read the Buy Now email declaration */
  hasReadEmailDeclaration: boolean;
}

/**
 * Details from the About You screen
 */
export interface ApplicantDetails {
  id: string;
  firstName: string;
  age: string;
  phone: string;
  gender: string;
  smoker: string;
  isPolicyOwner: boolean;
  isGuardian?: boolean;
}

/**
 * Details from the Choose Your Cover screen
 */
export interface PolicyDetails {
  // Step 1
  productTypes: ProductType[];
  // Step 2
  hospitalProductCode?: string;
  everydayProductCode?: string;
  // Step 3
  excess: string;
  productCodeToSelectedExcess: Record<string, string>;

  // Step 4
  nonPharmacPlus: string;
}

/**
 * Details from the Buy Now screen Payment Details step
 */
export interface PaymentDetails {
  paymentMethod: PaymentMethod;
  frequency: PaymentFrequency; // also used in header and Choose Cover screen
  firstPaymentDate: string; // dd/mm/yyyy format, not Date object (Date objects can't be serialized into localStorage)
  bankName: string;
  accountName: string;
  accountNumber: string;
  termsAndConditions: boolean; // 'agreed' checkbox
  sessionId?: string; // The Billing Session sessionId, used by Credit Card payments
}

/**
 * Additional details for people gathered on Buy Now step
 */
export interface ApplicantExtraDetails {
  surname: string;
  email: string;
  dateOfBirth: string; // dd/mm/yyyy format, not Date object (Date objects can't be serialized into localStorage)
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  addressLine4: string;
  addressLine5: string;
}

export interface DeclarationDetails {
  hasInsurance: 'Yes' | 'No' | null;
  allApplicantsEligible: boolean;
  hasReadPreExistingConditions: boolean;
  hasReadPrivacyPolicy: boolean;
}

export enum PaymentFrequency {
  Weekly = 'Weekly',
  Fortnightly = 'Fortnightly',
  Monthly = 'Monthly',
  Quarterly = 'Quarterly',
  HalfYearly = 'Half Yearly',
  Yearly = 'Yearly',
}

export enum PaymentMethod {
  DirectDebit = 'DirectDebit',
  CreditCard = 'CreditCard',
}

/**
 * The various steps of the Buy Now form that we are on
 */
export enum BuyNowStep {
  YourDetails,
  AaMembership,
  Declaration,
  PaymentDetails,
}

export enum OverallProductMix {
  None,
  HospitalOnly,
  EverydayOnly,
  HospitalAndEveryday,
}

// Cannot have any non-scalar children eg. Date objects, as this
// is serialized into localStorage.
// When making breaking changes, also increment the version in:
// * src/utils/localStorageUtils.tsx:QUOTE_SESSION_KEY
// * e2e/tests/utils.js:ResumeSessionModal.setupResumeSessionModal
export class QuoteSession {
  joinId?: string;
  adviserDetails?: AdviserDetails;
  adviserDeclaration?: AdviserDeclaration;
  yourDetailsDeclaration?: YourDetailsDeclaration;
  directDebitAdviserDeclaration?: AdviserDeclaration;
  creditCardAdviserDeclaration?: AdviserDeclaration;
  applicantDetails: ApplicantDetails[];
  applicantExtraDetails: Record<string, ApplicantExtraDetails>;
  memberPolicyDetails: Record<string, PolicyDetails>;
  policyDetails: PolicyDetails; // TODO Remove this once Tailor Quote implemented
  declaration?: DeclarationDetails;
  paymentDetails: PaymentDetails;
  /** A list of pages that have had completed (ie. valid) form data added to the quote session */
  completedPages: FormPage[];
  /** Current step to display on Buy Now page */
  buyNowStep: BuyNowStep;
  promoCode: string | null;
  // "Member policy" specific details
  hasMember?: boolean;
  externalMemberApplicant: string;
  externalMemberNumber: string;

  constructor() {
    // Adviser is undefined if the brand doesn't have advisers
    if (config.brand.hasAdviser) {
      this.adviserDetails = {
        adviserNumber: '',
        uan: '',
        agreementId: '',
        hasReadDeclaration: false,
      };
      // Adviser Declaration has empty values set for all possible questions by default
      this.adviserDeclaration = {
        group: (
          config.brand.content.buyNow.step3 as BuyNowAdviserDeclarationContent
        ).groups.map((group) => ({
          question: group.questions
            .filter((q) => q.options)
            .map((_question) => ''),
        })),
      };
      this.yourDetailsDeclaration = {
        hasReadEmailDeclaration: false,
      };
      this.directDebitAdviserDeclaration = {
        group:
          config.brand.content.buyNow.step4.directDebit.adviserDeclaration!.groups.map(
            (group) => ({
              question: group.questions
                .filter((q) => q.options)
                .map((_question) => ''),
            })
          ),
      };
      this.creditCardAdviserDeclaration = {
        group:
          config.brand.content.buyNow.step4.creditCard.adviserDeclaration!.groups.map(
            (group) => ({
              question: group.questions
                .filter((q) => q.options)
                .map((_question) => ''),
            })
          ),
      };
    } else {
      this.declaration = {
        hasInsurance: null,
        allApplicantsEligible: false,
        hasReadPreExistingConditions: false,
        hasReadPrivacyPolicy: false,
      };
    }

    this.applicantDetails = [createApplicant(true)];
    this.applicantExtraDetails = {
      [this.applicantDetails[0].id]: createApplicantExtraDetails(),
    };
    this.memberPolicyDetails = {
      [this.applicantDetails[0].id]: createPolicyDetails(),
    };
    this.policyDetails = createPolicyDetails();
    this.paymentDetails = {
      paymentMethod: PaymentMethod.DirectDebit,
      frequency: PaymentFrequency.Weekly,
      firstPaymentDate: '',
      bankName: '',
      accountName: '',
      accountNumber: '',
      termsAndConditions: false,
    };
    this.completedPages = [];
    this.buyNowStep = BuyNowStep.YourDetails;
    this.promoCode = null;

    // hasMember is undefined by default; user must select an option to proceed
    this.hasMember = undefined;
    this.externalMemberApplicant = '';
    this.externalMemberNumber = '';
  }

  /**
   * Returns the specified applicant's policy details, or the default policy details
   * if no applicant is specified.
   * @param applicantId
   * @returns
   */
  getApplicantPolicyDetails(applicantId: string | null): PolicyDetails {
    return applicantId
      ? this.memberPolicyDetails[applicantId]
      : this.getDefaultPolicyDetails();
  }

  /**
   * Returns the default policy details.  Useful when adding new applicants.
   */
  getDefaultPolicyDetails(): PolicyDetails {
    return this.memberPolicyDetails[this.applicantDetails[0].id];
  }

  /**
   * Returns the applicant details for the nominated owner.
   * Note that session will always maintain a single applicant
   * with isPolicyOwner=true.  In the stages before Buy Now, this
   * will default to the first applicant.  On the Buy Now page,
   * the user can select a different applicant.
   * @param quoteSession
   * @returns
   */
  getNominatedOwner(): ApplicantDetails {
    const owners = this.applicantDetails.filter((a) => a.isPolicyOwner);
    if (owners.length !== 1) {
      throw new Error(
        `getNominatedOwner called but ${owners.length} owners found`
      );
    }
    return owners[0];
  }

  getOverallProductMix(): OverallProductMix {
    const allPolicyDetails = Object.values(this.memberPolicyDetails);
    const hasHospital = allPolicyDetails.some(
      (policyDetails) => !!policyDetails.hospitalProductCode
    );
    const hasEveryday = allPolicyDetails.some(
      (policyDetails) => !!policyDetails.everydayProductCode
    );
    if (hasHospital && hasEveryday) {
      return OverallProductMix.HospitalAndEveryday;
    }
    if (hasHospital) {
      return OverallProductMix.HospitalOnly;
    }
    if (hasEveryday) {
      return OverallProductMix.EverydayOnly;
    }
    return OverallProductMix.None;
  }

  /**
   * Update the applicantDetails on the quote.
   * N.B.  This should only be called from the About You page.
   * To update an individual applicant (create/edit applicant) ... use the
   * updateSingleApplicationDetails method.
   * @param applicantDetails the latest applicantDetails
   */
  updateAllApplicantDetails(applicantDetails: ApplicantDetails[]) {
    this.applicantDetails = applicantDetails;
    // If any applicants have been added ... create a policyDetails record for them
    // as a copy of the first member's and give them an empty set of "extra details"
    for (const applicant of applicantDetails) {
      if (!this.memberPolicyDetails[applicant.id]) {
        if (applicant.isGuardian) {
          // Guardians are 'Policy Owner No Cover'
          this.memberPolicyDetails[applicant.id] = createPolicyDetails();
        } else {
          this.memberPolicyDetails[applicant.id] = clone(
            this.getDefaultPolicyDetails()
          );
        }
      }
      if (!this.applicantExtraDetails[applicant.id]) {
        this.applicantExtraDetails[applicant.id] =
          createApplicantExtraDetails();
      }
    }
    // If we have any applicants > 75 ... we need to remove Hospital cover from all applicants
    // Note that this method should ONLY be called from the About You page
    // which implies that the quote has NOT been tailored for individual applicants.
    //
    if (
      this.applicantDetails.some((a) => this.isIneligibleForHospitalCover(a))
    ) {
      this.applyMemberPolicyChange(null, (policyDetails) => {
        this.removeHospitalCover(policyDetails);
      });
    }
  }

  updateAllApplicantExtraDetails(
    applicantExtraDetails: Record<string, ApplicantExtraDetails>
  ) {
    this.applicantExtraDetails = applicantExtraDetails;
  }

  updateSingleApplicantDetails(
    applicant: ApplicantDetails,
    /**
     * If set to false, will give the applicant empty cover if they do not have
     * existing cover. Defaults to adding the default cover to users who
     * do not have existing cover (eg. newly added applicants)
     */
    useDefaultCoverIfEmpty = true
  ) {
    this.updateApplicantRecord(applicant);
    // This is a new applicant.  Give them the same policy
    // details as the "first" applicant as a default, and
    // a fresh set of "extra details".
    if (!this.memberPolicyDetails[applicant.id]) {
      if (!useDefaultCoverIfEmpty || applicant.isGuardian) {
        // If the new member is a guardian, they have no cover (PONC = Policy Owner No Cover)
        this.memberPolicyDetails[applicant.id] = createPolicyDetails();
      } else {
        this.memberPolicyDetails[applicant.id] = clone(
          this.getDefaultPolicyDetails()
        );
      }
    }
    if (!this.applicantExtraDetails[applicant.id]) {
      this.applicantExtraDetails[applicant.id] = createApplicantExtraDetails();
    }
    if (this.isIneligibleForHospitalCover(applicant)) {
      const policyDetails = this.getApplicantPolicyDetails(applicant.id);
      this.removeHospitalCover(policyDetails);
    }
  }

  updateExternalMembers(
    formExternalMemberNumber: string,
    formHasMember: boolean | undefined
  ) {
    this.externalMemberNumber = formExternalMemberNumber;
    const isExternalMemberStillPresent = this.applicantDetails.some(
      (applicant) => applicant.id === this.externalMemberApplicant
    );
    // Blank external member number if we change hasMember value or member has been removed
    if (this.hasMember !== formHasMember || !isExternalMemberStillPresent) {
      this.externalMemberNumber = '';
      if (this.buyNowStep > BuyNowStep.AaMembership) {
        this.buyNowStep = BuyNowStep.AaMembership;
      }
    }
    // Blank external member applicant if we change hasMember
    if (this.hasMember !== formHasMember) {
      this.externalMemberApplicant = '';
    }
    this.hasMember = formHasMember;
  }

  private updateApplicantRecord(applicant: ApplicantDetails) {
    const existingIndex = this.applicantDetails.findIndex(
      (a) => a.id === applicant.id
    );
    if (existingIndex === -1) {
      this.applicantDetails.push(applicant);
    } else {
      this.applicantDetails[existingIndex] = applicant;
    }
  }

  private isIneligibleForHospitalCover(applicant: ApplicantDetails) {
    return parseInt(applicant.age) > 75;
  }

  /**
   * Returns whether the quote session has tailored cover for any applicants.
   */
  hasTailoredCover(): boolean {
    return !this.applicantDetails.every(
      (a) =>
        // It's the same applicant, or
        a.id === this.applicantDetails[0].id ||
        // the policy details are the same, or
        policyDetailsAreEqual(
          this.getApplicantPolicyDetails(a.id),
          this.getApplicantPolicyDetails(this.applicantDetails[0].id)
        )
    );
  }

  /**
   * Check if saving an applicant should require the user to tailor the cover
   * for that applicant.
   * @param applicantDetails applicant details to check
   * @returns true if 1) the applicant is > 75 AND 2) the applicant already has
   * hospital cover OR this is a new applicant AND the "default" cover (which would
   * be copied from the first applicant) includes hospital cover.
   */
  mustTailorQuote(applicantDetails: ApplicantDetails, allowEmptyPolicy = true) {
    // If we're eligible for hospital cover, we don't need to tailor our quote.
    if (!this.isIneligibleForHospitalCover(applicantDetails)) {
      return false;
    }
    // If we're a guardian and have no cover, we don't need to tailor our quote.
    let policyDetails = this.memberPolicyDetails[applicantDetails.id];
    if (
      applicantDetails.isGuardian &&
      policyDetailsAreEqual(policyDetails, createPolicyDetails())
    ) {
      return false;
    }
    // If this is a brand new applicant, check the "default" cover that would
    // be applied if this applicant is added.
    if (!policyDetails) {
      policyDetails = this.getDefaultPolicyDetails();
    }
    // If we've specified that empty policies are not OK, check that we don't have
    // an empty policy
    if (
      !allowEmptyPolicy &&
      !applicantDetails.isGuardian &&
      (policyDetails.productTypes.length === 0 ||
        (!policyDetails.everydayProductCode &&
          !policyDetails.hospitalProductCode))
    ) {
      return true;
    }
    // If the user has selected a hospital cover, they must tailor their quote.
    return (
      !!policyDetails.hospitalProductCode ||
      policyDetails.productTypes.includes('Hospital')
    );
  }

  selectProductTypes(applicantId: string | null, productTypes: ProductType[]) {
    this.applyMemberPolicyChange(applicantId, (policyDetails) => {
      policyDetails.productTypes = productTypes;
      if (productTypes.includes('Hospital')) {
        this.ensureHospitalRelatedSettingsDefaults(policyDetails);
      } else {
        this.removeHospitalCover(policyDetails);
      }
      if (!productTypes.includes('Everyday')) {
        this.removeEverydayCover(policyDetails);
      }
    });
  }

  /**
   * Select a hospital plan.  If the codes include a Hospital code then
   * select the most recently viewed excess for the code (or default if none).
   * @param applicantId applicantId to apply to or null to apply to all
   * @param productCodes array of product codes
   */
  selectHospitalPlan(applicantId: string | null, hospitalProductCode: string) {
    this.applyMemberPolicyChange(applicantId, (policyDetails) => {
      policyDetails.hospitalProductCode = hospitalProductCode;
      if (policyDetails.productCodeToSelectedExcess[hospitalProductCode]) {
        policyDetails.excess =
          policyDetails.productCodeToSelectedExcess[hospitalProductCode];
      }
    });
  }

  selectEverydayPlan(applicantId: string | null, everydayProductCode: string) {
    this.applyMemberPolicyChange(applicantId, (policyDetails) => {
      policyDetails.everydayProductCode = everydayProductCode;
    });
  }

  /**
   * Update the selected excess value.  For each valid hospital plan,
   * save this is the selected excess value if valid for that plan, else
   * save the nearest excess for that plan.
   * @param applicantId applicantId to apply to or null to appl to all
   * @param excessValue
   */
  selectApplicantExcessValue(applicantId: string | null, excessValue: string) {
    this.applyMemberPolicyChange(applicantId, (policyDetails) => {
      for (const hospitalProduct of this.getValidHospitalProducts()) {
        this.setPolicyExcess(policyDetails, hospitalProduct, excessValue);
        // If this is the currently selected product then update the
        // top-level excess selection
        if (
          policyDetails.hospitalProductCode ===
          hospitalProduct.productDetails.code
        ) {
          policyDetails.excess =
            policyDetails.productCodeToSelectedExcess[
              hospitalProduct.productDetails.code
            ];
        }
      }
    });
  }

  private setPolicyExcess(
    policyDetails: PolicyDetails,
    hospitalProduct: ProductConfig,
    excessValue: string
  ) {
    const option = hospitalProduct.excesses?.find(
      (o) => o.value === +excessValue
    );
    if (option) {
      policyDetails.productCodeToSelectedExcess[
        hospitalProduct.productDetails.code
      ] = excessValue;
    } else {
      policyDetails.productCodeToSelectedExcess[
        hospitalProduct.productDetails.code
      ] = String(
        this.getNextLowestExcess(hospitalProduct.excesses!, +excessValue).value
      );
    }
  }

  private getNextLowestExcess(
    options: ProductOption[],
    value: number
  ): ProductOption {
    let previousOption: ProductOption | undefined;
    for (const option of options) {
      if (option.value > value) {
        return previousOption || option;
      }
      previousOption = option;
    }
    return options[options.length - 1];
  }

  private ensureHospitalRelatedSettingsDefaults(policyDetails: PolicyDetails) {
    for (const product of this.getValidHospitalProducts()) {
      if (
        !policyDetails.productCodeToSelectedExcess[product.productDetails.code]
      ) {
        policyDetails.productCodeToSelectedExcess[product.productDetails.code] =
          config.defaultHospitalExcess.toString();
      }
    }
    if (!policyDetails.nonPharmacPlus) {
      policyDetails.nonPharmacPlus = '0';
    }
  }

  private removeHospitalCover(policyDetails: PolicyDetails) {
    policyDetails.productTypes = policyDetails.productTypes.filter(
      (t) => t !== 'Hospital'
    );
    for (const hospitalProduct of this.getValidHospitalProducts()) {
      policyDetails.productCodeToSelectedExcess[
        hospitalProduct.productDetails.code
      ] = config.defaultHospitalExcess.toString();
    }
    policyDetails.hospitalProductCode = undefined;
    // Set hospital values the same way they are set in createPolicyDetails()
    //
    policyDetails.excess = config.defaultHospitalExcess.toString();
    policyDetails.nonPharmacPlus = '0';
  }

  private removeEverydayCover(policyDetails: PolicyDetails) {
    policyDetails.everydayProductCode = undefined;
  }

  selectNonPharmacPlus(applicantId: string | null, nonPharmacPlus: string) {
    this.applyMemberPolicyChange(
      applicantId,
      (policyDetails) => (policyDetails.nonPharmacPlus = nonPharmacPlus)
    );
  }

  /**
   * Apply a change to the policy details ... either for a single applicant, or all
   * @param applicantId Set to an applicant id to apply to a single applicant or null to applly to all.
   * @param changeFunction Callback function that will be called with e PolicyDetails object.
   */
  private applyMemberPolicyChange(
    applicantId: string | null,
    changeFunction: (memberPolicyDetails: PolicyDetails) => void
  ) {
    if (applicantId) {
      changeFunction(this.memberPolicyDetails[applicantId]);
    } else {
      for (const member of Object.values(this.memberPolicyDetails)) {
        changeFunction(member);
      }
    }
  }

  removeApplicant(applicant: ApplicantDetails) {
    this.applicantDetails = this.applicantDetails.filter(
      (a) => a.id !== applicant.id
    );
    if (applicant.id === this.externalMemberApplicant) {
      this.externalMemberNumber = '';
      if (this.buyNowStep > BuyNowStep.AaMembership) {
        this.buyNowStep = BuyNowStep.AaMembership;
      }
    }
    delete this.applicantExtraDetails[applicant.id];
    delete this.memberPolicyDetails[applicant.id];
    if (!this.applicantDetails.find((a) => a.isPolicyOwner)) {
      // Need to have an owner.  If they haven't reached Buy Now
      // yet, default to the first (remaining) applicant.
      //
      this.applicantDetails[0].isPolicyOwner = true;
    }
  }

  getValidHospitalProducts() {
    return hospitalProducts.filter((p) =>
      isValidMemberProductCode(this.hasMember, p.productDetails.code)
    );
  }

  getValidEverydayProducts() {
    return everydayProducts.filter((p) =>
      isValidMemberProductCode(this.hasMember, p.productDetails.code)
    );
  }

  /**
   * Transform form data to account for dependencies between form fields.
   * This is important as we can change the value of things such as isMember
   * on screens where we cannot see the effect of our change.
   */
  enforceRules() {
    // If they have changed the AA member selection ... then swap
    // any AA product codes to the correct variant (member/non-member).
    this.applicantDetails.forEach((applicantDetails) => {
      this.applyMemberPolicyChange(applicantDetails.id, (policyDetails) => {
        // If we have any selected excesses, swap them to the equivalent member product
        // if necessary.
        Object.keys(policyDetails.productCodeToSelectedExcess).forEach(
          (originalProductCode) => {
            const newProductCode =
              this.getMemberProductCode(originalProductCode);
            if (originalProductCode !== newProductCode) {
              const newProduct = getProductByCode(newProductCode);
              if (!newProduct) {
                return;
              }
              this.setPolicyExcess(
                policyDetails,
                newProduct,
                policyDetails.productCodeToSelectedExcess[originalProductCode]
              );
              // Remove any selected excesses that no longer apply. This avoids starting with $500 excess,
              // changing hasMember which copies the $500 excess into the new product codes in
              // productCodeToSelectedExcess, choosing a new higher excess, then changing hasMember back
              // which would otherwise then revert back to the $500 excess if we don't remove it.
              delete policyDetails.productCodeToSelectedExcess[
                originalProductCode
              ];
            }
          }
        );
        // If we have a hospital product, make sure it's appropriate to the hasMember status
        const originalHospitalProductCode = policyDetails.hospitalProductCode;
        if (originalHospitalProductCode) {
          policyDetails.hospitalProductCode = this.getMemberProductCode(
            originalHospitalProductCode
          );
          // If the hospital product changed, also update the excess
          if (
            policyDetails.hospitalProductCode !== originalHospitalProductCode &&
            policyDetails.productCodeToSelectedExcess[
              policyDetails.hospitalProductCode
            ]
          ) {
            policyDetails.excess =
              policyDetails.productCodeToSelectedExcess[
                policyDetails.hospitalProductCode
              ];
          }
        }
        // Update the everyday product if there is one
        if (policyDetails.everydayProductCode) {
          policyDetails.everydayProductCode = this.getMemberProductCode(
            policyDetails.everydayProductCode
          );
        }
      });
    });
    // If we've set hasMember to 'No', blank out the membership number
    if (!this.hasMember && this.externalMemberNumber) {
      this.externalMemberNumber = '';
    }
    // Ages must match dates of birth; if not, remove the date of birth.
    for (const applicant of this.applicantDetails) {
      // If we have a dateOfBirth that does not match our new age, remove the dateOfBirth
      // so the user must correct it on the Buy Now screen.
      const dobObj = dateUtils.getDateObject(
        this.applicantExtraDetails[applicant.id].dateOfBirth
      );
      if (dobObj) {
        if (+applicant.age !== dateUtils.getAgeForDateOfBirth(dobObj)) {
          this.applicantExtraDetails[applicant.id].dateOfBirth = '';
        }
      }
    }
  }

  /**
   * Given a product code, return it as is, or ... if it doesn't match
   * the AA member status of the policy ... return the one that does.
   *
   * @param productCode a product code
   * @returns the product code appropriate for this policy
   */
  getMemberProductCode(productCode: string) {
    if (!config.brand.hasMemberProducts) {
      return productCode;
    }
    const productConfig = config.brand.productConfig.find(
      (c) => c.productDetails.code === productCode
    )!;

    const memberProduct = config.brand.productConfig.find((c) => {
      return (
        c.productDetails.name === productConfig.productDetails.name &&
        c.productDetails.isMemberProduct === this.hasMember
      );
    });

    return memberProduct!.productDetails.code;
  }
}
