import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { addressFinderApi } from 'src/services/addressFinder/addressFinderApi';
import { billingApi } from 'src/services/billing/billingApi';
import { logApi } from 'src/services/log/logApi';
import { contentfulApi } from '../services/contentful/contentfulApi';
import { joinApi } from '../services/join/joinApi';
import { membershipValidationApi } from '../services/membershipValidation/membershipValidationApi';
import { priceApi } from '../services/price/priceApi';
import { submissionApi } from '../services/submission/submissionApi';
import { rtkQueryErrorLogger } from './rtkQueryErrorLogger';

const rootReducer = combineReducers({
  [priceApi.reducerPath]: priceApi.reducer,
  [joinApi.reducerPath]: joinApi.reducer,
  [submissionApi.reducerPath]: submissionApi.reducer,
  [billingApi.reducerPath]: billingApi.reducer,
  [logApi.reducerPath]: logApi.reducer,
  [contentfulApi.reducerPath]: contentfulApi.reducer,
  [addressFinderApi.reducerPath]: addressFinderApi.reducer,
  [membershipValidationApi.reducerPath]: membershipValidationApi.reducer,
});

/**
 * Exported as a function so that the test runner can run renderWithProviders, and
 * get the same providers set up as the regular app.
 */

const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(
        priceApi.middleware,
        joinApi.middleware,
        submissionApi.middleware,
        billingApi.middleware,
        logApi.middleware,
        contentfulApi.middleware,
        addressFinderApi.middleware,
        membershipValidationApi.middleware,
        rtkQueryErrorLogger
      ),
  });
};

export const store = setupStore();

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
