import { GiftGraphicIcon } from '@nib/icons';
import InfoBox from '@nib/info-box';
import { SpecialOfferResponseData } from 'src/services/join/contentfulApiTypes';
import styled from 'styled-components';
import MarkdownContent from '../MarkdownContent';

// Align the markdown to the top of the box
const StyledInfobox = styled(InfoBox)`
  p {
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const SpecialOfferInfoBox = (props: {
  specialOffer: SpecialOfferResponseData;
}) => {
  const { specialOfferAvailable } = props.specialOffer;
  return (
    <StyledInfobox withIcon={true} icon={GiftGraphicIcon}>
      <MarkdownContent content={specialOfferAvailable} />
    </StyledInfobox>
  );
};

export default SpecialOfferInfoBox;
