import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/dist/query';
import { MutationLifecycleApi } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { logError, logInfo } from '../../utils/logUtils';
import {
  BillingServiceHppRegisterOptions,
  BillingServiceHppRegisterResponse,
} from './billingApiTypes';

export const onHppRegisterStarted = async (
  props: BillingServiceHppRegisterOptions,
  api: MutationLifecycleApi<
    BillingServiceHppRegisterOptions,
    BaseQueryFn<
      string | FetchArgs,
      unknown,
      FetchBaseQueryError,
      {},
      FetchBaseQueryMeta
    >,
    BillingServiceHppRegisterResponse,
    'billingApi'
  >
) => {
  const { correlationId } = props;
  const { queryFulfilled } = api;
  logInfo({
    message: 'sending POST HppRegister',
    correlationId,
  });

  try {
    const { data: response } = await queryFulfilled;
    const sessionId = response.sessionId;
    logInfo({
      message: 'POST HppRegister succeeded',
      data: { sessionId: sessionId },
      correlationId,
    });
  } catch (err) {
    logError({
      message: 'POST HppRegister failed',
      data: { error: (err as any)?.error },
      correlationId,
    });
  }
};
