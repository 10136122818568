import { Column, Columns, Stack } from '@nib/layout';
import config from 'src/utils/env';
import FormTextbox from '../FormTextbox';

const content = config.brand.content.buyNow;

interface ManualAddressInputProps {
  formMode?: string;
  names: {
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    addressLine4: string;
    addressLine5: string;
  };
}

const ManualAddressInput = (props: ManualAddressInputProps) => {
  const { names, formMode = 'light' } = props;
  return (
    <Stack space={5}>
      <Columns collapseBelow="sm" space={4}>
        <Column width="2/3">
          <FormTextbox
            label={content.step1.addressLine1Label}
            name={names.addressLine1}
            formMode={formMode}
            // This looks a bit odd because the value to get suburb and city
            // are address-level3 and address-level2, but this is indeed correct despite
            // the numbers being out of order and using a different naming scheme.
            autoComplete={'address-line1'}
          />
        </Column>
        <Column width="1/3">
          <FormTextbox
            label={content.step1.addressLine2Label}
            name={names.addressLine2}
            formMode={formMode}
            autoComplete={'address-level3'}
          />
        </Column>
      </Columns>
      <Columns collapseBelow="sm" space={4}>
        <Column width="1/3">
          <FormTextbox
            label={content.step1.addressLine3Label}
            name={names.addressLine3}
            formMode={formMode}
            autoComplete={'address-level2'}
          />
        </Column>
        <Column width="1/3">
          <FormTextbox
            label={content.step1.addressLine4Label}
            name={names.addressLine4}
            formMode={formMode}
            autoComplete={'postal-code'}
          />
        </Column>
        <Column width="1/3">
          <FormTextbox
            label={content.step1.addressLine5Label}
            name={names.addressLine5}
            formMode={formMode}
            autoComplete={'country-name'}
          />
        </Column>
      </Columns>
    </Stack>
  );
};

export default ManualAddressInput;
