import { EmailQuoteFormValues } from 'src/components/EmailQuote/EmailQuoteModal';
import { SendAndSaveQuoteFormValues } from 'src/components/EmailQuote/SaveAndSendQuoteModal';
import config from 'src/utils/env';
import * as Yup from 'yup';
import { validateEmailAddress, validatePersonalName } from '../validation';
import PhoneSchema from './PhoneSchema';

const messages = config.brand.content.validation;

export const EmailQuoteFormValuesSchema: Yup.ObjectSchema<EmailQuoteFormValues> =
  Yup.object().shape({
    firstName: Yup.string()
      .ensure()
      .max(30, messages.name.maxLength)
      .test(validatePersonalName(messages.name.format)),
    phoneNumber: PhoneSchema,
    email: Yup.string()
      .ensure()
      .required(messages.sendAQuoteEmail.required)
      .min(5, messages.sendAQuoteEmail.maxLength)
      .max(50, messages.sendAQuoteEmail.maxLength)
      .test(validateEmailAddress(messages.sendAQuoteEmail.invalid)),
    hasAgreedToBeContacted: Yup.boolean().default(true),
  });

export const SaveAndSendFormValuesSchema: Yup.ObjectSchema<SendAndSaveQuoteFormValues> =
  Yup.object().shape({
    firstName: Yup.string()
      .ensure()
      .max(30, messages.name.maxLength)
      .test(validatePersonalName(messages.name.format)),
    email: Yup.string()
      .ensure()
      .max(50, messages.sendAQuoteEmail.maxLength)
      .test(validateEmailAddress(messages.sendAQuoteEmail.invalid))
      .test({
        name: 'validateNotAAEmailAddress',
        message: messages.sendAQuoteEmail.invalid,
        test: (value: string | undefined) => {
          return value ? !value.endsWith('@aa.co.nz') : true;
        },
      }),
    hasAgreedToBeContacted: Yup.boolean().default(true),
  });
