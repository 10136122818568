import Copy from '@nib-components/copy';
import Heading from '@nib-components/heading';
import { Box, Section, Stack } from '@nib/layout';
import config from 'src/utils/env';
import MarkdownContent from '../MarkdownContent';
import SpecialOfferCard from '../SpecialOffer/SpecialOfferCard';
interface TitlePanelProps {
  title: string;
  subTitle?: string;
  showSpecialOffer: boolean;
  currentJourney?: number;
}

const TitlePanel = (props: TitlePanelProps) => {
  return (
    <Section background="sageGreen20">
      <Stack space={2}>
        <Heading
          component="h1"
          size={{ xs: 3, md: 2, lg: 1 }}
          color={config.brand.customisation?.headersColor}
          fontType="serif"
        >
          {props.title}
        </Heading>
        {props.subTitle && (
          <Box marginBottom={props.showSpecialOffer ? 5 : 0}>
            <Copy data-testid="subTitle" measure={false}>
              <MarkdownContent content={props.subTitle} />
            </Copy>
          </Box>
        )}
        {props.showSpecialOffer && (
          <SpecialOfferCard currentJourney={props.currentJourney} />
        )}
      </Stack>
    </Section>
  );
};

export default TitlePanel;
