import Form from '@nib-components/form';
import config from 'src/utils/env';
import styled from 'styled-components';
import PageBackground from '../PageBackground';
import ScrollToFieldError from './ScrollToFieldError';

// Copied from @nib-components/form, as FormProps not exported
declare const validFormModes: readonly ['light', 'white'];
export declare type FormModes = (typeof validFormModes)[number];
declare type Nullable<T> = T | null;
interface FormProps {
  id: string;
  name: string;
  title?: string;
  onSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
  action?: string;
  containerWidth?: Nullable<string>;
  spaceChildren?: boolean;
  formMode?: FormModes;
  isCompact?: boolean;
  children?: React.ReactNode;
  [key: string]: unknown;
}

type CustomisedFormProps = FormProps & {
  formMode: FormModes;
};

const TransparentForm = styled(Form)`
  background-color: transparent;
`;

const CustomisedForm = (props: CustomisedFormProps) => {
  const { children, ...formProps } = props;

  return (
    <PageBackground formMode={props.formMode}>
      <ScrollToFieldError />
      <TransparentForm
        // This is needed in addition to field-level controls
        // in order for Chrome to actually honour autocomplete="off"
        autoComplete={config.brand.hasAdviser ? 'off' : 'on'}
        {...formProps}
      >
        {children}
      </TransparentForm>
    </PageBackground>
  );
};

export default CustomisedForm;
