import { clone } from 'ramda';
import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ManageApplicantForm from 'src/components/ManageApplicantForm';
import { FormPage } from 'src/types/FormPage';
import { FormPageProps } from 'src/types/FormPageProps';
import { QuoteSession } from 'src/types/QuoteSession';
import config from 'src/utils/env';
import { getFormPageByIndex } from 'src/utils/formPageUtils';
import gtmUtils from 'src/utils/gtmUtils';
import {
  createApplicant,
  createApplicantExtraDetails,
} from 'src/utils/quoteUtils';

const content = config.brand.content.addApplicant;
const helmetContent = config.brand.content.addApplicant.helmet!;

const AddApplicantPage = (props: FormPageProps) => {
  const { onSubmit } = props;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { returnTo } = useParams<{ returnTo: string }>();

  const [redirectTo, setRedirectTo] = React.useState<string>();

  React.useEffect(() => {
    if (!redirectTo || redirectTo === pathname) {
      return;
    }
    // Replaces the new URL as the current URL, instead of adding to the history stack.
    navigate(redirectTo, { replace: true });
    setRedirectTo(undefined);
  }, [navigate, pathname, redirectTo]);

  React.useEffect(() => {
    if (returnTo) {
      const formPage = getFormPageByIndex(parseInt(returnTo))?.index;
      if (formPage) {
        gtmUtils.startAddPerson(formPage);
      }
    }
  }, [returnTo]);

  const handleMalformedUrl = () => {
    if (redirectTo) {
      return; // we don't need to redirect if we're already redirecting
    }
    // Return to the furthest page we can reach
    setRedirectTo(getFormPageByIndex(FormPage.BuyNow)!.path);
  };

  const quoteSession = clone(props.quoteSession);
  if (returnTo === undefined) {
    // No return page specified
    handleMalformedUrl();
    return null;
  }
  const formPage = getFormPageByIndex(parseInt(returnTo))?.index;
  if (!formPage) {
    // Invalid return page specified
    handleMalformedUrl();
    return null;
  }

  // Add an extra applicant to the initial values for the form.
  const newApplicant = createApplicant();
  quoteSession.applicantDetails.push(newApplicant);
  quoteSession.applicantExtraDetails[newApplicant.id] =
    createApplicantExtraDetails();

  return (
    <ManageApplicantForm
      title={content.title}
      // We're editing the last applicant that we just added
      applicantIndex={quoteSession.applicantDetails.length - 1}
      cancelButtonText={content.cancelButton}
      submitButtonText={content.submitButton}
      onSubmit={(nextPageId: FormPage | null, quoteSession?: QuoteSession) => {
        // If user clicks cancel then quoteSession will be undefined
        // We only want to track GTM form submits when they continue ... not cancel
        if (quoteSession) {
          const formPage = getFormPageByIndex(parseInt(returnTo))?.index;
          if (formPage) {
            gtmUtils.submitAddPerson(formPage);
          }
        }
        onSubmit(nextPageId, quoteSession);
      }}
      returnTo={formPage}
      quoteSession={quoteSession}
      helmetContent={helmetContent}
    />
  );
};

export default AddApplicantPage;
