import { nanoid } from '@reduxjs/toolkit';
import {
  ApplicantDetails,
  ApplicantExtraDetails,
  PolicyDetails,
  QuoteSession,
} from 'src/types/QuoteSession';
import config from './env';

export const createQuoteSession = (): QuoteSession => {
  return new QuoteSession();
};

export const createPolicyDetails = (): PolicyDetails => {
  return {
    productTypes: [],
    excess: config.defaultHospitalExcess.toString(),
    productCodeToSelectedExcess: {},
    nonPharmacPlus: '0',
  };
};

export const createApplicant = (isPolicyOwner = false): ApplicantDetails => {
  return {
    id: nanoid(),
    firstName: '',
    age: '',
    phone: '',
    gender: '',
    smoker: 'No',
    isPolicyOwner,
  };
};

export const createApplicantExtraDetails = (): ApplicantExtraDetails => ({
  surname: '',
  email: '',
  dateOfBirth: '',
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  addressLine4: '',
  addressLine5: '',
});

export const policyDetailsAreEqual = (
  a: PolicyDetails,
  b: PolicyDetails
): boolean => {
  // Product types can differ, and the map of previously selected excesses isn't important
  return (
    a.everydayProductCode === b.everydayProductCode &&
    a.excess === b.excess &&
    a.hospitalProductCode === b.hospitalProductCode &&
    a.nonPharmacPlus === b.nonPharmacPlus
  );
};
