import { PrimaryButton } from '@nib-components/button';
import { Box, Stack } from '@nib/layout';
import Loader from '@nib/loader';
import { Formik } from 'formik';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuoteSession } from 'src/contexts/QuoteSessionProvider';
import {
  JoinApiRequestCallbackPreferredCallbackDay,
  JoinApiRequestCallbackPreferredCallbackTimeRange,
} from 'src/services/join/joinApiTypes';
import { useRequestCallback } from 'src/store/hooks/useRequestCallback';
import config from 'src/utils/env';
import { NeedAdviceFormValuesSchema } from 'src/utils/validation/schema/NeedAdviceFormValuesSchema';
import styled from 'styled-components';
import BrandedModal from '../BrandedModal';
import CustomisedForm from '../form/CustomisedForm';
import FormRadioGroup from '../form/FormRadioGroup';
import FormTextarea from '../form/FormTextArea';
import FormTextbox from '../form/FormTextbox';
import MarkdownContent from '../MarkdownContent';
import BottomButtons from '../navigation/BottomButtons';

const content = config.brand.content.needAdvice;

const StyledCustomisedForm = styled(CustomisedForm)`
  padding: 0px;
`;

interface ThankYouProps {
  onClose: () => void;
}

const ThankYou = ({ onClose }: ThankYouProps) => {
  return (
    <Box>
      <MarkdownContent content={content.thanksDetail} />
      <PrimaryButton
        type="submit"
        size="large"
        fullWidth={{ xs: true, md: false }}
        onClick={() => onClose()}
      >
        {content.doneButtonText}
      </PrimaryButton>
    </Box>
  );
};

export interface NeedAdviceFormValues {
  firstName: string;
  phoneNumber: string;
  preferredCallbackDay: string;
  preferredCallbackTimeRange: string;
  notes?: string;
}

interface NeedAdviceModalProps {
  onClose: () => void;
}

const NeedAdviceModal = (props: NeedAdviceModalProps) => {
  const { onClose } = props;
  const [showThankYou, setShowThankYou] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const createCallBack = useRequestCallback();
  const quoteSession = useQuoteSession();
  const applicant = quoteSession.getNominatedOwner();

  const initialValues: NeedAdviceFormValues = {
    firstName: applicant.firstName,
    phoneNumber: applicant.phone,
    preferredCallbackDay: 'AsSoonAsPossible',
    preferredCallbackTimeRange: 'AnyTime',
  };

  const handleSubmit = async (values: NeedAdviceFormValues) => {
    try {
      const bookACallPageRequest = false;
      setIsLoading(true);
      await createCallBack(
        values.firstName,
        values.phoneNumber,
        bookACallPageRequest,
        values.preferredCallbackDay,
        values.preferredCallbackTimeRange,
        values.notes,
        quoteSession
      );
      setIsLoading(false);
      setIsSuccess(true);
      setShowThankYou(true);
    } catch (error) {
      console.warn(
        'Failed to request callback for need advice ... navigating to the error page'
      );
      navigate(`/error?returnURL=${location.pathname}`);
    }
  };

  return (
    <BrandedModal
      visible={true}
      onClose={onClose}
      title={isSuccess ? content.thanksTitle : content.title}
      closeOnClick={isSuccess ? true : false}
      closeOnEsc={true}
    >
      {showThankYou ? (
        <ThankYou
          onClose={() => {
            setShowThankYou(false);
            onClose();
          }}
        />
      ) : (
        <Box>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={NeedAdviceFormValuesSchema}
            /* Without validateOnBlur=false, validation runs twice and the second time has the old value */
            validateOnBlur={false}
          >
            {({ isSubmitting, handleReset, handleSubmit }) => {
              return (
                <StyledCustomisedForm
                  id="needAdvice"
                  name="needAdvice"
                  onReset={handleReset}
                  onSubmit={handleSubmit}
                  formMode={'white'}
                >
                  <Stack space={6}>
                    <MarkdownContent content={content.mainMessage} />

                    <MarkdownContent content={content.promptToCall} />

                    <FormTextbox
                      label={content.nameLabel}
                      name="firstName"
                      maxLength={30}
                      formMode="white"
                      // Autocomplete doesn't need to be switched off for advisers as
                      // the modal isn't shown for advisers
                      autoComplete="first-name"
                    />
                    <FormTextbox
                      label={content.phoneNumberLabel}
                      name="phoneNumber"
                      maxLength={30}
                      formMode="white"
                      type="tel"
                      // Autocomplete doesn't need to be switched off for advisers as
                      // the modal isn't shown for advisers
                      autoComplete="tel"
                    />
                    <>
                      <FormRadioGroup
                        label={content.chooseDayToCallLabel}
                        name="preferredCallbackDay"
                        formMode="white"
                        options={JoinApiRequestCallbackPreferredCallbackDay}
                      />
                      <FormRadioGroup
                        label={content.chooseTimeToCallLabel}
                        name="preferredCallbackTimeRange"
                        formMode="white"
                        options={
                          JoinApiRequestCallbackPreferredCallbackTimeRange
                        }
                      />
                      <FormTextarea
                        name="notes"
                        label={content.notesLabel}
                        formMode="white"
                        isOptional={true}
                        tag="optional"
                      />
                      <MarkdownContent
                        content={content.callAtBestTimeMessage}
                      />
                    </>
                    <BottomButtons
                      disabled={isSubmitting}
                      cancelButtonText={content.cancelButtonText}
                      onCancel={() => onClose()}
                      submitButtonText={content.bookCallButtonText}
                      hideSubmitButtonIcon={true}
                      cancelButtonSize="medium"
                      submitButtonSize="medium"
                    />
                    {isLoading && <Loader />}
                  </Stack>
                  <MarkdownContent content={content.promptForFinancialAdvice} />
                </StyledCustomisedForm>
              );
            }}
          </Formik>
        </Box>
      )}
    </BrandedModal>
  );
};

export default NeedAdviceModal;
