import { Container } from '@nib/layout';
import Loader from '@nib/loader';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PageBackground from 'src/components/PageBackground';
import { useSubmitJoin } from 'src/hooks/useSubmitJoin';
import { FormPage } from 'src/types/FormPage';
import { QuoteSession } from 'src/types/QuoteSession';
import { getFormPageByIndex } from 'src/utils/formPageUtils';
import { getQuoteSession } from 'src/utils/localStorageUtils';
import styled from 'styled-components';

const CenteredLoaderContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6rem;
`;

/**
 * Fetches the Windcave session from the Billing Service, and
 * submits the details to the Join service.
 *
 * Redirects the user to the form if their session doesn't exist, or
 * to the thankyou page if it does.
 */
const WindcaveRedirectPage: React.FC = () => {
  const { search } = useLocation();
  const quoteSession = getQuoteSession();
  const navigate = useNavigate();

  const sessionId = new URLSearchParams(search).get('sessionId');

  if (!quoteSession) {
    navigate(getFormPageByIndex(FormPage.AboutYou)!.path);
  }

  if (!sessionId || sessionId !== quoteSession?.paymentDetails.sessionId) {
    // Some kind if mismatch ... go to the error page
    console.warn(
      'Failed to match session id on windcave redirect ... navigating to the error page'
    );
    navigate(`/error?returnURL=${getFormPageByIndex(FormPage.BuyNow)!.path}`, {
      replace: true,
    });
  }

  return (
    <PageBackground formMode="light">
      <Container>
        {quoteSession && <SessionJoiner quoteSession={quoteSession} />}
      </Container>
    </PageBackground>
  );
};

const SessionJoiner = (props: { quoteSession: QuoteSession }) => {
  const { quoteSession } = props;
  const submitJoin = useSubmitJoin();
  const navigate = useNavigate();

  React.useEffect(() => {
    submitJoin(quoteSession)
      .then(() => {
        // Replace this redirect page in history to prevent users
        // using back button to get back to it.
        navigate('/welcome', { replace: true });
      })
      .catch((error) => {
        navigate(
          `/error?returnURL=${getFormPageByIndex(FormPage.BuyNow)!.path}`,
          { replace: true }
        );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <CenteredLoaderContainer>
      <Loader size="xl" />
    </CenteredLoaderContainer>
  );
};

export default WindcaveRedirectPage;
