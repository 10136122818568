import Accordion from '@nib-components/accordion';
import { Box } from '@nib/layout';
import RichText from '../RichText';
import { ContentfulAccordionPanelProps } from '../types';

const AccordionPanel = (props: ContentfulAccordionPanelProps) => {
  return (
    <Box>
      <Box>
        <RichText document={props.fields.description} />
      </Box>
      <Box>
        <Accordion multiExpanded={false}>
          {props.fields.items.map((accordionItem, i) => (
            <Accordion.Item
              title={accordionItem.fields.title}
              key={`accordionItem-${i}`}
            >
              <RichText document={accordionItem.fields.content} />
            </Accordion.Item>
          ))}
        </Accordion>
      </Box>
    </Box>
  );
};

export default AccordionPanel;
