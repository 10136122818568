import { clone } from 'ramda';
import { useContext } from 'react';
import { PriceContext } from 'src/contexts/PriceContext';
import { useCreateQuoteMutation } from 'src/services/join/joinApi';
import { JoinApiCreateQuotePayload } from 'src/services/join/joinApiTypes';
import { QuoteSession } from 'src/types/QuoteSession';
import { generateCorrelationId } from 'src/utils/correlationUtils';
import emailQuoteUtils from 'src/utils/emailQuoteUtils';
import config from 'src/utils/env';
import { getJoinId, saveQuoteSession } from 'src/utils/localStorageUtils';
import { logError, logWarning } from 'src/utils/logUtils';
import { useSaveCart } from './useSaveCart';

export function useSaveQuote() {
  const [createQuote] = useCreateQuoteMutation();
  const { data: priceData } = useContext(PriceContext);
  const saveCart = useSaveCart();

  const saveQuote = async (
    quoteSession: QuoteSession,
    firstName: string,
    customerEmailAddress: string,
    hasOptedOutOfMarketing: boolean,
    phoneNumber?: string
  ): Promise<string | void> => {
    if (!priceData) {
      throw Error('saveQuote called but priceData is not available');
    }

    updateApplicant(firstName, customerEmailAddress, quoteSession, phoneNumber);
    let joinId = await getJoinIdForQuote(quoteSession);
    const quotePayload = emailQuoteUtils.createQuotePayload(
      quoteSession,
      priceData,
      firstName,
      customerEmailAddress,
      hasOptedOutOfMarketing
    );

    if (config.brand.hasAdviser) {
      const createQuoteResponse = await create(quotePayload, joinId);
      const resumeUrl = generateCopyLinkURL(joinId, createQuoteResponse.data);
      return resumeUrl;
    }
    await create(quotePayload, joinId);
  };

  const create = async (
    quotePayload: JoinApiCreateQuotePayload,
    joinId: string
  ) => {
    return createQuote({
      joinId,
      quotePayload,
      correlationId: generateCorrelationId(),
    }).unwrap();
  };

  const getJoinIdForQuote = async (quoteSession: QuoteSession) => {
    let joinId = getJoinId();
    if (joinId) {
      return joinId;
    }
    // If we get here it means that this user/browser is trying
    // to send a quote BUT ... they have never successfully saved a cart
    // so they have no joinId.  This can only happen if for some reason
    // their API calls to POST/PUT cart (join api) have all failed ... but their calls
    // to retrieve prices (pricing service) have worked.  Keep in mind that errors that result
    // from calls to POST/PUT cart will not show errors in the UI ... whereas
    // failure to retrieve pricing will push the user to the error page.
    //
    // So, if the user has been able to retrieve prices, but has not been able
    // to save a cart (join api), we don't want to stop them from saving a quote
    // just because they don't have a join id.  So, we make another attempt here to create
    // a cart and get a join id.  If that fails, we throw an error and they can't
    // save a quote.
    //
    const correlationId = generateCorrelationId();
    logWarning({
      message:
        'useSaveQuote called but we have no joinId.  Attempting to create a cart to create a joinId',
      correlationId,
    });
    try {
      await saveCart(quoteSession, true);
      const newJoinId = getJoinId();

      logWarning({
        message: `useSaveQuote successfully created a cart which allocated joinId: ${newJoinId}`,
        correlationId,
      });
      return newJoinId!;
    } catch (error) {
      logError({
        message: `useSaveQuote failed to create a cart to create a joinId`,
        correlationId,
      });
      throw error;
    }
  };

  const generateCopyLinkURL = (joinId: string, quoteIndex: number) => {
    const baseUrl = `${window.location.origin}${
      config.brand.baseUrl === '/' ? '' : config.brand.baseUrl
    }`;

    const params = new URLSearchParams();
    params.append('id', joinId);
    params.append('v', quoteIndex.toString());

    // Construct the full URL
    const url = `${baseUrl}/resume/?${params.toString()}`;

    return url;
  };

  const updateApplicant = (
    firstName: string,
    email: string,
    quoteSession: QuoteSession,
    phoneNumber?: string
  ) => {
    const clonedApplicant = clone(quoteSession.getNominatedOwner());
    const clonedExtraApplicantDetails = clone(
      quoteSession.applicantExtraDetails
    );

    clonedApplicant.firstName = clonedApplicant.firstName || firstName;
    clonedApplicant.phone = clonedApplicant.phone || phoneNumber || '';
    quoteSession.updateSingleApplicantDetails(clonedApplicant);

    clonedExtraApplicantDetails[clonedApplicant.id].email =
      clonedExtraApplicantDetails[clonedApplicant.id].email || email;
    quoteSession.updateAllApplicantExtraDetails(clonedExtraApplicantDetails);

    saveQuoteSession(quoteSession);
    saveCart(quoteSession);
  };

  return saveQuote;
}
