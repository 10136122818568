import React from 'react';
import { useParams } from 'react-router';
import { ApplicantIdContext } from './ApplicantIdContext';

export const ApplicantIdProvider: React.FC = ({ children }) => {
  const { applicantId = null } = useParams<{
    applicantId: string;
  }>();

  return (
    <ApplicantIdContext.Provider value={{ applicantId }}>
      {children}
    </ApplicantIdContext.Provider>
  );
};
