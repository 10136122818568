import { SecondaryButton } from '@nib-components/button';
import { ChevronLeftSystemIcon } from '@nib/icons';
import { Box, Container, Stack } from '@nib/layout';
import { useLocation, useNavigate } from 'react-router';
import TitlePanel from 'src/components/heading/TitlePanel';
import HelmetComponent from 'src/components/HelmetComponent/HelmetComponent';
import MarkdownContent from 'src/components/MarkdownContent';
import ResumeSessionRedirector from 'src/components/ResumeSessionRedirector';
import { useQuoteSession } from 'src/contexts/QuoteSessionProvider';
import { FormPage } from 'src/types/FormPage';
import config from 'src/utils/env';
import { getFormPageByIndex } from 'src/utils/formPageUtils';

const pages = config.brand.content.pages;

/**
 * A page with configurable content.
 * React router uses this page for URLs that don't match anything else.
 * The URL is examined, and then matched against a list of content pages from configuration.
 * If there are no matching pages found, the user is redirected to the furthest page they've
 * visited in the join form.
 */
const ContentPage = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const quoteSession = useQuoteSession();

  const content = pages.find((p) => p.url === pathname);

  if (!content) {
    return <ResumeSessionRedirector quoteSession={quoteSession} />;
  }

  // Find the path for the highest numbered form page we visited.
  // Assumes if we completed a page, we saw the next page.
  const furthestCompletedIndex = quoteSession.completedPages.reduce(
    (acc, cur) => (cur > acc ? cur : acc),
    -1
  );
  const furthestVisitedPath = getFormPageByIndex(
    Math.min(furthestCompletedIndex + 1, FormPage.BuyNow)
  )!.path;

  const BackButton = () => (
    <SecondaryButton
      iconPlacement="left"
      icon={ChevronLeftSystemIcon}
      onClick={() => navigate(furthestVisitedPath)}
    >
      Back
    </SecondaryButton>
  );

  return (
    <Box>
      <HelmetComponent content={content.helmet} />
      <TitlePanel title={content.title} showSpecialOffer={false} />
      <Box padding={{ xs: 4, md: 6 }}>
        <Container>
          <Stack space={5}>
            <BackButton />
            <MarkdownContent content={content.content} />
            <BackButton />
          </Stack>
        </Container>
      </Box>
    </Box>
  );
};

export default ContentPage;
