import { Box, Column, Columns } from '@nib/layout';
import React from 'react';
import { ApplicantFormVariation } from 'src/types/ApplicantForm';
import config from 'src/utils/env';
import FormRadioGroup from '../form/FormRadioGroup';
import FormTextbox from '../form/FormTextbox';
import AgeFormField from './AgeFormField';
import AgeInfoBox from './AgeInfoBox';
import SmokerFormField from './SmokerFormField';

const Row = ({ children }: React.PropsWithChildren<{}>) => {
  return (
    <Columns space={{ xs: 0, lg: 7 }} collapseBelow="lg">
      {children}
    </Columns>
  );
};

const Cell = ({ children }: React.PropsWithChildren<{}>) => {
  return (
    <Column width={{ xs: 'content', lg: '1/2' }}>
      <Box marginBottom={6}>{children}</Box>
    </Column>
  );
};

export interface ApplicantFormProps {
  /** Prefixed before each field name, eg 'applicants.6' would make firstName have fieldname 'applicants.6.firstName' */
  fieldNamePrefix: string;
  /**
   * Set to 'first' (default) to display as the top form on About You screen, or 'other' to display
   * as subsequent forms on About You, which have a different layout.
   */
  variation: ApplicantFormVariation;
}

/**
 * Displays a set of form fields for an Applicant on the About You screen.
 */
const ApplicantForm = ({ fieldNamePrefix, variation }: ApplicantFormProps) => {
  if (variation === undefined) {
    variation = 'first';
  }
  const labels = config.brand.content.aboutYou;
  return (
    <Box>
      <Row>
        <Cell>
          <FormTextbox
            label={
              variation === 'first' ? labels.nameLabel : labels.otherNameLabel
            }
            name={`${fieldNamePrefix}.firstName`}
            tag="optional"
            maxLength={30}
            autoComplete={'given-name'}
          />
        </Cell>
        <Cell>
          <AgeFormField
            label={
              variation === 'first' ? labels.ageLabel : labels.otherAgeLabel
            }
            name={`${fieldNamePrefix}.age`}
            maxLength={3}
            autoComplete={'age'}
          />
          <AgeInfoBox fieldNamePrefix={fieldNamePrefix} />
        </Cell>
      </Row>
      <Row>
        <Cell>
          <FormRadioGroup
            label={
              variation === 'first'
                ? labels.genderLabel
                : labels.otherGenderLabel
            }
            name={`${fieldNamePrefix}.gender`}
            options={{
              Male: 'Male',
              Female: 'Female',
            }}
          />
        </Cell>
        <Cell>
          <SmokerFormField
            label={
              variation === 'first'
                ? labels.smokerLabel
                : labels.otherSmokerLabel
            }
            name={`${fieldNamePrefix}.smoker`}
            options={{
              Yes: 'Yes',
              No: 'No',
            }}
            tooltipContents={
              variation === 'first'
                ? labels.smokerInfoText
                : labels.otherSmokerInfoText
            }
          />
        </Cell>
      </Row>
      {variation === 'first' && (
        <Row>
          <Cell>
            <FormTextbox
              label={labels.phoneLabel}
              name={`${fieldNamePrefix}.phone`}
              help={labels.phoneInfoText}
              tag="optional"
              maxLength={30}
              inputMode="tel"
              stripWhitespace={true}
              type="tel"
              autoComplete={'tel-national'}
            />
          </Cell>
        </Row>
      )}
    </Box>
  );
};

export default ApplicantForm;
