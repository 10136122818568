import { generateCorrelationId } from 'src/utils/correlationUtils';
import dateUtils from 'src/utils/dateUtils';
import { ValidateExternalMemberNumberOptions } from './membershipValidationApiTypes';

export class MembershipValidationApiUtils {
  createValidateExternalMemberNumberOptions(
    lastName: string,
    dateOfBirthUiFormat: string,
    membershipNumber: string
  ): ValidateExternalMemberNumberOptions {
    const options: ValidateExternalMemberNumberOptions = {
      correlationId: generateCorrelationId(),
      lastName: lastName.trim(),
      dateOfBirth: dateUtils.formatUiDateToISO(dateOfBirthUiFormat),
      membershipNumber: this.formatMembershipNumber(membershipNumber),
    };
    return options;
  }
  formatMembershipNumber(membershipNumber: string): string {
    return membershipNumber.replace(/[-\s]/g, '');
  }
}

const membershipValidationApiUtils = new MembershipValidationApiUtils();
export default membershipValidationApiUtils;
