import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/dist/query';
import { QueryLifecycleApi } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { logError, logInfo } from 'src/utils/logUtils';
import {
  ValidateExternalMemberNumberOptions,
  ValidateExternalMemberNumberResponse,
} from './membershipValidationApiTypes';

export const onValidateMembershipNumberStarted = async (
  props: ValidateExternalMemberNumberOptions,
  api: QueryLifecycleApi<
    ValidateExternalMemberNumberOptions,
    BaseQueryFn<
      string | FetchArgs,
      unknown,
      FetchBaseQueryError,
      {},
      FetchBaseQueryMeta
    >,
    ValidateExternalMemberNumberResponse,
    'membershipValidationApi'
  >
) => {
  const { correlationId, ...data } = props;
  const { queryFulfilled } = api;
  logInfo({
    message: 'Sending POST to validate membership number',
    data,
    correlationId: correlationId,
  });
  try {
    await queryFulfilled;
    logInfo({
      message: 'POST to validate membership number succeeded',
      correlationId: correlationId,
    });
  } catch (err) {
    logError({
      message: 'POST to validate membership number failed',
      data: { error: (err as any)?.error },
      correlationId: correlationId,
    });
  }
};
