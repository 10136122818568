import {
  JoinApiCreateQuotePayload,
  JoinApiPolicyDetails,
  JoinApiQuoteApplicant,
  JoinApiQuoteDetails,
} from 'src/services/join/joinApiTypes';
import { PriceApiFetchPriceResponse } from 'src/services/price/priceApiTypes';
import priceApiUtils from 'src/services/price/priceApiUtils';
import submissionApiUtils from 'src/services/submission/submissionApiUtils';
import { FormPage } from 'src/types/FormPage';
import {
  ApplicantDetails,
  PolicyDetails,
  QuoteSession,
} from 'src/types/QuoteSession';
import { generateCorrelationId } from './correlationUtils';
import dateUtils from './dateUtils';
import config from './env';
import { getJoinApiBrand } from './joinApiUtils';
import {
  getJoinId,
  getOriginatingBrand,
  saveAdviserDetails,
  saveJoinId,
  saveOriginatingBrand,
} from './localStorageUtils';
import { logInfo } from './logUtils';
import { createApplicantExtraDetails } from './quoteUtils';

export const JOIN_API_QUOTE_SCHEMA_VERSION = 1;

export class EmailQuoteUtils {
  resumedQuoteBillingDate?: string; // YYYY-MM-DD

  isQuoteResumed = () => {
    return !!this.resumedQuoteBillingDate;
  };

  saveResumedQuoteDetails(
    joinId: string,
    quoteDetails: JoinApiQuoteDetails,
    expired: boolean
  ) {
    this.saveJoinIdFromQuote(joinId);
    this.saveCrossJoinOriginatingInfo(quoteDetails);
    if (!expired) {
      // If the quote has expired (30 days) ... then they can continue
      // but they are no longer guaranteed to get the quoted price.
      // If we don't set the resumedQuoteBillingDate below they will
      // get today's prices.
      //
      this.resumedQuoteBillingDate = quoteDetails.effectiveDate;
    }
  }

  private saveCrossJoinOriginatingInfo(quoteDetails: JoinApiQuoteDetails) {
    if (config.brand.hasAdviser) {
      // If we are resuming a quote on the aacn flow ... we don't want
      // to save any details like adviser details specially as these are
      // saved outside of the specific quote in local storage.
      // The quoteSession should already have the adviser details on aacn
      // and the brand for submission will already be AA-Centre
      //
      return;
    }
    // N.B.  We always preserve the originating brand ... even if the quote
    // has expired.  If a quote starts out as AACN ... it stays AACN ... forever.
    //
    saveOriginatingBrand(quoteDetails.originatingBrand);
    saveAdviserDetails(quoteDetails.adviserDetails);
  }

  removeCrossJoinOriginatingInfo() {
    saveOriginatingBrand(undefined);
    saveAdviserDetails(undefined);
  }

  createQuoteSession(quoteDetails: JoinApiQuoteDetails): QuoteSession {
    // If/when we have changes to the JoinApiQuoteDetails structure, and a new
    // JOIN_API_QUOTE_SCHEMA_VERSION ... the caller of this method should check
    // the version on the retrieved quote.  If the versions don't match, it
    // is up to the caller to migrate the old payload contents to the new structure
    // and update the version on the payload before calling this method.
    if (quoteDetails.schemaVersion !== JOIN_API_QUOTE_SCHEMA_VERSION) {
      console.warn(
        `restoreQuoteSession: called with schemaVersion: ${quoteDetails.schemaVersion} expected version: ${JOIN_API_QUOTE_SCHEMA_VERSION}`
      );
      // Ideally would throw an error here ... but in case future devs
      // don't properly test with old quotes we continue and hope for the best ...
      //
    }

    const quoteSession = new QuoteSession();
    quoteSession.completedPages = [FormPage.AboutYou, FormPage.ChooseYourCover]; // Resume on quote summary
    quoteSession.hasMember = quoteDetails.hasMember;
    quoteSession.promoCode = quoteDetails.promoCode || null; // If sent as null will come back as undefined
    quoteSession.paymentDetails.frequency = quoteDetails.paymentFrequency;
    if (quoteDetails.adviserDetails) {
      // When the quote is saved, the adviser number, uan and agreementId are alll sent.
      // The API looks up the adviser number and if it can't find it (or the uan/agreementId don't)
      // match ... the quote is rejected.
      //
      quoteSession.adviserDetails = {
        adviserNumber: quoteDetails.adviserDetails.adviserNumber,
        uan: quoteDetails.adviserDetails.uan!,
        agreementId: quoteDetails.adviserDetails.agreementId!,
        hasReadDeclaration: true, // Not persisted with quote but must be true for save quote
      };
    }
    this.restoreQuoteSessionApplicants(quoteSession, quoteDetails);
    return quoteSession;
  }

  createQuotePayload(
    quoteSession: QuoteSession,
    priceData: PriceApiFetchPriceResponse,
    firstName: string,
    customerEmailAddress: string,
    hasOptedOutOfMarketing: boolean
  ): JoinApiCreateQuotePayload {
    const quotePayload: JoinApiCreateQuotePayload = {
      contactDetails: {
        ...this.getConditionalStringProperty('firstName', firstName),
        ...this.getConditionalStringProperty(
          'customerEmailAddress',
          customerEmailAddress
        ),
      },
      quoteDetails: this.createQuoteDetails(
        quoteSession,
        priceData,
        hasOptedOutOfMarketing
      ),
    };
    return quotePayload;
  }

  private saveJoinIdFromQuote(joinId: string) {
    const existingJoinId = getJoinId();
    if (!existingJoinId) {
      logInfo({
        message: `Resumed quote on browser with no existing join id.  Switching to use join id: ${joinId} from the quote`,
        correlationId: generateCorrelationId(),
      });
      saveJoinId(joinId);
      return;
    }

    if (joinId === existingJoinId) {
      logInfo({
        message: `Resumed quote on browser with existing join id: ${existingJoinId}.  Continuing to use this join id as it matches the one from the quote`,
        correlationId: generateCorrelationId(),
      });
    } else {
      logInfo({
        message: `Resumed quote on browser with existing join id: ${existingJoinId}.  Switching to use join id: ${joinId} from the quote`,
        correlationId: generateCorrelationId(),
      });
      saveJoinId(joinId);
    }
  }

  private createQuoteDetails(
    quoteSession: QuoteSession,
    priceData: PriceApiFetchPriceResponse,
    hasOptedOutOfMarketing: boolean
  ): JoinApiQuoteDetails {
    const quoteDetails: JoinApiQuoteDetails = {
      schemaVersion: JOIN_API_QUOTE_SCHEMA_VERSION,
      brand: getJoinApiBrand(),
      originatingBrand: this.getOriginatingBrand(),
      hasMember: quoteSession.hasMember,
      promoCode: quoteSession.promoCode || undefined,
      paymentFrequency: quoteSession.paymentDetails.frequency,
      applicants: this.createQuoteApplicants(quoteSession, priceData),
      totalPrice: priceApiUtils.getPolicyTotalPrice(priceData),
      effectiveDate: dateUtils.getCurrentDate(),
      hasOptedOutOfMarketing: hasOptedOutOfMarketing,
    };
    if (quoteSession.adviserDetails) {
      quoteDetails.adviserDetails = {
        adviserNumber: quoteSession.adviserDetails.adviserNumber,
        agreementId: quoteSession.adviserDetails.agreementId, // Validated by API
        uan: quoteSession.adviserDetails.uan, // Validated by API
      };
    }
    return quoteDetails;
  }

  private getOriginatingBrand() {
    return getOriginatingBrand() || getJoinApiBrand();
  }

  private getConditionalStringProperty(
    key: string,
    value?: string | null
  ): Record<string, string> {
    const trimmedValue = value?.trim();
    return trimmedValue ? { [key]: trimmedValue } : {};
  }

  private restoreQuoteSessionApplicants(
    quoteSession: QuoteSession,
    quoteDetails: JoinApiQuoteDetails
  ) {
    quoteSession.applicantDetails = [];
    quoteSession.memberPolicyDetails = {};
    quoteSession.applicantExtraDetails = {};
    for (const applicant of quoteDetails.applicants) {
      quoteSession.applicantDetails.push(
        this.joinApiApplicantToApplicantDetails(applicant)
      );
      quoteSession.memberPolicyDetails[applicant.id] =
        this.joinApiPolicyDetailsToPolicyDetails(applicant.policyDetails);
      // Nothing in the extra details gets persisted in the quote
      // just need to create the default objects.
      quoteSession.applicantExtraDetails[applicant.id] =
        createApplicantExtraDetails();
    }
  }

  private joinApiApplicantToApplicantDetails(
    quoteApplicant: JoinApiQuoteApplicant
  ): ApplicantDetails {
    // Note that DynamoDB refuses to story empty strings as property values!
    // So when we load quotes ... if optional string values are "missing" in
    // the response ... we set the properties to empty string which is
    // what the app does in the normal join flow.
    //
    const applicantDetails: ApplicantDetails = {
      id: quoteApplicant.id,
      firstName: quoteApplicant.firstName || '',
      age: quoteApplicant.age || '',
      gender: quoteApplicant.gender || '',
      phone: quoteApplicant.phone || '',
      smoker: quoteApplicant.isSmoker ? 'Yes' : 'No',
      isPolicyOwner: quoteApplicant.isPolicyOwner,
      isGuardian: quoteApplicant.isGuardian,
    };

    return applicantDetails;
  }

  private joinApiPolicyDetailsToPolicyDetails(
    joinApiPolicyDetails: JoinApiPolicyDetails
  ): PolicyDetails {
    const policyDetails: PolicyDetails = {
      productTypes: joinApiPolicyDetails.productTypes,
      hospitalProductCode: joinApiPolicyDetails.hospitalProductCode,
      everydayProductCode: joinApiPolicyDetails.everydayProductCode,
      excess: joinApiPolicyDetails.excess,
      nonPharmacPlus: joinApiPolicyDetails.nonPharmacPlus,
      productCodeToSelectedExcess: this.joinApiProductCodeToExcessMapToLocalMap(
        joinApiPolicyDetails.productCodeToSelectedExcess
      ),
    };

    return policyDetails;
  }

  private joinApiProductCodeToExcessMapToLocalMap(
    productCodeToSelectedExcess: Record<string, string>
  ) {
    // The Join API actually changes upper case keys in the map
    // to camel case (e.g. "IXH" => "ixh").  We change them back here.
    //
    const upperCasedKeysMap: Record<string, string> = {};
    for (const key in productCodeToSelectedExcess) {
      upperCasedKeysMap[key.toUpperCase()] = productCodeToSelectedExcess[key];
    }
    return upperCasedKeysMap;
  }

  private createQuoteApplicants(
    quoteSession: QuoteSession,
    priceData: PriceApiFetchPriceResponse
  ): JoinApiQuoteApplicant[] {
    return (
      quoteSession.applicantDetails
        .map((a) => this.createQuoteApplicant(a, quoteSession, priceData))
        // Applicants with no price (policy owner no cover) should not
        // be sent with the quote.  The API will reject anything with zero
        // price.  Also, email quote is meant to only persist details entered
        // before the Buy Now page ... and guardians can only be entered
        // on the buy now page.
        //
        .filter((ja) => ja.policyDetails.applicantPrice > 0)
    );
  }

  private createQuoteApplicant(
    applicantDetails: ApplicantDetails,
    quoteSession: QuoteSession,
    priceData: PriceApiFetchPriceResponse
  ): JoinApiQuoteApplicant {
    const policyDetails = quoteSession.memberPolicyDetails[applicantDetails.id];
    const quoteApplicant: JoinApiQuoteApplicant = {
      id: applicantDetails.id,
      firstName: applicantDetails.firstName,
      age: applicantDetails.age,
      gender: applicantDetails.gender,
      phone: applicantDetails.phone,
      isSmoker: applicantDetails.smoker === 'Yes',
      isPolicyOwner: applicantDetails.isPolicyOwner,
      isGuardian: applicantDetails.isGuardian,
      policyDetails: this.createQuoteApplicantPolicyDetails(
        applicantDetails,
        policyDetails,
        quoteSession,
        priceData
      ),
    };

    return quoteApplicant;
  }

  private createQuoteApplicantPolicyDetails(
    applicantDetails: ApplicantDetails,
    policyDetails: PolicyDetails,
    quoteSession: QuoteSession,
    priceData: PriceApiFetchPriceResponse
  ): JoinApiPolicyDetails {
    const joinApiPolicyDetails: JoinApiPolicyDetails = {
      productTypes: policyDetails.productTypes,
      hospitalProductCode: policyDetails.hospitalProductCode,
      everydayProductCode: policyDetails.everydayProductCode,
      excess: policyDetails.excess,
      nonPharmacPlus: policyDetails.nonPharmacPlus,
      productCodeToSelectedExcess: policyDetails.productCodeToSelectedExcess,
      hospitalBenefitCode: this.getHospitalBenefitCode(
        applicantDetails,
        quoteSession,
        priceData
      ),
      everydayBenefitCode: this.getEverydayBenefitCode(
        applicantDetails,
        quoteSession,
        priceData
      ),
      nonPharmacPlusBenefitCode: this.getNonPharmacPlusBenefitCode(
        applicantDetails,
        policyDetails,
        quoteSession
      ),
      applicantPrice: priceApiUtils.getApplicantTotalPrice(
        applicantDetails,
        quoteSession,
        priceData
      ),
    };
    return joinApiPolicyDetails;
  }

  private getHospitalBenefitCode(
    applicantDetails: ApplicantDetails,
    quoteSession: QuoteSession,
    priceData: PriceApiFetchPriceResponse
  ): string | undefined {
    const product = submissionApiUtils.getHospitalPartyProduct(
      applicantDetails.id,
      quoteSession,
      priceData
    );
    if (!product) {
      return undefined;
    }
    return product.boaBenefitCode;
  }

  private getEverydayBenefitCode(
    applicantDetails: ApplicantDetails,
    quoteSession: QuoteSession,
    priceData: PriceApiFetchPriceResponse
  ): string | undefined {
    const product = submissionApiUtils.getEverydayPartyProduct(
      applicantDetails.id,
      quoteSession,
      priceData
    );
    if (!product) {
      return undefined;
    }
    return product.boaBenefitCode;
  }

  private getNonPharmacPlusBenefitCode(
    applicantDetails: ApplicantDetails,
    policyDetails: PolicyDetails,
    quoteSession: QuoteSession
  ): string | undefined {
    if (policyDetails.nonPharmacPlus === '0') {
      return undefined;
    }
    const option = priceApiUtils.getNonPharmacPlusOption(
      applicantDetails.id,
      quoteSession,
      +policyDetails.nonPharmacPlus
    );
    if (!option) {
      return undefined;
    }
    return option.code;
  }
}

const emailQuoteUtils = new EmailQuoteUtils();
export default emailQuoteUtils;
