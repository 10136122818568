import Copy from '@nib-components/copy';
import { FieldsetFormControl } from '@nib-components/form-control';
import Radio from '@nib-components/radio';
import { BadgesGraphicIcon } from '@nib/icons';
import { Stack } from '@nib/layout';
import React from 'react';
import { ApplicantIdContext } from 'src/contexts/ApplicantIdContext';
import priceApiUtils from 'src/services/price/priceApiUtils';
import { QuoteSession } from 'src/types/QuoteSession';
import config from 'src/utils/env';
import { formatCurrency } from 'src/utils/formatters/formatCurrency';
import styled from 'styled-components';

const Points = styled('ul')`
  padding-left: 0;
`;

const Point = styled('li')`
  list-style-type: none;
  margin: 0 0 1rem 0;
  padding: 0 0 0 2rem;
  position: relative;
`;

const PointIconContainer = styled('div')`
  position: absolute;
  left: 0;
  top: 0.25rem;
`;

interface ChooseExcessStepProps {
  quoteSession: QuoteSession;
  selected: string;
  onSelect: (productOptionCode: string) => void;
}

const ChooseExcessStep = (props: ChooseExcessStepProps) => {
  const { selected, onSelect, quoteSession } = props;
  const { applicantId } = React.useContext(ApplicantIdContext);
  const optionConfig = priceApiUtils.getHospitalExcessOptionConfig(
    quoteSession,
    applicantId
  );
  if (!optionConfig) {
    return null;
  }

  return (
    <Stack space={4}>
      <Points>
        {config.brand.content.chooseYourCover.step3.points.map((point, i) => (
          <Point key={`point-${i}`}>
            <PointIconContainer>
              <BadgesGraphicIcon size="xs" />
            </PointIconContainer>
            <Copy measure={true} component="span">
              {point}
            </Copy>
          </Point>
        ))}
        {optionConfig.plansHaveDifferentExcessOptions && (
          <Point key={`point-different-excess-options`}>
            <PointIconContainer>
              <BadgesGraphicIcon size="xs" />
            </PointIconContainer>
            <Copy measure={true} component="span">
              {config.brand.content.chooseYourCover.step3.differentExcessesNote}
            </Copy>
          </Point>
        )}
      </Points>
      <FieldsetFormControl id="excess" label="" width="100%" name="excess">
        <>
          {optionConfig.options.map((option) => {
            return (
              <Radio
                key={option.value}
                name="excess"
                id={`excess-${option.value}`}
                value={String(option.value)}
                label={formatCurrency(option.value, false)}
                selected={String(option.value) === selected}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  onSelect(e.target.value);
                }}
                disabled={option.disabled}
              />
            );
          })}
        </>
      </FieldsetFormControl>
    </Stack>
  );
};

export default ChooseExcessStep;
