import { Block, Inline } from '@contentful/rich-text-types';
import NibHeading, { HeadingProps } from '@nib-components/heading';
import { ReactNode } from 'react';

type Size = HeadingProps['size'];

export const Heading = (size: Size, fontType?: string) => {
  const EntryHeading = (
    _node: Block | Inline,
    children: ReactNode
  ): JSX.Element => (
    <NibHeading size={size} fontType={fontType}>
      {children}
    </NibHeading>
  );

  return EntryHeading;
};
