export enum FormPage {
  AboutYou = 0,
  ChooseYourCover = 1,
  TailorYourQuote = 2,
  BuyNow = 3,
  Thankyou = 4,
}

/**
 * Defines the navigation tabs across the top of the screen.
 */
export interface FormPageInfo {
  index: FormPage;
  label: string;
  altLabel?: string;
  path: string;
}
