import { Box, Stack } from '@nib/layout';
import Loader from '@nib/loader';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import MarkdownContent from 'src/components/MarkdownContent';
import PageBackground from 'src/components/PageBackground';
import ResumeQuoteModal from 'src/components/ResumeQuote/ResumeQuoteModal';
import { useGetQuoteQuery } from 'src/services/join/joinApi';
import { QuoteSession } from 'src/types/QuoteSession';
import emailQuoteUtils from 'src/utils/emailQuoteUtils';
import config from 'src/utils/env';
import gtmUtils from 'src/utils/gtmUtils';
import { createJoinApiGetQuoteOptions } from 'src/utils/joinApiUtils';
import { saveQuoteSession } from 'src/utils/localStorageUtils';
import styled from 'styled-components';

const content = config.brand.content.resumeQuote;

const CenteredLoaderContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6rem;
`;

const ResumeQuotePage = ({
  onQuoteLoaded,
  onStartOverQuote,
}: {
  onQuoteLoaded: (quoteSession: QuoteSession) => void;
  onStartOverQuote: () => void;
}) => {
  const [getQuoteHandled, setGetQuoteHandled] = useState(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [quoteLoaded, setQuoteLoaded] = useState<boolean>(false);
  const { search } = useLocation();
  const location = useLocation();
  const navigate = useNavigate();

  const joinId = new URLSearchParams(search).get('id')!;
  const version = new URLSearchParams(search).get('v')!;

  const {
    data: getQuoteQueryResponse,
    error,
    isSuccess,
  } = useGetQuoteQuery(createJoinApiGetQuoteOptions(joinId, +version));

  if (error && !getQuoteHandled) {
    setGetQuoteHandled(true);
    if ('status' in error) {
      const { status } = error;
      if (status === 404) {
        setQuoteLoaded(false);
        setShowModal(true);
      } else {
        const returnUrl = location.pathname + search;
        const encodedReturnURL = encodeURIComponent(returnUrl);
        console.warn(
          'Failed to get quote on resume a quote ... navigating to the error page'
        );
        navigate(`/error?returnURL=${encodedReturnURL}`);
      }
    }
  }

  if (isSuccess && !getQuoteHandled) {
    // Checking getQuoteHandled ensures that we only process the loaded
    // quote once ... even if the component gets re-rendered ... which can happen
    // as the result of calls to the logging api etc.
    //
    setGetQuoteHandled(true);
    const { quoteDetails, expired } = getQuoteQueryResponse.data;
    const quoteSession = emailQuoteUtils.createQuoteSession(quoteDetails);
    emailQuoteUtils.saveResumedQuoteDetails(joinId, quoteDetails, expired);
    gtmUtils.viewResumeQuote(quoteSession.applicantDetails);
    if (expired) {
      setQuoteLoaded(true);
      setShowModal(true);
    } else {
      saveQuoteSession(quoteSession);
      onQuoteLoaded(quoteSession);
    }
  }

  return (
    <>
      <PageBackground formMode="light">
        <CenteredLoaderContainer>
          <Box textAlign={'center'}>
            <Stack>
              <Loader size="xl" />
              <MarkdownContent content={content.loadingQuoteLabel} />
            </Stack>
          </Box>
        </CenteredLoaderContainer>
      </PageBackground>
      {showModal && (
        <ResumeQuoteModal
          quoteLoaded={quoteLoaded}
          onGetUpdatedQuote={() => {
            if (getQuoteQueryResponse) {
              const { quoteDetails } = getQuoteQueryResponse.data;
              const quoteSession =
                emailQuoteUtils.createQuoteSession(quoteDetails);
              saveQuoteSession(quoteSession);
              onQuoteLoaded(quoteSession);
            }
          }}
          onStartOverQuote={() => {
            onStartOverQuote();
          }}
        />
      )}
    </>
  );
};

export default ResumeQuotePage;
