import { SecondaryButton } from '@nib-components/button';
import { BaseButtonProps } from '@nib-components/button/dist/@types/BaseButton';
import { EmailSentSystemIcon } from '@nib/icons';
import { featureOn } from '@nib/nz-feature-flags';
import React from 'react';
import emailQuoteUtils from 'src/utils/emailQuoteUtils';
import config from 'src/utils/env';
import EmailQuoteModal from './EmailQuoteModal';
import SaveAndSendQuoteModal from './SaveAndSendQuoteModal';

const EmailQuoteButton = (
  props: Omit<BaseButtonProps, 'color' | 'children'>
) => {
  const [showModal, setShowModal] = React.useState<boolean>(false);

  return (
    <>
      {featureOn(config, 'useEmailQuote') &&
      !emailQuoteUtils.isQuoteResumed() ? (
        <SecondaryButton
          type="button"
          fullWidth={{ xs: true, md: false }}
          size={props.submitButtonSize ?? 'large'}
          icon={EmailSentSystemIcon}
          iconPlacement={'left'}
          onClick={() => setShowModal(true)}
          color={props.cancelButtonColor}
          disabled={false}
        >
          {config.brand.content.emailQuote.buttonText}
        </SecondaryButton>
      ) : (
        <></>
      )}
      {showModal ? (
        config.brand.hasAdviser ? (
          <SaveAndSendQuoteModal onClose={() => setShowModal(false)} />
        ) : (
          <EmailQuoteModal onClose={() => setShowModal(false)} />
        )
      ) : (
        <></>
      )}
    </>
  );
};

export default EmailQuoteButton;
