import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { ContentfulRichTextProps } from '../types';
import { Divider } from './divider';
import { EmbeddedEntry } from './embeddedEntry';
import { EmbeddedEntryInline } from './embeddedEntryInline';
import { Heading } from './heading';
import { Paragraph } from './paragraph';
import { Quote } from './Quote';
import { ListItem, UnorderedList } from './unorderedList';

/**
 * Renders a Rich Text node
 */
const RichText = (props: ContentfulRichTextProps) => {
  return (
    <>
      {documentToReactComponents(props.document, {
        renderNode: {
          [INLINES.EMBEDDED_ENTRY]: EmbeddedEntryInline,
          [BLOCKS.EMBEDDED_ENTRY]: EmbeddedEntry,
          [BLOCKS.HEADING_1]: Heading(1, 'serif'),
          [BLOCKS.HEADING_2]: Heading(2, 'serif'),
          [BLOCKS.HEADING_3]: Heading(3),
          [BLOCKS.HEADING_4]: Heading(4),
          [BLOCKS.HEADING_5]: Heading(5),
          [BLOCKS.HEADING_6]: Heading(6),
          [BLOCKS.PARAGRAPH]: Paragraph,
          [BLOCKS.HR]: Divider,
          [BLOCKS.QUOTE]: Quote,
          [BLOCKS.UL_LIST]: UnorderedList,
          [BLOCKS.LIST_ITEM]: ListItem,
        },
        // Render text nodes as a series of text nodes with newlines between.
        renderText: (text) => {
          return text
            .split('\n')
            .reduce(
              (
                children: React.ReactNode[],
                textSegment: React.ReactNode,
                index: number
              ) => {
                return [
                  ...children,
                  index > 0 && <br key={index} />,
                  textSegment,
                ];
              },
              []
            );
        },
      })}
    </>
  );
};

export default RichText;
