import {
  AdviserDetails,
  ApplicantDetails,
  QuoteSession,
} from 'src/types/QuoteSession';
import config from 'src/utils/env';
import * as Yup from 'yup';
import {
  validateAge120,
  validateAlphanumeric,
  validateNumeric,
  validatePersonalName,
  validatePositive,
} from '../validation';
import PhoneSchema from './PhoneSchema';

const messages = config.brand.content.validation;

export const ApplicantDetailsSchema: Yup.ObjectSchema<ApplicantDetails> =
  Yup.object().shape({
    id: Yup.string().ensure(),
    firstName: Yup.string()
      .ensure()
      .max(30, messages.name.maxLength)
      .test(validatePersonalName(messages.name.format)),
    age: Yup.string()
      .ensure()
      .required(messages.age.required)
      .test(validateNumeric(messages.age.format))
      .max(3, messages.age.maxLength)
      .test(validatePositive(messages.age.under0))
      .test(validateAge120(messages.age.over120)),
    phone: PhoneSchema,
    gender: Yup.string().ensure().required(messages.gender.required),
    smoker: Yup.string().ensure().required(messages.smoker.required),
    isPolicyOwner: Yup.boolean().required(),
    isGuardian: Yup.boolean().optional(),
  });

export const AdviserDetailsSchema: Yup.ObjectSchema<AdviserDetails> =
  Yup.object({
    adviserNumber: Yup.string()
      .ensure()
      .when({
        is: () => config.brand.hasAdviser,
        then: (schema) => {
          // Adviser Number is only validated if the brand uses it
          return schema
            .required(messages.adviserNumber.required)
            .test(validateAlphanumeric(messages.adviserNumber.format))
            .max(6, messages.adviserNumber.maxLength);
        },
        otherwise: (schema) => schema,
      }),
    uan: Yup.string()
      .ensure()
      // UAN is only validated if the brand uses it; the field is hidden, but
      // if the field is empty the user can't proceed from About You
      .when({
        is: () => config.brand.hasAdviser,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema,
      }),
    agreementId: Yup.string()
      .ensure()
      // Application ID is only validated if the brand uses it; the field is hidden, but
      // if the field is empty the user can't proceed from About You
      .when({
        is: () => config.brand.hasAdviser,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema,
      }),
    hasReadDeclaration: Yup.boolean()
      .default(false)
      // hasReadDeclaration is only validated if the brand uses it; the field is hidden, but
      // if the field is empty the user can't proceed from About You
      .when({
        is: () => config.brand.hasAdviser,
        then: (schema) =>
          schema.equals(
            [true],
            config.brand.content.validation.hasReadDeclaration.required
          ),
        otherwise: (schema) => schema,
      }),
  });

/**
 * Validation schema for the About You page
 */
export const AboutYouSchema: Yup.ObjectSchema<
  Pick<QuoteSession, 'applicantDetails' | 'hasMember' | 'adviserDetails'>
> = Yup.object().shape({
  applicantDetails: Yup.array().defined().of(ApplicantDetailsSchema),
  // hasMember is only required if the brand uses member products
  hasMember: Yup.boolean().test(
    'hasMemberRequired',
    messages.hasMember.required,
    (value) => {
      if (!config.brand.hasMemberProducts) {
        return true;
      }
      return value === true || value === false;
    }
  ),
  adviserDetails: AdviserDetailsSchema,
});
