import { SecondaryButton, TertiaryButton } from '@nib-components/button';
import Copy from '@nib-components/copy';
import { FormControl } from '@nib-components/form-control';
import Textbox from '@nib-components/textbox';
import {
  copyFontFamily,
  formLabelColor,
  formLabelSize,
} from '@nib-components/theme';
import { DeleteSystemIcon } from '@nib/icons';
import { Box, Inline, Stack } from '@nib/layout';
import React, { useEffect, useState } from 'react';
import { useAutocomplete } from 'src/hooks/useAutocomplete';
import styled from 'styled-components';
import { useLazyValidatePromoCodeQuery } from '../../services/join/joinApi';
import { FormPageProps } from '../../types/FormPageProps';
import config from '../../utils/env';
import { getJoinApiBrand } from '../../utils/joinApiUtils';

// Configured the same as FormControl label
const PromoCodeHeader = styled.p`
  color: ${formLabelColor};
  font-family: ${copyFontFamily};
  font-size: ${formLabelSize};
  font-weight: 700;
  line-height: 1.5;
  width: 100%;
`;

const BoxStyled = styled(Box)`
  > div > div {
    max-width: 350px;
    margin-right: 20px;
  }

  > div > div > div {
    max-width: 350px;
  }
`;

const content = config.brand.content.quoteSummary;

const PromoCode = (props: FormPageProps) => {
  const { quoteSession, onSubmit } = props;

  const [value, setValue] = useState(quoteSession.promoCode ?? '');
  const [isValid, setIsValid] = useState(quoteSession.promoCode != null);
  const [isValidated, setIsValidated] = useState(
    quoteSession.promoCode != null
  );
  const [promoCodeText, setPromoCodeText] = useState('');

  const [validatePromoCode] = useLazyValidatePromoCodeQuery();

  useEffect(() => {
    if (quoteSession.promoCode != null) {
      validatePromoCode({
        brandId: getJoinApiBrand(),
        promoCode: quoteSession.promoCode,
      })
        .unwrap()
        .then((promoCodeInfo) => {
          setIsValid(true);
          setPromoCodeText(promoCodeInfo.data.confirmationDescription);
        })
        .catch(() => {
          setIsValid(false);
          setPromoCodeText('');
          setIsValidated(true);

          quoteSession.promoCode = null;
          onSubmit(null, quoteSession);
        });
    }
  }, [onSubmit, quoteSession, validatePromoCode]);

  const validate = () => {
    validatePromoCode({
      brandId: getJoinApiBrand(),
      promoCode: value,
    })
      .unwrap()
      .then((promoCodeInfo) => {
        setIsValid(true);
        setPromoCodeText(promoCodeInfo.data.confirmationDescription);
        quoteSession.promoCode = value;
        onSubmit(null, quoteSession);
      })
      .catch(() => {
        setIsValid(false);
        setPromoCodeText('');
        setIsValidated(true);
      });
  };

  const removePromoCode = () => {
    setIsValidated(false);
    setIsValid(false);
    setPromoCodeText('');

    quoteSession.promoCode = null;
    onSubmit(null, quoteSession);
  };

  const promocodeAutocomplete = useAutocomplete();

  return isValid ? (
    <Stack>
      <PromoCodeHeader>{content.promoCodeLabel}</PromoCodeHeader>
      <Box space={{ xs: 2, md: 4 }}>
        <Copy measure={false} data-testid="promo-code-success">
          {promoCodeText}
        </Copy>
        <TertiaryButton
          color="destructive"
          icon={DeleteSystemIcon}
          iconPlacement="left"
          onClick={removePromoCode}
        >
          {content.promoCodeRemoveButton}
        </TertiaryButton>
      </Box>
    </Stack>
  ) : (
    <Inline space={{ xs: 2, md: 4 }} collapseBelow="md">
      <BoxStyled>
        <FormControl
          id="promoCode"
          name="promoCode"
          help={content.promoCodeHelpText}
          label={content.promoCodeLabel}
          valid={false}
          validated={isValidated}
          error={content.promoCodeInvalid}
          width={10}
          autoComplete={promocodeAutocomplete}
        >
          <Textbox
            value={value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setValue(e.target.value.toUpperCase());
              setIsValidated(false);
              if (e.target.value.length > 10) {
                setIsValidated(true);
              }
            }}
          />
        </FormControl>
      </BoxStyled>
      <Box paddingTop={{ xs: 4, md: 8, lg: 8 }}>
        <SecondaryButton
          color="dark"
          fullWidth={{ xs: true, md: true, lg: false }}
          onClick={validate}
          disabled={value.length < 1 || value.length > 10}
        >
          {content.promoCodeValidateButton}
        </SecondaryButton>
      </Box>
    </Inline>
  );
};

export default PromoCode;
