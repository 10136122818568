import { Block, Inline } from '@contentful/rich-text-types';
import { ChevronRightSystemIcon, IconProps } from '@nib/icons';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { mr, my, pl, py } from 'styled-components-spacing';

const DesignIcon = styled(ChevronRightSystemIcon)`
  flex: 1 0 auto;
  ${mr(2)}
  margin-top: 2px;
  vertical-align: middle;
  display: inline-block;
`;

const StyledUL = styled.ul`
  ${pl(0)}
  list-style-type: none;
`;

const StyledLi = styled.li`
  display: flex;
  ${py(1)}
  p {
    ${my(0)}
  }
`;

const ListItemWrapper = styled.span`
  flex: 1 0 calc(100% - 24px);
  vertical-align: middle;
  display: inline-block;
`;

export const UnorderedList = (
  node: Block | Inline,
  children: ReactNode
): JSX.Element => <StyledUL>{children}</StyledUL>;

export const ListItem = (
  _node: Block | Inline,
  children: ReactNode
): JSX.Element => {
  const designIconProps: IconProps = {
    size: 'xs',
  };
  return (
    <StyledLi>
      <DesignIcon variant="design" color="darkest" {...designIconProps} />
      <ListItemWrapper>{children}</ListItemWrapper>
    </StyledLi>
  );
};
