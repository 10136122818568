import { Block, Inline } from '@contentful/rich-text-types';
import Copy from '@nib-components/copy';
import { colorBrandBase } from '@nib-components/theme';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { pl, py } from 'styled-components-spacing';

const StyledCopy = styled(Copy)`
  border-left: 2px solid ${colorBrandBase};
  ${pl(5)}
  ${py(2)}
  margin-left: auto;

  > p {
    margin: 0;
  }
`;

export const Quote = (
  node: Block | Inline,
  children: ReactNode
): JSX.Element => (
  <StyledCopy large component="blockquote">
    {children}
  </StyledCopy>
);
