import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import config from 'src/utils/env';
import {
  AddressFinderGetAddressDetailsRequest,
  AddressFinderGetAddressDetailsResponse,
  AddressFinderGetTokenResponse,
  AddressFinderSearchAddressRequest,
  AddressFinderSearchAddressResponse,
} from './addressFinderApiTypes';

const ADDRESS_SEARCH_DEFAULT_MAX_RESULTS = 5;

export const addressFinderApi = createApi({
  reducerPath: 'addressFinder',
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.nzPostApiRoot}/addresschecker/1.0`,
  }),
  endpoints: (builder) => ({
    getToken: builder.query<string, void>({
      query: () => ({
        url: config.addressCheckerApiRoot,
        headers: {
          'X-NibNZ-Version': '1',
        },
      }),
      transformResponse: (response: AddressFinderGetTokenResponse) =>
        response.accessToken,
      // Keep this around for a day, so we don't have to re-request it
      // every time we reload a screen with the address finder on it
      keepUnusedDataFor: 86400,
    }),
    searchAddress: builder.query<
      AddressFinderSearchAddressResponse,
      AddressFinderSearchAddressRequest
    >({
      query: ({ q, max = ADDRESS_SEARCH_DEFAULT_MAX_RESULTS, token }) => ({
        url: `/suggest`,
        params: { q, max },
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
      }),
    }),
    getAddressDetails: builder.query<
      AddressFinderGetAddressDetailsResponse,
      AddressFinderGetAddressDetailsRequest
    >({
      query: ({ dpid, token }) => ({
        url: `/details`,
        params: { dpid },
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
      }),
    }),
  }),
});

export const {
  useGetTokenQuery,
  useSearchAddressQuery,
  useLazyGetAddressDetailsQuery,
} = addressFinderApi;
