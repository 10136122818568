import { colorBrandBase } from '@nib-components/theme';
import styled from 'styled-components';

// Apply the brand base to the SVG
const StyledSvg = styled('svg')`
  color: ${colorBrandBase};
`;

const EmailHeartIcon = () => {
  return (
    <StyledSvg
      width="65"
      height="65"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0516 13.3729C14.6709 13.2499 14.2663 13.2192 13.8714 13.2833C13.4764 13.3474 13.1023 13.5044 12.7799 13.7414C12.4576 13.9784 12.1961 14.2887 12.0172 14.6466C11.7799 15.1204 11.6976 15.6573 11.7824 16.1804C11.8672 16.7036 12.1146 17.1867 12.4894 17.5613L12.497 17.5689L17.25 22.5275L22.0104 17.5613C22.3852 17.1866 22.6327 16.7035 22.7177 16.1804C22.8026 15.6572 22.7208 15.1206 22.4838 14.6466C22.3047 14.2887 22.0432 13.9785 21.7208 13.7414C21.3983 13.5044 21.0242 13.3474 20.6291 13.2833C20.2341 13.2192 19.8294 13.2499 19.4486 13.3729C19.0677 13.4959 18.7215 13.7076 18.4386 13.9906C18.4386 13.9906 18.4386 13.9906 18.4386 13.9906L17.6036 14.8256C17.5098 14.9193 17.3826 14.972 17.25 14.972C17.1174 14.972 16.9902 14.9193 16.8964 14.8256L16.0614 13.9906C15.7786 13.7076 15.4324 13.4958 15.0516 13.3729ZM22.931 14.423L23.3782 14.1994C23.7091 14.8612 23.8233 15.6103 23.7047 16.3407C23.5864 17.0692 23.2425 17.7421 22.7214 18.2647L17.611 23.596C17.5166 23.6944 17.3863 23.75 17.25 23.75C17.1137 23.75 16.9834 23.6944 16.889 23.596L11.7786 18.2647C11.2574 17.7422 10.9134 17.0691 10.7953 16.3404C10.6769 15.6101 10.7915 14.861 11.1228 14.1994M22.931 14.423L23.3782 14.1994C23.1283 13.6998 22.7632 13.2666 22.3131 12.9357C21.863 12.6048 21.3407 12.3856 20.7892 12.2962C20.2378 12.2068 19.673 12.2496 19.1413 12.4213C18.6097 12.5929 18.1265 12.8884 17.7314 13.2834L17.25 13.7649L16.7686 13.2835C16.7686 13.2835 16.7686 13.2835 16.7686 13.2835C16.3737 12.8885 15.8905 12.5929 15.3589 12.4213C14.8274 12.2496 14.2626 12.2067 13.7112 12.2962C13.1598 12.3856 12.6376 12.6049 12.1876 12.9358C11.7376 13.2666 11.3726 13.6998 11.1228 14.1994"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 1.25C1.98478 1.25 1.73043 1.35536 1.54289 1.54289C1.35536 1.73043 1.25 1.98478 1.25 2.25V14.25C1.25 14.5152 1.35536 14.7696 1.54289 14.9571C1.73043 15.1446 1.98478 15.25 2.25 15.25H8.25C8.52614 15.25 8.75 15.4739 8.75 15.75C8.75 16.0261 8.52614 16.25 8.25 16.25H2.25C1.71957 16.25 1.21086 16.0393 0.835786 15.6642C0.460714 15.2891 0.25 14.7804 0.25 14.25V2.25C0.25 1.71957 0.460714 1.21086 0.835786 0.835786C1.21086 0.460714 1.71957 0.25 2.25 0.25H20.25C20.7804 0.25 21.2891 0.460714 21.6642 0.835786C22.0393 1.21086 22.25 1.71957 22.25 2.25V9.75C22.25 10.0261 22.0261 10.25 21.75 10.25C21.4739 10.25 21.25 10.0261 21.25 9.75V2.25C21.25 1.98478 21.1446 1.73043 20.9571 1.54289C20.7696 1.35536 20.5152 1.25 20.25 1.25H2.25Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.692674 0.995163C0.86103 0.776278 1.17495 0.735317 1.39384 0.903673L9.53784 7.16767C10.0287 7.54529 10.6307 7.75006 11.25 7.75006C11.8693 7.75006 12.4713 7.54531 12.9621 7.1677L21.1062 0.903674C21.325 0.735317 21.639 0.776278 21.8073 0.995163C21.9757 1.21405 21.9347 1.52797 21.7158 1.69633L13.5719 7.9603C13.5719 7.96031 13.5719 7.9603 13.5719 7.9603C12.9062 8.47239 12.0899 8.75006 11.25 8.75006C10.4101 8.75006 9.59385 8.47241 8.92816 7.96033C8.92815 7.96032 8.92817 7.96033 8.92816 7.96033L0.784163 1.69633C0.565278 1.52797 0.524317 1.21405 0.692674 0.995163Z"
      />
    </StyledSvg>
  );
};

export default EmailHeartIcon;
