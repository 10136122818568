import { clone } from 'ramda';
import { useLocation, useNavigate } from 'react-router';
import { useRequestCallbackMutation } from 'src/services/join/joinApi';
import { JoinApiRequestCallbackOptions } from 'src/services/join/joinApiTypes';
import { QuoteSession } from 'src/types/QuoteSession';
import { generateCorrelationId } from 'src/utils/correlationUtils';
import gtmUtils from 'src/utils/gtmUtils';
import { createRequestCallbackOptions } from 'src/utils/joinApiUtils';
import { getJoinId, saveQuoteSession } from 'src/utils/localStorageUtils';
import { logError, logWarning } from 'src/utils/logUtils';
import { useSaveCart } from './useSaveCart';

export function useRequestCallback() {
  const [requestCallback] = useRequestCallbackMutation();
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const saveCart = useSaveCart();

  const createCallback = async (
    firstName: string,
    phoneNumber: string,
    bookACallPageRequest: boolean,
    preferredCallbackDay?: string,
    preferredCallbackTimeRange?: string,
    notes?: string,
    quoteSession?: QuoteSession
  ): Promise<void> => {
    if (quoteSession) {
      updateApplicant(firstName, phoneNumber, quoteSession);
      await saveCartForCallback(quoteSession);
    }

    if (notes) {
      notes = sanitiseNotes(notes);
    }

    const requestCallbackOptions = createRequestCallbackOptions(
      firstName,
      phoneNumber,
      preferredCallbackDay,
      preferredCallbackTimeRange,
      notes
    );
    if (bookACallPageRequest && !quoteSession) {
      const joinId = new URLSearchParams(search).get('id')!;
      requestCallbackOptions.joinId = joinId;
    }

    await create(requestCallbackOptions);
  };

  const create = async (callbackPayload: JoinApiRequestCallbackOptions) => {
    gtmUtils.submitRequestCallback(
      callbackPayload.requestDetails.name,
      callbackPayload.requestDetails.phoneNumber
    );
    try {
      return requestCallback(callbackPayload).unwrap();
    } catch (error) {
      console.warn(
        'Failed to request callback for need advice ... navigating to the error page'
      );
      navigate(`/error?returnURL=${pathname}`);
    }
  };

  const saveCartForCallback = async (quoteSession: QuoteSession) => {
    const correlationId = generateCorrelationId();
    try {
      await saveCart(quoteSession, true);
      const newJoinId = getJoinId();
      logWarning({
        message: `useRequestCallback successfully saved a cart which allocated joinId: ${newJoinId}`,
        correlationId,
      });
    } catch (error) {
      logError({
        message: `useRequestCallback failed to save a cart to create a joinId`,
        correlationId,
      });
      throw error;
    }
  };

  const sanitiseNotes = (notes: string) => {
    // Replace newlines with empty spaces
    const notesWithoutNewlines = notes.replace(/\n/g, ' ');

    // Define the whitelist regular expression
    // This includes:
    // - Lowercase letters a-z
    // - Uppercase letters A-Z
    // - Numbers 0-9
    // - Specific punctuation ,.?!:;&()/$@*#-+=_%''~
    // - Space
    const whitelistRegex = /[^a-zA-Z0-9,.?!:;&()/$@*#-+=_%''~ ]/g;

    // Replace characters not in the whitelist with an empty string
    // This effectively removes them
    const sanitisedNotes = notesWithoutNewlines.replace(whitelistRegex, '');

    return sanitisedNotes;
  };

  const updateApplicant = (
    firstName: string,
    phoneNumber: string,
    quoteSession: QuoteSession
  ) => {
    const clonedApplicant = clone(quoteSession.getNominatedOwner());

    clonedApplicant.firstName = clonedApplicant.firstName || firstName;
    clonedApplicant.phone = clonedApplicant.phone || phoneNumber || '';
    quoteSession.updateSingleApplicantDetails(clonedApplicant);

    saveQuoteSession(quoteSession);
  };

  return createCallback;
}
