import React from 'react';
import { PriceApiFetchPriceResponse } from 'src/services/price/priceApiTypes';

type PriceState = {
  isFetching: boolean;
  data: PriceApiFetchPriceResponse | undefined;
};

const defaultState: PriceState = {
  isFetching: false,
  data: undefined,
};

export const PriceContext = React.createContext(defaultState);
