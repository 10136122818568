import Alert from '@nib-components/alert';
import { Container } from '@nib/layout';
import config from 'src/utils/env';

const Banner = () => {
  const startDate = config.brand.content.bannerValidDate?.startDate || '';
  const endDate = config.brand.content.bannerValidDate?.endDate || '';
  const isBannerValid =
    startDate &&
    endDate &&
    new Date() >= new Date(startDate) &&
    new Date() <= new Date(endDate);

  return isBannerValid ? (
    <Container>
      <Alert
        type="info"
        title="We updated our plans on 1 February 2024."
        fullWidth={false}
      >
        This won’t impact your price, but we want to let you know what’s
        changed. Please take a look at the change summaries in the Important
        Things to Know Section at the bottom of the quote summary page before
        purchasing. Get in touch if you have any questions.
      </Alert>
    </Container>
  ) : null;
};

export default Banner;
