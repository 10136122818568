import Accordion from '@nib-components/accordion';
import { PrimaryButton } from '@nib-components/button';
import { hospitalTiers } from '@nib-components/colors';
import Heading from '@nib-components/heading';
import {
  ProductCardBody,
  ProductCardHeader,
  ProductCardWrapper,
} from '@nib-components/product-card';
import Select from '@nib-components/select';
import { colorAccent, ThemeSchema } from '@nib-components/theme';
import {
  DoctorFemaleGraphicIcon,
  HospitalGraphicIcon,
  RewardsGraphicIcon,
  TickSystemIcon,
} from '@nib/icons';
import { Box, Stack } from '@nib/layout';
import Loader from '@nib/loader';
import React from 'react';
import MarkdownContent from 'src/components/MarkdownContent';
import { ApplicantIdContext } from 'src/contexts/ApplicantIdContext';
import { PriceContext } from 'src/contexts/PriceContext';
import { useQuoteSession } from 'src/contexts/QuoteSessionProvider';
import priceApiUtils from 'src/services/price/priceApiUtils';
import {
  CoverBenefitHighlights,
  CoverBenefitHighlightsContent,
} from 'src/types/Content/ChooseYourCoverContent';
import {
  ProductConfig,
  ProductTier,
  ProductType,
} from 'src/types/ProductConfig';
import {
  PaymentFrequency,
  PaymentMethod,
  QuoteSession,
} from 'src/types/QuoteSession';
import config from 'src/utils/env';
import { formatCurrency } from 'src/utils/formatters/formatCurrency';
import { getPaymentFrequencyOptions } from 'src/utils/formPageUtils';
import { getQuoteSession } from 'src/utils/localStorageUtils';
import { getPolicyDocumentUri } from 'src/utils/productUtils';
import styled, { useTheme } from 'styled-components';
import CoverBenefitsList from './CoverBenefitsList';
import CoverHighlightsList from './CoverHighlightsList';

const content = config.brand.content.chooseYourCover.step2;

export const PRODUCT_CARD_CLASSNAME = 'product-card';

const ProductCardWrapperStyled = config.brand.hasMemberProducts
  ? styled(ProductCardWrapper)`
      border-top: 5px solid;
      border-top-color: ${(props) =>
        props.tier === 'gold'
          ? colorAccent
          : config.brand.customisation?.headersColor};
    `
  : ProductCardWrapper;

const HighlightsExpander = styled(Accordion.Item)`
  & h5 {
    font-size: 1rem;
    font-weight: 700;
    color: ${config.brand.customisation?.headersColor ?? 'inherit'};
  }
`;

const MarkdownContainer = styled('div')`
  p {
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const DesktopBottomSelectProductContainer = styled('div')`
  margin-top: 3%;
`;

interface ProductCardProps {
  product: ProductConfig;
  // Manages state of 'Select' primary button
  selected: boolean;
  onSelect: () => void;
  // Manages state of frequency select
  frequency: PaymentFrequency;
  setFrequency: (f: PaymentFrequency) => void;
  // Controls whether the benefits list is expanded
  isExpanded: boolean;
  onExpand: (expanded: boolean) => void;
  // Controls which individual benefits are expanded
  expandedBenefitIndex: number | undefined;
  setExpandedBenefitIndex: (expandedIndex: number | undefined) => void;
}

/**
 * Returns an appropriate icon based on a product type and tier
 */
const getIconForProduct = (productType: ProductType, tier: ProductTier) => {
  if (tier === 'gold') {
    return RewardsGraphicIcon;
  }
  switch (productType) {
    case 'Everyday':
      return DoctorFemaleGraphicIcon;
    case 'Hospital':
      return HospitalGraphicIcon;
  }
};

const BottomSelectProductButton = (
  props: ProductCardProps,
  productCode: string
) => {
  return (
    <Box justifyContent="center" display="flex">
      <Box width="310px" maxWidth="100%" display="inline-block">
        <Stack space={4}>
          <PrimaryButton
            data-testid={`product-card-bottom-button-${productCode}`}
            type="button"
            fullWidth={true}
            selected={props.selected}
            onClick={props.onSelect}
            icon={props.selected ? TickSystemIcon : undefined}
          >
            {content.selectButtonText}
          </PrimaryButton>
        </Stack>
      </Box>
    </Box>
  );
};

interface PriceValueProps {
  product: ProductConfig;
  quoteSession: QuoteSession;
}
const PriceValue: React.FC<PriceValueProps> = (props) => {
  const { product, quoteSession } = props;
  const { applicantId } = React.useContext(ApplicantIdContext);
  const { data: priceData } = React.useContext(PriceContext);

  if (!priceData) {
    return <Loader />;
  }
  let price: number;
  if (applicantId) {
    price = priceApiUtils.getApplicantPlanPrice(
      applicantId,
      product,
      quoteSession,
      priceData
    );
  } else {
    price = priceApiUtils.getTotalPlanPrice(product, quoteSession, priceData);
  }
  return <>{price.toFixed(2)}</>;
};

interface PlanExcessDescriptionProps {
  product: ProductConfig;
}
const PlanExcessDescription: React.FC<PlanExcessDescriptionProps> = ({
  product,
}) => {
  const { applicantId } = React.useContext(ApplicantIdContext);

  if (product.productDetails.productType !== 'Hospital') {
    return null;
  }
  const option = priceApiUtils.getEffectiveHospitalExcess(
    applicantId,
    product,
    getQuoteSession()!
  );

  return <>With {formatCurrency(option.value, false)} excess</>;
};

const getIconProductIconColour = (productTier: ProductTier) => {
  if (config.brand.hasMemberProducts) {
    const iconColour =
      productTier === 'gold'
        ? colorAccent
        : config.brand.customisation?.headersColor;
    return iconColour;
  } else {
    return productTier === 'gold' ? hospitalTiers.gold : hospitalTiers.basic;
  }
};

const ProductCard = (props: ProductCardProps) => {
  const {
    product,
    selected,
    onSelect,
    frequency,
    setFrequency,
    isExpanded,
    onExpand,
    expandedBenefitIndex,
    setExpandedBenefitIndex,
  } = props;
  const quoteSession = useQuoteSession();
  const {
    paymentDetails: { paymentMethod },
    hasMember,
  } = quoteSession;

  // Find main icon
  const ProductIcon = getIconForProduct(
    product.productDetails.productType,
    product.productDetails.productTier
  );

  // Find highlight content
  const highlightOptions: CoverBenefitHighlightsContent =
    product.productDetails.productType === 'Hospital'
      ? content.highlights.hospital
      : content.highlights.everyday;
  const highlights: CoverBenefitHighlights =
    product.productDetails.productTier === 'gold'
      ? highlightOptions.premium
      : highlightOptions.standard;

  const showDisclaimerForDirectDebit =
    config.brand.hasDirectDebitDiscount &&
    paymentMethod !== PaymentMethod.CreditCard;
  const showDisclaimerForMember = config.brand.hasMemberDiscount && hasMember;

  // Find policy document markdown
  const policyDocumentUrl = getPolicyDocumentUri(
    product.productDetails.productDocumentCode,
    product.productDetails.policyDocumentCode
  );

  const policyDocumentMarkdown =
    config.brand.content.chooseYourCover.step2.policyDocumentText.replace(
      /{url}/g,
      policyDocumentUrl
    );

  const theme = useTheme() as ThemeSchema;
  const isMobile = window.innerWidth < theme.breakpoints.md;

  // Card width is set by Swiper in the parent
  return (
    <ProductCardWrapperStyled
      type="hospital"
      tier={product.productDetails.productTier}
      className={PRODUCT_CARD_CLASSNAME}
    >
      <ProductCardHeader
        type="hospital"
        tier={product.productDetails.productTier}
        isLoading={false}
        space={4}
      >
        <ProductIcon
          size="xl"
          fill={getIconProductIconColour(product.productDetails.productTier)}
        />
        <ProductCardHeader.Name
          as="div"
          data-testid={`product-card-name-${product.productDetails.code}`}
        >
          <Heading size={4} color={config.brand.customisation?.headersColor}>
            {product.productDetails.name}
          </Heading>
        </ProductCardHeader.Name>
        <ProductCardHeader.Price
          hasDisclaimer={
            showDisclaimerForDirectDebit || showDisclaimerForMember
          }
          data-testid={`product-card-price-${product.productDetails.code}`}
        >
          <PriceValue product={product} quoteSession={quoteSession} />{' '}
        </ProductCardHeader.Price>
        <PlanExcessDescription product={product} />{' '}
        <Box
          width="310px"
          maxWidth="100%"
          textAlign="center"
          display="inline-block"
        >
          <Stack space={4}>
            <Select
              data-testid={`product-card-frequency-select-${product.productDetails.code}`}
              options={getPaymentFrequencyOptions(paymentMethod)}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                setFrequency(e.target.value as PaymentFrequency);
              }}
              value={frequency}
              formMode="white"
            />
            <PrimaryButton
              data-testid={`product-card-button-${product.productDetails.code}`}
              type="button"
              fullWidth={true}
              selected={selected}
              onClick={onSelect}
              icon={selected ? TickSystemIcon : undefined}
            >
              {content.selectButtonText}
            </PrimaryButton>
          </Stack>
        </Box>
      </ProductCardHeader>

      <ProductCardBody isUnavailable={false} space={4}>
        <CoverHighlightsList highlights={highlights} />
        <Accordion
          expandedIndex={isExpanded ? 0 : undefined}
          borderBottom={isExpanded ? false : true}
        >
          <HighlightsExpander
            title={isExpanded ? 'View less details' : 'View more details'}
            titleComponent="h5"
            onExpand={() => onExpand(true)}
            onCollapse={() => {
              onExpand(false);
            }}
            contentPadding={0}
          >
            {isExpanded && ( // not needed for expand/contract, but this speeds the page up when selecting productType
              <>
                <CoverBenefitsList
                  data-testid={`product-card-benefits-${product.productDetails.code}`}
                  benefits={product.coverBenefits}
                  expandedIndex={expandedBenefitIndex}
                  setExpandedIndex={setExpandedBenefitIndex}
                />
                <DesktopBottomSelectProductContainer>
                  {!isMobile &&
                    BottomSelectProductButton(
                      props,
                      product.productDetails.code
                    )}
                </DesktopBottomSelectProductContainer>
              </>
            )}
          </HighlightsExpander>
        </Accordion>
        {isMobile &&
          BottomSelectProductButton(props, product.productDetails.code)}
        <Box textAlign="center">
          <MarkdownContainer>
            <MarkdownContent content={policyDocumentMarkdown} />
          </MarkdownContainer>
        </Box>
      </ProductCardBody>
    </ProductCardWrapperStyled>
  );
};

export default ProductCard;
