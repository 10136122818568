import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import config from 'src/utils/env';
import { prepareHeaders } from '../serviceHelpers';

import { onValidateMembershipNumberStarted } from './eventHandlers';
import {
  ValidateExternalMemberNumberOptions,
  ValidateExternalMemberNumberResponse,
} from './membershipValidationApiTypes';

const membershipValidationApi = createApi({
  reducerPath: 'membershipValidationApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.membershipValidationApiRoot}`,
    prepareHeaders,
  }),
  endpoints(builder) {
    return {
      validateMembershipNumber: builder.query<
        ValidateExternalMemberNumberResponse,
        ValidateExternalMemberNumberOptions
      >({
        query: (request) => {
          const { correlationId, ...options } = request;
          return {
            url: 'api/v1/AA/validateMembership',
            method: 'POST',
            body: options,
            headers: {
              'x-correlation-id': correlationId,
            },
          };
        },
        onQueryStarted: onValidateMembershipNumberStarted,
      }),
    };
  },
});

export const { useLazyValidateMembershipNumberQuery } = membershipValidationApi;

export { membershipValidationApi };
