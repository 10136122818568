/**
 * Returns a string with dollar sign and thousands separated by commas, to two decimal places.
 *
 * @param amount
 */
export function formatCurrency(amount: number, showCents: boolean = true) {
  return Intl.NumberFormat('en-NZ', {
    style: 'currency',
    currency: 'NZD',
    minimumFractionDigits: showCents ? 2 : 0,
  }).format(amount);
}
