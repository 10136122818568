import Alert from '@nib-components/alert';
import { Box } from '@nib/layout';
import { useFormikContext } from 'formik';
import { path } from 'ramda';
import { BuyNowFormData } from 'src/types/BuyNowForm';
import config from 'src/utils/env';
import NeedAdviceButton from '../NeedAdvice/NeedAdviceButton';

const content = config.brand.content.buyNow.step1;

function getInfoTextForAge(
  age: number,
  hasHospitalCover: boolean
): string | null {
  if (isNaN(age)) {
    return null;
  }
  if (age > 120) {
    return content.ageOver120InfoText;
  }
  if (age > 75 && hasHospitalCover) {
    return content.ageOver75InfoText;
  }
  return null;
}

interface SmokerInfoBoxProps {
  fieldNamePrefix: string;
}

/**
 * Displays an info box if age meets various criteria
 */
const AgeInfoBox = ({ fieldNamePrefix }: SmokerInfoBoxProps) => {
  const { values, errors } = useFormikContext<BuyNowFormData>();

  const agePath = fieldNamePrefix.split('.');
  agePath.push('age');

  const hasHospitalCoverPath = fieldNamePrefix.split('.');
  hasHospitalCoverPath.push('hasHospitalCover');

  // If there is a validation error message, we don't want to show
  // this box (eg. the user has entered "-6" or "1.2")
  // Note that this works with the >120 rule, as that has no error message.
  if (path(agePath, errors)) {
    return null;
  }

  const ageValue: number = parseInt(path(agePath, values) ?? '');
  const hasHospitalCover: boolean = !!path(hasHospitalCoverPath, values);

  const infoText: string | null = getInfoTextForAge(ageValue, hasHospitalCover);

  if (infoText === null) {
    return null;
  }

  return (
    <Box marginTop={6}>
      <Alert
        type={ageValue > 75 ? 'error' : 'info'}
        variation="soft"
        role="alert"
      >
        <Box marginBottom={4}>{infoText}</Box>
        {ageValue > 75 && <NeedAdviceButton size="small" />}
      </Alert>
    </Box>
  );
};
export default AgeInfoBox;
