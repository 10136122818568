import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { generateCorrelationId } from 'src/utils/correlationUtils';
import config from 'src/utils/env';
import { prepareHeaders } from '../serviceHelpers';
import {
  SpecialOfferResponse,
  ValidateSpecialOfferRequest,
  ValidateSpecialOfferResponse,
} from './contentfulApiTypes';
import {
  onCreateCartQueryStarted,
  onCreateQuoteQueryStarted,
  onGetQuoteQueryStarted,
  onGetSpecialOfferQueryStarted,
  onRequestCallbackQueryStarted,
  onUpdateCartQueryStarted,
  onValidateAdviserNumberQueryStarted,
  onValidatePromoCodeQueryStarted,
  onValidateSpecialOfferQueryStarted,
} from './eventHandlers';
import {
  JoinApiCreateCartOptions,
  JoinApiCreateCartResponse,
  JoinApiCreateQuoteOptions,
  JoinApiCreateQuoteResponse,
  JoinApiGetQuoteOptions,
  JoinApiGetQuoteResponse,
  JoinApiRequestCallbackOptions,
  JoinApiRequestCallbackResponse,
  JoinApiUpdateCartOptions,
  JoinApiUpdateCartResponse,
  JoinApiValidateAdviserNumberOptions,
  JoinApiValidateAdviserNumberResponse,
  JoinApiValidatePromoCodeOptions,
  JoinApiValidatePromoCodeResponse,
} from './joinApiTypes';

const joinApi = createApi({
  reducerPath: 'joinApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.joinApiRoot}`,
    prepareHeaders,
  }),
  tagTypes: ['joinSession', 'cart'],
  endpoints(builder) {
    return {
      createCart: builder.mutation<
        JoinApiCreateCartResponse,
        JoinApiCreateCartOptions
      >({
        query: (request) => {
          return {
            url: `/v2/join/session/cart`,
            method: 'POST',
            body: request.cartDetails,
            headers: {
              'x-correlation-id': request.correlationId,
            },
          };
        },
        onQueryStarted: onCreateCartQueryStarted,
        invalidatesTags: ['cart'], // May not be needed as we probably don't need to fetch the cart
      }),
      updateCart: builder.mutation<
        JoinApiUpdateCartResponse,
        JoinApiUpdateCartOptions
      >({
        query: (request) => {
          return {
            url: `/v2/join/session/${request.joinId}/cart`,
            method: 'PUT',
            body: request.cartDetails,
            headers: {
              'x-correlation-id': request.correlationId,
            },
          };
        },
        onQueryStarted: onUpdateCartQueryStarted,
        invalidatesTags: ['cart'], // May not be needed as we probably don't need to fetch the cart
      }),
      createQuote: builder.mutation<
        JoinApiCreateQuoteResponse,
        JoinApiCreateQuoteOptions
      >({
        query: (request) => {
          return {
            url: `/v1/join/session/${request.joinId}/quote`,
            method: 'POST',
            body: request.quotePayload,
            headers: {
              'x-correlation-id': request.correlationId,
            },
          };
        },
        onQueryStarted: onCreateQuoteQueryStarted,
      }),
      getQuote: builder.query<JoinApiGetQuoteResponse, JoinApiGetQuoteOptions>({
        query: (request) => {
          return {
            url: `v1/join/session/${request.joinId}/quote/${request.quoteIndex}`,
            method: 'GET',
            headers: {
              'x-correlation-id': generateCorrelationId(),
            },
          };
        },
        onQueryStarted: onGetQuoteQueryStarted,
      }),
      getSpecialOffer: builder.query<SpecialOfferResponse, void>({
        query: () => {
          return {
            url: `v1/join/specialOffer/${config.contentfulBrandId}`,
            method: 'GET',
            headers: {
              'x-correlation-id': generateCorrelationId(),
            },
          };
        },
        onQueryStarted: onGetSpecialOfferQueryStarted,
      }),
      validateSpecialOffer: builder.query<
        ValidateSpecialOfferResponse,
        ValidateSpecialOfferRequest
      >({
        query: (request) => {
          return {
            url: `v1/join/specialOffer/${config.contentfulBrandId}/validate`,
            method: 'POST',
            body: request,
            headers: {
              'x-correlation-id': generateCorrelationId(),
            },
          };
        },
        onQueryStarted: onValidateSpecialOfferQueryStarted,
      }),
      requestCallback: builder.mutation<
        JoinApiRequestCallbackResponse,
        JoinApiRequestCallbackOptions
      >({
        query: (options) => {
          const url = options.joinId
            ? `/v2/join/${options.joinId}/callback`
            : `/v1/join/callback`;
          return {
            url,
            method: 'POST',
            body: options.requestDetails,
            headers: {
              'x-correlation-id': options.correlationId,
            },
          };
        },
        onQueryStarted: onRequestCallbackQueryStarted,
      }),
      validatePromoCode: builder.query<
        JoinApiValidatePromoCodeResponse,
        JoinApiValidatePromoCodeOptions
      >({
        query: (request) => {
          return {
            url: 'v1/join/session/promo',
            method: 'POST',
            body: request,
            headers: {
              'x-correlation-id': generateCorrelationId(),
            },
          };
        },
        onQueryStarted: onValidatePromoCodeQueryStarted,
      }),
      validateAdviserNumber: builder.query<
        JoinApiValidateAdviserNumberResponse,
        JoinApiValidateAdviserNumberOptions
      >({
        query: (request) => {
          return {
            url: 'v1/adviser/validateExternalNumber',
            method: 'POST',
            body: request,
            headers: {
              'x-correlation-id': generateCorrelationId(),
            },
          };
        },
        onQueryStarted: onValidateAdviserNumberQueryStarted,
      }),
    };
  },
});

export const {
  useUpdateCartMutation,
  useCreateCartMutation,
  useCreateQuoteMutation,
  useGetSpecialOfferQuery,
  useRequestCallbackMutation,
  useValidateSpecialOfferQuery,
  useGetQuoteQuery,
  useLazyValidatePromoCodeQuery,
  useLazyValidateAdviserNumberQuery,
} = joinApi;

export { joinApi };
