import {
  MemberProductConfig,
  PlanConfig,
  ProductConfig,
} from 'src/types/ProductConfig';
import config from './env';

/**
 * The products available for the current brand.
 */
export const planConfig = getBrandPlanConfig();

/**
 * The set of everyday products available for the current brand.
 */
export const everydayProducts: ProductConfig[] = planConfig.hasMemberProducts
  ? [
      ...planConfig.everydayProducts.memberProducts,
      ...planConfig.everydayProducts.nonMemberProducts,
    ]
  : planConfig.everydayProducts;

/**
 * The set of hospital products available for the current brand.
 */
export const hospitalProducts: ProductConfig[] = planConfig.hasMemberProducts
  ? [
      ...planConfig.hospitalProducts.memberProducts,
      ...planConfig.hospitalProducts.nonMemberProducts,
    ]
  : planConfig.hospitalProducts;

/**
 * Filter to only return products that meet the combination of the brand's
 * hasMemberProducts value and the member's isMember value.
 */
export const isValidMemberProductCode = (
  isMember: boolean | undefined,
  productCode: string
): boolean => {
  // If the plan doesn't have any member products, accept all products
  if (!config.brand.hasMemberProducts) {
    return true;
  }
  // Find the matching plan
  const productConfig = getProductByCode(productCode);
  if (!productConfig) {
    return false;
  }
  // If the plan does have member products, show the ones that match the user's
  // member status. Assume undefined is false.
  return !!isMember === !!productConfig.productDetails.isMemberProduct;
};

/**
 * Find a product by the productCode
 */
export const getProductByCode = (
  productCode: string
): ProductConfig | undefined => {
  return config.brand.productConfig.find(
    (c) => c.productDetails.code === productCode
  );
};

/**
 * Constructs a policy document URL
 */
export const getPolicyDocumentUri = (
  productDocumentCode: string,
  policyDocumentCode: string
): string => {
  return config.policyDocumentUri
    .replace('{productDocumentCode}', productDocumentCode)
    .replace('{policyDocumentCode}', policyDocumentCode);
};

/**
 * Fetches the configuration of products for the current brand.
 */
export function getBrandPlanConfig(): PlanConfig {
  if (config.brand.hasMemberProducts) {
    return {
      hasMemberProducts: config.brand.hasMemberProducts,
      hospitalProducts: getMemberHospitalProductConfig(),
      everydayProducts: getMemberEverydayProductConfig(),
    };
  }
  return {
    hasMemberProducts: config.brand.hasMemberProducts,
    hospitalProducts: getHospitalProductConfig(),
    everydayProducts: getEverydayProductConfig(),
  };
}

function getHospitalProductConfig(): ProductConfig[] {
  return config.brand.productConfig.filter(
    (pc) => pc.productDetails.productType === 'Hospital'
  );
}

function getEverydayProductConfig(): ProductConfig[] {
  return config.brand.productConfig.filter(
    (pc) => pc.productDetails.productType === 'Everyday'
  );
}

function getMemberHospitalProductConfig(): MemberProductConfig {
  return getMemberProductConfig(getHospitalProductConfig());
}

function getMemberEverydayProductConfig(): MemberProductConfig {
  return getMemberProductConfig(getEverydayProductConfig());
}

function getMemberProductConfig(
  productConfig: ProductConfig[]
): MemberProductConfig {
  const memberProducts = productConfig.filter(
    (pc) => pc.productDetails.isMemberProduct
  );
  const nonMemberProducts = productConfig.filter(
    (pc) => pc.productDetails.isMemberProduct === false
  );
  return {
    memberProducts,
    nonMemberProducts,
  };
}
