import FeaturePanel from '@nib-components/feature-panel';
import { WelcomeFeaturePanelContentAA } from 'src/types/Content/WelcomeFeaturePanelContent';
import config from 'src/utils/env';
import BrandedStyleHeader from '../heading/BrandedStyleHeader';
import EmailHeartIcon from './IconComponents/EmailHeartIcon';

const FeaturePanels = () => {
  let aaFeaturePanel = config.brand.content.welcome
    .featurePanel as WelcomeFeaturePanelContentAA;
  return (
    <BrandedStyleHeader>
      <FeaturePanel
        title={config.brand.content.welcome.featurePanelTitle}
        space={2}
      >
        <FeaturePanel.Feature
          icon={EmailHeartIcon}
          title={aaFeaturePanel.checkYourEmailFeature.featureTitle}
        >
          {aaFeaturePanel.checkYourEmailFeature.featureInfo}
        </FeaturePanel.Feature>
      </FeaturePanel>
    </BrandedStyleHeader>
  );
};

export default FeaturePanels;
