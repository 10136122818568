import { Helmet } from 'react-helmet';
import { HelmetContent } from 'src/types/Content/HelmetContent';

const HelmetComponent = (props: {
  content: HelmetContent;
  multiplePeople?: boolean;
}) => {
  const { title, titleMultiplePeople } = props.content;
  const effectiveTitle =
    props.multiplePeople && titleMultiplePeople ? titleMultiplePeople : title;

  return (
    <Helmet>
      <meta charSet="uts-8" />
      <title>{effectiveTitle}</title>
    </Helmet>
  );
};

export default HelmetComponent;
