import React from 'react';
import ErrorPage from 'src/pages/ErrorPage';
import { generateCorrelationId } from 'src/utils/correlationUtils';
import { logError } from 'src/utils/logUtils';

interface ErrorBoundaryState {
  hasError: boolean;
}

interface ErrorBoundaryProps {
  children: React.ReactNode;
  startAgain: () => void;
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  // Error listeners was originally added to this Error Boundary class however,
  // GTM (Google Tag Manager) was throwing errors.
  // Causing the user to be redirected to the error boundary fallback UI (Error Page).
  // Because of that we decided on using this error boundaries without listeners.

  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    const errorTitle = `Error Boundary: ${error.name}: ${error.message}`;
    logError({
      message: errorTitle,
      data: { error: error, errorInfo: errorInfo },
      correlationId: generateCorrelationId(),
    });
  }

  handleStartAgain = () => {
    this.setState({ hasError: false });

    this.props.startAgain();
  };

  render() {
    if (this.state.hasError) {
      return (
        <ErrorPage
          isFromErrorBoundary={true}
          startAgain={this.handleStartAgain}
        />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
