import { Block, Inline } from '@contentful/rich-text-types';
import { Box } from '@nib/layout';
import { EmbeddedEntryInline } from './embeddedEntryInline';

export const EmbeddedEntry = (node: Block | Inline): JSX.Element => {
  return (
    <Box>
      <EmbeddedEntryInline {...node} />
    </Box>
  );
};
