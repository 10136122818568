import { PrimaryButton, SecondaryButton } from '@nib-components/button';
import { Inline, Stack } from '@nib/layout';
import config from 'src/utils/env';
import styled from 'styled-components';
import BrandedModal from '../BrandedModal';

const content = config.brand.content.resumeQuote;

const NonDismissibleModal = styled(BrandedModal)`
  header button {
    display: none;
  }
`;

interface ResumeQuoteModalProps {
  quoteLoaded: boolean;
  onGetUpdatedQuote: () => void;
  onStartOverQuote: () => void;
}

const ResumeQuoteModal = (props: ResumeQuoteModalProps) => {
  const { onGetUpdatedQuote, onStartOverQuote, quoteLoaded } = props;
  return (
    <NonDismissibleModal
      visible={true}
      title={
        quoteLoaded ? content.expiredQuoteLabel : content.veryExpiredQuoteLabel
      }
      closeOnClick={false}
      closeOnEsc={false}
    >
      <Stack space={{ xs: 4, md: 6 }}>
        <Inline space={2} collapseBelow="md">
          {quoteLoaded ? (
            <>
              <SecondaryButton
                fullWidth={{ xs: true, md: false }}
                onClick={() => {
                  onStartOverQuote();
                }}
              >
                {content.expiredModalStartAgainButtonLabel}
              </SecondaryButton>
              <PrimaryButton
                fullWidth={{ xs: true, md: false }}
                onClick={() => {
                  onGetUpdatedQuote();
                }}
              >
                {content.expiredModalUpdateQuoteButtonLabel}
              </PrimaryButton>
            </>
          ) : (
            <PrimaryButton
              fullWidth={{ xs: true, md: false }}
              onClick={() => {
                onStartOverQuote();
              }}
            >
              {content.expiredModalStartAgainButtonLabel}
            </PrimaryButton>
          )}
        </Inline>
      </Stack>
    </NonDismissibleModal>
  );
};

export default ResumeQuoteModal;
