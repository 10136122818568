import {
  JoinApiAdviserDetails,
  JoinApiBrand,
} from 'src/services/join/joinApiTypes';
import { AdviserDetails, QuoteSession } from 'src/types/QuoteSession';

// Update this when making breaking changes to the structure of QuoteSession, to ensure
// users do not get whitescreens by trying to load an invalid session.
// Also update the corresponding value in
// `e2e/tests/utils.js:ResumeSessionModal.setupResumeSessionModal`
const QUOTE_SESSION_KEY = 'QuoteSession_v15';

const JOIN_ID_KEY = 'JoinId';
const DEFAULT_CORRELATION_ID_KEY = 'DefaultCorrelationId';
const ORIGINATING_BRAND_KEY = 'OriginatingBrand';
const ADVISER_DETAILS_KEY = 'AdviserDetails';

export const getQuoteSession = (): QuoteSession | null => {
  const storedSessionString = localStorage.getItem(QUOTE_SESSION_KEY);
  if (storedSessionString) {
    const quoteSession = new QuoteSession();
    const restoredProperties = JSON.parse(storedSessionString);
    return Object.assign(quoteSession, restoredProperties);
  }
  return null;
};

export const saveQuoteSession = (quoteSession: QuoteSession) => {
  localStorage.setItem(QUOTE_SESSION_KEY, JSON.stringify(quoteSession));
};
export const getJoinId = () => localStorage.getItem(JOIN_ID_KEY);
export const saveJoinId = (joinId: string) =>
  localStorage.setItem(JOIN_ID_KEY, joinId);

export const getDefaultCorrelationid = () =>
  localStorage.getItem(DEFAULT_CORRELATION_ID_KEY);
export const saveDefaultCorrelationId = (correlationId: string) =>
  localStorage.setItem(DEFAULT_CORRELATION_ID_KEY, correlationId);

export const getOriginatingBrand = () =>
  localStorage.getItem(ORIGINATING_BRAND_KEY) as JoinApiBrand | null;
export const saveOriginatingBrand = (
  originatingBrand: JoinApiBrand | undefined
) => {
  if (originatingBrand) {
    localStorage.setItem(ORIGINATING_BRAND_KEY, originatingBrand);
  } else {
    // All quotes SHOULD have an originatingBrand
    // But if we load one that doesn't don't save (maybe obsolete/test quote)
    // don't store undefined/garbage
    //
    localStorage.removeItem(ORIGINATING_BRAND_KEY);
  }
};

export const getAdviserDetails = () => {
  const storedAdviserDetailsString = localStorage.getItem(ADVISER_DETAILS_KEY);
  if (storedAdviserDetailsString) {
    return JSON.parse(storedAdviserDetailsString) as AdviserDetails;
  } else {
    return null;
  }
};

export const saveAdviserDetails = (
  adviserDetails: JoinApiAdviserDetails | undefined
) => {
  if (adviserDetails) {
    localStorage.setItem(ADVISER_DETAILS_KEY, JSON.stringify(adviserDetails));
  } else {
    localStorage.removeItem(ADVISER_DETAILS_KEY);
  }
};

export const removeAllFromLocalStorage = () => {
  // Don't use localStorage.clear() as there may be other third party stuff
  // we don't want to remove.
  localStorage.removeItem(QUOTE_SESSION_KEY);
  localStorage.removeItem(JOIN_ID_KEY);
  localStorage.removeItem(DEFAULT_CORRELATION_ID_KEY);
  // Do NOT remove ORIGINATING_BRAND_KEY or ADVISER_DETAILS_KEY !!
  // The intention is that quotes that start out in AACN keep that brand
};
