import Alert from '@nib-components/alert';
import { SecondaryButton, TertiaryButton } from '@nib-components/button';
import Heading from '@nib-components/heading';
import Card from '@nib/card';
import { AddSystemIcon, SavingsGraphicIcon } from '@nib/icons';
import InfoBox from '@nib/info-box';
import {
  Box,
  breakpoint,
  Column,
  Columns,
  Container,
  Stack,
} from '@nib/layout';
import { FieldArray, Formik } from 'formik';
import { clone } from 'ramda';
import { useEffect } from 'react';
import AdviserNumberField from 'src/components/AboutYou/AdviserNumberField';
import ApplicantForm from 'src/components/AboutYou/ApplicantForm';
import BrandedCard from 'src/components/BrandedCard';
import CustomisedForm from 'src/components/form/CustomisedForm';
import FormCheckbox from 'src/components/form/FormCheckbox';
import HasMemberRadioGroup from 'src/components/form/HasMemberRadioGroup';
import HeadingPanel from 'src/components/heading/HeadingPanel';
import HelmetComponent from 'src/components/HelmetComponent/HelmetComponent';
import MarkdownContent from 'src/components/MarkdownContent';
import BottomButtons from 'src/components/navigation/BottomButtons';
import NeedAdviceButton from 'src/components/NeedAdvice/NeedAdviceButton';
import VerticalIndentBar from 'src/components/VerticalIndentBar';
import { FormPage } from 'src/types/FormPage';
import { FormPageProps } from 'src/types/FormPageProps';
import { generateCorrelationId } from 'src/utils/correlationUtils';
import config from 'src/utils/env';
import gtmUtils from 'src/utils/gtmUtils';
import { logInfo } from 'src/utils/logUtils';
import { createApplicant } from 'src/utils/quoteUtils';
import { AboutYouSchema } from 'src/utils/validation/schema/AboutYouSchema';
import styled from 'styled-components';

const ResponsiveHeading = styled('div')`
  h1 {
    ${breakpoint('xxl')`${{
      width: '100%',
    }}`}
    ${breakpoint('xl', 'xxl')`${{
      width: '65%',
    }}`}
    ${breakpoint('lg', 'xl')`${{
      'min-width': '60%;',
      'max-width': '300px;',
    }}`}
    ${breakpoint('md', 'lg')`${{
      width: '62%',
    }}`}
    ${breakpoint('sm', 'md')`${{
      width: '100%',
    }}`}
    ${breakpoint('xs', 'sm')`${{
      'min-width': '77%;',
      'max-width': '296px;',
    }}`}
  }
`;

const MarkdownContainer = styled('div')`
  p {
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const AboutYouPage = (props: FormPageProps) => {
  const { onSubmit } = props;
  const quoteSession = clone(props.quoteSession);
  useEffect(() => {
    gtmUtils.viewAboutYou();
    logInfo({
      message: 'PAGE About You page loaded',
      correlationId: generateCorrelationId(),
    });
  }, []);

  return (
    <Box>
      <HelmetComponent content={config.brand.content.aboutYou.helmet} />
      <ResponsiveHeading>
        <HeadingPanel
          background="sageGreen60"
          title={config.brand.content.aboutYou.title}
          subTitle={config.brand.content.aboutYou.subTitle}
          image={
            config.brand.content.aboutYou.heroAltText
              ? {
                  src: `${process.env.PUBLIC_URL}/assets/images/about-you/hero.png`,
                  altText: config.brand.content.aboutYou.heroAltText,
                  width: 14, // 14 rems
                }
              : undefined
          }
        />
      </ResponsiveHeading>
      <Formik
        enableReinitialize={true}
        initialValues={{
          adviserDetails: quoteSession.adviserDetails,
          applicantDetails: quoteSession.applicantDetails,
          hasMember: quoteSession.hasMember,
          externalMemberNumber: quoteSession.externalMemberNumber,
        }}
        onSubmit={(formData) => {
          quoteSession.updateAllApplicantDetails(formData.applicantDetails);
          quoteSession.updateExternalMembers(
            formData.externalMemberNumber,
            formData.hasMember
          );
          if (config.brand.hasAdviser) {
            quoteSession.adviserDetails = formData.adviserDetails;
          }
          onSubmit(FormPage.ChooseYourCover, quoteSession);
        }}
        validationSchema={AboutYouSchema}
      >
        {({ values, isValidating, handleReset, handleSubmit }) => (
          <CustomisedForm
            id="aboutYou"
            name="aboutYou"
            containerWidth="100%"
            spaceChildren={false}
            onReset={handleReset}
            onSubmit={handleSubmit}
            formMode="light"
          >
            <Container>
              <FieldArray name="applicantDetails">
                {({ push, remove }) => {
                  return (
                    <Box>
                      {config.brand.hasAdviser && (
                        <Box marginBottom={6}>
                          <Columns collapseBelow="lg" space={7}>
                            <Column width="1/2">
                              <AdviserNumberField
                                label={
                                  config.brand.content.aboutYou
                                    .adviserNumberLabel
                                }
                                name="adviserDetails.adviserNumber"
                              />
                            </Column>
                          </Columns>
                        </Box>
                      )}
                      {config.brand.hasAdviser && (
                        <Box marginBottom={6}>
                          <BrandedCard>
                            <Card.Content
                              title={
                                config.brand.content.aboutYou
                                  .adviserDeclarationTitle!
                              }
                            >
                              <MarkdownContainer>
                                <MarkdownContent
                                  content={
                                    config.brand.content.aboutYou
                                      .adviserDeclarationText
                                  }
                                />
                              </MarkdownContainer>
                              <FormCheckbox
                                name="adviserDetails.hasReadDeclaration"
                                label={
                                  config.brand.content.aboutYou
                                    .adviserDeclarationLabel!
                                }
                                formMode="white"
                              />
                            </Card.Content>
                          </BrandedCard>
                        </Box>
                      )}
                      <Box>
                        {values.applicantDetails.map((_a, index) => {
                          return (
                            <Box key={`applicantForm${index}`}>
                              {index > 0 && (
                                <Box marginBottom={4}>
                                  <Heading
                                    component="h2"
                                    size={{ xs: 3, lg: 2 }}
                                    color={
                                      config.brand.customisation?.headersColor
                                    }
                                  >
                                    {
                                      config.brand.content.aboutYou
                                        .additionalPersonHeading
                                    }
                                  </Heading>
                                </Box>
                              )}
                              <Box
                                display="flex"
                                flexDirection="row"
                                marginBottom={index > 0 ? 6 : 0}
                              >
                                {index > 0 && <VerticalIndentBar />}
                                <Box flex="1" flexGrow={1}>
                                  <ApplicantForm
                                    fieldNamePrefix={`applicantDetails.${index}`}
                                    variation={index === 0 ? 'first' : 'other'}
                                  />
                                  {index > 0 && (
                                    <TertiaryButton
                                      type="button"
                                      size="small"
                                      onClick={() => remove(index)}
                                    >
                                      <u>
                                        {
                                          config.brand.content.aboutYou
                                            .removePersonButtonText
                                        }
                                      </u>
                                    </TertiaryButton>
                                  )}
                                </Box>
                              </Box>
                            </Box>
                          );
                        })}
                      </Box>
                      <Box marginBottom={6}>
                        <Stack space={6}>
                          <SecondaryButton
                            icon={AddSystemIcon}
                            iconPlacement="left"
                            type="button"
                            // Maximum number of people is 26.
                            disabled={values.applicantDetails.length >= 26}
                            onClick={() => push(createApplicant())}
                            size="small"
                          >
                            {config.brand.content.aboutYou.addPersonButtonText}
                          </SecondaryButton>
                          {values.applicantDetails.length >= 26 && (
                            <Alert
                              type="info"
                              variation="soft"
                              role="alert"
                              title={
                                config.brand.content.aboutYou
                                  .maxMembersInfoTitle
                              }
                            >
                              <Box marginBottom={4}>
                                {
                                  config.brand.content.aboutYou
                                    .maxMembersInfoText
                                }
                              </Box>
                              <NeedAdviceButton size="small" />
                            </Alert>
                          )}
                          {config.brand.hasMemberProducts && (
                            <Columns collapseBelow="md" space={4}>
                              <Column width={{ lg: '1/2', xl: '1/3' }}>
                                <HasMemberRadioGroup
                                  label={
                                    config.brand.content.aboutYou.hasMemberLabel
                                  }
                                  name={'hasMember'}
                                />
                              </Column>
                              <Column width={{ lg: '1/2', xl: '2/3' }}>
                                <InfoBox icon={SavingsGraphicIcon}>
                                  {
                                    config.brand.content.aboutYou
                                      .hasMemberInfoText
                                  }
                                </InfoBox>
                              </Column>
                            </Columns>
                          )}
                        </Stack>
                      </Box>
                      <BottomButtons
                        submitButtonText={
                          config.brand.content.aboutYou.nextButtonText
                        }
                        // show a spinner while the async validator is running
                        isLoading={isValidating}
                      />
                      <NeedAdviceButton className="fixedFloating" />
                    </Box>
                  );
                }}
              </FieldArray>
            </Container>
          </CustomisedForm>
        )}
      </Formik>
    </Box>
  );
};

export default AboutYouPage;
