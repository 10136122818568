import { clone } from 'ramda';
import TagManager from 'react-gtm-module';
import {
  SpecialOfferResponse,
  ValidateSpecialOfferResponse,
} from 'src/services/join/contentfulApiTypes';
import { PriceApiFetchPriceResponse } from 'src/services/price/priceApiTypes';
import priceApiUtils from 'src/services/price/priceApiUtils';
import { FormPage } from 'src/types/FormPage';
import { ProductConfig } from 'src/types/ProductConfig';
import {
  ApplicantDetails,
  ApplicantExtraDetails,
  PaymentFrequency,
  PolicyDetails,
  QuoteSession,
} from 'src/types/QuoteSession';
import dateUtils from './dateUtils';
import {
  GtmApplicantEventInfo,
  GtmApplicantInfoStepName,
  GtmBaseEvent,
  GtmEcommerce,
  GtmEcommerceEvent,
  GtmEcommerceEventType,
  GtmEcommerceItem,
  GtmEmptyStepView,
  GtmEvent,
  GtmFormEvent,
  GtmFormEventType,
  GtmFormName,
  GtmGender,
  GtmRequestCallbackEvent,
  GtmSendQuoteEvent,
  GtmStepName,
  GtmViewAboutYouEvent,
  GtmViewApplicantInfoStepEvent,
  GtmViewWelcomeEvent,
  GtmWelcomeUserInfo,
} from './gtmTypes';
import { isEligibleForSpecialOffer } from './joinApiUtils';
import { getJoinId, getOriginatingBrand } from './localStorageUtils';
import { getProductByCode } from './productUtils';

export class GtmUtils {
  viewAboutYou() {
    const event = this.createAboutYouEvent();
    this.pushEvent(event);
  }

  viewChooseYourCover(applicantDetails: ApplicantDetails[]) {
    this.createAndSendApplicantInfoStepEvent(
      'Choose your cover',
      applicantDetails
    );
  }

  viewResumeQuote(applicantDetails: ApplicantDetails[]) {
    this.createAndSendApplicantInfoStepEvent('Resume quote', applicantDetails);
  }

  selectPlanOption() {
    this.createAndPushJoinStepView('Choose your cover | plan option selected');
  }

  selectCoverLevel() {
    this.createAndPushJoinStepView(
      'Choose your cover | level of cover selected'
    );
  }

  selectExcessAmount() {
    this.createAndPushJoinStepView(
      'Choose your cover | excess option selected'
    );
  }

  selectNonPharmacPlus() {
    this.createAndPushJoinStepView(
      'Choose your cover | non-pharmac plus option selected'
    );
  }

  viewQuoteSummary(applicantDetails: ApplicantDetails[]) {
    this.createAndSendApplicantInfoStepEvent('Quote summary', applicantDetails);
  }

  viewCart(
    quoteSession: QuoteSession,
    priceData: PriceApiFetchPriceResponse,
    getSpecialOfferResponse: SpecialOfferResponse,
    validateSpecialOfferResponse: ValidateSpecialOfferResponse
  ) {
    this.createAndPushEcommerceEvent(
      'view_cart',
      quoteSession,
      priceData,
      getSpecialOfferResponse,
      validateSpecialOfferResponse
    );
  }

  viewFinaliseAndBuy(applicantDetails: ApplicantDetails[]) {
    this.createAndSendApplicantInfoStepEvent(
      'Finalise and buy',
      applicantDetails
    );
  }

  beginCheckout(
    quoteSession: QuoteSession,
    priceData: PriceApiFetchPriceResponse,
    getSpecialOfferResponse: SpecialOfferResponse,
    validateSpecialOfferResponse: ValidateSpecialOfferResponse
  ) {
    this.createAndPushEcommerceEvent(
      'begin_checkout',
      quoteSession,
      priceData,
      getSpecialOfferResponse,
      validateSpecialOfferResponse
    );
  }

  addPaymentInfo(
    quoteSession: QuoteSession,
    priceData: PriceApiFetchPriceResponse,
    getSpecialOfferResponse: SpecialOfferResponse,
    validateSpecialOfferResponse: ValidateSpecialOfferResponse
  ) {
    this.createAndPushEcommerceEvent(
      'add_payment_info',
      quoteSession,
      priceData,
      getSpecialOfferResponse,
      validateSpecialOfferResponse
    );
  }

  viewAaMembershipStep() {
    // This event is not in the nib GTM spec ... but added it just
    // in case AA ever want to use the GTM data layer.
    //
    this.createAndPushJoinStepView('Finalise and buy | AA membership step');
  }

  viewDeclarationStep() {
    this.createAndPushJoinStepView(
      'Finalise and buy | Viewed declaration step'
    );
  }

  viewPaymentDetailsStep() {
    this.createAndPushJoinStepView('Finalise and buy | Payment details');
  }

  viewWelcome(quoteSession: QuoteSession) {
    const event = this.createViewWelcomeEvent(quoteSession);
    this.pushEvent(event);
  }

  purchase(
    quoteSession: QuoteSession,
    priceData: PriceApiFetchPriceResponse,
    campaignCode: string | undefined,
    submissionReferenceId: string
  ) {
    const event = this.createGtmEcommerceEvent(
      'purchase',
      quoteSession,
      priceData,
      campaignCode,
      submissionReferenceId
    );

    this.pushEcommerceEvent(event);
  }

  startAddPerson(formPage: FormPage) {
    const event = this.createFormStartEvent(
      'Join | Add person',
      this.formPageToGtmStepName(formPage),
      undefined
    );
    this.pushEvent(event);
  }

  submitAddPerson(formPage: FormPage) {
    const event = this.createFormSubmitEvent(
      'Join | Add person',
      this.formPageToGtmStepName(formPage),
      undefined
    );
    this.pushEvent(event);
  }

  removePerson(formPage: FormPage) {
    const event = this.createFormSubmitEvent(
      'Join | Remove person',
      this.formPageToGtmStepName(formPage),
      undefined
    );
    this.pushEvent(event);
  }

  startEditDetails(applicantId: string, quoteSession: QuoteSession) {
    const event = this.createFormStartEvent(
      'Join | Edit details',
      undefined,
      this.getPersonNumberAsString(applicantId, quoteSession)
    );
    this.pushEvent(event);
  }

  submitEditDetails(applicantId: string, quoteSession: QuoteSession) {
    const event = this.createFormSubmitEvent(
      'Join | Edit details',
      undefined,
      this.getPersonNumberAsString(applicantId, quoteSession)
    );
    this.pushEvent(event);
  }

  startEditCover(applicantId: string, quoteSession: QuoteSession) {
    const event = this.createFormStartEvent(
      'Join | Edit cover',
      undefined,
      this.getPersonNumberAsString(applicantId, quoteSession)
    );
    this.pushEvent(event);
  }

  submitEditCover(applicantId: string, quoteSession: QuoteSession) {
    const event = this.createFormSubmitEvent(
      'Join | Edit cover',
      undefined,
      this.getPersonNumberAsString(applicantId, quoteSession)
    );
    this.pushEvent(event);
  }

  submitRequestCallback(firstName: string, phone: string) {
    const event = this.createRequestCallbackEvent(firstName, phone);
    this.pushEvent(event);
  }

  submitSendQuote(firstName: string, phone: string, email: string) {
    const event = this.createSendQuoteEvent(firstName, phone, email);
    this.pushEvent(event);
  }

  private getPersonNumberAsString(
    applicantId: string,
    quoteSession: QuoteSession
  ): string {
    const index = quoteSession.applicantDetails.findIndex(
      (a) => a.id === applicantId
    );
    if (index === -1) {
      throw Error(
        `gtmUtils.getPersonNumberAsString: unable to find applicant with id: ${applicantId}`
      );
    }
    return String(index + 1); // 1-based
  }

  private formPageToGtmStepName(formPage: FormPage): GtmStepName {
    switch (formPage) {
      case FormPage.AboutYou:
        return 'About you';
      case FormPage.ChooseYourCover:
        return 'Choose your cover';
      case FormPage.TailorYourQuote:
        return 'Quote summary';
      default:
        throw Error(
          `GtmUtils.formPageToGtmStepName: called with unexpected formPage: ${formPage}`
        );
    }
  }

  private createAndSendApplicantInfoStepEvent(
    stepName: GtmApplicantInfoStepName,
    applicantDetails: ApplicantDetails[]
  ) {
    const event = this.createApplicantInfoStepEvent(stepName, applicantDetails);
    this.pushEvent(event);
  }

  private createAboutYouEvent() {
    const event: GtmViewAboutYouEvent = {
      event: 'join_step_view',
      event_info: {
        step_name: 'About you',
        application_process_id: this.getApplicationProcessId(),
      },
    };
    return event;
  }

  private createApplicantInfoStepEvent(
    step_name: GtmApplicantInfoStepName,
    applicantDetails: ApplicantDetails[]
  ) {
    const event: GtmViewApplicantInfoStepEvent = {
      event: 'join_step_view',
      event_info: this.createApplicantInfoStepEventInfo(
        step_name,
        applicantDetails
      ),
    };
    return event;
  }

  private createApplicantInfoStepEventInfo(
    step_name: GtmApplicantInfoStepName,
    applicantDetails: ApplicantDetails[]
  ) {
    const eventInfo: GtmApplicantEventInfo = {
      step_name: step_name,
      number_of_people: String(applicantDetails.length),
      applicant_gender: this.getApplicantGender(applicantDetails[0]),
      applicant_age: applicantDetails[0].age,
      additional_people_ages: this.getAdditionalPeopleAges(applicantDetails),
      additional_people_genders:
        this.getAdditionalPeopleGenders(applicantDetails),
      application_process_id: this.getApplicationProcessId(),
    };
    return eventInfo;
  }

  private createViewWelcomeEvent(quoteSession: QuoteSession) {
    const ownerApplicantDetails = quoteSession.getNominatedOwner();
    const ownerExtraDetails =
      quoteSession.applicantExtraDetails[ownerApplicantDetails.id];
    const event: GtmViewWelcomeEvent = {
      event: 'join_step_view',
      event_info: this.createApplicantInfoStepEventInfo(
        'Welcome',
        quoteSession.applicantDetails
      ),
      user_info: this.createWelcomeUserInfo(
        ownerApplicantDetails,
        ownerExtraDetails
      ),
    };
    return event;
  }

  private createWelcomeUserInfo(
    applicantDetails: ApplicantDetails,
    extraDetails: ApplicantExtraDetails
  ) {
    const userInfo: GtmWelcomeUserInfo = {
      first_name: this.normaliseName(applicantDetails.firstName),
      last_name: this.normaliseName(extraDetails.surname),
      email: this.normaliseName(extraDetails.email),
      phone: this.normalisePhone(applicantDetails.phone),
      city: this.normaliseCity(extraDetails.addressLine3),
      postcode: this.normaliseName(extraDetails.addressLine4),
      dob: dateUtils.formatUiDateToGtm(extraDetails.dateOfBirth),
    };
    return userInfo;
  }

  // These normaliseXXX functions follow the conventions specified
  // by Meta ...
  // https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/customer-information-parameters/
  // and recommended by Datatribe who provided nib the
  // spec for this GTM implementation
  //
  private normaliseName(input: string) {
    if (!input) {
      return '';
    }
    return input.trim().toLowerCase();
  }

  private normaliseCity(input: string) {
    return this.normaliseName(input).replace(/\s+/g, ''); // Yes no spaces at all!
  }

  private normalisePhone(input: string) {
    if (!input) {
      return '';
    }
    if (input.startsWith('0')) {
      return `64${input.slice(1)}`; // Assume NZ
    } else {
      return input;
    }
  }

  private normaliseCurrency(value: number) {
    return Math.round(value * 100) / 100;
  }

  private getApplicantGender(
    applicant: ApplicantDetails
  ): GtmGender | undefined {
    switch (applicant.gender) {
      case 'Male':
        return 'm';
      case 'Female':
        return 'f';
      default:
        return undefined;
    }
  }

  private getAdditionalPeopleAges(allApplicants: ApplicantDetails[]): string {
    if (allApplicants.length < 2) {
      return '';
    }
    const ages = allApplicants.slice(1).map((a) => a.age);
    return ages.join();
  }

  private getAdditionalPeopleGenders(
    allApplicants: ApplicantDetails[]
  ): string {
    if (allApplicants.length < 2) {
      return '';
    }
    const genders = allApplicants
      .slice(1)
      .map((a) => this.getApplicantGender(a));
    return genders.join();
  }

  private getEffectiveCampaignCode(
    quoteSession: QuoteSession,
    getSpecialOfferResponse: SpecialOfferResponse,
    validateSpecialOfferResponse: ValidateSpecialOfferResponse
  ): string | undefined {
    if (!getSpecialOfferResponse.data) {
      return undefined;
    }
    const hasEligibleMembers = quoteSession.applicantDetails.some((a) =>
      isEligibleForSpecialOffer(a.id, validateSpecialOfferResponse)
    );
    return hasEligibleMembers
      ? getSpecialOfferResponse.data.campaignCode
      : undefined;
  }

  private createGtmEcommerceEvent(
    eventType: GtmEcommerceEventType,
    quoteSession: QuoteSession,
    priceData: PriceApiFetchPriceResponse,
    campaignCode: string | undefined,
    submissionReferenceId?: string
  ): GtmEcommerceEvent {
    const event: GtmEcommerceEvent = {
      event: eventType,
      ecommerce: this.getGtmEcommerce(
        quoteSession,
        priceData,
        campaignCode,
        submissionReferenceId
      ),
    };
    return event;
  }

  private getGtmEcommerce(
    quoteSession: QuoteSession,
    priceData: PriceApiFetchPriceResponse,
    campaignCode: string | undefined,
    submissionReferenceId?: string
  ): GtmEcommerce {
    const ecommerce: GtmEcommerce = {
      currency: 'NZD',
      tax: undefined,
      value: this.getGtmTotalWeeklyValue(quoteSession, priceData),
      coupon: campaignCode,
      promo: quoteSession.promoCode || undefined,
      resumed_quote_origin: this.getResumedFrom(),
      items: this.getGtmPurchaseItems(quoteSession, priceData),
    };
    if (submissionReferenceId) {
      ecommerce.transaction_id = submissionReferenceId;
    }
    return ecommerce;
  }

  private getResumedFrom() {
    // If we (ever) resumed from quote then we send the resumed quote origin
    // We do this, even if the user resumed from quote ... and then
    // closed the browser and then reopened it and resumed locally.
    // The idea is that we want to track how many joins actually went
    // through the send quote/resume quote path at some point.
    //
    const originatingBrand = getOriginatingBrand();
    switch (originatingBrand) {
      case 'DTCJoin':
        return 'nib';
      case 'AACentre':
        return 'AA Centre';
      case 'AAJoin':
        return 'AA';
      default:
        return undefined;
    }
  }

  private getGtmTotalWeeklyValue(
    quoteSession: QuoteSession,
    priceData: PriceApiFetchPriceResponse,
    applicantDetails?: ApplicantDetails
  ): number {
    let quoteSessionToPrice = quoteSession;
    if (
      quoteSessionToPrice.paymentDetails.frequency !== PaymentFrequency.Weekly
    ) {
      quoteSessionToPrice = clone(quoteSession);
      quoteSessionToPrice.paymentDetails.frequency = PaymentFrequency.Weekly;
    }

    let price: number;
    if (applicantDetails) {
      price = priceApiUtils.getApplicantTotalPrice(
        applicantDetails,
        quoteSessionToPrice,
        priceData
      );
    } else {
      price = priceApiUtils.getPolicyTotalPriceForQuoteSession(
        priceData,
        quoteSessionToPrice
      );
    }
    return this.normaliseCurrency(price);
  }

  private getGtmPurchaseItems(
    quoteSession: QuoteSession,
    priceData: PriceApiFetchPriceResponse
  ): GtmEcommerceItem[] {
    return quoteSession.applicantDetails.map((applicant) => {
      const policyDetails = quoteSession.memberPolicyDetails[applicant.id];
      return this.createGtmEcommerceItem(
        quoteSession,
        priceData,
        applicant,
        policyDetails
      );
    });
  }

  private createGtmEcommerceItem(
    quoteSession: QuoteSession,
    priceData: PriceApiFetchPriceResponse,
    applicantDetails: ApplicantDetails,
    policyDetails: PolicyDetails
  ): GtmEcommerceItem {
    const item: GtmEcommerceItem = {
      item_id: this.getItemId(policyDetails),
      item_name: this.getItemName(policyDetails),
      item_category: this.getItemCategory(policyDetails),
      person_age: applicantDetails.age,
      person_gender: this.getApplicantGender(applicantDetails)!,
      join_flow_id: this.getApplicationProcessId()!,
      non_pharmac_plus: this.getItemNonPharmacPlus(policyDetails),
      hospital_excess: this.getItemHospitalExcess(policyDetails),
      is_policy_owner: applicantDetails.isPolicyOwner,
      price: this.getGtmTotalWeeklyValue(
        quoteSession,
        priceData,
        applicantDetails
      ),
      quantity: 1,
    };
    return item;
  }

  private getItemId(policyDetails: PolicyDetails): string {
    const productConfigs = this.getSelectedProductConfigs(policyDetails);
    return productConfigs.map((pc) => pc.productDetails.code).join();
  }

  private getItemName(policyDetails: PolicyDetails): string {
    const productConfigs = this.getSelectedProductConfigs(policyDetails);
    return productConfigs.map((pc) => pc.productDetails.name).join(' + ');
  }

  private getItemCategory(policyDetails: PolicyDetails): string {
    const productConfigs = this.getSelectedProductConfigs(policyDetails);
    if (productConfigs.length === 0) {
      return '';
    }
    if (productConfigs.length === 1) {
      return `${productConfigs[0].productDetails.productType} only`;
    }
    return productConfigs
      .map((pc) => pc.productDetails.productType)
      .join(' + ');
  }

  private getItemHospitalExcess(
    policyDetails: PolicyDetails
  ): string | undefined {
    return policyDetails.hospitalProductCode ? policyDetails.excess : undefined;
  }

  private getItemNonPharmacPlus(
    policyDetails: PolicyDetails
  ): string | undefined {
    return policyDetails.hospitalProductCode
      ? policyDetails.nonPharmacPlus
      : undefined;
  }

  private getSelectedProductConfigs(
    policyDetails: PolicyDetails
  ): ProductConfig[] {
    const productConfigs: ProductConfig[] = [];
    if (policyDetails.hospitalProductCode) {
      productConfigs.push(getProductByCode(policyDetails.hospitalProductCode)!);
    }
    if (policyDetails.everydayProductCode) {
      productConfigs.push(getProductByCode(policyDetails.everydayProductCode)!);
    }
    return productConfigs;
  }

  private createRequestCallbackEvent(firstName: string, phone: string) {
    const event: GtmRequestCallbackEvent = {
      event: 'form_submit',
      event_info: {
        form_name: 'Join | request callback',
        join_flow_id: this.getApplicationProcessId()!,
      },
      user_info: {
        first_name: this.normaliseName(firstName),
        phone: this.normalisePhone(phone),
      },
    };
    return event;
  }

  private createSendQuoteEvent(
    firstName: string,
    phone: string,
    email: string
  ) {
    const event: GtmSendQuoteEvent = {
      event: 'form_submit',
      event_info: {
        form_name: 'Join | send quote',
        join_flow_id: this.getApplicationProcessId()!,
      },
      user_info: {
        first_name:
          firstName === '' ? undefined : this.normaliseName(firstName),
        phone: phone === '' ? undefined : this.normalisePhone(phone),
        email: this.normaliseName(email),
      },
    };
    return event;
  }

  private createAndPushJoinStepView(step_name: string) {
    const event: GtmEmptyStepView = {
      event: 'join_step_view',
      event_info: {
        step_name,
      },
    };
    this.pushEvent(event);
  }

  private createFormStartEvent(
    formName: GtmFormName,
    stepName: GtmStepName | undefined,
    personNumber: string | undefined
  ) {
    return this.createFormEvent('form_start', formName, stepName, personNumber);
  }

  private createFormSubmitEvent(
    formName: GtmFormName,
    stepName: GtmStepName | undefined,
    personNumber: string | undefined
  ) {
    return this.createFormEvent(
      'form_submit',
      formName,
      stepName,
      personNumber
    );
  }

  private createFormEvent(
    event: GtmFormEventType,
    formName: GtmFormName,
    stepName: GtmStepName | undefined,
    personNumber: string | undefined
  ) {
    const formEvent: GtmFormEvent = {
      event,
      event_info: {
        form_name: formName,
      },
    };
    if (stepName) {
      formEvent.event_info.step_name = stepName;
    }
    if (personNumber) {
      formEvent.event_info.person_number = personNumber;
    }
    return formEvent;
  }

  private createAndPushEcommerceEvent(
    eventType: GtmEcommerceEventType,
    quoteSession: QuoteSession,
    priceData: PriceApiFetchPriceResponse,
    getSpecialOfferResponse: SpecialOfferResponse,
    validateSpecialOfferResponse: ValidateSpecialOfferResponse
  ) {
    const effectiveCampaignCode = this.getEffectiveCampaignCode(
      quoteSession,
      getSpecialOfferResponse,
      validateSpecialOfferResponse
    );
    const event = this.createGtmEcommerceEvent(
      eventType,
      quoteSession,
      priceData,
      effectiveCampaignCode
    );
    // Send ecommerce event
    this.pushEcommerceEvent(event);
  }

  private pushEvent<T extends GtmBaseEvent<GtmEvent, any>>(event: T) {
    // Uncomment if needing to validate GTM datalayer pushes
    // console.log(`gtmUtils.pushEvent: `);
    // console.log(JSON.stringify(event, null, 2));
    TagManager.dataLayer({ dataLayer: event });
  }

  private pushEcommerceEvent(event: GtmEcommerceEvent) {
    // Uncomment if needing to validate GTM datalayer pushes
    // console.log(`gtmUtils.pushEcommerceEvent: `);
    // console.log(JSON.stringify(event, null, 2));

    // Datatribe spec says to always push an object with ecommerce: null before
    // sending a new ecommerce event
    //
    TagManager.dataLayer({
      dataLayer: {
        ecommerce: null,
      },
    });
    TagManager.dataLayer({ dataLayer: event });
  }

  private getApplicationProcessId(): string | undefined {
    const joinId = getJoinId();
    return joinId ? joinId : undefined;
  }
}

const gtmUtils = new GtmUtils();

export default gtmUtils;
