import { clone } from 'ramda';
import React from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Sticky from 'react-stickynode';
import 'src/utils/logUtils'; // Import early needed to avoid startup errors with redux store
import AppContainer from './components/AppContainer';
import ApplicantHeading from './components/ChooseYourCover/ApplicantHeading';
import ErrorBoundary from './components/ErrorBoundary';
import FormHeadingPanel, {
  FormHeadingPanelProps,
} from './components/heading/FormHeadingPanel';
import FormProgressBar from './components/navigation/FormProgressBar';
import FormRoute from './components/navigation/FormRoute';
import ResumeSessionModal from './components/ResumeSessionModal';
import ResumeSessionRedirector from './components/ResumeSessionRedirector';
import { ApplicantIdProvider } from './contexts/ApplicantIdProvider';
import { PriceProvider } from './contexts/PriceProvider';
import { QuoteSessionProvider } from './contexts/QuoteSessionProvider';
import initialiseHotjar from './hotjarInitialisation';
import AboutYouPage from './pages/AboutYouPage';
import AddApplicantPage from './pages/AddApplicantPage';
import BookACallPage from './pages/BookACallPage';
import BuyNowPage from './pages/BuyNowPage';
import ChooseYourCoverPage from './pages/ChooseYourCoverPage';
import ContentPage from './pages/ContentPage';
import EditApplicantPage from './pages/EditApplicantPage';
import ErrorPage from './pages/ErrorPage';
import QuoteSummaryPage from './pages/QuoteSummaryPage';
import RedirectPage from './pages/RedirectPage';
import ResumeQuotePage from './pages/ResumeQuotePage';
import WelcomePage from './pages/WelcomePage';
import WindcaveRedirectPage from './pages/WindcaveRedirectPage';
import { useSaveCart } from './store/hooks/useSaveCart';
import { FormPage } from './types/FormPage';
import { QuoteSession } from './types/QuoteSession';
import config from './utils/env';
import { getFormPageByIndex } from './utils/formPageUtils';
import {
  getQuoteSession,
  removeAllFromLocalStorage,
  saveQuoteSession,
} from './utils/localStorageUtils';
import { createQuoteSession } from './utils/quoteUtils';

const initializeQuoteSession = (): QuoteSession => {
  if (config.brand.hasAdviser) {
    // For AACentre ... we NEVER restore the quote session from local storage
    // because advisers should always start fresh for each new prospective buyer
    //
    return createQuoteSession();
  }
  // Read quote session from local storage.  If not found, create one
  //
  return getQuoteSession() || createQuoteSession();
};

const isPathEditCover = (path: string) => {
  return path.startsWith('/edit-cover/');
};

function App() {
  // initialising hotjar for user tracking
  React.useEffect(() => {
    initialiseHotjar();
  }, []);

  // The data gathered by the application form
  const [quoteSession, setQuoteSession] = React.useState<QuoteSession>(
    initializeQuoteSession
  );

  // Have we checked whether a quote should be loaded from local storage?
  const [quoteSessionInitialized, setQuoteSessionInitialized] =
    React.useState<boolean>(() => getQuoteSession() == null);
  const [quoteSavedBeforeEditCover, setQuoteSavedBeforeEditCover] =
    React.useState<QuoteSession | null>(null);
  const [editCoverCompleted, setEditCoverCompleted] = React.useState(false);

  const [resumedQuote, setResumedQuote] = React.useState<boolean>(false);

  const { search, pathname: currentPath } = useLocation();

  if (isPathEditCover(currentPath)) {
    if (!quoteSavedBeforeEditCover && !editCoverCompleted) {
      setQuoteSavedBeforeEditCover(clone(quoteSession));
    }
  } else {
    if (editCoverCompleted) {
      setEditCoverCompleted(false);
    }
    if (quoteSavedBeforeEditCover) {
      // User hit back button instead of cancel or continue from edit-cover
      setQuoteSession(quoteSavedBeforeEditCover);
      setQuoteSavedBeforeEditCover(null);
    }
  }

  const navigate = useNavigate();
  const saveCart = useSaveCart();

  const handleResumeSession = () => {
    setQuoteSessionInitialized(true);
  };

  // If we have a session ID from Windcave and it matches our quote session,
  // mark the quote session as initialised so we don't see the Resume modal;
  // we're continuing a previous session.
  const sessionId = new URLSearchParams(search).get('sessionId');
  if (
    !quoteSessionInitialized &&
    quoteSession &&
    sessionId &&
    quoteSession.paymentDetails.sessionId === sessionId
  ) {
    handleResumeSession();
  }

  const handleStartNewSession = () => {
    setQuoteSession(createQuoteSession());
    setQuoteSessionInitialized(true);
    removeAllFromLocalStorage();
    // Note - FormRoute will navigate the user to About You if this
    // means they no longer meet validation rules
  };

  // Save the quote session to application state, local storage, and update the cart
  const saveAndUpdateCart = (q: QuoteSession) => {
    setQuoteSession(q); // Update component state
    saveQuoteSession(q); // Save to local storage
    saveCart(q); // Save cart to the Join API
  };

  /**
   * Handle a submit, optionally with a new set of data to update into the
   * current quote.
   */
  const handleSubmit = (
    nextPage: FormPage | null,
    quoteSessionUpdate?: Partial<QuoteSession>
  ) => {
    // Find if we have new pages to add to the 'completed pages' list
    const completedPages = [...quoteSession.completedPages];
    // Skips adding to 'Completed pages' if we have sent null as the next page,
    // or if we are submitting to the About You page (FormPage.AboutYou === 0)
    if (nextPage) {
      const submittingPage = nextPage - 1;
      if (submittingPage >= 0 && !completedPages.includes(submittingPage)) {
        completedPages.push(submittingPage);
      }
    }
    // Merge old and new data
    const updatedQuote = new QuoteSession();
    Object.assign(updatedQuote, quoteSession);
    Object.assign(updatedQuote, quoteSessionUpdate);
    updatedQuote.completedPages = completedPages;
    updatedQuote.enforceRules();

    if (isPathEditCover(currentPath)) {
      // When we are on the edit-cover page and the user changes things ...
      // we update the QuoteSession in the React state ... but we don't persist
      // it to local storage unless/until they click "Confirm".
      // This avoids nasty situations where a once valid quote can be left in
      // an invalid state if the user starts to edit it but doesn't select products.
      //
      setQuoteSession(updatedQuote);
    } else {
      saveAndUpdateCart(updatedQuote);
    }
    handlePageChange(nextPage);
    // Note - if the current screen doesn't meet validation requirements, FormRoute
    // will kick the user back to the last page they're able to fill in, including
    // validation that is no longer met after enforceFormRules changes data.
    //
    // If there's a way to mess data up during the flow, the page suddenly changing
    // when you didn't ask it to is a surefire sign that a data change just had
    // unintended consequences, and the flow needs to be revisited!
  };

  const persistEditCoverChanges = (nextPage: FormPage) => {
    if (!quoteSavedBeforeEditCover) {
      throw new Error(
        'persistEditCoverChanges: called but there is no quoteSavedBeforeEditCover'
      );
    }
    setEditCoverCompleted(true);
    setQuoteSavedBeforeEditCover(null);
    saveAndUpdateCart(quoteSession);
    handlePageChange(nextPage);
  };

  const cancelEditCoverChanges = (
    nextPage: FormPage,
    quoteSession: QuoteSession
  ) => {
    if (!quoteSavedBeforeEditCover) {
      throw new Error(
        `cancelEditCoverChanges called but there is no quote saved`
      );
    }
    setQuoteSavedBeforeEditCover(null);
    saveAndUpdateCart(quoteSession);
    handlePageChange(nextPage);
  };

  const handlePageChange = (nextPage: FormPage | null) => {
    if (nextPage === null) {
      return;
    }
    const nextFormPage = getFormPageByIndex(nextPage);
    if (nextFormPage) {
      navigate(nextFormPage.path);
    }
  };

  // Marks a page as completed in the quote session, if needed.
  const setCompletedPage = (formPage: FormPage) => {
    if (quoteSession.completedPages.includes(formPage)) {
      return;
    }
    const updatedQuote = new QuoteSession();
    Object.assign(updatedQuote, quoteSession);
    updatedQuote.completedPages.push(formPage);

    saveAndUpdateCart(updatedQuote);
  };

  // Curry the header components to avoid repetition
  const ProgressBar = () => (
    <FormProgressBar
      onSelectFormPage={handlePageChange}
      quoteSession={quoteSession}
    />
  );
  const HeadingPanel = ({
    onAdd,
    quoteSession,
  }: Pick<FormHeadingPanelProps, 'onAdd'> & {
    quoteSession: QuoteSession;
  }) => (
    <FormHeadingPanel
      quoteSession={quoteSession}
      names={quoteSession.applicantDetails.map(
        (applicant) => applicant.firstName
      )}
      onAdd={onAdd}
      frequency={quoteSession.paymentDetails.frequency}
      setFrequency={(frequency) => {
        handleSubmit(null, {
          paymentDetails: {
            ...quoteSession.paymentDetails,
            frequency,
          },
        });
      }}
    />
  );

  const ResumeSession = () => {
    if (config.brand.hasAdviser) {
      return !quoteSessionInitialized ? <>{handleStartNewSession()}</> : null;
    } else {
      return !quoteSessionInitialized ? (
        <>
          <ResumeSessionRedirector quoteSession={quoteSession} />
          <ResumeSessionModal
            onResume={handleResumeSession}
            onStartAgain={handleStartNewSession}
          />
        </>
      ) : null;
    }
  };

  const handleErrorBoundaryRestart = () => {
    handleStartNewSession();
    handlePageChange(FormPage.AboutYou);
  };

  return (
    <>
      <ErrorBoundary startAgain={handleErrorBoundaryRestart}>
        <Routes>
          <Route
            path="/error"
            element={<ErrorPage isFromErrorBoundary={false} />}
          />
          <Route path="/" element={<AppContainer />}>
            <Route
              path={getFormPageByIndex(FormPage.AboutYou)!.path}
              element={
                <QuoteSessionProvider quoteSession={quoteSession}>
                  <FormRoute
                    quoteSession={quoteSession}
                    setCompletedPage={setCompletedPage}
                    formPage={FormPage.AboutYou}
                  >
                    <Sticky innerZ="2">
                      <ProgressBar />
                    </Sticky>
                    <AboutYouPage
                      quoteSession={quoteSession}
                      onSubmit={handleSubmit}
                    />
                  </FormRoute>
                  <ResumeSession />
                </QuoteSessionProvider>
              }
            />
            <Route
              path={getFormPageByIndex(FormPage.ChooseYourCover)!.path}
              element={
                <FormRoute
                  quoteSession={quoteSession}
                  setCompletedPage={setCompletedPage}
                  formPage={FormPage.ChooseYourCover}
                >
                  <QuoteSessionProvider quoteSession={quoteSession}>
                    <PriceProvider quoteSession={quoteSession}>
                      <Sticky innerZ="2">
                        <ProgressBar />
                        <HeadingPanel
                          quoteSession={quoteSession}
                          onAdd={() => {
                            navigate(
                              `/add-applicant/${FormPage.ChooseYourCover}`
                            );
                          }}
                        />
                      </Sticky>
                      <ChooseYourCoverPage
                        quoteSession={quoteSession}
                        onSubmit={handleSubmit}
                      />
                    </PriceProvider>
                  </QuoteSessionProvider>
                  <ResumeSession />
                </FormRoute>
              }
            />
            <Route
              path="/edit-cover/:returnTo/:applicantId"
              element={
                <FormRoute
                  quoteSession={quoteSession}
                  formPage={FormPage.ChooseYourCover}
                >
                  <ApplicantIdProvider>
                    <QuoteSessionProvider quoteSession={quoteSession}>
                      <PriceProvider quoteSession={quoteSession}>
                        <Sticky innerZ="2">
                          <ProgressBar />
                          <HeadingPanel quoteSession={quoteSession} />
                          <ApplicantHeading quoteSession={quoteSession} />
                        </Sticky>
                        <ChooseYourCoverPage
                          quoteSession={quoteSession}
                          cancelEditCoverChanges={cancelEditCoverChanges}
                          persistEditCoverChanges={persistEditCoverChanges}
                          onSubmit={handleSubmit}
                        />
                      </PriceProvider>
                    </QuoteSessionProvider>
                  </ApplicantIdProvider>
                  <ResumeSession />
                </FormRoute>
              }
            />
            <Route
              path={getFormPageByIndex(FormPage.TailorYourQuote)!.path}
              element={
                <FormRoute
                  quoteSession={quoteSession}
                  setCompletedPage={setCompletedPage}
                  formPage={FormPage.TailorYourQuote}
                >
                  <QuoteSessionProvider quoteSession={quoteSession}>
                    <PriceProvider quoteSession={quoteSession}>
                      <Sticky innerZ="2">
                        <ProgressBar />
                        <HeadingPanel
                          quoteSession={quoteSession}
                          onAdd={() => {
                            navigate(
                              `/add-applicant/${FormPage.TailorYourQuote}`
                            );
                          }}
                        />
                      </Sticky>
                      <QuoteSummaryPage
                        quoteSession={quoteSession}
                        onSubmit={handleSubmit}
                        resumedQuote={resumedQuote}
                      />
                    </PriceProvider>
                  </QuoteSessionProvider>
                  <ResumeSession />
                </FormRoute>
              }
            />
            <Route
              path={getFormPageByIndex(FormPage.BuyNow)!.path}
              element={
                <FormRoute
                  quoteSession={quoteSession}
                  setCompletedPage={setCompletedPage}
                  formPage={FormPage.BuyNow}
                >
                  <QuoteSessionProvider quoteSession={quoteSession}>
                    <PriceProvider quoteSession={quoteSession}>
                      <Sticky innerZ="2">
                        <ProgressBar />
                        <HeadingPanel quoteSession={quoteSession} />
                      </Sticky>
                      <BuyNowPage
                        quoteSession={quoteSession}
                        onSubmit={handleSubmit}
                      />
                    </PriceProvider>
                  </QuoteSessionProvider>
                  <ResumeSession />
                </FormRoute>
              }
            />
            <Route
              path="/add-applicant/:returnTo"
              element={
                <QuoteSessionProvider quoteSession={quoteSession}>
                  <AddApplicantPage
                    quoteSession={quoteSession}
                    onSubmit={handleSubmit}
                  />
                  <ResumeSession />
                </QuoteSessionProvider>
              }
            />
            <Route
              path="/edit-applicant/:returnTo/:applicantId"
              element={
                <QuoteSessionProvider quoteSession={quoteSession}>
                  <EditApplicantPage
                    quoteSession={quoteSession}
                    onSubmit={handleSubmit}
                  />
                  <ResumeSession />
                </QuoteSessionProvider>
              }
            />
            <Route
              path="/payment-redirect"
              element={<WindcaveRedirectPage />}
            />
            <Route
              path="/resume"
              element={
                <>
                  <ResumeQuotePage
                    onQuoteLoaded={(quoteSession: QuoteSession): void => {
                      setQuoteSession(quoteSession);
                      handleResumeSession();
                      setResumedQuote(true);
                      handlePageChange(FormPage.TailorYourQuote);
                    }}
                    onStartOverQuote={() => {
                      handleStartNewSession();
                      handlePageChange(FormPage.AboutYou);
                    }}
                  />
                </>
              }
            />
            <Route
              path="/book-a-call"
              element={
                <BookACallPage
                  onQuoteLoaded={(quoteSession: QuoteSession): void => {
                    setQuoteSession(quoteSession);
                    handleResumeSession();
                    setResumedQuote(true);
                    handlePageChange(FormPage.TailorYourQuote);
                  }}
                  onStartOverQuote={() => {
                    handleStartNewSession();
                    handlePageChange(FormPage.AboutYou);
                  }}
                />
              }
            />
            <Route
              path="/welcome"
              element={
                <WelcomePage
                  onLoad={() => setQuoteSession(createQuoteSession())}
                />
              }
            />
            <Route index element={<RedirectPage />} />
            <Route
              path="*"
              element={
                <QuoteSessionProvider quoteSession={quoteSession}>
                  <ContentPage />
                </QuoteSessionProvider>
              }
            />
          </Route>
        </Routes>
      </ErrorBoundary>
    </>
  );
}

export default App;
