import { PrimaryButton } from '@nib-components/button';
import { BaseButtonProps } from '@nib-components/button/dist/@types/BaseButton';
import { ThemeSchema } from '@nib-components/theme';
import { PhoneSystemIcon } from '@nib/icons';
import { breakpoint } from '@nib/layout';
import React from 'react';
import config from 'src/utils/env';
import styled, { useTheme } from 'styled-components';
import NeedAdviceModal from './NeedAdviceModal';

const FloatingButton = styled(PrimaryButton)`
  &.fixedFloating {
    position: fixed;
    box-shadow: rgba(0, 0, 0, 0.25) 0 0 8px 3px;

    // On mobile, a round button
    height: 90px;
    width: 90px;
    border-radius: 50%;
    z-index: 1;

    bottom: 12px;
    right: 16px;

    //added to stop button letters from breaking
    ${breakpoint('xs', 'sm')`
    div {
      margin-left: ${config.brand.hasMemberProducts ? '-0.4rem' : '-0.6rem'};
    }
    span {
      display: block;
      width: 70px;
    }
    svg {
      display:none;
    }
    `}

    ${breakpoint('sm')`
      right: 32px;
      height: initial;
      width: initial;
      border-radius: 100px;
    `};
    ${breakpoint('md')`
      bottom: 176px;
    `}
    ${breakpoint('xl')`
      bottom: 101px;
    `}
  }
`;

const useIcon = (className: string) => {
  const theme = useTheme() as ThemeSchema;
  const isMobile = window.innerWidth < theme.breakpoints.md;
  // Don't use the phone icon when we are showing the round floating
  // button on mobile.
  // Note that we don't use a window resize listener here ... so if the
  // user resizes the browser into or out of mobile range, we won't
  // detect that without a refresh.  However, hardly seems worth doing
  // in this case (would need to debounce etc).  Worst case, the user might
  // see the button with/without the icon when it should be otherwise.
  //
  if (className === 'fixedFloating' && isMobile) {
    return null;
  }
  return PhoneSystemIcon;
};

/**
 * A button that displays the 'Need Advice' modal.
 */
const NeedAdviceButton = (
  props: Omit<BaseButtonProps, 'color' | 'children'>
) => {
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const icon = useIcon(props.className as string);

  if (config.brand.hasAdviser) {
    return <></>;
  } else {
    return (
      <>
        <FloatingButton
          {...props}
          icon={icon}
          iconPlacement="left"
          onClick={() => setShowModal(true)}
          type="button"
        >
          {config.brand.content.needAdvice.buttonText}
        </FloatingButton>
        {showModal && <NeedAdviceModal onClose={() => setShowModal(false)} />}
      </>
    );
  }
};

export default NeedAdviceButton;
