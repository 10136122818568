import { SecondaryButton } from '@nib-components/button';
import Copy from '@nib-components/copy';
import { FormControl } from '@nib-components/form-control';
import Textbox from '@nib-components/textbox';
import { colorLightest, formHelpTextColor } from '@nib-components/theme';
import { LocationSystemIcon } from '@nib/icons';
import { Box, Stack } from '@nib/layout';
import Loader from '@nib/loader';
import { getIn, useFormikContext } from 'formik';
import React from 'react';
import { useAutocomplete } from 'src/hooks/useAutocomplete';
import {
  useGetTokenQuery,
  useLazyGetAddressDetailsQuery,
  useSearchAddressQuery,
} from 'src/services/addressFinder/addressFinderApi';
import styled from 'styled-components';
import ManualAddressInput from './ManualAddressInput';

// Make search items with click handlers styled with correct cursor
// and hover effects
const ClickableSearchItem = styled('div')`
  cursor: pointer;
  &:hover {
    background-color: ${colorLightest};
  }
`;
const SearchItem = ({
  children,
  onClick,
}: React.PropsWithChildren<{ onClick?: () => void }>) => {
  const SearchItemElement = onClick ? ClickableSearchItem : Box;
  return (
    <SearchItemElement onClick={onClick}>
      <Box paddingHorizontal={4} paddingVertical={2}>
        {children}
      </Box>
    </SearchItemElement>
  );
};

// Makes the options appear like a dropdown list, and sets the max
// width so they look good at all screen sizes.
const SearchItemContainer = styled('div')`
  top: 6rem;
  position: absolute;
  width: 100%;
  z-index: 1;
`;
const SearchItemPositioner = styled('div')`
  position: relative;
`;

// Debounce hook used to only query NZ Post Address Finder API after a period of inactivity
const ADDRESS_SEARCH_DEBOUNCE_MS = 400;
function useDebounce(value: string): string {
  const [debouncedValue, setDebouncedValue] = React.useState<string>(value);
  React.useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, ADDRESS_SEARCH_DEBOUNCE_MS);

    return () => {
      clearTimeout(handler);
    };
  }, [value]);

  return debouncedValue;
}

export type FormAddressSearchProps = {
  names: {
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    addressLine4: string;
    addressLine5: string;
  };
  labels: {
    search: string;
    manualButton: string;
    searchButton: string;
    notFoundMessage: string;
  };
  formMode?: 'light' | 'white';
};

const FormAddressSearch = (props: FormAddressSearchProps) => {
  const { names, labels, formMode = 'white' } = props;
  const { setFieldValue, setFieldTouched, values, errors, touched } =
    useFormikContext();

  // Local state of address search box
  const [value, setValue] = React.useState<string>('');
  const debouncedValue = useDebounce(value.trim());
  const [isManualEntry, setIsManualEntry] = React.useState<boolean>(false);
  const [resultsVisible, setResultsVisible] = React.useState<boolean>(false);
  const [selectedAddress, setSelectedAddress] = React.useState<string>();
  const [searchBoxTouched, setSearchBoxTouched] =
    React.useState<boolean>(false);

  // API calls
  const { data: token } = useGetTokenQuery();
  const {
    data: addressData,
    isFetching,
    isError,
  } = useSearchAddressQuery(
    { q: debouncedValue, token: token ?? '' },
    {
      skip: !debouncedValue.length || !token || value === selectedAddress,
    }
  );
  const [getAddressDetails, { isFetching: isFetchingDetails }] =
    useLazyGetAddressDetailsQuery();

  // When we change the search term, show the results box
  React.useEffect(() => {
    if (debouncedValue === '') {
      return;
    }
    setResultsVisible(true);
  }, [debouncedValue]);

  // If we get an API error, hide the results box
  React.useEffect(() => {
    if (!isError) {
      return;
    }
    setResultsVisible(false);
  }, [isError]);

  // If the results are visible, clicking outside of the results box closes the results
  React.useEffect(() => {
    if (!resultsVisible) {
      return;
    }
    const listener = () => {
      setResultsVisible(false);
    };
    window.addEventListener('click', listener);
    return () => {
      window.removeEventListener('click', listener);
    };
  }, [resultsVisible]);

  // Find the error from the first address field that has one, or undefined
  const searchBoxErrorField = Object.values(names).find((name) =>
    getIn(errors, name)
  );
  const searchBoxError = searchBoxErrorField
    ? getIn(errors, searchBoxErrorField)
    : undefined;
  // If all parent fields are valid, the search box is also valid
  const isSearchBoxValid = !searchBoxError;
  // If any all child fields are touched, the parent field is touched
  const isSearchBoxTouched =
    searchBoxTouched ||
    Object.values(names).every((name) => getIn(touched, name));

  // If we select an address, set it as the new value of the search box
  React.useEffect(() => {
    if (selectedAddress) {
      setValue(selectedAddress);
    }
  }, [selectedAddress]);

  // If all address fields are filled in, show
  // this address as the selected address in 'Search' mode
  // above the text box
  const searchBoxHelp = isSearchBoxValid
    ? Object.values(names)
        .map((name) => getIn(values, name))
        // Do not comma separate empty values
        .filter((name) => !!name.trim())
        .join(', ')
    : '';

  const autoComplete = useAutocomplete();

  return (
    <Stack space={5}>
      {isManualEntry ? (
        <ManualAddressInput names={names} formMode={formMode} />
      ) : (
        <SearchItemPositioner>
          <FormControl
            label={labels.search}
            formMode={formMode}
            error={searchBoxError}
            valid={isSearchBoxValid}
            validated={isSearchBoxTouched && !isFetching && !isFetchingDetails}
            // Turn off Chrome autofill, as it appears on top of the search results
            autoComplete={autoComplete}
          >
            <Textbox
              value={value}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setValue(e.target.value);
              }}
              onBlur={() => setSearchBoxTouched(true)}
              onFocus={() => {
                // Blank out search entry box to avoid users changing the address
                // search term without choosing an option and thinking they have updating
                // their selected address, since their previously selected address will
                // still pass validation.
                setResultsVisible(false);
                setValue('');
              }}
              maxLength={255}
              data-testid="address-search"
            />
          </FormControl>
          {/* We don't want to show results if we're displaying the selected address text */}
          {resultsVisible && value !== selectedAddress && (
            <SearchItemContainer>
              <Box
                background="white"
                boxShadow="standard"
                data-testid="address-search-results"
              >
                <Stack>
                  {isFetching ? (
                    <SearchItem>
                      <Loader size="xs" />
                    </SearchItem>
                  ) : (
                    <>
                      {addressData?.addresses?.map((address) => (
                        <SearchItem
                          key={address.DPID}
                          onClick={() => {
                            // When we select an item, put it into the search box, fetch its details
                            // and set them as our form's address values.
                            // Hide the results box.
                            setSelectedAddress(address.FullAddress);
                            getAddressDetails({
                              dpid: address.DPID,
                              token: token ?? '',
                            })
                              .unwrap()
                              .then((addressDetailsData) => {
                                const addressDetails =
                                  addressDetailsData.details.find(
                                    (a) => a.DPID === address.DPID
                                  );
                                if (addressDetails) {
                                  setFieldValue(
                                    names.addressLine1,
                                    addressDetails.AddressLine1
                                  );
                                  if (addressDetails.RuralDelivery) {
                                    setFieldValue(
                                      names.addressLine2,
                                      addressDetails.RuralDelivery
                                    );
                                    setFieldValue(
                                      names.addressLine3,
                                      addressDetails.MailTown ||
                                        addressDetails.CityTown
                                    );
                                  } else {
                                    const addressLine2Value =
                                      addressDetails.BoxBagType
                                        ? addressDetails.AddressLine2
                                        : addressDetails.Suburb;

                                    setFieldValue(
                                      names.addressLine2,
                                      addressLine2Value
                                    );
                                    setFieldValue(
                                      names.addressLine3,
                                      addressDetails.CityTown
                                    );
                                  }
                                  setFieldValue(
                                    names.addressLine4,
                                    addressDetails.Postcode
                                  );
                                  setFieldValue(
                                    names.addressLine5,
                                    'New Zealand'
                                  );
                                  // Touch all fields to display validation
                                  // on the search box
                                  Object.values(names).forEach((name) =>
                                    setFieldTouched(name, true)
                                  );
                                }
                              });
                            setResultsVisible(false);
                          }}
                        >
                          <Box paddingHorizontal={4} paddingVertical={2}>
                            <Copy>{address.FullAddress}</Copy>
                          </Box>
                        </SearchItem>
                      ))}
                      {!addressData || addressData?.addresses?.length === 0 ? (
                        <SearchItem>
                          <Copy>{labels.notFoundMessage}</Copy>
                        </SearchItem>
                      ) : null}
                    </>
                  )}
                </Stack>
              </Box>
            </SearchItemContainer>
          )}
          {/* 
            We show help text under the field instead of above using FormControl's `help`
            attribute so that our results box always displays in the correct position.
          */}
          {(searchBoxHelp || isFetchingDetails) && (
            <Box marginTop={2}>
              {isFetchingDetails ? (
                <Loader size="xs" />
              ) : (
                <Copy small color={formHelpTextColor}>
                  {searchBoxHelp}
                </Copy>
              )}
            </Box>
          )}
        </SearchItemPositioner>
      )}
      <SecondaryButton
        type="button"
        size="small"
        icon={LocationSystemIcon}
        iconPlacement="left"
        onClick={() => setIsManualEntry(!isManualEntry)}
      >
        {isManualEntry ? labels.searchButton : labels.manualButton}
      </SecondaryButton>
    </Stack>
  );
};

export default FormAddressSearch;
