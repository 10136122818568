import { NavigateFunction, useLocation, useNavigate } from 'react-router';
import { Location } from 'react-router-dom';
import { ValidateSpecialOfferResponse } from 'src/services/join/contentfulApiTypes';
import { joinApi } from 'src/services/join/joinApi';
import { priceApi } from 'src/services/price/priceApi';
import { PriceApiFetchPriceResponse } from 'src/services/price/priceApiTypes';
import { useSubmitJoinMutation } from 'src/services/submission/submissionApi';
import submissionApiUtils from 'src/services/submission/submissionApiUtils';
import { useAppDispatch } from 'src/store/hooks';
import { usePriceData } from 'src/store/hooks/usePriceData';
import { AppDispatch } from 'src/store/store';
import { QuoteSession } from 'src/types/QuoteSession';
import { generateCorrelationId } from 'src/utils/correlationUtils';
import gtmUtils from 'src/utils/gtmUtils';
import {
  createValidateSpecialOfferRequest,
  isEligibleForSpecialOffer,
} from 'src/utils/joinApiUtils';
import { getJoinId } from 'src/utils/localStorageUtils';
import { logError } from 'src/utils/logUtils';

const getPriceData = async (
  dispatch: AppDispatch,
  navigate: NavigateFunction,
  location: Location,
  priceDataPromise: Promise<PriceApiFetchPriceResponse>
) => {
  try {
    return await priceDataPromise;
  } catch (error) {
    // Reset the API state so that we try again next time we render this component
    // In practice, it may be impossible to actually end up here in this catch block.
    // If we are really having trouble accessing the pricing API, then we will get
    // bounced from the Buy Now page to the error page before we even get a chance
    // to attempt to submit the join.  On the other hand, if we
    // successfully accessed the price API on the Buy Now page ... either the first
    // time ... or after pressing Retry from the error page ... then the result will
    // be in the RTK Query cache so this query here should always succeed.
    //
    dispatch(priceApi.util.resetApiState());
    console.warn('Failed to get price data ... navigating to the error page');
    navigate(`/error?returnURL=${location.pathname}`);
  }
};

const getCampaignCode = async (
  dispatch: AppDispatch,
  quoteSession: QuoteSession
): Promise<string | null> => {
  const activeCampaignCode = await getActiveCampaignCode(dispatch);
  if (!activeCampaignCode) {
    return null;
  }
  const validateResponse: ValidateSpecialOfferResponse | null =
    await getValidateSpecialOfferResponse(dispatch, quoteSession);
  if (!validateResponse) {
    return null;
  }
  const hasEligibleMembers = quoteSession.applicantDetails.some((a) =>
    isEligibleForSpecialOffer(a.id, validateResponse)
  );
  return hasEligibleMembers ? activeCampaignCode : null;
};

const getActiveCampaignCode = async (dispatch: AppDispatch) => {
  try {
    const data = await dispatch(
      joinApi.endpoints.getSpecialOffer.initiate()
    ).unwrap();
    return data.data?.campaignCode || null;
  } catch (error) {
    logError({
      message:
        'Submitting join but unable to retrieve current special offer so may need to be added manually in BOA',
      correlationId: generateCorrelationId(),
      data: {
        joinId: getJoinId(),
        error: (error as any)?.error,
      },
    });
    return null;
  }
};

const getValidateSpecialOfferResponse = async (
  dispatch: AppDispatch,
  quoteSession: QuoteSession
) => {
  try {
    const data = await dispatch(
      joinApi.endpoints.validateSpecialOffer.initiate(
        createValidateSpecialOfferRequest(quoteSession)
      )
    ).unwrap();
    return data || null;
  } catch (error) {
    logError({
      message:
        'Submitting join but unable to validate special offer eligibility so may need to be added manually in BOA',
      correlationId: generateCorrelationId(),
      data: {
        joinId: getJoinId(),
        error: (error as any)?.error,
      },
    });
    return null;
  }
};

export function useSubmitJoin() {
  const [createJoinSession] = useSubmitJoinMutation();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const getPriceApiData = usePriceData();

  const submitJoin = async (quoteSession: QuoteSession) => {
    const priceDataPromise = getPriceApiData(quoteSession);
    const priceData = await getPriceData(
      dispatch,
      navigate,
      location,
      priceDataPromise
    );
    const campaignCode = await getCampaignCode(dispatch, quoteSession);

    return createJoinSession(
      submissionApiUtils.createSubmissionOptions(
        quoteSession,
        priceData!,
        campaignCode
      )
    )
      .unwrap()
      .then((response) => {
        gtmUtils.viewWelcome(quoteSession);
        const submissionReferenceId = response.data;
        gtmUtils.purchase(
          quoteSession,
          priceData!,
          campaignCode || undefined,
          submissionReferenceId
        );
      });
  };

  return submitJoin;
}
