import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { QueryLifecycleApi } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { generateCorrelationId } from 'src/utils/correlationUtils';
import { logError, logInfo } from 'src/utils/logUtils';
import {
  ContentfulGetEntryRequest,
  ContentfulGetEntryResponse,
} from './contentfulApiTypes';

export const onContentfulGetEntryQueryStarted = async (
  arg: ContentfulGetEntryRequest,
  api: QueryLifecycleApi<
    ContentfulGetEntryRequest,
    BaseQueryFn<ContentfulGetEntryRequest, ContentfulGetEntryResponse>,
    ContentfulGetEntryResponse,
    'contentfulApi'
  >
) => {
  const { id } = arg;
  const { queryFulfilled } = api;

  const data = {
    entryId: id,
  };
  const correlationId = generateCorrelationId();

  logInfo({
    message: 'sending Contentful.GetEntry',
    data,
    correlationId,
  });

  try {
    await queryFulfilled;
    logInfo({
      message: 'Contentful.GetEntry succeeded',
      data,
      correlationId,
    });
  } catch (err) {
    logError({
      message: 'Contentful.GetEntry failed',
      data: { ...data, error: (err as any)?.error },
      correlationId,
    });
  }
};
