import Theme from '@nib-components/theme';
import * as DOMPurify from 'dompurify';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './store/store';
import config from './utils/env';

// Sets the point that scrollIntoView() treats as the top of the page.
// THis prevents things being scrolled underneath the floating header.
import './style.css';

// Certain known console errors are temporarily suppressed when running in dev
// mode (so we can see potential other errors).
// If you want to see all of the errors in dev mode ... comment out the line below.
import { filterConsoleErrors } from './utils/consoleUtils';

// Include Google Tag Manager if needed
require('./components/tracking/GoogleTagManager/GtmInit');

DOMPurify.addHook('afterSanitizeAttributes', function (node) {
  // Make links open in a separate tab ... except for tel: links
  if (node instanceof HTMLAnchorElement && node.protocol !== 'tel:') {
    node.setAttribute('target', '_blank');
    node.setAttribute('rel', 'noopener noreferrer');
    //Open link in new tab
    //since DOMPurify sanitize will remove target attribute
    //add rel attribute to prevent XSS attack
  }
});

// Suppress some known console errors so we can see any new ones in dev mode
filterConsoleErrors();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter basename={config.brand.baseUrl}>
        <Theme theme={config.brand.theme} mode={{ form: 'light' }}>
          <App />
        </Theme>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
