import Alert from '@nib-components/alert';
import { Box } from '@nib/layout';
import { PaymentFrequency } from 'src/types/QuoteSession';
import config from 'src/utils/env';
import { formatCurrency } from 'src/utils/formatters/formatCurrency';
import MarkdownContent from '../MarkdownContent';

interface PriceInfoBoxProps {
  fromAmount: number;
  fromFrequency: PaymentFrequency;
  amount: number;
  frequency: PaymentFrequency;
}

/**
 * Displays an info box if changes in the Your Details step changed pricing
 */
const PriceInfoBox = (props: PriceInfoBoxProps) => {
  const { fromAmount, fromFrequency, amount, frequency } = props;
  // Replace tokens
  let content = config.brand.content.buyNow.step1.pricingUpdatedMessage;
  content = content.replace(/{fromAmount}/g, formatCurrency(fromAmount));
  content = content.replace(/{fromFrequency}/g, fromFrequency);
  content = content.replace(/{amount}/g, formatCurrency(amount));
  content = content.replace(/{frequency}/g, frequency);
  return (
    <Box marginTop={6}>
      <Alert type="info" variation="soft" role="alert">
        <MarkdownContent content={content} />
      </Alert>
    </Box>
  );
};

export default PriceInfoBox;
