import Alert from '@nib-components/alert';
import {
  typeValues,
  variationValues,
} from '@nib-components/alert/dist/@types/types';
import styled from 'styled-components';
import MarkdownContent, { MarkdownContentProps } from './MarkdownContent';

const StyledAlert = styled(Alert)`
  p {
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

type MarkdownAlertProps = MarkdownContentProps & {
  type: typeValues;
  title?: string;
  variation?: variationValues;
  fullWidth?: boolean;
};

/**
 * A Mesh Alert that can contain markdown styled appropriately.
 */
const MarkdownAlert = (props: MarkdownAlertProps) => {
  const { title, type, variation, fullWidth, ...markdownProps } = props;
  return (
    <StyledAlert
      title={title}
      type={type}
      variation={variation}
      fullWidth={fullWidth}
      role="alert"
    >
      <MarkdownContent {...markdownProps} />
    </StyledAlert>
  );
};

export default MarkdownAlert;
