import { FormControl } from '@nib-components/form-control';
import { FormControlProps } from '@nib-components/form-control/dist/@types/FormControl';
import Textbox from '@nib-components/textbox';
import { Box } from '@nib/layout';
import { FieldHookConfig, useField, useFormikContext } from 'formik';
import { clone } from 'ramda';
import React from 'react';
import { useAutocomplete } from 'src/hooks/useAutocomplete';

export type FormTextboxProps = Omit<FormControlProps, 'children'> &
  FieldHookConfig<string> & {
    isOptional?: boolean;
    label: string;
    help?: string;
    maxLength?: number;
    /** Strips all whitespace from the string onBlur. Leading and trailing whitespace is always removed. */
    stripWhitespace?: boolean;
    /** Note that autocomplete is disabled by default, and is also disabled if the brand has advisers. */
    autoComplete?: string;
  };

const FormTextbox = ({
  isOptional,
  label,
  name,
  help,
  maxLength,
  stripWhitespace = false,
  autoComplete,
  ...otherProps
}: FormTextboxProps) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField<string>({ name });
  // Note: onChange is not used, we implement our own below.
  const {
    onBlur,
    onChange: _onChange,
    value: formikValue,
    ...fieldProps
  } = field;

  // Keep field state locally, and only allow onBlur to manage the top-level Formik state.
  // This cuts down on re-renders, and allows user to type into a field without
  // it affecting other fields or validation state immediately, which can be jarring.
  const [value, setValue] = React.useState<string>(formikValue);

  // Update local value with any changes to formik value.
  // Happens when removing people from About You screen.
  React.useEffect(() => {
    setValue(formikValue);
  }, [setValue, formikValue]);

  const autoCompleteValue = useAutocomplete(autoComplete);

  return (
    <Box>
      <FormControl
        id={field.name}
        name={field.name}
        label={label}
        help={help}
        valid={meta.error === undefined}
        validated={meta.touched}
        error={meta.error}
        isEmptyAndOptional={isOptional && !value}
        autoComplete={autoCompleteValue}
        {...otherProps}
      >
        <Textbox
          value={value}
          // On change, set the field value into local state
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setValue(e.target.value);
          }}
          // On blur, set the form value into Formik
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            const newEvent = clone(e);
            const originalValue = e.target.value;
            // If Indicated, remove all whitespace
            if (stripWhitespace) {
              newEvent.target.value = newEvent.target.value.replace(/\s/g, '');
            } else {
              // Otherwise, just remove leading and trailing whitespace
              newEvent.target.value = newEvent.target.value.trim();
            }
            if (newEvent.target.value !== originalValue) {
              setValue(newEvent.target.value);
            }
            onBlur(newEvent);
            setFieldValue(field.name, newEvent.target.value, true);
          }}
          // If we pressed enter, emit a field change event so that
          // Formik updates the field's value before the form element's
          // submit event.  If we do not do this, the form will submit
          // with the previous value of the field instead of the new value
          // the user just typed.
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter' || e.keyCode === 13) {
              const newEvent = clone(e);
              const originalValue = e.currentTarget.value;
              // If Indicated, remove all whitespace
              if (stripWhitespace) {
                newEvent.currentTarget.value =
                  newEvent.currentTarget.value.replace(/\s/g, '');
              } else {
                // Otherwise, just remove leading and trailing whitespace
                newEvent.currentTarget.value =
                  newEvent.currentTarget.value.trim();
              }
              if (newEvent.currentTarget.value !== originalValue) {
                setValue(newEvent.currentTarget.value);
              }
              setFieldValue(field.name, newEvent.currentTarget.value, true);
            }
          }}
          maxLength={maxLength}
          {...fieldProps}
        />
      </FormControl>
    </Box>
  );
};

export default FormTextbox;
