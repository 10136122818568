import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const RedirectPage: React.FC = () => {
  const navigate = useNavigate();
  useEffect((): void => {
    navigate('/about-you', { replace: true });
  });
  return <div />;
};

export default RedirectPage;
