import Accordion from '@nib-components/accordion';
import RichText from '../RichText';
import { ContentfulAccordionItemProps } from '../types';

const AccordionItem = (props: ContentfulAccordionItemProps) => {
  return (
    <Accordion.Item title={props.fields.title}>
      <RichText document={props.fields.content} />
    </Accordion.Item>
  );
};

export default AccordionItem;
