import Divider from '@nib-components/divider';
import { ErrorMessage, ValidationIcon } from '@nib-components/form-control';
import { Box, Column, Columns, Container, Inline, Stack } from '@nib/layout';
import { Formik } from 'formik';
import { clone } from 'ramda';
import { useContext, useEffect, useState } from 'react';
import ChooseExcessStep from 'src/components/ChooseYourCover/ChooseExcessStep';
import ChooseNonPharmacPlusStep from 'src/components/ChooseYourCover/ChooseNonPharmacPlusStep';
import ChoosePlanOptionsStep from 'src/components/ChooseYourCover/ChoosePlanOptionsStep';
import ChoosePlanStep from 'src/components/ChooseYourCover/ChoosePlanStep';
import EmailQuoteButton from 'src/components/EmailQuote/EmailQuoteButton';
import CustomisedForm from 'src/components/form/CustomisedForm';
import StepHeading from 'src/components/heading/StepHeading';
import TitlePanel from 'src/components/heading/TitlePanel';
import HelmetComponent from 'src/components/HelmetComponent/HelmetComponent';
import BottomButtons from 'src/components/navigation/BottomButtons';
import NeedAdviceButton from 'src/components/NeedAdvice/NeedAdviceButton';
import { ApplicantIdContext } from 'src/contexts/ApplicantIdContext';
import { FormPage } from 'src/types/FormPage';
import { FormPageProps } from 'src/types/FormPageProps';
import { ProductOption, ProductType } from 'src/types/ProductConfig';
import { QuoteSession } from 'src/types/QuoteSession';
import config from 'src/utils/env';
import gtmUtils from 'src/utils/gtmUtils';
import ChooseYourCoverSchema from 'src/utils/validation/schema/ChooseYourCoverSchema';
import styled from 'styled-components';
import Banner from '../components/Banner';

const ZeroFontSizeColumn = styled(Column)`
  font-size: 0;
`;

type ChooseYourCoverPageProps = FormPageProps & {
  returnTo?: FormPage;
  persistEditCoverChanges?: (nextPage: FormPage) => void;
  cancelEditCoverChanges?: (
    nextPage: FormPage,
    quoteSession: QuoteSession
  ) => void;
};

const ChooseYourCoverPage = (props: ChooseYourCoverPageProps) => {
  const {
    onSubmit,
    persistEditCoverChanges,
    cancelEditCoverChanges,
    returnTo = FormPage.TailorYourQuote,
  } = props;
  const quoteSession = clone(props.quoteSession);
  const { applicantDetails, paymentDetails } = quoteSession;
  const { applicantId } = useContext(ApplicantIdContext);
  // If no applicant is specified, will return the "default" policy details
  // from the policy owner.
  const policyDetails = quoteSession.getApplicantPolicyDetails(applicantId);
  const [originalPolicyDetails] = useState(clone(policyDetails));
  const [originalFrequency] = useState(quoteSession.paymentDetails.frequency);

  // If we HAVE to set cover for a single applicant we're editing cover for,
  // we need to remove this applicant before we cancel.
  // We set this into state using the original quote session on render, to avoid
  // the Cancel button popping back into existence when:
  // * There is only one applicant, and they have hospital cover
  // * The user edits them to be >75
  // * The system shows this screen, the cancel button is hidden
  // * The user selects new cover for the applicant
  const [mustTailorQuote] = useState(
    !!applicantId &&
      quoteSession.mustTailorQuote(
        applicantDetails.find((a) => a.id === applicantId)!,
        false
      )
  );

  useEffect(() => {
    if (applicantId) {
      gtmUtils.startEditCover(applicantId, quoteSession);
    } else {
      gtmUtils.viewChooseYourCover(quoteSession.applicantDetails);
    }
    // Only push to GTM on first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Banner />
      <HelmetComponent
        content={
          applicantId
            ? config.brand.content.chooseYourCover.helmetIndividual
            : config.brand.content.chooseYourCover.helmet
        }
      />
      {!applicantId && (
        <TitlePanel
          title={config.brand.content.chooseYourCover.title}
          subTitle={
            applicantDetails.length > 1
              ? config.brand.content.chooseYourCover.subTitleMultiplePeople
              : config.brand.content.chooseYourCover.subTitle
          }
          showSpecialOffer={true}
          currentJourney={FormPage.ChooseYourCover}
        />
      )}

      <Formik
        enableReinitialize={true}
        initialValues={{
          policyDetails,
        }}
        onSubmit={() => {
          if (applicantId) {
            gtmUtils.submitEditCover(applicantId, quoteSession);
            // If we were editing an individual applicant (edit cover from tailor your quote screen)
            // then any changes that have been made have updated the local state but not
            // been persisted to local storage.  If we reach this onSubmit handler then
            // the user has clicked "Continue" and we need to persist the changes
            //
            persistEditCoverChanges!(returnTo);
          } else {
            onSubmit(returnTo, quoteSession);
          }
        }}
        validationSchema={ChooseYourCoverSchema}
        validateOnMount={true}
      >
        {({
          values,
          errors,
          touched,
          handleReset,
          handleSubmit,
          validateForm,
        }) => {
          // Find which steps have their form controls visible. Titles are always visible.
          const step1 = true;
          const step2 = step1 && !errors.policyDetails?.productTypes;
          const step3 =
            step2 &&
            !errors.policyDetails?.hospitalProductCode &&
            !errors.policyDetails?.everydayProductCode;
          const step4 = step3 && !errors.policyDetails?.excess;
          // Steps 3 & 4 have their titles hidden too if the user hasn't selected a hospital type product.
          const hasHospital =
            values.policyDetails.productTypes.includes('Hospital');
          // Find the excesses and non-PHARMAC Plus options; from a product that is selected, and has 'hospital' type
          const hospitalOption = config.brand.productConfig.find(
            (pc) =>
              pc.productDetails.code ===
              values.policyDetails.hospitalProductCode
          );
          const nonPharmacPlusOptions: ProductOption[] =
            hospitalOption?.nonPharmacPlusOptions ?? [];

          // The first two steps don't have rendered form fields that can display error messages,
          // so we display them manually.
          const showStep1Error =
            errors.policyDetails?.productTypes &&
            touched.policyDetails?.productTypes;
          // There are no fields for hospitalProductCode / everydayProductCode, so we use touched
          // status from the productTypes field instead.
          const showStep2Error =
            touched.policyDetails?.productTypes &&
            (errors.policyDetails?.everydayProductCode ||
              errors.policyDetails?.hospitalProductCode);

          return (
            <CustomisedForm
              id="chooseYourCover"
              name="chooseYourCover"
              formMode="light"
              containerWidth="100%"
              spaceChildren={false}
              onReset={handleReset}
              onSubmit={handleSubmit}
            >
              <Container>
                <Stack space={{ xs: 5, md: 6 }}>
                  <StepHeading visible={step1}>
                    {config.brand.content.chooseYourCover.step1.title}
                  </StepHeading>
                  {showStep1Error && (
                    <Columns verticalAlign="center">
                      <ZeroFontSizeColumn width="content">
                        <ValidationIcon
                          valid={false}
                          outside
                          isFieldset
                          isCompact={true}
                        />
                      </ZeroFontSizeColumn>
                      <Column>
                        <ErrorMessage
                          valid={false}
                          id="error-error-policyDetails.productTypes"
                          error={errors.policyDetails?.productTypes as string}
                        />
                      </Column>
                    </Columns>
                  )}
                  {step1 && (
                    <ChoosePlanOptionsStep
                      canSelectHospital={
                        // We can select hospital cover if no applicants under consideration
                        // are over 75yo.
                        applicantDetails.filter((a) => {
                          // If we're editing for a single applicant, only consider that applicant's age
                          if (applicantId && a.id !== applicantId) {
                            return false;
                          }
                          return parseInt(a.age) > 75;
                        }).length === 0
                      }
                      value={values.policyDetails.productTypes}
                      onChange={(productTypes: ProductType[]) => {
                        gtmUtils.selectPlanOption();
                        quoteSession.selectProductTypes(
                          applicantId,
                          productTypes
                        );
                        onSubmit(null, quoteSession);
                        validateForm();
                      }}
                    />
                  )}
                  <StepHeading visible={step2}>
                    {config.brand.content.chooseYourCover.step2.title}
                  </StepHeading>
                  {showStep2Error && (
                    <Columns verticalAlign="center">
                      <ZeroFontSizeColumn width="content">
                        <ValidationIcon
                          valid={false}
                          outside
                          isFieldset
                          isCompact={true}
                        />
                      </ZeroFontSizeColumn>
                      <Column>
                        <ErrorMessage
                          valid={false}
                          id="error-error-policyDetails.everydayProductCode"
                          error={
                            (errors.policyDetails
                              ?.everydayProductCode as string) ||
                            (errors.policyDetails
                              ?.hospitalProductCode as string)
                          }
                        />
                      </Column>
                    </Columns>
                  )}
                  {step2 && (
                    <ChoosePlanStep
                      isMember={quoteSession.hasMember}
                      productTypes={values.policyDetails.productTypes}
                      hospitalProductCode={
                        values.policyDetails.hospitalProductCode
                      }
                      everydayProductCode={
                        values.policyDetails.everydayProductCode
                      }
                      setHospitalProductCode={(hospitalProductCode: string) => {
                        gtmUtils.selectCoverLevel();
                        quoteSession.selectHospitalPlan(
                          applicantId,
                          hospitalProductCode
                        );
                        onSubmit(null, quoteSession);
                        validateForm(); // Needed for e2e tests
                      }}
                      setEverydayProductCode={(everydayProductCode: string) => {
                        gtmUtils.selectCoverLevel();
                        quoteSession.selectEverydayPlan(
                          applicantId,
                          everydayProductCode
                        );
                        onSubmit(null, quoteSession);
                        validateForm(); // Needed for e2e tests
                      }}
                      frequency={paymentDetails.frequency}
                      setFrequency={(frequency) => {
                        paymentDetails.frequency = frequency;
                        onSubmit(null, quoteSession);
                      }}
                    />
                  )}
                  {hasHospital && !step2 && <Divider />}
                  {hasHospital && (
                    <StepHeading visible={step3}>
                      {config.brand.content.chooseYourCover.step3.title}
                    </StepHeading>
                  )}
                  {hasHospital && step3 && (
                    <ChooseExcessStep
                      quoteSession={quoteSession}
                      selected={values.policyDetails.excess}
                      onSelect={(excess: string) => {
                        gtmUtils.selectExcessAmount();
                        quoteSession.selectApplicantExcessValue(
                          applicantId,
                          excess
                        );
                        onSubmit(null, quoteSession);
                      }}
                    />
                  )}
                  {hasHospital && !step3 && <Divider />}
                  {hasHospital && (
                    <StepHeading visible={step4}>
                      {config.brand.content.chooseYourCover.step4.title}
                    </StepHeading>
                  )}
                  {hasHospital && step4 && (
                    <ChooseNonPharmacPlusStep
                      nonPharmacPlusOptions={nonPharmacPlusOptions}
                      selected={values.policyDetails.nonPharmacPlus}
                      frequency={paymentDetails.frequency}
                      onSelect={(nonPharmacPlus: string) => {
                        gtmUtils.selectNonPharmacPlus();
                        quoteSession.selectNonPharmacPlus(
                          applicantId,
                          nonPharmacPlus
                        );
                        onSubmit(null, quoteSession);
                      }}
                    />
                  )}
                  {applicantId ? (
                    <BottomButtons
                      cancelButtonText={
                        // Hide the cancel button if there's only one user and we must set their cover.
                        mustTailorQuote &&
                        quoteSession.applicantDetails.length === 1
                          ? undefined
                          : config.brand.content.chooseYourCover.cancelButton
                      }
                      onCancel={() => {
                        quoteSession.memberPolicyDetails[applicantId] =
                          originalPolicyDetails;
                        quoteSession.paymentDetails.frequency =
                          originalFrequency;
                        // If we HAVE to set cover for a single applicant we're editing cover for,
                        // we need to remove this applicant before we cancel.
                        // If they are the last user, the cancel button does not appear.
                        if (mustTailorQuote) {
                          quoteSession.removeApplicant(
                            quoteSession.applicantDetails.find(
                              (a) => a.id === applicantId
                            )!
                          );
                        }
                        cancelEditCoverChanges!(returnTo, quoteSession);
                      }}
                      submitButtonText={
                        config.brand.content.chooseYourCover.submitButton
                      }
                    />
                  ) : (
                    <Inline space={{ xs: 2, md: 4 }}>
                      {step3 ? <EmailQuoteButton></EmailQuoteButton> : <></>}

                      <BottomButtons
                        submitButtonText={
                          applicantDetails.length > 1
                            ? config.brand.content.chooseYourCover
                                .nextButtonMultiplePeople
                            : config.brand.content.chooseYourCover.nextButton
                        }
                      />
                    </Inline>
                  )}
                </Stack>
                {!applicantId && <NeedAdviceButton className="fixedFloating" />}
              </Container>
            </CustomisedForm>
          );
        }}
      </Formik>
    </Box>
  );
};

export default ChooseYourCoverPage;
