import React from 'react';
import { QuoteSession } from 'src/types/QuoteSession';
import { QuoteSessionContext } from './QuoteSessionContext';

interface QuoteSessionProviderProps {
  quoteSession: QuoteSession;
}

export const QuoteSessionProvider: React.FC<QuoteSessionProviderProps> = ({
  quoteSession,
  children,
}) => {
  return (
    <QuoteSessionContext.Provider value={quoteSession}>
      {children}
    </QuoteSessionContext.Provider>
  );
};

export function useQuoteSession() {
  const quoteSession = React.useContext(QuoteSessionContext);
  if (quoteSession === undefined) {
    throw new Error(
      'useQuoteSession must be used within a QuoteSessionProvider'
    );
  }
  return quoteSession;
}
