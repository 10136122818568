import { Stack } from '@nib/layout';
import Loader from '@nib/loader';
import { useField, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useLazyValidateAdviserNumberQuery } from 'src/services/join/joinApi';
import { AdviserDetails, QuoteSession } from 'src/types/QuoteSession';
import config from 'src/utils/env';
import { AdviserDetailsSchema } from 'src/utils/validation/schema/AboutYouSchema';
import FormTextbox from '../form/FormTextbox';

interface AdviserNumberFieldProps {
  label: string;
  name: string;
}

/**
 * Handles the value of `adviserDetails.adviserNumber` via a text box, and then
 * calls the Adviser Number validation API and uses the response to populate the
 * `adviserDetails.uan` and `adviserDetails.agreementId` fields.
 */
const AdviserNumberField = (props: AdviserNumberFieldProps) => {
  const { label, name } = props;
  const [{ value }, meta] = useField(name);
  const { setFieldValue } =
    useFormikContext<Pick<QuoteSession, 'adviserDetails'>>();

  const [validationError, setValidationError] = useState<string>('');

  // The query to validate a member number.
  const [validateAdviserNumber, { isFetching }] =
    useLazyValidateAdviserNumberQuery();

  // When we change field value, blank out the hidden fields and make an API call.
  // RTK Query will cache results to avoid making too many calls.
  // Since FormTextbox only emits a value onBlur, we don't need to debounce the
  // value.
  useEffect(() => {
    if (!value) {
      return;
    }
    // For any change, remove the uan and agreementId form values
    setValidationError('');
    setFieldValue('adviserDetails.uan', '', true);
    setFieldValue('adviserDetails.agreementId', '', true);
    // If there are no validation errors eg. illegal characters, make the API call.
    // We need to validate this here because meta.error won't be updated till after
    // the validation completes, which is asynchronous.
    try {
      const tempAdviserDetails: AdviserDetails = {
        adviserNumber: value,
        agreementId: '-',
        uan: '-',
        hasReadDeclaration: true,
      };
      AdviserDetailsSchema.validateSync(tempAdviserDetails);
      validateAdviserNumber({ adviserNumber: value }, true)
        .unwrap()
        .then((response) => {
          if (!response.data.valid) {
            // "Invalid" API response
            setValidationError(
              config.brand.content.validation.adviserNumber.format
            );
            return;
          }
          // "Valid" API response
          setFieldValue('adviserDetails.uan', response.data.uan, true);
          setFieldValue(
            'adviserDetails.agreementId',
            response.data.agreementId,
            true
          );
        })
        .catch((_err: any) => {
          setValidationError(
            config.brand.content.validation.adviserNumber.error
          );
        });
    } catch (_err: any) {
      // Field is invalid; do nothing, let Formik show the field error
    }
  }, [setFieldValue, validateAdviserNumber, value]);

  return (
    <Stack space={5}>
      <FormTextbox
        name={name}
        label={label}
        stripWhitespace={true}
        maxLength={6}
        // Field is valid if both Yup and API validation pass
        valid={meta.error === undefined && !validationError}
        // Show the validation message from the API, fall back to any message from Yup
        error={validationError || meta.error}
        // Don't show a validation state while loading
        validated={meta.touched && !isFetching}
        autoComplete={'off'}
      />
      {isFetching && <Loader size="sm" />}
    </Stack>
  );
};

export default AdviserNumberField;
