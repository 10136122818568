import config from 'src/utils/env';

/**
 * Hook to generate a memoized autocomplete string.
 *
 * If autoComplete is not supplied, it disables autocomplete.
 *
 * If the configured brand has advisers, also disables autocomplete.
 *
 * Otherwise returns the string supplied.
 */
export function useAutocomplete(autoComplete?: string) {
  // Autocomplete is disabled by default, and also if the brand has advisers.
  if (!autoComplete || config.brand.hasAdviser) {
    return 'off';
  } else {
    return autoComplete;
  }
}
