import Alert from '@nib-components/alert';
import { Box } from '@nib/layout';
import { useFormikContext } from 'formik';
import { path } from 'ramda';
import { ApplicantDetails } from 'src/types/QuoteSession';
import config from 'src/utils/env';
import NeedAdviceButton from '../NeedAdvice/NeedAdviceButton';

function getInfoTextForAge(age: number): string | null {
  if (isNaN(age)) {
    return null;
  }
  if (age < 16) {
    return config.brand.content.aboutYou.ageUnder16InfoText;
  }
  if (age > 120) {
    return config.brand.content.aboutYou.ageOver120InfoText;
  }
  if (age > 75) {
    return config.brand.content.aboutYou.ageOver75InfoText;
  }
  return null;
}

interface SmokerInfoBoxProps {
  fieldNamePrefix: string;
}

/**
 * Displays an info box if age meets various criteria.
 * Used by About You, Add Applicant, and Edit Applicant screens.
 */
const AgeInfoBox = ({ fieldNamePrefix }: SmokerInfoBoxProps) => {
  const { values, errors } = useFormikContext<{
    applicantDetails: ApplicantDetails[];
  }>();

  const agePath = fieldNamePrefix.split('.');
  agePath.push('age');

  // If there is a validation error message, we don't want to show
  // this box (eg. the user has entered "-6" or "1.2")
  // Note that this works with the >120 rule, as that has no error message.
  if (path(agePath, errors)) {
    return null;
  }

  const ageValue: number = parseInt(path(agePath, values) ?? '');

  // If the applicant is <16, and there are any guardians, or people with age >16,
  // we don't want to show this message.
  if (!isNaN(ageValue) && ageValue < 16) {
    const hideMessage = values.applicantDetails.find(
      (a) => a.isGuardian || parseInt(a.age) >= 16
    );
    if (hideMessage) {
      return null;
    }
  }

  const infoText: string | null = getInfoTextForAge(ageValue);

  if (infoText === null) {
    return null;
  }

  return (
    <Box marginTop={6}>
      <Alert
        type={ageValue > 120 ? 'error' : 'info'}
        variation="soft"
        role="alert"
      >
        <Box marginBottom={4}>{infoText}</Box>
        {ageValue > 75 && <NeedAdviceButton size="small" />}
      </Alert>
    </Box>
  );
};
export default AgeInfoBox;
