import { PrimaryButton, SecondaryButton } from '@nib-components/button';
import ErrorTemplate from '@nib-components/error-template';
import { Box, Inline } from '@nib/layout';
import Loader from '@nib/loader';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Footer } from 'src/components/Footer';
import { Header } from 'src/components/Header';
import HelmetComponent from 'src/components/HelmetComponent/HelmetComponent';
import { FormPage } from 'src/types/FormPage';
import config from 'src/utils/env';
import { getFormPageByIndex } from 'src/utils/formPageUtils';

interface ErrorPageProps {
  isFromErrorBoundary: boolean;
  startAgain?: () => void;
}

const ErrorPage: React.FC<ErrorPageProps> = ({
  isFromErrorBoundary,
  startAgain,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorBoundaryRetryCount, setErrorBoundaryRetryCount] = useState(0);
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const params = new URLSearchParams(search);
  const returnURL = params.get('returnURL');
  const retryThreshold = 1;

  const goBack = () => {
    setIsLoading(true);
  };

  const incrementRetryCount = () => {
    setErrorBoundaryRetryCount((prevRetryCount) => prevRetryCount + 1);
  };

  // Waits for 1s after the user clicks "Retry", so that
  // the user gets some feedback on what is happening.
  // If the page they return to immediately kicks them back to
  // the error page, this lets them know that *something* has
  // happened, and the Retry button isn't just broken.
  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (isLoading) {
      timeoutId = setTimeout(() => {
        setIsLoading(false);
        if (returnURL) {
          navigate(returnURL as string);
        } else {
          if (isFromErrorBoundary) {
            const errorBoundaryUrl = pathname + search;

            navigate(errorBoundaryUrl);
            incrementRetryCount();
          } else {
            navigate(getFormPageByIndex(FormPage.AboutYou)!.path);
          }
        }
      }, 1000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [
    errorBoundaryRetryCount,
    isFromErrorBoundary,
    isLoading,
    navigate,
    pathname,
    returnURL,
    search,
  ]);

  return (
    <ErrorTemplate
      title={config.brand.content.errorPage.title}
      description={config.brand.content.errorPage.description}
      header={<Header />}
      footer={<Footer />}
    >
      <HelmetComponent content={config.brand.content.errorPage.helmet} />
      <Box>
        {isLoading ? (
          <Loader />
        ) : (
          <Inline align="center" space={{ xs: 4, xl: 6 }}>
            {isFromErrorBoundary &&
            errorBoundaryRetryCount >= retryThreshold ? (
              <PrimaryButton onClick={startAgain}>Start Again</PrimaryButton>
            ) : (
              <></>
            )}
            <PrimaryButton onClick={goBack}>Retry</PrimaryButton>
            <SecondaryButton href={config.brand.content.errorPage.contactUs}>
              Contact us
            </SecondaryButton>
          </Inline>
        )}
      </Box>
    </ErrorTemplate>
  );
};

export default ErrorPage;
