import { priceApi } from 'src/services/price/priceApi';
import { PriceApiFetchPriceRequestV2 } from 'src/services/price/priceApiTypes';
import priceApiUtils from 'src/services/price/priceApiUtils';
import { QuoteSession } from 'src/types/QuoteSession';
import { useAppDispatch } from '../hooks';

export function usePriceData() {
  const dispatch = useAppDispatch();
  const getPriceApiData = async (quoteSession: QuoteSession) => {
    const request = priceApiUtils.createFetchPriceRequestV2(
      quoteSession
    ) as PriceApiFetchPriceRequestV2;
    const data = await dispatch(
      priceApi.endpoints.fetchPriceV2.initiate(request)
    ).unwrap();
    return data;
  };

  return getPriceApiData;
}
