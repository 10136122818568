import Checkbox from '@nib-components/checkbox';
import { FieldsetFormControl } from '@nib-components/form-control';
import { FieldsetFormControlProps } from '@nib-components/form-control/dist/@types/FieldsetFormControl';
import { FieldHookConfig, useField, useFormikContext } from 'formik';

export type FormCheckboxProps = Omit<FieldsetFormControlProps, 'children'> &
  FieldHookConfig<boolean> & {
    label: string;
  };

const FormCheckbox = ({ label, name, ...otherProps }: FormCheckboxProps) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField<boolean>({ name });

  // Checkboxes are OK to immediately emit state changes to formik; no need
  // to keep local state as per text boxes

  return (
    <FieldsetFormControl
      label=""
      id={field.name}
      name={field.name}
      valid={meta.error === undefined}
      validated={meta.touched}
      error={meta.error}
      hasNegativeFieldsetMargin={true}
      onClick={(e: any) => {
        setFieldValue(name, !field.value);
      }}
      {...otherProps}
    >
      <Checkbox label={label} checked={!!field.value} />
    </FieldsetFormControl>
  );
};

export default FormCheckbox;
