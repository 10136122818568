import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import { FormPage } from 'src/types/FormPage';
import { QuoteSession } from 'src/types/QuoteSession';
import {
  findFurthestValidPage,
  getFormPageByIndex,
} from 'src/utils/formPageUtils';

interface ResumeSessionRedirectorProps {
  quoteSession: QuoteSession;
}

/**
 * Invisible component that redirects the user to the furthest (still valid) page they've visited, if needed.
 */
const ResumeSessionRedirector = ({
  quoteSession,
}: ResumeSessionRedirectorProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const furthestVisitedPath = getBestPath(quoteSession);

  React.useEffect(() => {
    // If the page we want to go to doesn't exist,
    // or we're already on that screen,
    // or we're displaying a mandatory 'tailor your quote' screen,
    // or we're on the Error screen,
    // don't try to navigate away.
    if (
      furthestVisitedPath === undefined ||
      pathname === furthestVisitedPath ||
      pathname === '/error' ||
      pathname.match(/^\/edit-cover\/[0-9]+\/.+$/)
    ) {
      return;
    }
    // Otherwise, go to the furthest screen the user has visited.
    navigate(furthestVisitedPath);
  }, [furthestVisitedPath, navigate, pathname]);

  return null;
};

const getBestPath = (quoteSession: QuoteSession) => {
  // Find the path for the highest numbered form page we visited.
  // Assumes if we completed a page, we saw the next page.
  const furthestCompletedIndex = quoteSession.completedPages.reduce(
    (acc, cur) => (cur > acc ? cur : acc),
    -1
  );
  const furthestVisitedPageIndex = Math.min(
    furthestCompletedIndex + 1,
    FormPage.BuyNow
  );

  const furthestValidPageIndex = findFurthestValidPage(
    furthestVisitedPageIndex,
    quoteSession,
    false
  );

  // The furthestVisitedPageIndex is not necessarily a valid place to visit
  // For example, a user may:
  // - make it all the to quote summary (i.e. had valid product selections)
  // - navigate to choose your cover
  // - change from hospital only to everyday only BUT don't choose a product
  // - close the browser or navigate away
  // - navigate again to the join flow.
  // At that point, the furthest "visited" page is quote summary ... but
  // we need to force the user back to the choose your cover page to complete
  // their product selections (the furthest "valid" page).
  // So we take the minimum of the furthestValid and furthestVisited
  //
  const bestPathIndex = Math.min(
    furthestValidPageIndex,
    furthestVisitedPageIndex
  );
  const bestPageInfo = getFormPageByIndex(bestPathIndex);
  return bestPageInfo?.path;
};

export default ResumeSessionRedirector;
