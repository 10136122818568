import React from 'react';
import config from 'src/utils/env';
import styled from 'styled-components';

const headerColor = config.brand.customisation?.headersColor;
const BrandedHeaders = headerColor
  ? styled('div')`
      h1 {
        color: ${headerColor};
      }
      h2 {
        color: ${headerColor};
      }
      h3 {
        color: ${headerColor};
      }
      h4 {
        color: ${headerColor};
      }
    `
  : styled('div')``;

const BrandedStyleHeader: React.FC = ({ ...props }) => {
  return <BrandedHeaders {...props}></BrandedHeaders>;
};

export default BrandedStyleHeader;
