import Copy, { Bold } from '@nib-components/copy';
import { ClockGraphicIcon } from '@nib/icons';
import { Box } from '@nib/layout';
import styled from 'styled-components';
import timeUtils from '../../utils/timeUtils';

const Copy1 = styled(Copy)`
  display: inline-flex;
  align-items: center;
`;

const Copy2 = styled(Copy)`
  padding-left: 0.5rem;
`;

interface CountdownClockProps {
  endDate: Date;
}

const CountDownClock = (props: CountdownClockProps) => {
  const { endDate } = props;
  const { daysFromNow, hoursFromNow } = timeUtils.calculateTimeGap(endDate);

  if (daysFromNow === 0 && hoursFromNow === 0) return null;

  let timeGapString = (
    <Copy2>
      Offer ends in <Bold>{daysFromNow}</Bold>{' '}
      {daysFromNow > 1 ? 'days' : 'day'} <Bold>{hoursFromNow}</Bold>{' '}
      {hoursFromNow > 1 ? 'hours' : 'hour'}
    </Copy2>
  );

  return (
    <Box>
      <Copy1>
        <Copy align="left">
          <ClockGraphicIcon size="md" fill="trueGreen" />
        </Copy>
        {timeGapString}
      </Copy1>
    </Box>
  );
};

export default CountDownClock;
