import { Tab, TabList, TabPanel, Tabs } from '@nib-components/tabs';
import { Box, Container, Hidden } from '@nib/layout';
import { ProgressStepper, StepType } from '@nib/progress-stepper';
import { useLocation, useParams } from 'react-router-dom';
import { FormPage } from 'src/types/FormPage';
import { QuoteSession } from 'src/types/QuoteSession';
import config from 'src/utils/env';
import {
  findFurthestValidPage,
  getFormPageByPath,
} from 'src/utils/formPageUtils';

interface FormProgressBarProps {
  onSelectFormPage: (nextPageId: FormPage) => void;
  /** Which pages of the form we have data for */
  quoteSession: QuoteSession;
}

/**
 * Displays the user's current progress, shown across the top of the main form.
 */
const FormProgressBar = ({
  onSelectFormPage,
  quoteSession,
}: FormProgressBarProps) => {
  const { pathname } = useLocation();
  const params = useParams();
  const selectedIndex = getFormPageByPath(pathname)?.index;
  const completedPages = quoteSession.completedPages;
  const hasTailoredCover = quoteSession.hasTailoredCover();
  const multiplePeople = quoteSession.applicantDetails.length > 1;
  // If we're not on a form page, render nothing.
  if (selectedIndex === undefined) {
    return null;
  }

  const mobileSteps: StepType[] = config.brand.content.formPages.map(
    (formPage, i) => ({
      title:
        multiplePeople && formPage.altLabel
          ? formPage.altLabel
          : formPage.label,
      isAccessed: completedPages.includes(i) || completedPages.includes(i - 1),
      isDisabled:
        // Disable About You and Choose Your Cover if we've tailored the quote
        hasTailoredCover &&
        (formPage.index === FormPage.AboutYou ||
          formPage.index === FormPage.ChooseYourCover),
      isComplete: completedPages.includes(i),
    })
  );

  const tabList = config.brand.content.formPages.map((formPage) => (
    <Tab key={`tab-${formPage.index}`} tabIndex={formPage.index.toString()}>
      {multiplePeople && formPage.altLabel ? formPage.altLabel : formPage.label}
    </Tab>
  ));

  return (
    <Box background="white">
      <Container>
        <Hidden below="lg">
          <Tabs
            selectedIndex={selectedIndex}
            onSelect={(nextPageId) => {
              const furthestValidPage = findFurthestValidPage(
                nextPageId,
                quoteSession,
                !!params.applicantId
              );
              // If we're trying to skip ahead of where we're allowed, don't accept
              if (nextPageId > furthestValidPage) {
                return false;
              }
              // Disable About You and Choose Your Cover if we've tailored the quote
              if (
                hasTailoredCover &&
                (nextPageId === FormPage.AboutYou ||
                  nextPageId === FormPage.ChooseYourCover)
              ) {
                return false;
              }
              // Otherwise, let the user go to this page
              onSelectFormPage(nextPageId);
            }}
          >
            <TabList>{tabList}</TabList>
            {/* Invisible tabpanels to avoid Tabs throwing errors */}
            {config.brand.content.formPages.map((formPage) => (
              <TabPanel key={`tabpanel-${formPage.index}`}></TabPanel>
            ))}
          </Tabs>
        </Hidden>
        <Hidden above="lg">
          <ProgressStepper
            currentStep={selectedIndex}
            goToStep={onSelectFormPage}
            steps={mobileSteps}
            fill={true}
            collapseBelow="lg"
          />
        </Hidden>
      </Container>
    </Box>
  );
};

export default FormProgressBar;
