import Copy from '@nib-components/copy';
import Card from '@nib/card';
import { Column, Columns, p, pl, pr, px, py } from '@nib/layout';
import { SpecialOfferResponseData } from 'src/services/join/contentfulApiTypes';
import config from 'src/utils/env';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import CountDownClock from '../CountdownClock/CountdownClock';
import MarkdownContent from '../MarkdownContent';

interface SpecialOfferProps {
  specialOffer: SpecialOfferResponseData;
  currentJourney?: number;
}

const Column1 = styled(Column)`
  ${px(4)}
  ${py(6)}

  ${breakpoint('lg')`
    ${p(6)}
  `};
  ${breakpoint('xl')`
    ${py(6)}
    ${pr(0)}
    ${pl(7)}
  `};
  h3 {
    color: ${config.brand.customisation?.headersColor ?? 'inherit'};
  }
`;

const SpecialOffer = (props: SpecialOfferProps) => {
  let countDownClock;
  if (props.specialOffer.campaignCountdownTimer) {
    countDownClock = <CountDownClock endDate={props.specialOffer.endDate} />;
  }

  const SpecialOfferAvailable = () => {
    if (props.currentJourney) {
      if ([2, 3].includes(props.currentJourney))
        return (
          <Copy>
            <MarkdownContent
              content={props.specialOffer.specialOfferAvailable}
            />
          </Copy>
        );
    }
    return;
  };

  const SpecialOfferImage = () => {
    if (props.specialOffer.specialOfferImage) {
      return (
        <Column width={{ xl: '1/2', xxxl: '2/5' }} hiddenBelow="xl">
          <Copy align="center">
            <img
              src={props.specialOffer.specialOfferImage}
              alt={config.brand.content.chooseYourCover.specialOfferAltText}
            />
          </Copy>
        </Column>
      );
    }
  };

  const column1Width = () => {
    if (props.specialOffer.specialOfferImage) {
      return { lg: 'content', xl: '1/2', xxxl: '3/5' };
    }
    return { xxxl: 'content' };
  };

  return (
    <Columns space={4}>
      <Column1 width={column1Width}>
        <Card.Content
          title={props.specialOffer.specialOfferDetails}
          titleSize={5}
        >
          {SpecialOfferAvailable}
          {countDownClock}
          <Copy small={true} measure={false}>
            <MarkdownContent
              content={props.specialOffer.specialOfferDetailsLegal}
            />
          </Copy>
        </Card.Content>
      </Column1>
      {SpecialOfferImage()}
    </Columns>
  );
};

export default SpecialOffer;
