import { Document } from '@contentful/rich-text-types';
import Loader from '@nib/loader';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ContentfulNode from 'src/components/Contentful';
import { useQuoteSession } from 'src/contexts/QuoteSessionProvider';
import {
  contentfulApi,
  useGetEntryQuery,
} from 'src/services/contentful/contentfulApi';
import { useAppDispatch } from 'src/store/hooks';
import { OverallProductMix, QuoteSession } from 'src/types/QuoteSession';
import config from 'src/utils/env';

/**
 * The data returned by Contentful for Important Information
 */
export interface ContentfulRichTextPanelData {
  contentTypeId: 'richTextPanel';
  fields: {
    content: Document;
  };
}

const getContentfulEntryId = (quoteSession: QuoteSession): string => {
  const entries =
    config.brand.content.quoteSummary.importantInfoContentfulEntries;
  switch (quoteSession.getOverallProductMix()) {
    case OverallProductMix.HospitalOnly:
      return entries.hospitalOnly;
    case OverallProductMix.EverydayOnly:
      return entries.everydayOnly;
    case OverallProductMix.HospitalAndEveryday:
      return entries.hospitalAndEveryDay;
    default:
      throw Error(
        `getContentfulEntryId: called but overall product mix is: ${quoteSession.getOverallProductMix()}`
      );
  }
};

/**
 * Fetches and renders the Important Information "Rich Text Panel" from Contentful.
 */
const ImportantInformation = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const quoteSession = useQuoteSession();

  const { data, isLoading, isError } = useGetEntryQuery({
    id: getContentfulEntryId(quoteSession),
    query: {
      include: 3,
    },
  });

  React.useEffect(() => {
    if (!isError) {
      return;
    }
    // Reset the API state so that we try again next time we render this component
    dispatch(contentfulApi.util.resetApiState());
    // Display the error page
    console.warn(
      'Failed to load import information ... navigating to the error page'
    );
    navigate(`/error?returnURL=${location.pathname}`);
  }, [dispatch, isError, navigate, location.pathname]);

  if (isLoading) {
    return <Loader size="md" />;
  }

  // If we finished loading, and there's no data, show nothing.
  // This happens when there's an API error, and before we trigger the API call.
  if (!data) {
    return null;
  }

  return <ContentfulNode {...data} />;
};

export default ImportantInformation;
