import { differenceInCalendarDays } from 'date-fns';
import { ProductType } from 'src/types/ProductConfig';
import * as Yup from 'yup';
import dateUtils from '../dateUtils';
import { everydayProducts, hospitalProducts } from '../productUtils';

// Rules should not return false for empty values
export const optional = (value: string | undefined): boolean =>
  value === undefined || value === null || value === '';

/**
 * Validate that a string contains only valid characters for a name.
 * @param message The error message to display if this test is not met.
 * @returns
 */
export const validatePersonalName: (
  message: string
) => Yup.TestConfig<string, Yup.AnyObject> = (message) => ({
  name: 'validatePersonalName',
  message,
  test: (name: string) => optional(name) || /^[-.'() a-zA-Z]+$/.test(name),
});

/**
 * Validate that a string contains only alphabet characters, comma, fullstop, apostrophe, and spaces.
 * @param message The error message to display if this test is not met.
 * @returns
 */
export const validatePaymentDetails: (
  message: string
) => Yup.TestConfig<string, Yup.AnyObject> = (message) => ({
  name: 'validatePaymentDetails',
  message,
  test: (name: string) => optional(name) || /^[,.' a-zA-Z]+$/.test(name),
});

/**
 * Validate that a string contains only numeric values (no decimal point)
 * @param message The error message to display if this test is not met.
 * @returns
 */
export const validateNumeric: (
  message: string
) => Yup.TestConfig<string, Yup.AnyObject> = (message) => ({
  name: 'validateNumeric',
  message,
  test: (value: string) => optional(value) || /^[\d-]+$/.test(value),
});

/**
 * Validate that a string contains only positive values or 0.
 * @param message The error message to display if this test is not met.
 * @returns
 */
export const validatePositive: (
  message: string
) => Yup.TestConfig<string, Yup.AnyObject> = (message) => ({
  name: 'validatePositive',
  message,
  test: (age: string) => {
    if (optional(age)) {
      return true;
    }
    // Detects any negative signs; validateNumeric will pick up if there are
    // illegal characters eg. letters
    return !/-/.test(age);
  },
});

/**
 * Validate that an age is under 120
 * @param message The error message to display if this test is not met.
 * @returns
 */
export const validateAge120: (
  message: string
) => Yup.TestConfig<string, Yup.AnyObject> = (message) => ({
  name: 'validateAge120',
  message,
  test: (age: string) => {
    if (optional(age)) {
      return true;
    }
    const ageNumber = parseInt(age);
    if (isNaN(ageNumber)) {
      return true; // another rule will pick this up
    }
    return ageNumber <= 120;
  },
});

/**
 * Validate that a string contains a valid phone number.
 * Note -
 * @param message The error message to display if this test is not met.
 * @returns
 */
export const validatePhoneNumber: (
  message: string
) => Yup.TestConfig<string, Yup.AnyObject> = (message) => ({
  name: 'validatePhoneNumber',
  message,
  test: (phone: string) => {
    // Ignore empty strings, required rule will detect that
    if (optional(phone)) {
      return true;
    }

    // Digits only; the user can enter spaces
    if (!/^[\d\s]+$/.test(phone)) {
      return false;
    }

    // Remove whitespace for validating format
    const phoneNoWhitespace = phone.replace(/\s/g, '');

    // cannot be all zeros
    // Phone numbers can't start with 1, 5, or 8 as their first digit.
    // Phone numbers can't start with 23, 24, or 25 as their first 2 digits.
    // Phone numbers starting with 3, 4, 6, 7, or 9 should have
    // ONLY 7 trailing digits to be valid phone numbers.
    // Phone numbers starting with 20, 21, 22, 26, 27, 18, or 29
    // should have 6-9 trailing digits (including both numbers) to be valid phone numbers.
    // HACK: Allows spaces, although the field behaviour will always remove them, as
    // Formik has some issues around the sequencing of setting values and validating them.
    if (
      !/^0(9|7|6|4|3)[\d]{7}$/.test(phoneNoWhitespace) &&
      !/^0(20|21|22|26|27|28|29)[\d]{6,9}$/.test(phoneNoWhitespace)
    ) {
      return false;
    }

    // No issues found
    return true;
  },
});

/**
 * Validate that a string length is between set limits, ignoring whitespace and leading 0.
 * @param message The error message to display if this test is not met.
 * @returns
 */
export const validatePhoneLength: (
  message: string
) => Yup.TestConfig<string, Yup.AnyObject> = (message) => ({
  name: 'validatePhoneLength',
  message,
  test: (phone: string) => {
    if (optional(phone)) {
      return true;
    }

    return phone.length >= 9 && phone.length <= 12;
  },
});

// Pre-calculate the possible codes for validating against
const everydayCodes = everydayProducts.map((p) => p.productDetails.code);
const hospitalCodes = hospitalProducts.map((p) => p.productDetails.code);

/**
 * Validates whether the user has chosen exactly one product code
 * for each of Everyday and/or Hospital, according to their selection
 * for Product Type.
 */
export const validateProductCodes: (
  productTypes: ProductType[]
) => Yup.TestConfig<string[], Yup.AnyObject> = (productTypes) => ({
  name: 'validateProductCodes',
  message: 'product codes do not match product types', // no visible message for this rule
  test: (productCodes: string[]) => {
    // If we have selected Everyday, we must have an Everyday product code
    const hasEveryday = productTypes.includes('Everyday');
    if (hasEveryday) {
      if (!productCodes.find((c) => everydayCodes.includes(c))) {
        return false;
      }
    }
    // If we have seleceted Hospital, we must have a hospital product code
    const hasHospital = productTypes.includes('Hospital');
    if (hasHospital) {
      if (!productCodes.find((c) => hospitalCodes.includes(c))) {
        return false;
      }
    }
    // If we got here, we either didn't select any product types, or we haven't selected
    // one code of each type
    return true;
  },
});

/**
 * Validate that an email address meets the criteria:
 * There are 4 parts to an email address. (Part-1)(Part-2)(Part-3)(Part-4) e.g a@b.c
 * Part 1:
 *  - alphanumeric
 *  - accepts special characters of only _<>.-+
 * Part 2:
 *  - only accepts @
 * Part 3:
 *  - alphanumeric
 *  - accepts special character of only _
 * Part 4:
 *  - must start with .
 *  - following characters must be alphanumeric or special character of _
 * @param message The error message to display if this test is not met.
 * @returns
 */
export const validateEmailAddress: (
  message: string
) => Yup.TestConfig<string, Yup.AnyObject> = (message) => ({
  name: 'validateEmailAddress',
  message,
  test: (email: string) => {
    if (optional(email)) {
      return true;
    }
    return !!email.match(
      /^[a-zA-Z0-9_<>.\-+]+@[a-zA-Z0-9_]+(\.[a-zA-Z0-9_]+)+$/
    );
  },
});

/**
 * Validate that a date meets the criteria:
 * - yyyy-mm-dd format (note that the DateTextbox translates this from dd/mm/yyyy into the form value)
 * - must be valid day for given month e.g cannot enter 31 February
 * @param message The error message to display if this test is not met.
 * @returns
 */
export const validateDateFormat: (
  message: string
) => Yup.TestConfig<string, Yup.AnyObject> = (message) => ({
  name: 'validateDateFormat',
  message,
  test: (dateString: string) => {
    if (optional(dateString)) {
      return true;
    }
    // Check format is dd/mm/yyyy
    return dateUtils.getDateObject(dateString) !== null;
  },
});

/**
 * Validate that a date is not in the future.
 * @param message The error message to display if this test is not met.
 * @returns
 */
export const validateDateNotInFuture: (
  message: string
) => Yup.TestConfig<string, Yup.AnyObject> = (message) => ({
  name: 'validateDateNotInFuture',
  message,
  test: (dateString: string) => {
    if (optional(dateString)) {
      return true;
    }
    const dateObj = dateUtils.getDateObject(dateString);
    if (dateObj === null) {
      return true; // let date format rule catch invalid dates
    }
    const days = differenceInCalendarDays(dateUtils.getStartOfDay(), dateObj);
    return days >= 0;
  },
});

/**
 * Validate that a birthdate makes an applicant 16 years of age or older.
 * @param message The error message to display if this test is not met.
 * @returns
 */
export const validateBirthdate16: (
  message: string
) => Yup.TestConfig<string, Yup.AnyObject> = (message) => ({
  name: 'validateBirthdate16',
  message,
  test: (dateString: string) => {
    if (optional(dateString)) {
      return true;
    }
    const dateObj = dateUtils.getDateObject(dateString);
    if (dateObj === null) {
      return true; // let date format rule catch invalid dates
    }
    return dateUtils.getAgeForDateOfBirth(dateObj) >= 16;
  },
});

/**
 * Validate that a birthdate does not make an applicant over 75 years of age.
 * Note that this is used with a 'when' clause to only activate with hospital cover.
 * @param message The error message to display if this test is not met.
 * @returns
 */
export const validateBirthdate75: (
  message: string
) => Yup.TestConfig<string, Yup.AnyObject> = (message) => ({
  name: 'validateBirthdate75',
  message,
  test: (dateString: string) => {
    if (optional(dateString)) {
      return true;
    }
    const dateObj = dateUtils.getDateObject(dateString);
    if (dateObj === null) {
      return true; // let date format rule catch invalid dates
    }
    return dateUtils.getAgeForDateOfBirth(dateObj) <= 75;
  },
});

/**
 * Validate that a birthdate makes an applicant 120 years of age or younger.
 * @param message The error message to display if this test is not met.
 * @returns
 */
export const validateBirthdate120: (
  message: string
) => Yup.TestConfig<string, Yup.AnyObject> = (message) => ({
  name: 'validateBirthdate120',
  message,
  test: (dateString: string) => {
    if (optional(dateString)) {
      return true;
    }
    const dateObj = dateUtils.getDateObject(dateString);
    if (dateObj === null) {
      return true; // let date format rule catch invalid dates
    }
    return dateUtils.getAgeForDateOfBirth(dateObj) <= 120;
  },
});

/**
 * Validate that a membership number is of format "3083 26XX XXXX XXXX".
 * Can be with or without spaces.
 * @param message The error message to display if this test is not met.
 * @returns
 */
export const validateExternalMemberNumber: (
  message: string
) => Yup.TestConfig<string, Yup.AnyObject> = (message) => ({
  name: 'validateExternalMemberNumber',
  message,
  test: (membershipNumber: string) => {
    if (optional(membershipNumber)) {
      return true;
    }
    return (
      membershipNumber.match(/^3083\s*26[0-9]{2}\s*[0-9]{4}\s*[0-9]{4}$/) !==
      null
    );
  },
});

/**
 * Validates that a field only contains letters and numbers, does not allow whitespace.
 */
export const validateAlphanumeric: (
  message: string
) => Yup.TestConfig<string, Yup.AnyObject> = (message) => ({
  name: 'validateAlphanumeric',
  message,
  test: (text: string) => {
    if (optional(text)) {
      return true;
    }
    return text.match(/^[a-zA-Z0-9]*$/) !== null;
  },
});
