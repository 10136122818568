import { colorBrandBase } from '@nib-components/theme';
import * as DOMPurify from 'dompurify';
import { marked } from 'marked';
import React from 'react';
import { Markdown } from 'src/types/Content/Markdown';
import config from 'src/utils/env';
import { getPolicyDocumentUri } from 'src/utils/productUtils';
import styled from 'styled-components';

const headerColor = config.brand.customisation?.headersColor;

const MarkdownContainer = headerColor
  ? styled('div')`
      a {
        color: ${colorBrandBase};
        font-weight: 500;
      }
      h1 {
        color: ${headerColor};
      }
      h2 {
        color: ${headerColor};
      }
      h3 {
        color: ${headerColor};
      }
      h4 {
        color: ${headerColor};
      }
    `
  : styled('div')`
      a {
        color: ${colorBrandBase};
        font-weight: 500;
      }
    `;

// Adapted from https://github.com/nib-group/design-system/blob/master/packages/product-card/src/Markdown/index.tsx
// Note that any faulty or unsafe escaping will be caught by DOMPurify, so we just embed attributes directly.

export interface MarkdownContentProps {
  content?: Markdown;
  // The name of a custom event to emit when users click on a link in the generated markup.
  // This will also prevent the link from being followed.
  linkEvent?: string;
}

/**
 * Renders markdown as HTML.
 *
 * Usage:
 *
 *     <MarkdownContent content={markdown} />
 */
class MarkdownContent extends React.Component<MarkdownContentProps> {
  constructor(props: MarkdownContentProps) {
    super(props);
    const renderer: marked.RendererObject = {
      list(body: string, ordered: boolean) {
        return `<${ordered ? 'ol' : 'ul'}>${body}</${ordered ? 'ol' : 'ul'}>`;
      },
      link(href: string | null, title: string | null, text: string) {
        // Converts URLs of "policy:foo:bar" to a policy document link
        const policyDocument = href && href.match(/^policy:([^:]+):([^:]+)$/);

        if (policyDocument) {
          href = getPolicyDocumentUri(policyDocument[1], policyDocument[2]);
        }

        return (
          '<a' +
          (props.linkEvent
            ? ` onclick="event.preventDefault(); event.target.dispatchEvent(new Event('${props.linkEvent}', {bubbles: true}));"`
            : '') +
          (href ? ` href="${href}"` : '') +
          (title ? ` title="${title}"` : '') +
          '>' +
          text +
          '</a>'
        );
      },
    };

    marked.use({ renderer });
  }

  shouldComponentUpdate(nextProps: any) {
    return nextProps.content !== this.props.content;
  }

  render() {
    return (
      <MarkdownContainer
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(
            marked.parse(this.props.content || '', {
              mangle: false,
              headerIds: false,
            }),
            {
              ALLOWED_ATTR: ['href', 'title', 'onclick'],
            }
          ),
        }}
      />
    );
  }
}

export default MarkdownContent;
