import config from 'src/utils/env';
import * as Yup from 'yup';
import { validatePhoneLength, validatePhoneNumber } from '../validation';

const messages = config.brand.content.validation;

const PhoneSchema = Yup.string()
  .ensure()
  .test(validatePhoneLength(messages.phone.maxLength))
  .test(validatePhoneNumber(messages.phone.format));

export default PhoneSchema;
