import { clone } from 'ramda';
import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ManageApplicantForm from 'src/components/ManageApplicantForm';
import { FormPage } from 'src/types/FormPage';
import { FormPageProps } from 'src/types/FormPageProps';
import { QuoteSession } from 'src/types/QuoteSession';
import config from 'src/utils/env';
import { getFormPageByIndex } from 'src/utils/formPageUtils';
import gtmUtils from 'src/utils/gtmUtils';

const content = config.brand.content.editApplicant;
const helmetContent = config.brand.content.editApplicant.helmet;

const EditApplicantPage = (props: FormPageProps) => {
  const { onSubmit } = props;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { returnTo, applicantId } = useParams<{
    returnTo: string;
    applicantId: string;
  }>();

  const [redirectTo, setRedirectTo] = React.useState<string>();

  React.useEffect(() => {
    if (!redirectTo || redirectTo === pathname) {
      return;
    }
    // Replaces the new URL as the current URL, instead of adding to the history stack.
    navigate(redirectTo, { replace: true });
    setRedirectTo(undefined);
  }, [navigate, pathname, redirectTo]);

  React.useEffect(() => {
    if (applicantId) {
      gtmUtils.startEditDetails(applicantId, quoteSession);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMalformedUrl = () => {
    if (redirectTo) {
      return; // we don't need to redirect if we're already redirecting
    }
    // Return to the furthest page we can reach
    setRedirectTo(getFormPageByIndex(FormPage.BuyNow)!.path);
  };

  const quoteSession = clone(props.quoteSession);
  if (returnTo === undefined) {
    // No return page specified
    handleMalformedUrl();
    return null;
  }
  const formPage = getFormPageByIndex(parseInt(returnTo))?.index;
  if (!formPage) {
    handleMalformedUrl();
    return null; // Return page was specified but doesn't exist
  }

  // Find the applicant we specified in the URL
  const applicantIndex = quoteSession.applicantDetails.findIndex(
    (a) => a.id === applicantId
  );
  if (applicantIndex === -1) {
    handleMalformedUrl();
    return null; // Applicant not found
  }

  return (
    <ManageApplicantForm
      title={content.title}
      applicantIndex={applicantIndex}
      cancelButtonText={content.cancelButton}
      submitButtonText={content.submitButton}
      onSubmit={(nextPageId: FormPage | null, quoteSession?: QuoteSession) => {
        // If user clicks cancel then quoteSession will be undefined
        // We only want to track GTM form submits when they continue ... not cancel
        if (applicantId && quoteSession) {
          gtmUtils.submitEditDetails(applicantId, quoteSession);
        }
        onSubmit(nextPageId, quoteSession);
      }}
      returnTo={formPage}
      quoteSession={quoteSession}
      helmetContent={helmetContent}
    />
  );
};

export default EditApplicantPage;
