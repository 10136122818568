import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import config from 'src/utils/env';
import { prepareHeaders } from '../serviceHelpers';
import { onSubmitJoinQueryStarted } from './eventHandlers';
import {
  JoinSubmissionOptions,
  JoinSubmissionResponse,
} from './submissionApiTypes';

const submissionApi = createApi({
  reducerPath: 'submissionApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.submissionApiRoot}`,
    prepareHeaders,
  }),
  endpoints(builder) {
    return {
      submitJoin: builder.mutation<
        JoinSubmissionResponse,
        JoinSubmissionOptions
      >({
        query: (options) => {
          return {
            url: `/v1/sales/consumer-join`,
            method: 'POST',
            body: options.joinSubmission,
            headers: {
              'x-correlation-id': options.correlationId,
            },
          };
        },
        onQueryStarted: onSubmitJoinQueryStarted,
      }),
    };
  },
});

export const { useSubmitJoinMutation } = submissionApi;

export { submissionApi };
