import { Box, Container, Stack } from '@nib/layout';
import { FieldArray, Formik } from 'formik';
import { clone } from 'ramda';
import ApplicantForm from 'src/components/AboutYou/ApplicantForm';
import TitlePanel from 'src/components/heading/TitlePanel';
import BottomButtons from 'src/components/navigation/BottomButtons';
import { HelmetContent } from 'src/types/Content/HelmetContent';
import { FormPage } from 'src/types/FormPage';
import { FormPageProps } from 'src/types/FormPageProps';
import { ManageApplicantSchema } from 'src/utils/validation/schema/ManageApplicantSchema';
import CustomisedForm from '../form/CustomisedForm';
import HelmetComponent from '../HelmetComponent/HelmetComponent';

export const ManageApplicantForm = (
  props: FormPageProps & {
    title: string;
    applicantIndex: number; // Which applicant from the list we are managing
    returnTo: FormPage; // The FormPage index we should return to once the process has completed
    cancelButtonText: string;
    submitButtonText: string;
    helmetContent: HelmetContent;
  }
) => {
  const {
    title,
    applicantIndex,
    returnTo,
    cancelButtonText,
    submitButtonText,
    helmetContent,
    onSubmit,
  } = props;

  const quoteSession = clone(props.quoteSession);

  return (
    <Box>
      <HelmetComponent content={helmetContent} />
      <TitlePanel title={title} showSpecialOffer={false} />
      <Formik
        initialValues={{
          applicantDetails: quoteSession.applicantDetails,
          hasMember: quoteSession.hasMember,
        }}
        onSubmit={(formData) => {
          // If we have any applicants between 75 and 120, and have 'Hospital' cover,
          // we need to go to 'Choose cover' mini-screen instead of back to our original step.
          // We store the form data in the quote session, and FormRoute detects that we need
          // to pick cover for them and forces us to that screen.
          const mustTailorQuote = quoteSession.mustTailorQuote(
            formData.applicantDetails[applicantIndex]
          );
          quoteSession.updateSingleApplicantDetails(
            formData.applicantDetails[applicantIndex],
            // If we must tailor the quote, do not use the default cover.
            // Uses empty cover.
            !mustTailorQuote
          );
          onSubmit(returnTo, quoteSession);
        }}
        validationSchema={ManageApplicantSchema}
      >
        {({ handleReset, handleSubmit }) => {
          // We are editing the last applicant.
          return (
            <CustomisedForm
              id="addApplicant"
              name="addApplicant"
              formMode="light"
              containerWidth="100%"
              spaceChildren={false}
              onReset={handleReset}
              onSubmit={handleSubmit}
            >
              <Container>
                <Stack space={5}>
                  <FieldArray name="applicantDetails">
                    {() => (
                      <ApplicantForm
                        fieldNamePrefix={`applicantDetails.${applicantIndex}`}
                        variation={'other'}
                      />
                    )}
                  </FieldArray>
                  <BottomButtons
                    cancelButtonText={cancelButtonText}
                    onCancel={() => onSubmit(returnTo)}
                    submitButtonText={submitButtonText}
                  />
                </Stack>
              </Container>
            </CustomisedForm>
          );
        }}
      </Formik>
    </Box>
  );
};

export default ManageApplicantForm;
