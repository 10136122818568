/**
 * @file Collection of helper functions for working with tabs.
 */
import { clone } from 'ramda';
import { FormPage, FormPageInfo } from 'src/types/FormPage';
import {
  PaymentFrequency,
  PaymentMethod,
  QuoteSession,
} from 'src/types/QuoteSession';
import config from './env';
import { AboutYouSchema } from './validation/schema/AboutYouSchema';
import ChooseYourCoverSchema from './validation/schema/ChooseYourCoverSchema';

// These values aren't valid when Credit Card is selected for paymentMethod
export const CREDIT_CARD_INVALID_PAYMENT_FREQUENCIES = [
  PaymentFrequency.Weekly,
  PaymentFrequency.Fortnightly,
];

export function getFormPageByIndex(index: number): FormPageInfo | undefined {
  return config.brand.content.formPages.find(
    (formPage) => formPage.index === index
  );
}

export function getFormPageByPath(path: string): FormPageInfo | undefined {
  return config.brand.content.formPages.find(
    (formPage) => formPage.path === path
  );
}

/** Get options for PaymentFrequency select boxes */
export function getPaymentFrequencyOptions(
  paymentMethod: PaymentMethod | undefined
): Record<string, PaymentFrequency> {
  // Convert from Entries to an object with the same string for key and value
  return Object.fromEntries(
    Object.values(PaymentFrequency)
      // Filter out invalid values if we're paying by Credit Card.
      // Note this includes all options if we havent' chosen a payment method yet.
      .filter(
        (f) =>
          paymentMethod !== PaymentMethod.CreditCard ||
          !CREDIT_CARD_INVALID_PAYMENT_FREQUENCIES.includes(f)
      )
      // Transform into a set of Entries
      .map((f) => [f, f])
  );
}

/** Returns the furthest page the user is allowed to visit */
export const findFurthestValidPage = (
  requestedFormPage: FormPage,
  quoteSession: QuoteSession,
  hasApplicantId: boolean = false
): FormPage => {
  const furthestCompletedIndex = quoteSession.completedPages.reduce(
    (acc, cur) => (cur > acc ? cur : acc),
    -1
  );
  const furthestVisitedIndex = getFormPageByIndex(
    Math.min(furthestCompletedIndex + 1, FormPage.BuyNow)
  )!.index;
  const hasTailoredCover = quoteSession.hasTailoredCover();

  switch (requestedFormPage) {
    case FormPage.AboutYou:
      // If we have tailored our quote, we can't visit this page.
      // Skip to the furthest page the user has visited.
      if (hasTailoredCover) {
        return findFurthestValidPage(
          furthestVisitedIndex,
          quoteSession,
          hasApplicantId
        );
      }
      // We can always go to this page
      return FormPage.AboutYou;
    case FormPage.ChooseYourCover:
      // If we have tailored our quote, we can't visit this page.
      // Skip to the furthest page the user has visited.
      if (hasTailoredCover) {
        return findFurthestValidPage(
          furthestVisitedIndex,
          quoteSession,
          hasApplicantId
        );
      }
      // The About You page must validate to show the Choose Your Cover page
      try {
        AboutYouSchema.validateSync(quoteSession);
        return FormPage.ChooseYourCover;
      } catch (e) {
        return findFurthestValidPage(
          FormPage.AboutYou,
          quoteSession,
          hasApplicantId
        );
      }
    case FormPage.TailorYourQuote:
    case FormPage.BuyNow:
      // AboutYou and ChooseYourCover pages must validate to show TailorYourQuote or BuyNow
      try {
        AboutYouSchema.validateSync(quoteSession);
        // We only need to validate to see if we're allowed on this page if we're not editing an individual
        // applicant.  If we're editing an individual applicant, URL checks in the rendered page will
        // detect if we're allowed to be there and deal with it if not.
        if (!hasApplicantId) {
          const validationSession = new QuoteSession();
          validationSession.policyDetails = clone(
            quoteSession.memberPolicyDetails[
              quoteSession.applicantDetails[0].id
            ]
          );
          ChooseYourCoverSchema.validateSync(validationSession);
        }
        return FormPage.BuyNow;
      } catch (e) {
        return findFurthestValidPage(
          FormPage.ChooseYourCover,
          quoteSession,
          hasApplicantId
        );
      }
    default:
      // If we asked about something out of bounds, we're doing this wrong.
      throw new Error(
        `FormRoute only works with AboutYou, ChooseYourCover, TailorQuote and BuyNow (used ${requestedFormPage})`
      );
  }
};
