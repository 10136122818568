import {
  ContentfulAccordionItemProps,
  ContentfulAccordionPanelProps,
  ContentfulNodeProps,
  ContentfulRichTextPanelProps,
  ContentfulSimpleTableProps,
  ContentfulTextLinkProps,
} from './types';

const contentfulNodeTypeGuard = (
  type: string,
  props: ContentfulNodeProps
): boolean => {
  return props.sys.contentType.sys.id === type;
};

export const isRichTextPanel = (
  props: ContentfulNodeProps
): props is ContentfulRichTextPanelProps =>
  contentfulNodeTypeGuard('richTextPanel', props);

export const isTextLink = (
  props: ContentfulNodeProps
): props is ContentfulTextLinkProps =>
  contentfulNodeTypeGuard('textLink', props);

export const isAccordionPanel = (
  props: ContentfulNodeProps
): props is ContentfulAccordionPanelProps =>
  contentfulNodeTypeGuard('accordionPanel', props);

export const isAccordionItem = (
  props: ContentfulNodeProps
): props is ContentfulAccordionItemProps =>
  contentfulNodeTypeGuard('accordionItem', props);

export const isSimpleTable = (
  props: ContentfulNodeProps
): props is ContentfulSimpleTableProps =>
  contentfulNodeTypeGuard('simpleTable', props);
