import { CardSystemIcon } from '@nib/icons';
import InfoBox from '@nib/info-box';
import { Box, Stack } from '@nib/layout';
import { format } from 'date-fns';
import { useQuoteSession } from 'src/contexts/QuoteSessionProvider';
import { FormPage } from 'src/types/FormPage';
import {
  PaymentDetails,
  PaymentFrequency,
  QuoteSession,
} from 'src/types/QuoteSession';
import config from 'src/utils/env';
import BrandedStyleHeader from '../heading/BrandedStyleHeader';
import MarkdownContent from '../MarkdownContent';
import BottomButtons from '../navigation/BottomButtons';
import AdviserDeclaration from './AdviserDeclaration';
import DoubleDeductionWarning from './DoubleDeductionWarning';
import FirstPaymentDateField from './FirstPaymentDateField';
import FirstPaymentDateWarning from './FirstPaymentDateWarning';
import PaymentFrequencyField from './PaymentFrequencyField';

const content = config.brand.content.buyNow.step4.creditCard;

interface CreditCardFormProps {
  // The local form values. Can be changed without affecting the top-level Quote Session
  // stored in local storage.
  paymentDetails: PaymentDetails;
  // Payment details affect the top header, so we need to be able to push these values into
  // the top-level Quote Session immediately instead of into the local form state.
  onSubmit: (nextPageId: FormPage | null, quoteSession?: QuoteSession) => void;
  canSubmit: boolean;
}

const CreditCardForm = (props: CreditCardFormProps) => {
  const { paymentDetails, canSubmit, onSubmit } = props;
  const quoteSession = useQuoteSession();

  // Replace the date into the 'What to expect' info
  const whatToExpectInfo = content.whatToExpectInfo.replace(
    '{date}',
    format(new Date(), 'do MMM yyyy')
  );

  return (
    <Box maxWidth="600px" data-testid="credit-card-form">
      <Stack space={5}>
        <PaymentFrequencyField
          label={content.paymentFrequencyLabel}
          name="paymentDetails.frequency"
          onSubmit={onSubmit}
        />
        <BrandedStyleHeader>
          <InfoBox backgroundColor="white" title={content.whatToExpectTitle}>
            <MarkdownContent content={whatToExpectInfo} />
          </InfoBox>
        </BrandedStyleHeader>
        <FirstPaymentDateField
          label={content.firstPaymentDateLabel}
          help={content.firstPaymentDateHelp}
          name="paymentDetails.firstPaymentDate"
          formMode="light"
          paymentFrequency={quoteSession.paymentDetails.frequency}
        />
        <DoubleDeductionWarning
          firstPaymentDate={paymentDetails.firstPaymentDate}
          paymentFrequency={paymentDetails.frequency}
          doubleDeductionAvoidableInfo={
            config.brand.content.buyNow.step4.doubleDeductionAvoidableInfo
          }
          doubleDeductionUnavoidableInfo={
            config.brand.content.buyNow.step4.doubleDeductionUnavoidableInfo
          }
        />
        <Box>
          {[
            PaymentFrequency.Quarterly,
            PaymentFrequency.HalfYearly,
            PaymentFrequency.Yearly,
          ].includes(paymentDetails.frequency) && <FirstPaymentDateWarning />}
        </Box>
        {config.brand.hasAdviser && (
          <AdviserDeclaration
            prefix="creditCardAdviserDeclaration"
            content={content.adviserDeclaration!}
          />
        )}
        <MarkdownContent content={content.nextButtonInfo} />
        <BottomButtons
          submitButtonText={content.nextButtonText}
          submitButtonIcon={CardSystemIcon}
          disabled={!canSubmit}
          isLoading={!canSubmit}
        />
      </Stack>
    </Box>
  );
};

export default CreditCardForm;
