import Accordion from '@nib-components/accordion';
import Copy, { Bold } from '@nib-components/copy';
import { Box, Stack } from '@nib/layout';
import { CoverBenefit, CoverBenefitLevelType } from 'src/types/Benefit';
import config from 'src/utils/env';
import styled from 'styled-components';
import {
  EnhancedCoverIcon,
  NoneCoverIcon,
  StandardCoverIcon,
} from './BenefitIcons';

const CoverBenefitItem = styled(Accordion.Item)<{ isPointsOnly: boolean }>`
& h6 {
  font-size: 1rem;
  font-weight: normal;
  text-align: left;
  color: ${config.brand.customisation?.headersColor ?? 'inherit'};
  };
}
& div[role='region'] {
  text-align: left;
  margin-left: ${(props) => (props.isPointsOnly ? '0rem;' : '1.75rem;')}
  padding: 0 1rem 1rem 1rem;
}
`;

interface CoverBenefitsListProps {
  benefits: CoverBenefit[];
  // Allows sharing control between benefit lists on different cards
  expandedIndex: number | undefined;
  setExpandedIndex: (expandedIndex: number | undefined) => void;
}

const getIconForBenefit = (coverLevel: CoverBenefitLevelType) => {
  switch (coverLevel) {
    case 'None':
      return NoneCoverIcon;
    case 'Standard':
      return StandardCoverIcon;
    case 'Enhanced':
      return EnhancedCoverIcon;
  }
};

/**
 * Displays a list of benefits with icons, expandable to display details for each one.
 */
const CoverBenefitsList = (props: CoverBenefitsListProps) => {
  const { benefits, expandedIndex, setExpandedIndex } = props;
  return (
    <Accordion
      borderTop={false}
      borderBottom
      multiExpanded={false}
      expandedIndex={expandedIndex}
    >
      {benefits.map((benefit, index) => (
        // If the benefits details is made up of points only we remove the margin left.
        <CoverBenefitItem
          isPointsOnly={benefit.contents.every(
            (benefitDetail) =>
              benefitDetail.points &&
              benefitDetail.points.length > 0 &&
              !benefitDetail.title &&
              !benefitDetail.text
          )}
          key={`benefit=${index}`}
          title={benefit.title}
          titleComponent="h6"
          icon={getIconForBenefit(benefit.coverLevel)}
          contentPadding={0}
          onExpand={() => setExpandedIndex(index)}
          onBeforeCollapse={() => {
            if (expandedIndex === undefined || index !== expandedIndex) {
              return;
            }
            setExpandedIndex(undefined);
          }}
        >
          {index === expandedIndex && ( // not needed for display purposes, but seems to speed page rendering up
            <Stack space={4}>
              {benefit.contents.map((benefitDetail, j) => (
                <Box key={`benefit-detail-${index}-${j}`}>
                  {benefitDetail.title && (
                    <Copy>
                      <Bold>{benefitDetail.title}</Bold>
                    </Copy>
                  )}
                  {benefitDetail.text &&
                    (benefitDetail.textPrefix ? (
                      <Box display="flex" flexDirection="row">
                        <Box flex="1" marginRight={2}>
                          <Copy>
                            <Bold>{benefitDetail.textPrefix}</Bold>
                          </Copy>
                        </Box>
                        <Box flex="1">
                          <Copy>{benefitDetail.text}</Copy>
                        </Box>
                      </Box>
                    ) : (
                      <Copy>{benefitDetail.text}</Copy>
                    ))}
                  {benefitDetail.points && benefitDetail.points.length > 0 && (
                    <ul>
                      {benefitDetail.points.map((point) => (
                        <li>
                          <Copy>{point}</Copy>
                        </li>
                      ))}
                    </ul>
                  )}
                </Box>
              ))}
            </Stack>
          )}
        </CoverBenefitItem>
      ))}
    </Accordion>
  );
};

export default CoverBenefitsList;
