import { SecondaryButton, TertiaryButton } from '@nib-components/button';
import Copy from '@nib-components/copy';
import Divider from '@nib-components/divider';
import Heading from '@nib-components/heading';
import Card from '@nib/card';
import { DeleteSystemIcon, EditSystemIcon, TickSystemIcon } from '@nib/icons';
import { Box, Column, Columns, Hidden, Inline, Stack } from '@nib/layout';
import Loader from '@nib/loader';
import { ProductConfig } from 'src/types/ProductConfig';
import { PaymentFrequency } from 'src/types/QuoteSession';
import config from 'src/utils/env';
import { formatCurrency } from 'src/utils/formatters/formatCurrency';
import { getProductByCode } from 'src/utils/productUtils';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import BrandedCard from '../BrandedCard';

const content = config.brand.content.quoteSummary;

// Allow behaviour like nested columns, using DL/DD/DT elements
const QuoteSummaryCardDetails = styled.dl`
  display: flex;
  flex-flow: column;
  margin: 0;
  ${breakpoint('md')`
    flex-flow: row wrap;
  `}
  &.noCollapse {
    flex-flow: row wrap;
  }
`;
const QuoteSummaryCardDetailTerm = styled.dt`
  flex-basis: 50%;
  margin: 0 0 0.25rem 0;
  font-weight: bold;
  color: ${config.brand.customisation?.headersColor ?? 'inherit'};
`;
const QuoteSummaryCardDetailValue = styled.dd`
  flex-basis: calc(50% - 2rem);
  flex-grow: 1;
  margin: 0 0 0.25rem 0;
  ${breakpoint('md')`
    margin-left: 1.5rem;
  `}
`;

// Set custom widths on Column elements to avoid
// the two buttons in the top row from breaking into a vertical stack
const Column1 = styled(Column)`
  // Must be 33.3333% so the second columns line up
  flex-basis: 33.3333%;
`;
const Column2 = styled(Column)`
  flex-basis: 20%;
  ${breakpoint('lg')`
    flex-basis: 15%;
  `}
`;
const Column3 = styled(Column)`
  flex-basis: 50%;
  ${breakpoint('lg')`
    flex-basis: 51.6667%;
  `}
`;

// Make the "Edit" buttons a fixed width, hide icons and
// go full width for mobile
const FixedWidthSecondaryButton = styled(SecondaryButton)`
  width: 100%;
  & svg {
    display: none;
  }
  ${breakpoint('md')`
    width: 150px;
    & svg {
      display: block;
    }
  `}
`;

// Position the "Delete" button in mobile view; also removes
// leftover margin from the CardHeader we're not using
const MobileDeleteButtonContainer = styled.div`
  position: relative;
  margin-top: -1rem;
  margin-bottom: -0.25rem;
`;
const MobileDeleteButtonPositioner = styled.div`
  position: absolute;
  right: -20px;
  top: -18px;
`;

const SummaryCardHeader = ({
  name,
  price,
  frequency,
  productCodes,
}: {
  name: string;
  price: number;
  frequency: PaymentFrequency;
  productCodes: string[];
}) => {
  if (productCodes.length === 0) {
    return (
      <Heading
        component="h3"
        size={{ xs: 4, md: 3 }}
        color={config.brand.customisation?.headersColor}
        data-testid="summary-card-header"
      >
        {name}, {content.summaryCard.noCoverText}
      </Heading>
    );
  } else {
    return (
      <Heading
        component="h3"
        size={{ xs: 4, md: 3 }}
        color={config.brand.customisation?.headersColor}
        data-testid="summary-card-header"
      >
        {name}, {price ? formatCurrency(price) : <Loader />} {frequency}
      </Heading>
    );
  }
};

interface QuoteSummaryCardProps {
  name: string;
  price: number;
  frequency: PaymentFrequency;
  age: string;
  gender: string;
  smoker: boolean;
  productCodes: string[];
  excess?: number;
  nonPharmacPlus?: number;
  hasSpecialOffer: boolean;
  canDelete: boolean;
  onDelete: () => void;
  onEditDetails: () => void;
  onEditCover: () => void;
}

const QuoteSummaryCard = (props: QuoteSummaryCardProps) => {
  const {
    name,
    price,
    frequency,
    age,
    gender,
    smoker,
    productCodes,
    excess,
    nonPharmacPlus,
    hasSpecialOffer,
    canDelete,
    onDelete,
    onEditDetails,
    onEditCover,
  } = props;

  // Find the selected products string
  const selectedProducts: ProductConfig[] = productCodes
    .map(getProductByCode)
    .filter(isProductConfig);
  const productString = selectedProducts
    .map((p) => p.productDetails.name)
    .join(' + ');

  // Prepare the excess and non-PHARMAC text components
  const excessDescription =
    excess === undefined
      ? ''
      : content.summaryCard.excessText.replace(
          '{amount}',
          formatCurrency(excess, false)
        );
  const nppDescription =
    nonPharmacPlus === undefined
      ? ''
      : content.summaryCard.nonPharmacPlusText.replace(
          '{amount}',
          formatCurrency(nonPharmacPlus, false)
        );

  return (
    <BrandedCard data-testid="quote-summary-card">
      <Card.Content title="">
        <MobileDeleteButtonContainer>
          <Stack space={3}>
            <Columns collapseBelow="md" space={{ xs: 1, md: 4 }}>
              <Column1>
                <Box
                  paddingTop={{ xs: 0, md: 1 }}
                  paddingRight={{ xs: canDelete ? 6 : 0, md: 0 }}
                >
                  <SummaryCardHeader
                    name={name}
                    price={price}
                    frequency={frequency}
                    productCodes={productCodes}
                  />
                </Box>
                {canDelete && (
                  <MobileDeleteButtonPositioner>
                    <Hidden above="md">
                      <TertiaryButton type="button" onClick={onDelete}>
                        <DeleteSystemIcon size="sm" />
                      </TertiaryButton>
                    </Hidden>
                  </MobileDeleteButtonPositioner>
                )}
              </Column1>
              <Column2>
                <Box
                  marginLeft={{ xs: 0, md: 4 }}
                  paddingTop={{ xs: 0, md: 1 }}
                >
                  <Copy data-testid="applicant-summary">
                    {age}, {gender}
                    {smoker ? `, ${content.summaryCard.smokerText}` : ''}
                  </Copy>
                </Box>
              </Column2>
              <Column3>
                <Inline
                  align="right"
                  collapseBelow="md"
                  space={{ xs: 1, md: 4 }}
                >
                  {canDelete && (
                    <Hidden below="md">
                      <TertiaryButton
                        type="button"
                        onClick={onDelete}
                        size="small"
                        data-testid="remove-this-person-button"
                      >
                        <Hidden below="lg">
                          <u>{content.removeButton}</u>
                        </Hidden>
                        <Hidden above="lg">
                          <DeleteSystemIcon size="xs" />
                        </Hidden>
                      </TertiaryButton>
                    </Hidden>
                  )}
                  <FixedWidthSecondaryButton
                    data-testid="edit-details-button"
                    type="button"
                    onClick={onEditDetails}
                    icon={EditSystemIcon}
                    iconPlacement="left"
                    size="small"
                  >
                    {content.editDetailsButton}
                  </FixedWidthSecondaryButton>
                </Inline>
              </Column3>
            </Columns>
            <Divider />
            <Columns collapseBelow="md" space={{ xs: 1, md: 4 }}>
              <Column width={'2/3'}>
                <Box
                  paddingTop={
                    nonPharmacPlus !== undefined && nonPharmacPlus > 0 ? 0 : 2
                  }
                >
                  <QuoteSummaryCardDetails>
                    <QuoteSummaryCardDetailTerm data-testid="product-description">
                      {productString}
                    </QuoteSummaryCardDetailTerm>
                    <QuoteSummaryCardDetailValue data-testid="excess-description">
                      {excessDescription}
                    </QuoteSummaryCardDetailValue>
                    {nonPharmacPlus !== undefined && nonPharmacPlus > 0 && (
                      <>
                        <QuoteSummaryCardDetailTerm data-testid="npp-description">
                          {content.nonParmacPlusLabel}
                        </QuoteSummaryCardDetailTerm>
                        <QuoteSummaryCardDetailValue data-testid="npp-value">
                          {nppDescription}
                        </QuoteSummaryCardDetailValue>
                      </>
                    )}
                  </QuoteSummaryCardDetails>
                </Box>
              </Column>
              <Column width={'1/3'}>
                <Box align="right">
                  <FixedWidthSecondaryButton
                    type="button"
                    onClick={onEditCover}
                    icon={EditSystemIcon}
                    iconPlacement="left"
                    size="small"
                    data-testid="edit-cover-button"
                  >
                    {content.editCoverButton}
                  </FixedWidthSecondaryButton>
                </Box>
              </Column>
            </Columns>
            {hasSpecialOffer && <Divider />}
            {hasSpecialOffer && (
              <Columns collapseBelow="md">
                <Column width={'2/3'}>
                  <QuoteSummaryCardDetails
                    className="noCollapse"
                    data-testid="special-offer"
                  >
                    <QuoteSummaryCardDetailTerm>
                      {content.specialOfferLabel}
                    </QuoteSummaryCardDetailTerm>
                    <QuoteSummaryCardDetailValue>
                      <Box display="inline" marginRight={3}>
                        <TickSystemIcon size="xs" />
                      </Box>
                      {content.specialOfferApplied}
                    </QuoteSummaryCardDetailValue>
                  </QuoteSummaryCardDetails>
                </Column>
              </Columns>
            )}
          </Stack>
        </MobileDeleteButtonContainer>
      </Card.Content>
    </BrandedCard>
  );
};

export default QuoteSummaryCard;

// Type guard to let us convert (ProductConfig | undefined)[] => ProductConfig[]
const isProductConfig = (
  item: ProductConfig | undefined
): item is ProductConfig => {
  return item !== undefined;
};
