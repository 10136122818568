import { formatDistanceToNowStrict } from 'date-fns';

export interface TimeGap {
  daysFromNow: number;
  hoursFromNow: number;
}

class TimeUtils {
  public calculateTimeGap(endDate: Date): TimeGap {
    let hoursFromNow = formatDistanceToNowStrict(new Date(endDate), {
      unit: 'hour',
    });
    let daysFromNow = formatDistanceToNowStrict(new Date(endDate), {
      unit: 'day',
      roundingMethod: 'floor',
    });

    let hours = parseInt(hoursFromNow) - parseInt(daysFromNow) * 24;

    return { daysFromNow: parseInt(daysFromNow), hoursFromNow: hours };
  }
}

const timeUtils = new TimeUtils();
export default timeUtils;
