import { Box } from '@nib/layout';
import { useFormikContext } from 'formik';
import { path } from 'ramda';
import FormRadioGroup, { FormRadioGroupProps } from '../form/FormRadioGroup';
import SmokerInfoIcon from './SmokerInfoIcon';

// Convert applicants.n.smoker => applicants.n.age
function getAgePath(smokerName: string): string[] {
  const agePath = [...smokerName.split('.')];
  agePath[agePath.length - 1] = 'age';
  return agePath;
}

type SmokerFormFieldProps = FormRadioGroupProps & {
  tooltipContents: string;
};

/**
 * A radio group that automatically enables/disables itself based on the value of
 * the 'age' field in the same applicant group
 */
const SmokerFormField = ({
  disabled,
  label,
  tooltipContents,
  ...props
}: SmokerFormFieldProps) => {
  const { values } = useFormikContext();

  const agePath = getAgePath(props.name);
  const ageValue: number = parseInt(path(agePath, values) ?? '');

  return (
    <FormRadioGroup
      // Note: this throws a console warning, but works correctly.
      // The label prop expects a string here, but JSX works.
      label={
        (
          <>
            <Box display="inline" marginRight={2}>
              {label}
            </Box>
            <Box display="inline">
              <SmokerInfoIcon
                id={`${props.name}-SmokerInfoIcon`}
                tooltipContents={tooltipContents}
              />
            </Box>
          </>
        ) as unknown as string
      } // HACK: mesh accepts this but complains on console
      {...props}
      disabled={disabled || (!isNaN(ageValue) && ageValue < 18)}
    />
  );
};
export default SmokerFormField;
