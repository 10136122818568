import { TertiaryButton } from '@nib-components/button';
import Copy from '@nib-components/copy';
import Heading from '@nib-components/heading';
import Select from '@nib-components/select';
import { colorDarker } from '@nib-components/theme';
import { AddSystemIcon } from '@nib/icons';
import { Box, Column, Columns, Container, Hidden } from '@nib/layout';
import Loader from '@nib/loader';
import React from 'react';
import { PriceContext } from 'src/contexts/PriceContext';
import priceApiUtils from 'src/services/price/priceApiUtils';
import {
  PaymentFrequency,
  PaymentMethod,
  QuoteSession,
} from 'src/types/QuoteSession';
import config from 'src/utils/env';
import { formatCurrency } from 'src/utils/formatters/formatCurrency';
import { getPaymentFrequencyOptions } from 'src/utils/formPageUtils';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

const content = config.brand.content.header;

const SmallSelect = styled(Select)`
  padding: 0.5rem 3.5rem 0.5rem 1rem;
`;

const TruncatedCopy = styled(Copy)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const VerticalDividerBox = styled('div')`
  text-align: left;
  border-right: none;
  padding-right: 0;
  ${breakpoint('md')`
    text-align: right;
    border-right: 1px solid ${colorDarker};
    padding-right: 0.5rem;
  `}
`;

// An invisible box allows us to turn things on and off while
// allowing them to take up the same amount of space in the layout.
const InvisibleBox = styled('span')<{ invisible: boolean }>`
  visibility: ${(props) => (props.invisible ? 'hidden' : 'visible')};
`;

export interface FormHeadingPanelProps {
  /** An array of names to display */
  names: string[];
  /** Event handler for when the user clicks the 'Add another person' button */
  onAdd?: () => void;
  /** Value of the frequency select box */
  frequency: PaymentFrequency;
  /** Event handler for when users change the payment frequency box */
  setFrequency: (f: PaymentFrequency) => void;
  quoteSession: QuoteSession;
}

const FormHeadingPanel = (props: FormHeadingPanelProps) => {
  const { names, onAdd, frequency, setFrequency, quoteSession } = props;
  const { isFetching: isFetchingPrices, data: priceData } =
    React.useContext(PriceContext);
  const price = priceApiUtils.getPolicyTotalPriceForQuoteSession(
    priceData,
    quoteSession
  );

  const showDirectDebitDiscountDisclaimer =
    config.brand.hasDirectDebitDiscount &&
    quoteSession?.paymentDetails.paymentMethod !== PaymentMethod.CreditCard; // null also shows the discount message
  const showMemberDiscountDisclaimer =
    config.brand.hasMemberDiscount && quoteSession?.hasMember;

  const addAPersonButton = () => {
    return (
      <Column width="1/3" hiddenBelow="md">
        <InvisibleBox invisible={!onAdd}>
          <VerticalDividerBox>
            <TertiaryButton
              size="small"
              icon={AddSystemIcon}
              iconPlacement="left"
              onClick={onAdd}
              color="dark"
              disabled={names.length >= 26}
            >
              <Hidden below="md" above="xl">
                Add person
              </Hidden>
              <Hidden below="xl">Add another person</Hidden>
            </TertiaryButton>
          </VerticalDividerBox>
        </InvisibleBox>
      </Column>
    );
  };

  return (
    <Box padding={{ xs: 1, md: 2, xl: 3 }} background="sageGreen60">
      <Container>
        <Columns collapseBelow="xs" space={2}>
          <Column width="1/4" hiddenBelow="lg">
            <Hidden below="sm">
              <Box paddingTop={2} marginLeft={{ xs: 3, md: 0 }}>
                <TruncatedCopy>
                  Cover for:{' '}
                  <Heading
                    component="span"
                    size={6}
                    color={config.brand.customisation?.headersColor}
                  >
                    {names
                      .map((n) => n.trim())
                      .map((n, i) => (n !== '' ? n : `Person ${i + 1}`))
                      .join(', ')}
                  </Heading>
                </TruncatedCopy>
              </Box>
            </Hidden>
          </Column>
          <Column width="3/4">
            <Box
              maxWidth="100%"
              display="flex"
              justifyContent={{
                md: 'flex-start',
                lg: 'flex-end',
              }}
            >
              <Columns space={1} align={'left'}>
                {addAPersonButton()}
                <Column width="2/3">
                  <Columns space={1}>
                    <Column width={{ xs: 'content', md: 'content', xl: '1/3' }}>
                      <Box
                        paddingVertical={{ xs: 2, md: 0 }}
                        paddingHorizontal={{ xs: 1, md: 0 }}
                        display="flex"
                        justifyContent={{ lg: 'flex-start', xxl: 'flex-end' }}
                        marginRight={{ xs: 1, md: 2 }}
                      >
                        <Heading
                          component="p"
                          size={2}
                          fontType="serif"
                          data-testid="FormHeadingPanel-Price"
                          align={{ lg: 'left', xl: 'right' }}
                          color={config.brand.customisation?.headersColor}
                        >
                          {isFetchingPrices &&
                          quoteSession &&
                          priceApiUtils.hasAnyCoverSelected(quoteSession) ? (
                            <Loader />
                          ) : (
                            formatCurrency(price)
                          )}
                        </Heading>
                      </Box>
                    </Column>
                    <Column width={{ xs: 'content', md: 'content', lg: '1/3' }}>
                      <Box
                        textAlign="left"
                        width="100%"
                        color="trueGreen"
                        paddingTop={{ xs: 1, md: 0 }}
                      >
                        <Hidden above="lg">
                          <Copy small={true}>{frequency.toLowerCase()}</Copy>
                        </Hidden>
                        <InvisibleBox
                          invisible={
                            !showDirectDebitDiscountDisclaimer &&
                            !showMemberDiscountDisclaimer
                          }
                        >
                          <Copy small={true}>{content.headerDiscountText}</Copy>
                        </InvisibleBox>
                      </Box>
                    </Column>

                    <Column hiddenBelow="lg" width="1/3">
                      <Box maxWidth="200px">
                        <SmallSelect
                          options={getPaymentFrequencyOptions(
                            quoteSession?.paymentDetails.paymentMethod
                          )}
                          onChange={(
                            e: React.ChangeEvent<HTMLSelectElement>
                          ) => {
                            setFrequency(e.target.value as PaymentFrequency);
                          }}
                          value={frequency}
                        />
                      </Box>
                    </Column>
                  </Columns>
                </Column>
              </Columns>
            </Box>
          </Column>
        </Columns>
      </Container>
    </Box>
  );
};

export default FormHeadingPanel;
