import {
  formLightBackgroundColor,
  formWhiteBackgroundColor,
  PropsWithTheme,
} from '@nib-components/theme';
import config from 'src/utils/env';
import styled, { createGlobalStyle } from 'styled-components';
import { FormModes } from './form/CustomisedForm';

type PageBackgroundProps = React.PropsWithChildren<{
  formMode: FormModes;
}>;

// Container sets the background colour of the container.
// Needed in addition to setting the page background colour
// for when the container appears over other content, eg.
// 'Edit Cover' header.
const BackgroundContainer = styled('div')<{
  backgroundColor: (props: PropsWithTheme) => string;
}>`
  background-color: ${({ backgroundColor, ...props }) =>
    backgroundColor(props as unknown as PropsWithTheme)};
`;

// Sets the page background colour, so that short pages like
// eg. Add Applicant have a uniform colour
const GlobalStyle = createGlobalStyle<{
  backgroundColor: (props: PropsWithTheme) => string;
}>`
  body {
    background-color: ${({ backgroundColor, ...props }) =>
      backgroundColor(props as unknown as PropsWithTheme)};
  }
`;

/**
 * A div that sets configured background color.
 * Can be either "white" or "light", matching the form colors either from the theme,
 * or overridden in config.
 */
const PageBackground = (props: PageBackgroundProps) => {
  const { children, formMode } = props;

  // Override form background colours according to (optional) configuration.
  // Fall back to the colours from the theme if no customisation is set.
  const backgroundColor =
    formMode === 'white'
      ? config.brand.customisation?.pageBackgroundColor?.white ??
        formWhiteBackgroundColor
      : config.brand.customisation?.pageBackgroundColor?.light ??
        formLightBackgroundColor;

  return (
    <BackgroundContainer backgroundColor={backgroundColor}>
      <GlobalStyle backgroundColor={backgroundColor} />
      {children}
    </BackgroundContainer>
  );
};

export default PageBackground;
