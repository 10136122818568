import {
  Table,
  TableBody,
  TableData,
  TableHead,
  TableHeading,
  TableHeadRow,
  TableRow,
} from '@nib/table';
import { ContentfulSimpleTableProps } from '../types';

const SimpleTable = (props: ContentfulSimpleTableProps) => {
  const {
    table: { data, columns },
    alternateRows,
    columnDisplay,
  } = props.fields;

  // Striped rows?
  const stripedRows: boolean | undefined =
    typeof alternateRows === 'boolean' ? alternateRows : undefined;
  const equalColumns: boolean = columnDisplay === 'Even' ? true : false;

  // Has heading row?
  const hasHeading = columns.some((col) => !!col.title);

  return (
    <Table equalColumns={equalColumns}>
      {hasHeading && (
        <TableHead>
          <TableHeadRow rowHeight="relaxed">
            {columns.map((col) => (
              <TableHeading key={col.title}>{col.title}</TableHeading>
            ))}
          </TableHeadRow>
        </TableHead>
      )}
      <TableBody stripedRows={stripedRows}>
        {data.map((row, i) => (
          <TableRow key={`table-row-${i}`} rowHeight="relaxed">
            {columns.map((_col, j) => (
              <TableData key={`table-cell-${i}-${j}`}>{row[j]}</TableData>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default SimpleTable;
