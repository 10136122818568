import type { Middleware, MiddlewareAPI } from '@reduxjs/toolkit';
import { isRejectedWithValue } from '@reduxjs/toolkit';

/**
 * Log a warning
 */
export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
      console.warn(
        `Redux action rejected.  endpoint: ${action.meta?.arg?.endpointName} response data: `,
        action.payload?.data && JSON.stringify(action.payload.data, null, 2)
      );
    }

    return next(action);
  };
